import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Text } from '~/domains/platform/design-system'

export const WrapperValue = styled(Text)`
  font-size: ${theme('fontSizes.large')};
  font-weight: ${theme('fontWeights.bold')};
  margin-top: 0.75rem;
`
