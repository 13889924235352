import { Stack } from '@dlpco/fluid-layout'
import { Button, Radio } from '@dlpco/ginga-stone'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { FieldSet } from '~/domains/platform/design-system/fieldset/fieldset'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'
import { type Consent } from '~/lib/types'
import { useWizardProps } from '~/ui/components/modals/wizard/use-wizard-props'

import { HasConsent } from '../has-consent'

export function ConsentChooseAccount(props: {
  consent?: Consent[]
  selection?: Consent
  name?: string
  setSelection: (value: Consent) => void
}) {
  const { consent, selection, name, setSelection } = props
  const {
    handleStep: { next }
  } = useWizardProps()

  return (
    <Choose>
      <Choose.When condition={Boolean(consent && consent?.length > 0)}>
        <Stack space="1.8rem">
          <Stack space="2.5rem">
            <Text fontSize="xLarge" fontWeight="medium">
              {name} deseja ter acesso à sua conta Stone
            </Text>
            <Text color="darkGray2" fontSize="medium" fontWeight="bold">
              Escolha a conta para dar acesso ao aplicativo:
            </Text>
          </Stack>
          <Stack space="0.5rem">
            <If condition={Boolean(consent)}>
              <For
                of={consent as Consent[]}
                render={(consent: Consent) => (
                  <Box mt={24} key={consent.ownerId}>
                    <FieldSet active={consent.resourceId === selection?.resourceId}>
                      <Flex alignItems="center">
                        <Radio
                          value={consent.resourceId}
                          checked={consent.resourceId === selection?.resourceId}
                          onChange={() => {
                            setSelection(consent)
                          }}
                        />
                        <Text ml={1} fontWeight="bold" color="darkGray" margin={1}>
                          {consent.ownerName}
                        </Text>
                      </Flex>
                      <Text>
                        {consent.ownerId.startsWith('user') ? 'Conta Pessoal' : 'Conta Empresarial'} -{' '}
                        {consent.immutableMetadata.accountCode}
                      </Text>
                    </FieldSet>
                  </Box>
                )}
              />
            </If>
          </Stack>
          <Box>
            <Button size="large" onClick={() => next({ consent: selection })} disabled={!selection}>
              Avançar
            </Button>
          </Box>
        </Stack>
      </Choose.When>
      <Choose.Otherwise>
        <HasConsent name={name} />
      </Choose.Otherwise>
    </Choose>
  )
}
