import { unmask } from '~/lib/helpers'

const stoneCodeDateFormat = /\/Date\((.*?)\+.*?\)\//

export function organizationCreatedAtDecorator(config: {
  organization: Organization
  stoneCodes: StoneCode[]
}): string {
  const { organization, stoneCodes } = config

  const stoneCodeCreation = String(findStoneCode()?.createdAt)

  if (stoneCodeDateFormat.test(stoneCodeCreation)) return convertUnixToISO(stoneCodeCreation) as string

  return organization.createdAt

  function findStoneCode() {
    return stoneCodes.find(x => unmask(x.document) === organization.document && x.createdAt)
  }
}

interface Organization {
  createdAt: string
  document: string
}

interface StoneCode {
  document: string
  createdAt?: string
}

export function convertUnixToISO(date: string) {
  const unixDate = stoneCodeDateFormat.exec(date)

  if (unixDate) return new Date(parseInt(unixDate[1])).toISOString()
}
