import { type InstalledPlugins } from '../index'
import { type TMInstanceWithPlugins, type TmPlugin } from '../types'

export interface SaleRangeTmMethods {
  getSaleLast3Months(): TmRange
  getSaleLast6Months(): TmRange
  getSaleLastYear(): TmRange
  getSaleThisMonth(): TmRange
}

type TmRange = [TMInstanceWithPlugins<InstalledPlugins>, TMInstanceWithPlugins<InstalledPlugins>]

export const saleRangeTMPlugin: TmPlugin<SaleRangeTmMethods> = (instance, timeMachine) => {
  const proto = instance.prototype

  proto.getSaleLast3Months = function () {
    return [timeMachine().subtractMonth(3).addDay(1).startOfDay(), timeMachine().endOfDay()]
  }

  proto.getSaleLast6Months = function () {
    return [timeMachine().subtractMonth(6).addDay(1).startOfDay(), timeMachine().endOfDay()]
  }

  proto.getSaleThisMonth = function () {
    return [timeMachine().startOfMonth().startOfDay(), timeMachine().endOfDay()]
  }
  proto.getSaleLastYear = function () {
    return [timeMachine().subtractYear(1).addDay(1).startOfDay(), timeMachine().endOfDay()]
  }
}
