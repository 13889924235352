import { theme } from 'styled-tools'

import { Box } from '~/domains/platform/design-system'
import { EmptyBlock } from '~/ui/components/utils/empty-block'

type SharedSkeletonFeedback = Parameters<typeof EmptyBlock>[0]

export function SharedSkeletonFeedback(props: SharedSkeletonFeedback) {
  return (
    <Box aria-busy role="progressbar" aria-valuetext="Carregando">
      <EmptyBlock
        color={theme('colors.lightGray')}
        fadeColor={theme('colors.mediumGray3')}
        height="2rem"
        width="100%"
        {...props}
      />
    </Box>
  )
}
