import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'

import { type SideModalProps } from './content-modal'

export const Wrapper = styled.div`
  background-color: ${theme('colors.white')};
  border-radius: ${theme('radii.default')};
  bottom: 0;
  max-height: calc(100vh - ${theme('sizes.header.height')} + 2rem);
  overflow-y: auto !important;
  position: fixed;
  right: 0;
  top: calc(${theme('sizes.header.height')});
  width: calc(100vw - 18rem);
  z-index: ${theme('zIndices.sideModal')};
  box-shadow: ${theme('shadows.medium')};

  &.visible {
    animation: modal-in 0.15s ease-in;
  }

  @keyframes modal-in {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`

export const Section = styled.div<{ fullSize: boolean; headerHeight: string }>`
  display: flex;
  padding: ${theme('space.xLarge')};
  > * {
    flex: 1;
  }

  ${props =>
    props.fullSize &&
    css`
      min-height: calc(100% - ${props.headerHeight});
    `}
`

export const HeaderSection = styled.div<Pick<SideModalProps, 'headerPosition'>>`
  display: flex;
  padding: ${theme('space.xLarge')};
  justify-content: flex-start;

  ${props =>
    props.headerPosition === 'right' &&
    css`
      > :nth-last-child(1) {
        padding-left: 1rem;
        margin-left: auto;
      }
    `}
`

export const ModalTitle = styled.div`
  font-size: ${theme('fontSizes.xLarge')};
  font-weight: ${theme('fontWeights.medium')};
`
