import { Stack } from '@dlpco/fluid-layout'
import { Button, Text } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { Field } from '~/domains/platform/design-system/field/field'
import BlackBird from '~/domains/platform/lib/blackbird'
import { timeMachine } from '~/domains/platform/lib/time-machine'
import { If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { type OpenFinanceStatementEntry } from '~/lib/types'

import { Toolbar } from './styles/toolbar'

export const Details = ({ data }: { data: OpenFinanceStatementEntry }) => {
  const {
    createdAt,
    creditorEntityName,
    creditorEntityCpfCnpj,
    type,
    payment,
    scheduledDate,
    id,
    paymentTransactionStatus,
    remittanceInformation
  } = data

  const handleCancelConsent = () => {
    BlackBird.travelTo({
      pathname: '/open-finance/meus-pagamentos/confirmar-cancelamento',
      query: { id }
    })
  }

  const cancelPaymentAvailable = paymentTransactionStatus === 'SCHEDULED'

  return (
    <>
      <Flex gap="2rem">
        <Field
          fontSize="small"
          label="Data de pagamento"
          value={timeMachine(createdAt).format('local')}
          renderProps={{ fontSize: 'small', fontWeight: 'bold' }}
        />
        <If condition={paymentTransactionStatus !== 'SCHEDULED'}>
          <Field fontSize="small" label="Hora" value={timeMachine(createdAt).format('smallHour')} />
        </If>
      </Flex>
      <Field fontSize="small" label="Descrição" value={remittanceInformation} fontWeight="semi" />
      <Divider backgroundColor="mediumGray3" />
      <Field
        fontSize="small"
        label="Nome do destino"
        value={creditorEntityName}
        renderProps={{ fontSize: 'large', fontWeight: 'bold' }}
      />
      <Field
        fontSize="small"
        label="CNPJ/CPF"
        value={stringFormat.hideDocument(creditorEntityCpfCnpj || '')}
        renderProps={{ fontSize: 'small', fontWeight: 'bold' }}
      />
      <Divider backgroundColor="mediumGray3" />
      <Field
        fontSize="small"
        label="Nome da origem"
        value="Nome da origem"
        renderProps={{ fontSize: 'large', fontWeight: 'bold' }}
      />
      <Field
        fontSize="small"
        label="CNPJ/CPF"
        value={stringFormat.hideDocument(creditorEntityCpfCnpj || '')}
        renderProps={{ fontSize: 'small', fontWeight: 'bold' }}
      />
      <Divider backgroundColor="mediumGray3" />
      <If condition={Boolean(payment)}>
        <Flex height="1.6rem" justifyContent="space-between">
          <Text color="neutral" size="small">
            Tarifa
          </Text>
          <Text weight="bold">Grátis</Text>
        </Flex>
        <Flex height="1.6rem" justifyContent="space-between">
          <Text color="neutral" size="small">
            Tipo
          </Text>
          <Text weight="bold">{`${type}`} - Open Banking</Text>
        </Flex>
        <If condition={paymentTransactionStatus === 'FINISHED'}>
          <Flex height="1.6rem" justifyContent="space-between">
            <Text color="neutral" size="small">
              ID Transação
            </Text>
            <Text weight="bold" size="small" data-testid="payment-id">
              {payment?.paymentId}
            </Text>
          </Flex>
        </If>
      </If>

      <If condition={Boolean(scheduledDate && cancelPaymentAvailable)}>
        <Toolbar>
          <Stack space="1rem">
            <Divider />
            <Button shape="pill" color="negative" size="large" onClick={handleCancelConsent}>
              Cancelar agendamento
            </Button>
          </Stack>
        </Toolbar>
      </If>
    </>
  )
}
