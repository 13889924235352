import { bffParser } from './helpers/bff-parser'
import { entityDetailParser } from './helpers/entity-detail-parser'
import { type BffData, fetchBff } from './services/fetch-bff'
import { type EntityData, fetchEntityDetail } from './services/fetch-entity-detail'
import { getExMachinaSource } from './services/get-ex-machina-source'
import { type Entity, type OptIns, type Subject } from './types'

interface Source<T = unknown> {
  fetcher: (optIns: OptIns[]) => Promise<T>
  parser: (data: T) => { subject: Subject<OptIns>; entity: Entity<OptIns> }
}

const bffSource: Source<BffData> = {
  fetcher: fetchBff,
  parser: bffParser
}

const entitySource: Source<EntityData> = {
  fetcher: fetchEntityDetail,
  parser: entityDetailParser
}

const sourceMap = {
  bff: bffSource,
  entity: entitySource
}

export const exMachina = async () => {
  const source = await getExMachinaSource()

  const { fetcher, parser } = sourceMap[source]

  /**
   * @disclaimer conditional fetching are currently not supported, so we passing all flags statically
   */
  const data = await fetcher(['acquirer', 'banking', 'stone_account', 'stone_account_kyc', 'stone_account_resources'])

  return parser(data as BffData & EntityData)
}
