import { httpClientPortal } from '~/lib/services/acquirer/http-client-portal'
import { type Sale } from '~/lib/types/acquirer/Sale'
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

export const getSales = (id?: string, stoneCode?: string) => {
  const params = {
    includeSplits: false
  }

  return httpClientPortal({ url: `/transactions/${id}`, params, headers: { merchantIds: stoneCode } })
}

export const useSale = (id?: string, stoneCode?: string) => {
  const { data: response, ...restQuery } = useQueryRequest<Sale>(
    ['useSale', id, stoneCode],
    () => getSales(id, stoneCode),
    { enabled: Boolean(id || stoneCode) }
  )

  return { data: response?.data, ...restQuery }
}
