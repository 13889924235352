import { type NextPage } from 'next'
import { useRouter } from 'next/router'

import { Loader } from '~/domains/platform/design-system/loader/loader'
import BlackBird from '~/domains/platform/lib/blackbird'
import { access } from '~/lib/helpers'
import { CleanPageContainer } from '~/ui/containers'

import { type WithDeusExMachinaProps } from '../deus-ex-machina/types'

export type WithRBACProps = WithDeusExMachinaProps

export function withRBAC(Page: NextPage<WithRBACProps>) {
  function WrappedPage({ entity, ...rest }: WithRBACProps) {
    const { pathname } = useRouter()

    const canAccess = access.rbac(pathname, entity?.roles ?? {})

    if (!canAccess) {
      BlackBird.goHome()

      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    return <Page entity={entity} {...rest} />
  }

  WrappedPage.displayName = `withRBAC(${Page.displayName})`

  return WrappedPage
}
