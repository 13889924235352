import { Stack } from '@dlpco/fluid-layout'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Card } from '~/domains/platform/design-system'

export const CardWrapper = styled(Card)`
  background-color: ${theme('colors.white')};
  height: 100vh;
  display: flex;
  align-items: center;
`

export const StackWrapper = styled(Stack)`
  background-color: ${theme('colors.lightGray')};
  height: 100vh;
  margin: -48px -48px -48px 0;
  padding: 2rem;
`
export const WrapperButton = styled.div`
  display: grid;
  max-width: 320px;
`
