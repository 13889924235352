import { type NextPage } from 'next'
import curry from 'lodash.curry'

import { type FeatureFlagsAvailable, Heimdall } from '~/domains/platform/core/heimdall'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { isomorphicPush } from '~/lib/helpers/utils/isomorphic-push'
import { type BFF } from '~/lib/types/pandas/BFF'
import { CleanPageContainer } from '~/ui/containers/clean-page-container'

interface withHeimdallGrantAccessPageProps extends BFF {}

export const withHeimdallGrantAccessPage = curry(function withHeimdallGrantAccessPage(
  requiredFlag: FeatureFlagsAvailable | FeatureFlagsAvailable[],
  Page: NextPage<withHeimdallGrantAccessPageProps>
): NextPage<withHeimdallGrantAccessPageProps> {
  function WrappedPage(props: withHeimdallGrantAccessPageProps) {
    const canAccess = Heimdall.pass([requiredFlag].flat(), 'some')

    if (!canAccess) {
      isomorphicPush('/404')
    } else {
      return <Page {...props} />
    }

    return (
      <CleanPageContainer>
        <Loader />
      </CleanPageContainer>
    )
  }

  WrappedPage.displayName = `withHeimdallGrantAccessPage(${Page.displayName})`

  return WrappedPage
})
