import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { type SchedulingType } from '~/lib/types'

interface RequestCancel {
  accountId: string
  operationId: string
  type: SchedulingType
}

export async function resourceTypeCancel({ accountId, operationId, type }: RequestCancel) {
  return await httpClientBanking({
    url: `/accounts/${accountId}/operations/actions/cancel`,
    method: 'POST',
    data: {
      action: 'cancel_only',
      accountId,
      operation_status: 'scheduled_and_approved',
      operations: [{ id: operationId, type }]
    }
  })
}
