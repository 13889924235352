import { useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import NextLink from 'next/link'
import { Center, Cluster } from '@dlpco/fluid-layout'
import { Button, Heading, Text } from '@dlpco/ginga-stone'

import { Box, Flex, Link } from '~/domains/platform/design-system'
import { AppleStoreIllustration } from '~/domains/platform/design-system/illustrations/apple-store.illustration'
import { GoogleStoreIllustration } from '~/domains/platform/design-system/illustrations/google-store.illustration'
import { KycAssessmentRedirectToMobileIllustration } from '~/domains/platform/design-system/illustrations/kyc-assessment-redirect-to-mobile.illustration'
import { RedirectToMobileIllustration } from '~/domains/platform/design-system/illustrations/redirect-to-mobile.illustration'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { trackAnalyticsEvent } from '~/lib/helpers'

export function RedirectToMobile({ hasInvitationKycAssessment }: { hasInvitationKycAssessment: boolean }) {
  const [unsupportedPlatform, setUnsupportedPlatform] = useState(false)

  return (
    <Choose>
      <Choose.When condition={hasInvitationKycAssessment}>
        <Flex justifyContent="center" alignItems="center" height="calc(100% - 40px)" width="100%">
          <Cluster align="center" justify="center" space="1.2rem">
            <Box>
              <Box>
                <KycAssessmentRedirectToMobileIllustration />
              </Box>

              <Flex justifyContent="space-around" alignItems="center" flexDirection="column" flexWrap="wrap" gap="2rem">
                <Box>
                  <Heading size="xLarge" weight="bold">
                    Precisamos de mais algumas informações.
                  </Heading>
                  <Box mt="1rem">
                    <Heading size="large" color="neutral">
                      Abra o aplicativo da Stone pra completar seu cadastro.
                    </Heading>
                  </Box>
                </Box>

                <Flex alignItems="flex-start" flexDirection="column" width="100%" gap="1rem">
                  <Text size="large">Não tem nosso aplicativo? Aproveite pra baixar.</Text>

                  <Flex width="15rem" alignItems="flex-start">
                    <NextLink href={'https://apps.apple.com/br/app/conta-stone/id1438680035'} passHref legacyBehavior>
                      <Link
                        onClick={() => {
                          trackAnalyticsEvent({
                            action: 'click',
                            category: 'RedirectToAppleStore',
                            label: 'Clicar em Baixar o app Stone no IOS'
                          })
                        }}
                        color="white"
                        width="100%"
                      >
                        <AppleStoreIllustration />
                      </Link>
                    </NextLink>
                    <NextLink
                      href={
                        'https://play.google.com/store/apps/details?id=co.stone.banking.mobile.flagship&hl=pt&gl=US'
                      }
                      passHref
                      legacyBehavior
                    >
                      <Link
                        onClick={() => {
                          trackAnalyticsEvent({
                            action: 'click',
                            category: 'RedirectToGoogleStore',
                            label: 'Clicar em Baixar o app Stone no Android'
                          })
                        }}
                        color="white"
                        width="100%"
                      >
                        <GoogleStoreIllustration />
                      </Link>
                    </NextLink>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Cluster>
        </Flex>
      </Choose.When>
      <Choose.Otherwise>
        <Flex justifyContent="center" alignItems="center" height="calc(100% - 40px)" width="100%">
          <Cluster align="center" justify="center" space="1.2rem">
            <Flex justifyContent="center" alignItems="center" flexDirection="column">
              <Heading size="xLarge" weight="bold">
                Baixe o aplicativo Stone para acessar sua conta pelo celular
              </Heading>
              <Box mt="1rem">
                <Heading size="large" color="neutral">
                  Aproveite todas as nossas soluções bem na palma da sua mão.
                </Heading>
              </Box>
              {unsupportedPlatform && (
                <Box color="red" pt="1.2rem">
                  <Text inheritColor>Plataforma ainda não suportada</Text>
                </Box>
              )}
            </Flex>

            <Center>
              <NextLink
                href={`${
                  isAndroid
                    ? 'https://play.google.com/store/apps/details?id=co.stone.banking.mobile.flagship&hl=pt&gl=US'
                    : isIOS
                    ? 'https://apps.apple.com/br/app/conta-stone/id1438680035'
                    : '#'
                }`}
                passHref
                legacyBehavior
              >
                <Link
                  onClick={() => {
                    if (!isAndroid && !isIOS) {
                      setUnsupportedPlatform(true)
                      trackAnalyticsEvent({
                        action: 'click',
                        category: 'RedirectToMobileUnsupportedPlatform',
                        label: 'Clicar em Baixar o app Stone no alert que redireciona para o app em telas menores'
                      })
                    }
                  }}
                  color="white"
                  style={{
                    paddingTop: '1.2rem',
                    width: '80%'
                  }}
                >
                  <Button style={{ width: '100%' }} size="large">
                    Baixar o app Stone
                  </Button>
                </Link>
              </NextLink>
            </Center>

            <Center>
              <RedirectToMobileIllustration />
            </Center>
          </Cluster>
        </Flex>
      </Choose.Otherwise>
    </Choose>
  )
}
