import { type TmPlugin } from '../types'

/**
 * @link [LocalizedFormat](https://day.js.org/docs/en/display/format#list-of-localized-formats)
 */
const allowedFormats = {
  default: 'L',
  defaultApi: 'YYYY-MM-DD',
  local: 'LL',
  fancy: 'DD MMM YYYY [•] HH:mm',
  fancyHour: 'DD MMM YYYY [às] HH:mm',
  smallFancy: 'DD MMM, YYYY',
  smallMonthFancy: 'MMMM, YYYY',
  variantSmallFancy: 'DD MMM YYYY',
  smallHour: 'LT',
  simplified: 'DD/MM',
  fullYear: 'YYYY',
  fullMonth: 'MMMM',
  brazilianPattern: 'DD/MM/YYYY',
  extensiveBrazilianPattern: 'DD [de] MMMM [de] YYYY',
  simpleBrazilianPattern: 'MMMM [de] YYYY',
  dateAndMonth: 'DD MMM',
  dateWithDaysOfTheWeek: 'dddd, DD MMM',
  yearAndMonth: 'YYYY-MM',
  isoStringWithUtcOffset: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  hourOnly: 'HH:mm',
  shortDate: 'DD MMM YY'
}

export interface FormatTmMethods {
  /**
   * @usage should any of these:
   * - `default` > '11/04/2000'
   * - `local` > '11 de Abril de 2000'
   * - `defaultApi` > '2000-04-11'
   * - `fancy` > '11 abr 2000 • 11:50'
   * - `fancyHour` > '11 abr 2000 às 11:50'
   * - `smallMonthFancy` > 'Abril, 2000'
   */
  format(format: keyof typeof allowedFormats): string
  formatDefaultLocalTime(): string
}

export const formatTmPlugin: TmPlugin<FormatTmMethods> = instance => {
  const proto = instance.prototype

  proto.format = function (format) {
    return this['$dateEngine'].format(allowedFormats[format])
  }

  proto.formatDefaultLocalTime = function () {
    return this['$dateEngine'].format()
  }
}
