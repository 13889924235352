import { useForm } from 'react-hook-form'

import { Box } from '~/domains/platform/design-system'
import { Select } from '~/domains/platform/design-system/select/select'

import { checkPeriod } from './helpers/check-period'
import { translatePeriod } from './helpers/translate-period'
import { type FormData } from './filter-form'
import { type FilterFormProps } from './types'

export const SimplerFilterForm = ({ filterPeriod, onChange, disabled, label }: Required<FilterFormProps>) => {
  const { handleSubmit, register } = useForm<FormData>({
    defaultValues: {
      period: checkPeriod(filterPeriod),
      customStartDate: filterPeriod.startDate,
      customFinalDate: filterPeriod.finalDate
    }
  })

  const onSubmit = handleSubmit(async ({ period, customStartDate, customFinalDate }) => {
    const { startDate, finalDate, title } = await translatePeriod(period, customStartDate, customFinalDate)
    onChange({
      startDate,
      finalDate,
      title,
      period: '',
      paymentMethod: null
    })
  })

  return (
    <Box as="form" data-testid="form" onChange={onSubmit}>
      <Select {...register('period')} data-testid="period" disabled={disabled} label={label}>
        <option value="today">Hoje</option>
        <option value="yesterday">Ontem</option>
        <option value="last_7">Últimos 7 dias</option>
        <option value="last_30">Últimos 30 dias</option>
        <option value="this_month">Este mês</option>
        <option value="last_month">Mês passado</option>
        <option value="last_six_months">Últimos 6 meses</option>
        <option value="last_year">Último ano</option>
      </Select>
    </Box>
  )
}
