import { Stack } from '@dlpco/fluid-layout'

import { Text } from '~/domains/platform/design-system'
import { Money } from '~/ui/components/fields/money'

interface MoneyResultProps {
  title: string
  value: number
  footer: string
  isPrimary?: boolean
}

export const MoneyResume = (props: MoneyResultProps) => {
  const { title, value, footer, isPrimary } = props

  return (
    <Stack space="2">
      <Text fontWeight="bold" fontSize="normal" color="mediumGray">
        {title}
      </Text>
      <Money
        pretty
        value={value}
        renderProps={{ fontSize: 'xxLarge', fontWeight: 'bold', color: isPrimary ? 'stoneGreen' : 'darkGray' }}
      />
      <Text color="mediumGray" fontSize="normal" aria-label="Footer do conteúdo do valor e taxas da antecipação">
        {footer}
      </Text>
    </Stack>
  )
}
