import { type PropsWithChildren } from 'react'

import * as S from './styles'

interface FieldSetProps {
  active?: boolean
  borderless?: boolean
  children: React.ReactNode
}

export function FieldSet({ active = false, borderless = false, children }: PropsWithChildren<FieldSetProps>) {
  return (
    <S.Wrapper active={active} borderless={borderless} aria-label="fieldset">
      {children}
    </S.Wrapper>
  )
}
