import styled, { keyframes } from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

const placeHolderShimmer = keyframes`
  0% { background-position: -468px 0; }
  100% { background-position: 468px 0; }
`

export const Block = styled.div<{
  backgroundColor?: string
  height?: string
  width?: string
  shadow?: boolean
  fadeColor?: any
  space?: boolean
}>`
  background-color: ${theme(`colors.${prop('backgroundColor')}`)};
  height: ${prop('height')};
  width: ${prop('width')};
  box-shadow: ${ifProp('shadow', theme('shadow.small'), 'none')};
  border-radius: ${theme('radii.default')};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    ${prop('backgroundColor')} 8%,
    ${prop('fadeColor')} 18%,
    ${prop('backgroundColor')} 33%
  );
  margin-bottom: ${ifProp('space', theme('space.small'))};
  background-size: 800px 104px;
`
