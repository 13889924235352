import { useRequest } from '~/ui/hooks/utils/service/use-request'

interface RequestType {
  balance: number
}

export const useBalance = (accountId: string): [number, boolean, () => Promise<boolean>] => {
  const url = `/accounts/${accountId}/balance`
  const {
    data = { balance: 0 },
    isValidating,
    revalidate
  } = useRequest<RequestType>({
    url
  })
  const { balance } = data
  return [balance, isValidating, revalidate]
}
