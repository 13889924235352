import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/ports'
import { entitiesListFactory } from '~/lib/helpers/auth/entities-list-factory'

import { type Assessment } from './entities/KYC'
import { kycAssessment } from './kyc-assessment'

function kycRequestsRules(options: { userKycRequest?: Assessment; entitiesKycRequests: Assessment[] }) {
  const { userKycRequest, entitiesKycRequests } = options

  const kycMandatoryRule =
    (userKycRequest?.isMandatory && userKycRequest?.reason !== 'account_request') ||
    entitiesKycRequests?.some(({ isMandatory, reason }) => isMandatory && reason !== 'account_request')

  const riskAssessment = kycAssessment.findAssessmentByReason('risk_analysis', userKycRequest, entitiesKycRequests)
  return { kycMandatoryRule, riskAssessment }
}

function kycIdentityRules(options: {
  entity: Entity
  subject: Subject<'stone_account_resources' | 'stone_account_kyc'>
}) {
  const { entity, subject } = options
  const {
    organizations,
    users,
    kycRequest: userKycRequest,
    unsafe: { entitiesKycRequests }
  } = subject

  const { kycMandatoryRule, riskAssessment } = kycRequestsRules({
    userKycRequest,
    entitiesKycRequests
  })

  const hasNoAvailableIdentities = entitiesListFactory({ organizations, subject, users }).length === 0

  const hasPendencies = entity.approvalStatus !== 'approved'

  const hasAcquirerVision = entity.roles.acquirer
  const hasBankingVision = entity.roles.banking

  return {
    riskAssessment,
    kycMandatoryRule,
    hasNoAvailableIdentities,
    hasPendencies,
    hasAcquirerVision,
    hasBankingVision
  }
}

export const kycRules = {
  kycRequestsRules,
  kycIdentityRules
}
