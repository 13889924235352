import { type Identity } from '~/lib/types'

import { type Assessment } from './entities/KYC'

function findAssessmentByReason(
  reason: Assessment['reason'],
  userAssessment?: Assessment,
  organizationAssessments?: Assessment[]
) {
  const notNullableOrgAssessments = organizationAssessments || []
  return [userAssessment, ...notNullableOrgAssessments].find(assessment => assessment?.reason === reason)
}

function findCurrentAssessment(options: {
  identity: Identity
  userAssessment?: Assessment
  organizationAssessments?: Assessment[]
}) {
  const { identity, userAssessment, organizationAssessments } = options

  const orgId = options.identity.id.split(':')[1]

  if (identity.id.includes('user')) return userAssessment

  if (userAssessment) return userAssessment

  return organizationAssessments?.find(item => item.organizationId === orgId)
}

export const kycAssessment = { findCurrentAssessment, findAssessmentByReason }
