import { forwardRef } from 'react'

import { Input } from '~/domains/platform/design-system/input/input'
import { type InputProps } from '~/domains/platform/design-system/input/interface'
import { stringFormat } from '~/lib/helpers/utils/string-format'

interface MoneyButtonProps extends Omit<InputProps, 'value'> {
  value?: string
}

export const MoneyInput = forwardRef<HTMLInputElement, MoneyButtonProps>(function MoneyInput(props, ref) {
  const { name, placeholder, onChange, ...rest } = props
  return (
    <Input
      name={name}
      data-testid="money-input"
      placeholder={placeholder || 'R$ 0,00'}
      onChange={e => {
        if (e.currentTarget.value) {
          const val = Number(stringFormat.onlyNumbers(e.currentTarget.value))
          e.currentTarget.value = stringFormat.currency(val)
          onChange && onChange(e)
        }
      }}
      ref={ref}
      {...rest}
    />
  )
})
