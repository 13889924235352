import { Sidebar } from '@dlpco/fluid-layout'
import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'

import { Card } from '~/domains/platform/design-system'
import { toRem } from '~/lib/helpers'

export const Icon = styled.div<{ turn: boolean }>`
  background-color: transparent;
  margin-top: auto;
  margin-bottom: auto;
  transform: ${ifProp('turn', 'rotate(-180deg)')};
  transform-origin: 50% 50%;

  svg {
    color: ${props => props.theme.colors.primary};
  }
`

export const MenuDropdown = styled(Card)`
  width: min(320px, 100vw);
  background-color: ${theme('colors.white')};
  display: flex;
  box-shadow: 0 ${toRem(5)} ${toRem(20)} rgba(0, 0, 0, 0.15);
  flex-direction: column;
  position: absolute;
  top: 100%;
  transform: translateY(1rem);
  right: ${toRem(1)};
  z-index: ${theme('zIndices.dropdown')};
  border-radius: ${theme('radii.default')};
  padding: 0;
  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(-3rem);
    width: 0;
    height: 0;
    border: 0.688em solid transparent;
    border-bottom-color: ${theme('colors.white')};
    border-top: 0;
    margin-left: -0.687em;
    margin-top: -0.687em;
  }
`
export const MenuItem = styled.div`
  cursor: pointer;
  padding: 1rem;
  color: ${theme('colors.darkGray')};

  :hover {
    background: ${theme('colors.muted')};
    color: ${theme('colors.primary')};

    p {
      color: ${theme('colors.primary')};
    }

    svg {
      color: ${theme('colors.primary')};
    }
  }
`

export const MenuWrapper = styled(Sidebar)`
  position: relative;

  :hover {
    cursor: pointer;
  }
`

MenuWrapper.defaultProps = { tabIndex: 0 }
