import { type NextPage } from 'next'
import { useRouter } from 'next/router'

import { Loader } from '~/domains/platform/design-system/loader/loader'
import BlackBird from '~/domains/platform/lib/blackbird'
import { kycFormatAssessmentStatusData } from '~/domains/stone-account/ports/kyc-format-assessment-status-data'
import { kycRules } from '~/domains/stone-account/ports/kyc-rules'
import { kycStatus } from '~/domains/stone-account/ports/kyc-status'
import { CHALLENGE_BLOCKED_PTBR } from '~/domains/stone-account/ports/stone-account.routes'
import { isomorphicCookies, trackEvents } from '~/lib/helpers'
import { CleanPageContainer } from '~/ui/containers'

import { Bucket } from '../../lib/bucket'
import { clientSideOnly } from '../../lib/client-side-only'
import { type WithDeusExMachinaProps } from '../deus-ex-machina/ports'

export function withEntityKYC(Page: NextPage<WithDeusExMachinaProps>) {
  function WrappedPage({ subject, entity, ...rest }: WithDeusExMachinaProps) {
    const {
      kycRequest: userKycRequest,
      unsafe: { entitiesKycRequests }
    } = subject

    const emailVerified = subject.emailVerified

    const entityRejected = entity.approvalStatus === 'rejected'

    const assessmentStatus = kycFormatAssessmentStatusData(entity, userKycRequest, entitiesKycRequests)
    const { status: statusKyc, updateAssessment } = kycStatus.assessmentStatus(assessmentStatus)

    const updateAssessmentReason = userKycRequest?.reason === 'update'

    const unblockChallengeAssessment = userKycRequest?.reason === 'unblock_challenge'
    const deviceRegistrationAssessment = userKycRequest?.reason === 'device_registration'
    const unblockChallengeAssessmentRequestedStatus = userKycRequest?.status === 'requested'

    const { push } = useRouter()

    const { hasLoggedBeforeCookie } = isomorphicCookies.getAll()

    const dontShowKycOnboardingFreemium =
      clientSideOnly(() => {
        return Bucket.local.get('dont::show::kyc::onboarding::freemium')
      }) || []

    const dontShowKycUpdateAssessment: string[] =
      window && JSON.parse(window.localStorage.getItem('dontShowKycUpdateAssessment') || '[]')

    const dontShowChallengeBlocked: string = (window && window.localStorage.getItem('dontShowChallengeBlocked')) || ''

    const { hasPendencies, hasNoAvailableIdentities, hasAcquirerVision, riskAssessment, hasBankingVision } =
      kycRules.kycIdentityRules({
        entity,
        subject
      })

    const isUpdateAssessmentCase =
      updateAssessmentReason && !dontShowKycUpdateAssessment.includes(updateAssessment?.id as string)

    const hasNoAccountAndEmailIsVerified = !hasBankingVision && !hasAcquirerVision && emailVerified && hasPendencies

    const isIdentityRejected = !hasAcquirerVision && emailVerified && entityRejected

    if (
      userKycRequest &&
      unblockChallengeAssessment &&
      unblockChallengeAssessmentRequestedStatus &&
      !dontShowChallengeBlocked.includes(subject.id)
    ) {
      push({ pathname: CHALLENGE_BLOCKED_PTBR })

      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    if (riskAssessment) {
      trackEvents({
        trackServices: ['analytics'],
        action: 'redirecting_user_to_blocked_page',
        category: 'KYC'
      })

      BlackBird.travelTo('/conta-bloqueada')

      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    if (
      isUpdateAssessmentCase ||
      hasNoAccountAndEmailIsVerified ||
      isIdentityRejected ||
      deviceRegistrationAssessment
    ) {
      trackEvents({
        trackServices: ['analytics'],
        action: 'redirecting_user_to_kyc_analysis',
        category: 'KYC'
      })
      BlackBird.travelTo('/conta-em-analise')

      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    if (
      entity.hasAnActivePaymentAccount &&
      !hasLoggedBeforeCookie &&
      !dontShowKycOnboardingFreemium.includes(entity.id) &&
      !dontShowKycUpdateAssessment.includes(updateAssessment?.id as string) &&
      hasPendencies &&
      !entityRejected &&
      statusKyc !== 'approved' &&
      userKycRequest?.status !== 'answered'
    ) {
      trackEvents({
        trackServices: ['analytics'],
        action: 'redirecting_freemium_user_to_onboarding_page',
        category: 'Freemium'
      })
      push({ pathname: '/complete-seus-dados', query: { statusKyc } })
      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    if (!emailVerified && hasPendencies) {
      push('/confirme-email')
      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    if (hasNoAvailableIdentities && BlackBird.getPathname() === '/home') {
      trackEvents({
        trackServices: ['analytics'],
        action: 'redirecting_user_to_account_switch',
        category: 'KYC'
      })
      window && window.sessionStorage.setItem('hasNoAvailableIdentities', JSON.stringify(hasNoAvailableIdentities))

      BlackBird.travelTo('/selecionar-conta')

      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    return <Page subject={subject} entity={entity} {...rest} />
  }

  WrappedPage.displayName = `withEntityKYC(${Page.displayName})`

  return WrappedPage
}
