import { type AxiosResponse } from 'axios'

import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { recursiveClient } from '~/domains/platform/infra/http/recursive-client'
import { appEnv, dynamicTemplate, isomorphicCookies } from '~/lib/helpers'
import { type PurchaseStoneCode } from '~/lib/types'

import { MultiPromiseHandler } from '../../http/multi-promise-handler'
import { type EntityAccount, type EntityAccountDetail, type MeDetails } from '../entities'
import { type OptIns, type RawPaymentAccount } from '../types'

import { getStoneCodes } from './get-stone-codes'

export interface EntityData {
  entityDetails: EntityAccountDetail
  subjectDetails: MeDetails
  rawPaymentAccounts: RawPaymentAccount[]
  stoneCodes: PurchaseStoneCode[]
  entities: EntityAccount[]
}

async function extractAxiosResponse<T>(response: Promise<AxiosResponse<T>>): Promise<T> {
  return response.then(res => res.data)
}

async function recursiveFetchEntity() {
  const response = await recursiveClient<EntityAccount>(
    {
      url: '/entity-accounts',
      params: {
        paginated: true
      }
    },
    [],
    { limit: 100 }
  )

  return response.data
}

export async function fetchEntityDetail(exMachinaOptIns: OptIns[]) {
  const { identityId: globalId } = isomorphicCookies.getAll()

  const handler = new MultiPromiseHandler<EntityData>()

  handler.addPromise(
    'subjectDetails',
    extractAxiosResponse(
      httpClientBanking<MeDetails>({
        url: '/me/details'
      })
    )
  )

  handler.addPromise('entities', recursiveFetchEntity())

  if (globalId) {
    handler.addPromise(
      'entityDetails',
      extractAxiosResponse(
        httpClientBanking<EntityAccountDetail>({
          url: dynamicTemplate('/entity-accounts/${globalId}', { globalId })
        })
      )
    )
  }

  if (exMachinaOptIns.includes('banking')) {
    handler.addPromise(
      'rawPaymentAccounts',
      extractAxiosResponse(
        httpClientBanking<RawPaymentAccount[]>({
          url: '/accounts',
          params: {
            with_enabled_features: true
          }
        })
      )
    )
  }

  if (exMachinaOptIns.includes('acquirer') && !appEnv.isPoitingToHomolog()) {
    handler.addPromise('stoneCodes', getStoneCodes())
  }

  return await handler.execute()
}
