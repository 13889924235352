import { type AxiosRequestConfig } from 'axios'

import { httpClientCustomer } from '~/lib/services/acquirer/http-client-customer/http-client-customer'

import { type ChallengeToken } from '../../shared/entities/challenge-token'

export interface SendAuthorizationChallengeCodeResponse {
  status: number
  statusText: string
  headers: any
  config: AxiosRequestConfig
  request?: any
  data: string | undefined
}

export const sendAuthorizationChallengeCode = async () => {
  const { data: response, ...restAxios } = await httpClientCustomer<ChallengeToken>({
    method: 'POST',
    url: '/challenge'
  })

  return { data: response.data?.id, ...restAxios }
}
