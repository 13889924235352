import { Stack } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { SuccessCheckedIllustration } from '~/domains/platform/design-system/illustrations/success-checked.illustration'
import BlackBird from '~/domains/platform/lib/blackbird'
import { useWizardProps } from '~/ui/components/modals/wizard/use-wizard-props'

export function Success() {
  const { content } = useWizardProps()

  return (
    <Box>
      <Stack space="15rem">
        <Flex alignItems="center">
          <SuccessCheckedIllustration />
          <Box>
            <Stack space="1rem">
              <Text fontSize="xLarge" fontWeight="medium">
                Recebemos seu cadastro
              </Text>
              <Text color="mediumGray" maxWidth="590px" fontSize="medium">
                Em até 2 dias úteis, vamos entrar em contato pelo aplicativo ou pelo e-mail {content?.email}
              </Text>
            </Stack>
          </Box>
        </Flex>
        <Box>
          <Button size="large" onClick={() => BlackBird.goHome()}>
            Voltar para o ínicio
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}
