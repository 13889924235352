import { useEffect, useState } from 'react'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Heading, Text, theme } from '@dlpco/ginga-stone'
import { type AxiosError } from 'axios'

import { salesAnalyticsEvents } from '~/domains/acquirer/sales/shared/helpers/sales-analytics-events'
import { Box, Flex } from '~/domains/platform/design-system'
import { chat } from '~/domains/platform/infra/chat/ports/chat'
import { If } from '~/domains/platform/lib/utilities-components'
import { EMAIL_REGEX } from '~/lib/constants'
import { trackEvents } from '~/lib/helpers'

import { CodeInput } from '../challenge-dialog'

type ConfirmerProps = {
  onConfirm(input: string): void
  fields?: number
  toConfirm?: string
  verificationError?: string
  onError?(error: Error): void
  onErrorTranslate?(error: Error): string
  onRevalidate?(): void
  scope?: string
}

let timeout: NodeJS.Timeout

export function Confirmer({
  toConfirm,
  onError,
  onErrorTranslate,
  onRevalidate,
  onConfirm,
  verificationError,
  fields = 6,
  scope
}: ConfirmerProps) {
  const [input, setInput] = useState('')
  const [error, setError] = useState('')
  const [coolDown, setCooldownTime] = useState(60)

  const minutes = Math.floor(coolDown / 60)
  const seconds = coolDown % 60

  const [minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('')
  const [secondLeft, secondRight] = String(seconds).padStart(2, '0').split('')

  const timeLeft = `${minuteLeft}${minuteRight}:${secondLeft}${secondRight}`

  const isTimerOn = coolDown > 0

  useEffect(() => {
    setError(verificationError ?? '')
  }, [verificationError])

  useEffect(() => {
    if (isTimerOn) {
      timeout = global.setTimeout(() => {
        setCooldownTime(coolDown - 1)
      }, 1000)
    } else {
      clearTimeout(timeout)
    }
  }, [coolDown, isTimerOn])

  const isEmail = EMAIL_REGEX.test(String(toConfirm))

  const onRevalidateAction = () => {
    trackEvents({
      trackServices: ['analytics', 'recording'],
      action: 'challenge_form_confirmer_resend_click',
      category: 'Challenge Form Confirmer',
      label: 'Cliente clica no botão de reenviar o código'
    })

    if (onRevalidate) {
      setCooldownTime(60)

      onRevalidate()
    }

    scope === 'sales' && salesAnalyticsEvents.cancellationChallenge('resend')
  }

  return (
    <form
      onSubmit={event => {
        try {
          event.preventDefault()
          onConfirm(input.toUpperCase())
          setInput('')
        } catch (err) {
          const error = err as AxiosError<any>
          onError && onError(error)
          onErrorTranslate && setError(onErrorTranslate(error))
        }
      }}
    >
      <Stack space="3rem">
        <Stack space="1rem">
          <Heading weight="bold" size="large" color="neutralHigh">
            {isEmail
              ? 'Digite o código de verificação enviado para seu e-mail'
              : 'Digite o código de verificação enviado por SMS'}
          </Heading>

          <Box>
            <Text color="neutralLow" size="large">
              {`${
                isEmail
                  ? 'Enviamos um email com o código de verificação para'
                  : 'Enviamos um SMS com o código de verificação para o telefone'
              } `}
            </Text>
            <Text size="large" weight="bold" color="neutralLow">
              {toConfirm}
            </Text>
          </Box>

          <Box display="inline-flex">
            <Text color="neutralLow">{`Perdi o acesso a este ${isEmail ? 'e-mail' : 'telefone'}.`}&nbsp;</Text>
            <Box
              as="span"
              style={{ textDecoration: 'none', cursor: 'pointer', color: `${theme.light.color.primaryHigh}` }}
              onClick={() => {
                chat.open()
                scope === 'sale' && salesAnalyticsEvents.cancellationChallenge('lost_access')
              }}
            >
              <Text weight="bold" color="primaryHigh">
                Entrar em contato
              </Text>
            </Box>
          </Box>

          <CodeInput
            data-testid="phone-number-code-input"
            name="phone-number-code-input"
            inputMode="numeric"
            fields={fields}
            onChange={(x: any) => setInput(x)}
            inputStyle={{
              textTransform: 'uppercase'
            }}
            value={input}
          />
          {error && (
            <>
              <Box mt="1.5rem" color="danger">
                <Text inheritColor>{error}</Text>
              </Box>
              <Box mt=".5rem" width="50ch">
                <Text color="neutral">
                  {`${
                    isEmail
                      ? 'Certifique-se de que você está digitando o código de verificação enviado para seu'
                      : 'Certifique-se de que você está digitando o código de verificação enviado por SMS para seu'
                  } ${isEmail ? 'email' : 'celular'}`}
                </Text>
              </Box>
            </>
          )}
        </Stack>

        <Flex>
          <Button
            type="submit"
            aria-label="Confirmar código"
            disabled={input.length !== fields}
            onClick={() => {
              trackEvents({
                trackServices: ['analytics', 'recording'],
                action: 'challenge_form_confirmer_button_confirm_click',
                category: 'Challenge Form Confirmer',
                label: 'Cliente clica no botão de confirmação'
              })
              scope === 'sales' && salesAnalyticsEvents.cancellationChallenge('confirm')
            }}
          >
            Confirmar
          </Button>

          <If condition={Boolean(onRevalidate)}>
            <Button
              variant="contentOnly"
              size="large"
              type="button"
              disabled={isTimerOn}
              aria-label="Reenviar código"
              onClick={onRevalidateAction}
            >
              {`Reenviar código ${isTimerOn ? 'em ' + timeLeft : ''}`}
            </Button>
          </If>
        </Flex>
      </Stack>
    </form>
  )
}
