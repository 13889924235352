import { httpWithChallenge } from '~/domains/platform/infra/http/http-with-challenge'
import { httpClientStoneAccount } from '~/domains/stone-account/shared/infra/http-client-stone-account'

export function registerName(
  { accountId, name }: { accountId: string; name: string },
  fetcher = httpWithChallenge,
  client = httpClientStoneAccount
) {
  return fetcher({
    config: {
      url: `/organizations/${accountId}`,
      method: 'PATCH',
      data: { full_name: name }
    },
    client
  })
}
