import { useQueryRequest } from '~/ui/hooks/utils'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

import { type CheckCreditAccess } from '../entities'
import { fetchCheckCreditAccess } from '../services/check-credit-access'

export function useCheckCreditAccess(
  { document }: { document: string },
  queryOptions?: UseQueryRequestOptions<CheckCreditAccess>
) {
  const { data: response, ...restQuery } = useQueryRequest<CheckCreditAccess>(
    ['useCheckCreditAccess'],
    () => fetchCheckCreditAccess({ document }),
    {
      ...queryOptions,
      staleTime: 15 * (60 * 1000) // 15 mins
    }
  )

  const data = response?.data

  return {
    data,
    ...restQuery
  }
}
