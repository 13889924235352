import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  position: relative;
  padding-top: 2rem;

  > :nth-child(1) {
    width: 100%;
  }
`
