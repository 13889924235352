import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'

import { Box } from '~/domains/platform/design-system'

export const Wrapper = styled(Box)<{ active?: boolean; borderless?: boolean }>`
  border: 1px solid
    ${ifProp('active', theme('colors.input.active'), ifProp('borderless', 'none', theme('colors.input.border')))};
  box-sizing: border-box;
  border-radius: ${theme('radii.default')};
  padding: ${ifProp('borderless', 0, theme('space.default'))};
  margin-bottom: ${theme('space.large')};
  width: 100%;
`
