import styled from 'styled-components'
import { ifNotProp, ifProp, theme } from 'styled-tools'

import '@stone-payments/jade-design-tokens/stone/color.css'

export const Side = styled.div<{ transparent?: boolean; minimal: boolean }>`
  background-color: ${ifNotProp('transparent', theme('colors.white'))};
  min-width: min(100vw, 16rem);
  width: 16rem;
  position: ${ifProp('minimal', 'fixed')};
  z-index: ${ifProp('minimal', theme('zIndices.sideBarMobile'), theme('zIndices.sideBar'))};
  border-right: 1px solid ${theme('colors.border.disabled')};
  overflow-y: ${ifProp('minimal', 'auto')};
  height: ${ifProp('minimal', '100%')};
`

export const BackgroundOverlay = styled.div<{ minimal: boolean }>`
  height: 100%;
  width: 100%;
  display: ${ifProp('minimal', 'block', 'none')};
  position: fixed;
  z-index: ${theme('zIndices.sideBarMobile')};
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
`

export const Container = styled.div<{ fullscreen: boolean }>`
  max-width: ${({ fullscreen }) => (fullscreen ? 'unset' : '1024px')};
  background: ${({ fullscreen, theme }) =>
    fullscreen ? `var(--theme-color-background-body)` : theme.colors.lightGray};

  width: 100%;
  margin: 0 auto;
  padding: ${padding('40px')};

  @media (max-width: 1200px) {
    padding: ${padding('32px')};
  }

  @media (max-width: 768px) {
    padding: ${padding('20px 16px')};
  }
`

function padding(value: string) {
  return function ({ fullscreen }: { fullscreen: boolean }) {
    return fullscreen ? '0px' : value
  }
}
