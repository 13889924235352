import { useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'
import dayjs from 'dayjs'

import { Box, Text } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { useToast } from '~/domains/platform/layout/toast'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { dynamicTemplate, stringFormat } from '~/lib/helpers'
import { resourceTypeCancel, shareReceipt } from '~/lib/services'
import { type SchedulingEntry, type SchedulingTypeCancel } from '~/lib/types'
import { useInfiniteList } from '~/ui/contexts'

import { translateType } from '../../translation'

export const Toolbar = ({ entry, cancellCallback }: { entry: SchedulingEntry; cancellCallback: () => void }) => {
  const { type, id: resourceId, status, amount, scheduledTo, accountId } = entry

  const scheduledToDate = scheduledTo

  const [loading, toggleLoading] = useToggle(false)
  const [showConfirm, toggleConfirm] = useToggle(false)

  const { listActions, list } = useInfiniteList()
  const { addToast } = useToast()

  const isNotRefunded = status !== 'REFUNDED'

  const typeResourceMap: Record<SchedulingTypeCancel, string> = {
    internal_transfer: 'internal_transfer',
    external_transfer: 'external_transfer',
    barcode_payment: 'payment',
    pix_payment: 'outbound_pix_payment',
    refund_for_pix_payment: 'refund_for_pix_payment',
    barcodeless_payment: 'barcodeless_payment'
  }

  const resourceType = typeResourceMap[type]
  const translatedType = translateType(type)

  const CONFIRM_CANCEL_MESSAGE = dynamicTemplate(
    'Você irá cancelar um ${type} agendado para ${date} no valor de ${amount}.',
    {
      type: translatedType,
      date: dayjs(scheduledToDate).format('LL'),
      amount: stringFormat.currency(Math.abs(amount))
    }
  )

  const cancel = async () => {
    toggleLoading(true)
    try {
      await resourceTypeCancel({
        accountId,
        operationId: resourceId,
        type
      })

      if (list) {
        listActions?.removeAt(list.findIndex(item => item.id === resourceId))
      }

      addToast({ message: 'Agendamento cancelado com sucesso', type: 'success' })
    } catch (error) {
      addToast({ message: 'Não foi possível cancelar o agendamento. Entre em contato com a gente.', type: 'error' })
    }
    toggleLoading(false)
    cancellCallback()
  }

  const share = () => shareReceipt('pdf')({ id: resourceId, type: resourceType, name: translatedType })

  return (
    <If condition={isNotRefunded}>
      <Box sx={{ position: 'relative' }}>
        <Dimmer isVisible={loading}>
          <Loader />
        </Dimmer>
        <Stack space="1rem">
          <Choose>
            <Choose.When condition={showConfirm}>
              <Text fontWeight="bolder">{CONFIRM_CANCEL_MESSAGE}</Text>
              <Button size="large" type="button" onClick={toggleConfirm}>
                Não, voltar
              </Button>
              <Button color="negative" variant="contentOnly" size="large" type="button" onClick={cancel}>
                Sim, cancelar
              </Button>
            </Choose.When>
            <Choose.Otherwise>
              <Button color="negative" variant="contentOnly" size="large" type="button" onClick={toggleConfirm}>
                Cancelar
              </Button>
              <Button size="large" type="button" onClick={share}>
                Baixar Comprovante
              </Button>
            </Choose.Otherwise>
          </Choose>
        </Stack>
      </Box>
    </If>
  )
}
