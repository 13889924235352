import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/ports'
import { withInjectedProps } from '~/domains/platform/infra/page-enhancers/compositional/with-injected-props'
import { Wizard } from '~/ui/components/modals/wizard/wizard'

import { ChooseKind, CompanyInfo, Info, ReviewData, Success } from './partials'

export function CreateAccount(props: {
  isOpen?: boolean
  subject: Subject
  toggle: () => void
  implicit?: boolean
  entity: Entity
}) {
  const { isOpen, subject, toggle, entity, implicit } = props
  const { paymentAccounts } = subject

  const hasPersonalAccount = paymentAccounts.some(account => account.ownerDocument === subject.document)

  const withChooseKindProps = withInjectedProps({ hasPersonalAccount, subject })
  const withReviewDataProps = withInjectedProps({ entity, subject, implicit })
  const withAccounts = withInjectedProps({ paymentAccounts })

  const steps = [
    {
      mainContent: withChooseKindProps(ChooseKind),
      sideContent: Info,
      hideIfImplicit: true
    },
    {
      mainContent: withAccounts(CompanyInfo),
      sideContent: Info
    },
    {
      mainContent: withReviewDataProps(ReviewData),
      sideContent: Info
    },
    {
      mainContent: Success,
      sideContent: Info
    }
  ].filter(step => !(implicit && step.hideIfImplicit))

  return (
    <Wizard
      isOpen={Boolean(isOpen)}
      openOnStep={implicit ? 1 : 0}
      steps={steps}
      title="Criação de conta"
      toggle={toggle}
    />
  )
}
