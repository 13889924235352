import { findResourceById } from '~/lib/helpers/auth/resources'
import { unmask } from '~/lib/helpers/utils/unmask'
import { type Account, type MerchantsContentProps, type Organization, type RawBFF, type Resource } from '~/lib/types'

import { accountSelector } from '../../helpers/account-selector'

import { organizationCreatedAtDecorator } from './organization-created-at-decorator'
import { organizationDecorator } from './organization-decorator'

export function organizationsDecorator(
  rawBff: RawBFF,
  usersStoneCodes: MerchantsContentProps[],
  resources: Resource[]
) {
  const { organizations, organizationsKycRequests, accounts } = rawBff

  return organizations?.data.map(organization => {
    const result = organizationDecorator({
      organization,
      stoneCode: stoneCode(),
      accountScopes: accountScopes(),
      assessment: organizationsKycRequests?.data.find(
        assessment => assessment.organizationId === organization.id.split(':')[1]
      )
    })

    return {
      ...result,
      createdAt: organizationCreatedAtDecorator({
        organization: result,
        stoneCodes: usersStoneCodes
      })
    } as Organization

    function stoneCode() {
      return usersStoneCodes.find(item => organization.document === unmask(item.document))
    }

    function accountScopes() {
      const paymentAccount = accountSelector(accounts as Account[], organization.id)
      const paymentAccountResource = findResourceById(resources, String(paymentAccount?.id))
      return paymentAccountResource?.scopes.map(x => x.name)
    }
  })
}
