import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToggle } from 'react-use'
import { Cluster, Grid, Stack } from '@dlpco/fluid-layout'
import { Alert, Badge, Button } from '@dlpco/ginga-stone'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { Field } from '~/domains/platform/design-system/field/field'
import { MoneyInput } from '~/domains/platform/design-system/money-input/money-input'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { type Sale } from '~/lib/types'
import { Money } from '~/ui/components/fields/money'

import { statusBadgeMap } from '../status-badge'

import { CancelTransaction } from './partials'
import * as S from './styles'

interface SaleCancelProps {
  sale: Sale
  back(): void
  onBackToSales(): void
  email: string
}

export function SaleCanCancelTransaction({ sale, back, email, onBackToSales }: SaleCancelProps) {
  const {
    grossAmount,
    status,
    card: { brand, fundingAccount, lastDigits },
    installmentsQty,
    affiliationCode,
    id
  } = sale

  const fixedAmount = fundingAccount === 'Débito' || fundingAccount === 'Voucher'

  const [editAmount, toggleEditAmount] = useToggle(false)
  const [disabled, toggleDisabled] = useToggle(false)
  const [isConfirming, toggleIsConfirming] = useToggle(false)
  const [amountToCancel, setAmountToCancel] = useState<number>(grossAmount)

  const { register, watch } = useForm<{ amount: string }>({
    defaultValues: {
      amount: stringFormat.formatCurrency(grossAmount)
    }
  })

  const amount = stringFormat.normalizeAmount(watch('amount', stringFormat.formatCurrency(grossAmount)))

  const partialRefund = amount < grossAmount

  useEffect(() => toggleDisabled(amount === 0 || amount > grossAmount), [toggleDisabled, amount, grossAmount])

  return (
    <S.Wrapper>
      <Choose>
        <Choose.When condition={!isConfirming}>
          <Stack space="1.5rem">
            <Text fontSize="large" fontWeight="body" color="darkGray">
              Detalhes da venda
            </Text>
            <Grid gutter="2rem" min="30ch">
              <Money pretty renderProps={{ color: 'colors.darkGray' }} label="Valor bruto" value={grossAmount} />
              <Field label="Bandeira" value={brand} />
              <Field label="Tipo" value={`${fundingAccount} ${installmentsQty}x`} />
              <Box />
            </Grid>
            <Grid gutter="1.5rem" min="30ch">
              <Field label="Número do cartão" value={lastDigits} />
              <Field label="Status" value={status}>
                {value => (
                  <Flex>
                    <Badge color={statusBadgeMap[status]}>{value}</Badge>
                  </Flex>
                )}
              </Field>
              <Box />
              <Box />
            </Grid>
            <Divider />
            <Grid gutter="1.5rem" min="5ch">
              <Choose>
                <Choose.When condition={editAmount}>
                  <Cluster>
                    <MoneyInput label="Valor Bruto a ser cancelado" {...register('amount')} />
                    <Text fontSize="small">
                      Você pode cancelar até <Text fontWeight="bold">{stringFormat.currency(grossAmount)}</Text>
                    </Text>
                  </Cluster>
                </Choose.When>
                <Choose.Otherwise>
                  <Money
                    pretty
                    renderProps={{ color: 'red' }}
                    label="Valor Bruto a ser cancelado"
                    value={grossAmount}
                  />
                  <Box>
                    <Button
                      color="positive"
                      variant="contentOnly"
                      icon="edit-outline"
                      disabled={fixedAmount}
                      onClick={toggleEditAmount}
                    >
                      Editar valor
                    </Button>
                  </Box>
                </Choose.Otherwise>
              </Choose>
              <Box />
              <Box />
              <Box />
              <Box />
            </Grid>
            <Divider />
            <If condition={fundingAccount !== 'Crédito'}>
              <Alert heading="Deseja cancelar essa venda?">
                {fundingAccount === 'Voucher'
                  ? 'Vendas por voucher só podem ser canceladas pelo emissor do cartão.'
                  : 'No débito você apenas pode cancelar o valor total da venda.'}
              </Alert>
            </If>
            <Grid gutter="1.5rem" min="30ch">
              <Button color="neutral" size="large" onClick={back}>
                Voltar
              </Button>
              <Button
                color="negative"
                size="large"
                disabled={disabled}
                onClick={() => {
                  setAmountToCancel(editAmount ? amount : grossAmount)
                  toggleIsConfirming(true)
                }}
              >
                {partialRefund ? 'Cancelar Parcialmente' : 'Cancelar Venda'}
              </Button>
              <Box />
              <Box />
            </Grid>
          </Stack>
        </Choose.When>
        <Choose.Otherwise>
          <CancelTransaction
            email={email}
            transactionId={id}
            affiliationCode={affiliationCode}
            amountToCancel={amountToCancel}
            onBackToSales={onBackToSales}
          />
        </Choose.Otherwise>
      </Choose>
    </S.Wrapper>
  )
}
