import { useToggle } from 'react-use'
import { Grid, Stack, StackSplit } from '@dlpco/fluid-layout'
import { Button, CopyButton } from '@dlpco/ginga-stone'

import { Box, Text } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { SuccessIllustration } from '~/domains/platform/design-system/illustrations/success.illustration'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { useToast } from '~/domains/platform/layout/toast'
import { exportFile, stringFormat, trackAnalyticsEvent } from '~/lib/helpers'
import { generateBoleto as generateBoletoService } from '~/lib/services'
import { WizardBanner } from '~/ui/components/modals/wizard'
import { useWizardProps } from '~/ui/components/modals/wizard/use-wizard-props'

export function Banner() {
  const { content } = useWizardProps()
  const { addToast } = useToast()
  const [loading, toggleLoading] = useToggle(false)

  async function generateBoleto() {
    try {
      toggleLoading()
      const { data } = await generateBoletoService({
        boletoId: content?.id
      })

      await exportFile({
        data,
        name: `${content?.id}.pdf`
      })
    } catch (error) {
      addToast({ type: 'error', message: 'Problemas ao gerar boleto. Tente novamente' })
    } finally {
      toggleLoading()
    }
  }

  if (content) {
    const { currency } = stringFormat
    const amountDescription = `Você acabou de criar um Boleto Stone de ${currency(content.amount)}.`

    return (
      <Stack space="1rem">
        <StackSplit>
          <WizardBanner>
            <Box>
              <SuccessIllustration />
              <Box alignSelf="center">
                <Stack space="1.5rem">
                  <Text fontSize="xxLarge">Tudo certo!</Text>
                  <Text fontSize="medium">{amountDescription}</Text>
                  <Text fontSize="medium">O dinheiro cai na sua Conta em até dois dias úteis após o pagamento.</Text>
                </Stack>
              </Box>
            </Box>
          </WizardBanner>
        </StackSplit>

        <StackSplit>
          <Grid gutter="2rem" min="20ch">
            <Button
              size="large"
              onClick={() => {
                generateBoleto()
                trackAnalyticsEvent({
                  action: 'click',
                  category: 'Deposit',
                  label: 'Clicar em baixar boleto de deposito na tela de sucesso de depositar'
                })
              }}
            >
              Baixar boleto
            </Button>

            <Dimmer isVisible={loading}>
              <Loader />
            </Dimmer>

            <CopyButton color="neutral" copiedValue={content.writableLine}>
              Copiar o código
            </CopyButton>
          </Grid>
        </StackSplit>
      </Stack>
    )
  }

  return (
    <Dimmer isVisible>
      <Loader />
    </Dimmer>
  )
}
