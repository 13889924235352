import { unmask } from '~/domains/banking/shared/helpers/unmask'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import {
  type Account,
  type Identity,
  type MerchantsContentProps,
  type Organization,
  type PurchaseStoneCode,
  type User
} from '~/lib/types'

import { accountSelector } from '../../helpers/account-selector'
import { identitySelector } from '../../helpers/identity-selector'
import { type BffData } from '../services/fetch-bff'
import { type Entity, type OptIns, type PaymentAccount, type Person, type Subject } from '../types'

import { entityFingerprint, personFingerprint, subjectFingerprint } from './assertions'
import { kycRequestSelector } from './kycRequestSelector'

const merchantDetailToPurchaseStoneCode = (merchantDetail: MerchantsContentProps): PurchaseStoneCode => {
  return {
    affiliationKey: merchantDetail.affiliationKey,
    cnpj: merchantDetail.document,
    fantasyName: merchantDetail.fantasyName,
    legalName: merchantDetail.companyName,
    mcc: merchantDetail.mccDescription,
    mccNumber: merchantDetail.mccId,
    stoneCode: merchantDetail.affiliationCode
  }
}

const personParser = (rawPerson: User | Organization): Person => {
  const isOrganization = 'documentType' in rawPerson

  return {
    [personFingerprint]: true,
    roles: rawPerson.metadata.roles,
    document: rawPerson.document,
    documentType: isOrganization ? rawPerson.documentType.toUpperCase() : stringFormat.documentType(rawPerson.document),
    email: rawPerson.email,
    displayName: rawPerson.fullName || rawPerson.legalName || 'Não informado',
    id: rawPerson.id,
    createdAt: rawPerson.createdAt,
    approvalStatus: rawPerson.approvalStatus,
    unsafe: { fullName: rawPerson.fullName || 'Não informado', legalName: rawPerson.legalName || 'Não informado' }
  }
}

const paymentAccountParser = (rawAccount: Account): PaymentAccount => {
  return {
    accountCode: rawAccount.accountCode,
    branchCode: rawAccount.branchCode,
    id: rawAccount.id,
    createdAt: rawAccount.createdAt,
    ownerDocument: rawAccount.ownerDocument,
    ownerId: rawAccount.ownerId,
    ownerName: rawAccount.ownerName,
    status: rawAccount.status,
    enabledFeatures: rawAccount.enabledFeatures,
    ownerKycStatus: rawAccount.metadata.ownerKycStatus
  }
}

const subjectParser = ({ bff: rawBff, credentials }: BffData): Subject<OptIns> => {
  const {
    user: rawSubject,
    organizations,
    users,
    userKycRequests,
    accounts,
    organizationsKycRequests,
    stoneCodes
  } = rawBff

  return {
    [subjectFingerprint]: true,
    displayName: rawSubject.fullName,
    email: rawSubject.email,
    phoneNumber: rawSubject.phoneNumber?.value,
    deviceType: rawSubject.deviceType,
    address: rawSubject.address?.value,
    document: rawSubject.document,
    approvalStatus: rawSubject.approvalStatus,
    kycStatus: rawSubject.metadata.kycStatus,
    emailVerified: Boolean(rawSubject.emailVerified),
    id: rawSubject.id,
    challengeBlocked: Boolean(rawSubject.challengeBlocked),
    organizations: organizations.map(personParser),
    users: users.map(personParser),
    kycRequest: userKycRequests,
    changeEmailRequest: rawSubject.changeEmailRequest,
    showKycChecks: rawSubject.showKycChecks,
    kycApproved: rawSubject.kycApproved,
    rejectedKycChecks: rawSubject.rejectedKycChecks,
    paymentAccounts: accounts.map(paymentAccountParser),
    enabledFeatures: rawSubject.enabledFeatures,
    createdAt: rawSubject.createdAt,
    credentials,
    unsafe: {
      /**
       * @deprecated Use `displayName` instead
       */
      fullName: rawSubject.fullName,
      /**
       * @deprecated Use `displayName` instead
       */
      legalName: rawSubject.legalName,
      /**
       * @deprecated Actually the entity has an account but we are attaching this to the subject
       */
      hasAnActivePaymentAccount: Boolean(
        accounts.find(acc => acc.ownerId === rawSubject.id && acc.status === 'ACTIVE')
      ),
      roles: rawSubject.metadata.roles,
      /**
       * @deprecated Use `entity.kycRquest` instead
       * @todo Remove this asap
       */
      entitiesKycRequests: organizationsKycRequests,
      /**
       * @deprecated We should not iterate subject owned stone codes
       * @todo Remove this asap
       */
      stoneCodes: stoneCodes.map(merchantDetailToPurchaseStoneCode)
    }
  }
}

const entityParser = (identity: Identity, rawData: BffData): Entity<OptIns> => {
  const { bff: rawBff } = rawData

  const { userKycRequests: userAssessment, organizationsKycRequests: organizationAssessments, accounts } = rawBff

  const currentAccount = accountSelector(accounts, identity.id)
  const kycRequest = kycRequestSelector(identity, organizationAssessments, userAssessment)

  return {
    [entityFingerprint]: true,
    ...personParser(identity),
    enabledFeatures: identity.enabledFeatures,
    rejectedKycChecks: identity.rejectedKycChecks,
    kycStatus: identity.metadata.kycStatus,
    paymentAccount: (currentAccount && paymentAccountParser(currentAccount)) as PaymentAccount,
    hasAnActivePaymentAccount: Boolean(currentAccount),
    kycRequest,
    stoneCodes:
      rawBff.stoneCodes
        ?.filter(({ document }) => unmask(document) === identity.document)
        .map(merchantDetailToPurchaseStoneCode) || []
  }
}

export const bffParser = (rawData: BffData) => {
  const identity = identitySelector(rawData.bff)

  return {
    subject: subjectParser(rawData),
    entity: entityParser(identity, rawData)
  }
}
