import { useEffect, useState } from 'react'

/**
 * Hook that returns a boolean indicating if the component is mounted.
 */
export function useMounted() {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return mounted
}
