import { AsideLayout } from '~/domains/platform/design-system/aside-layout/aside-layout'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/types'
import { If } from '~/domains/platform/lib/utilities-components'
import { type Scope } from '~/lib/types'
import { AccountDetails } from '~/ui/business-components/banking/account-details'

import { AccessAccountItem } from '../access-account'

import { CardWrapper, StackWrapper } from './styles'
import { ConsentSuccess } from './success'

export function ConsentSuccessFull(props: {
  account: PaymentAccount
  link: string
  implicit: boolean
  options?: Scope[]
  name?: string
}) {
  const {
    options,
    account: { ownerName, ownerDocument, id },
    link,
    implicit,
    name
  } = props
  return (
    <CardWrapper>
      <AsideLayout
        aside={
          <If
            condition={implicit}
            render={() => (
              <StackWrapper space="3.7rem">
                <AccountDetails ownerDocument={ownerDocument} ownerName={ownerName} id={id} />
                <If condition={Boolean(options)}>
                  <AccessAccountItem consentableScopes={options} />
                </If>
              </StackWrapper>
            )}
          />
        }
      >
        <ConsentSuccess name={name} link={link} />
      </AsideLayout>
    </CardWrapper>
  )
}
