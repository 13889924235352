import { type Entity } from '~/domains/platform/infra/deus-ex-machina/ports'

import { type Assessment } from './entities/KYC'

export function kycFormatAssessmentStatusData(
  entity: Entity,
  userKycRequest?: Assessment,
  organizationsKycRequest?: Assessment[]
) {
  return {
    identityKycStatus: entity?.kycStatus,
    userAssessment: userKycRequest,
    organizationAssessments: organizationsKycRequest
  }
}
