import { type UseRequestStatus } from './use-request'

interface UseStatusOptions {
  considerAllStatus?: boolean
}

export const useStatus = (
  statusList: UseRequestStatus[],
  statusesToConsolidate: UseRequestStatus[],
  options?: UseStatusOptions
) => {
  const consolidatedStatus = []

  statusesToConsolidate.map(consolidate =>
    statusList.map(list => list === consolidate && consolidatedStatus.push(list))
  )

  const consolidatedStatusLength = consolidatedStatus.length

  return options?.considerAllStatus ? consolidatedStatusLength === statusList.length : consolidatedStatusLength > 0
}
