import styled, { css } from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

import { Text } from '~/domains/platform/design-system'

export const Label = styled(Text)`
  display: flex;
  align-items: center;
  color: ${ifProp(
    'color',
    theme(`colors.${prop('color')}`),
    css`
      ${theme('colors.mediumGray2')}
    `
  )};
  font-size: ${ifProp(
    'fontSize',
    theme(`fontSizes.${prop('fontSize')}`),
    css`
      ${theme('fontSizes.normal')}
    `
  )};
  font-weight: ${ifProp(
    'fontWeight',
    theme(`fontWeights.${prop('fontWeight')}`),
    css`
      ${theme('fontWeights.normal')}
    `
  )};
`

export const Image = styled.img<{ leased?: boolean; imageWidth?: string }>`
  max-width: ${ifProp('imageWidth', prop('imageWidth'), '100%')};
  border-radius: ${ifProp('leased', '50%', '0')};
`

export const Value = styled(Text)`
  color: ${ifProp(
    'color',
    theme(`colors.${prop('color')}`),
    css`
      ${theme('colors.darkGray')}
    `
  )};
  font-size: ${ifProp(
    'fontSize',
    prop('fontSize'),
    css`
      ${theme('fontSizes.medium')}
    `
  )};
  overflow-wrap: break-word;
  font-weight: ${ifProp(
    'fontWeight',
    theme(`fontWeights.${prop('fontWeight')}`),
    css`
      ${theme('fontWeights.medium')}
    `
  )};
`
