import axios, { type AxiosTransformer } from 'axios'

import { authenticator } from '~/domains/platform/core/authenticator/authenticator'

import { type CustomAxiosRequestConfig, type HttpClientInstance } from './http-client-factory'
import { transformResponse } from './http-helpers'

interface HttpWithChallenge {
  client: HttpClientInstance
  config: CustomAxiosRequestConfig
}

export interface AnswerChallenge {
  challengeId?: string
  passwordType?: string
  password?: string
  verificationRequired?: string
  code?: string
}

interface PayloadChallenge {
  challengeId: string
  passwordType: string
  password: string
}

interface ChallengeAnswerPayload {
  challenge_id: string
  totp: string | null
  pin: string | null
  login_password: string | null
}

async function encryptPayload({ challengeId, passwordType, password }: PayloadChallenge) {
  const challengeAnswerPayload = {
    challenge_id: challengeId,
    totp: passwordType === 'otp' ? password : null,
    pin: passwordType === 'pin' ? password : null,
    login_password: passwordType === 'password' ? password : null
  }

  const challengeAnswer = await authenticator.encryptWithPublickey<ChallengeAnswerPayload>(challengeAnswerPayload)
  return challengeAnswer
}

export function httpWithChallenge({ config, client }: HttpWithChallenge) {
  return async (payload?: AnswerChallenge) => {
    if (
      payload?.challengeId &&
      payload?.passwordType &&
      payload?.password &&
      payload?.verificationRequired &&
      payload?.code
    ) {
      const { challengeId, passwordType, password } = payload
      const challengeAnswer = await encryptPayload({ challengeId, passwordType, password })

      return await client({
        haveToLog: false,
        ...config,
        headers: {
          ...config.headers,
          'X-Stone-Challenge-Solution': challengeAnswer,
          'x-stone-verification-id': payload.verificationRequired,
          'x-stone-verification-code': payload.code
        },
        transformResponse: [...(axios.defaults.transformResponse as AxiosTransformer[]), transformResponse]
      })
    }

    if (payload?.challengeId && payload?.passwordType && payload?.password) {
      const { challengeId, passwordType, password } = payload
      const challengeAnswer = await encryptPayload({ challengeId, passwordType, password })

      return await client({
        haveToLog: false,
        ...config,
        headers: {
          ...config.headers,
          'X-Stone-Challenge-Solution': challengeAnswer
        },
        transformResponse: [...(axios.defaults.transformResponse as AxiosTransformer[]), transformResponse]
      })
    }

    if (payload?.verificationRequired && payload?.code) {
      return await client({
        haveToLog: false,
        ...config,
        headers: {
          ...config.headers,
          'x-stone-verification-id': payload.verificationRequired,
          'x-stone-verification-code': payload.code
        },
        transformResponse: [...(axios.defaults.transformResponse as AxiosTransformer[]), transformResponse]
      })
    }

    return await client({
      haveToLog: false,
      ...config,
      transformResponse: [...(axios.defaults.transformResponse as AxiosTransformer[]), transformResponse]
    })
  }
}
