import styled from 'styled-components'
import { theme } from 'styled-tools'

export const OperationsTable = styled.div`
  > * {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    font-weight: ${theme('fontWeights.medium')};
    padding: 1rem;
    grid-gap: 1rem;
    align-items: center;

    > *:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`

export const OperationsTableHeader = styled.div`
  background: ${theme('colors.mediumGray4')};
  color: ${theme('colors.mediumGray')};
  border-bottom: 1px solid ${theme('colors.lightGray')};
`
