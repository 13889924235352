import { fetchMerchantsBySplit } from '~/domains/acquirer/ports/hooks/use-merchants'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'

export async function conversionMenu(entity: Entity<'acquirer'>) {
  const planFlag = Heimdall.pass(['autocred_web'])

  const merchants = await fetchMerchantsBySplit(entity.stoneCodes.map(item => item.stoneCode))

  const salesChannelId = merchants?.filter(item => item.salesChannelId === 10)
  const acquirerSalesChannelId = Boolean(entity.stoneCodes?.length === salesChannelId?.length)
  const showAutocredItem = entity.roles.banking && acquirerSalesChannelId

  return [
    {
      order: 4,
      label: 'Planos',
      icon: 'notebook-outline',
      show: planFlag && showAutocredItem,
      path: '/planos/ofertas',
      active: (route: string) => ['/smb/conversion/autocred/plans'].includes(route)
    }
  ]
}
