import { type PropsWithChildren } from 'react'

import { type AvatarProps } from './interface'
import { Circle } from './styles'

export const Avatar = ({ name, children, ...restProps }: PropsWithChildren<AvatarProps>) => {
  const [firstLetter] = name.split('')

  return (
    <Circle aria-hidden data-testid="letter" {...restProps}>
      {children || firstLetter}
    </Circle>
  )
}
