import styled from 'styled-components'

import { Card } from '~/domains/platform/design-system'

export const FormWrapper = styled.div`
  min-width: 260px;
  transform-origin: 50% 0%;
  margin-top: 1rem;
  z-index: 999;
`.withComponent(Card)

export const Wrapper = styled.div`
  position: relative;
`
export const ButtonWrapper = styled.div`
  position: relative;
  > :nth-child(1) {
    width: 100%;
  }
`
