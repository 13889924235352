import { type BadgeComponent, type OwnPropsOf } from '@dlpco/ginga-stone'

import { type SaleOperation } from '~/lib/types'

export const statusBadgeMap: Record<SaleOperation['status'], OwnPropsOf<BadgeComponent>['color']> = {
  Capturada: 'positive',
  Recebida: 'positive',
  'Chargeback estorno': 'positive',
  Chargeback: 'positive',
  Antecipada: 'neutral',
  Cancelada: 'negative',
  'Cancelada Parcialmente': 'negative',
  'Cancelamento solicitado': 'warning',
  'Chargeback parcial': 'negative',
  Compensado: 'positive',
  'A receber': 'neutral'
}
