import { useEffect, useState } from 'react'
import { isBrowser } from 'react-device-detect'
import { type NextPage } from 'next'
import NextLink from 'next/link'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Display, Heading, Text } from '@dlpco/ginga-stone'

import { authenticator } from '~/domains/platform/core/authenticator/authenticator'
import { ExclamationIllustration } from '~/domains/platform/design-system/illustrations'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { logger } from '~/domains/platform/infra/monitoring/logger'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'
import { TopBarLayout } from '~/ui/components/layouts/top-bar-layout'
import { CleanPageContainer, ErrorContainer } from '~/ui/containers'
import { useQueryRequest } from '~/ui/hooks/utils'

import { exMachina } from '../core'
import { type Entity, type OptIns, type Subject, type WithDeusExMachinaProps } from '../types'

interface DeusExMachinaProps {
  entity: Entity<OptIns>
  subject: Subject<OptIns>
}

let lock = false
export const withDeusExMachina = (
  Page: NextPage<Omit<WithDeusExMachinaProps, keyof DeusExMachinaProps>>
): NextPage<Omit<WithDeusExMachinaProps, keyof DeusExMachinaProps>> => {
  function WrappedPage(props: Omit<WithDeusExMachinaProps, keyof DeusExMachinaProps>) {
    const { isSuccess, deusExMachina, isError, error } = useDeusExMachina()

    if (isSuccess && deusExMachina) {
      return <Page {...deusExMachina} {...props} />
    }

    return (
      <Choose>
        <Choose.When
          condition={isError}
          render={() => {
            const title = error?.response?.status ? `Erro ${error.response.status}` : undefined
            const requestId = error?.response?.headers['x-request-id']
            const caption = requestId && `id:${requestId}`

            return <Error title={title} caption={caption} />
          }}
        />
        <Choose.Otherwise>
          <CleanPageContainer>
            <Loader />
          </CleanPageContainer>
        </Choose.Otherwise>
      </Choose>
    )
  }

  WrappedPage.displayName = `withDeusExMachinaPages(${Page.displayName})`

  return WrappedPage
}

export function useDeusExMachina() {
  const { identityId } = isomorphicCookies.getAll()
  const [isSessionValid, setIsSessionValid] = useState(authenticator.isSessionValid())

  const {
    data: deusExMachina,
    isSuccess,
    isError,
    error
  } = useQueryRequest<DeusExMachinaProps, unknown, true>(['useDeusExMachina', identityId], () => exMachina(), {
    enabled: isSessionValid,
    staleTime: 30000
  })

  {
    /*
      @disclaimer: We're settings the cookie to have an expiration of 6 months as a hotfix.
                   This should be remove ASAP and moved along with token existance verification
                   to a session lib that should be release on v1.26 or v1.27.
    */
  }
  deusExMachina?.entity?.id &&
    isomorphicCookies.set(
      'identityId',
      deusExMachina?.entity.id,
      { maxAge: 60 * 60 * 24 * 30 * 6 } // ~6 months
    )
  deusExMachina?.entity?.paymentAccount?.id &&
    isomorphicCookies.set(
      'accountId',
      deusExMachina?.entity.paymentAccount.id || '',
      { maxAge: 60 * 60 * 24 * 30 * 6 } // ~6 months
    )

  useEffect(() => {
    async function revalidateSession() {
      if (!lock) {
        lock = true
        try {
          await authenticator.revalidateSession()
          if (authenticator.isSessionValid()) setIsSessionValid(true)
          else throw 'Cannot revalidate session. Will log user out.'
        } catch (error) {
          BlackBird.leave({ shouldRedirect: true })
        } finally {
          lock = false
        }
      }
    }
    if (!isSessionValid) revalidateSession()
  })

  return { deusExMachina, isSuccess, isError, error }
}

function Error({ title, caption, error }: { title?: string; caption?: string; error?: Error }) {
  const isLoggedIn = Boolean(isomorphicCookies.getAll()['token'])

  useEffect(() => {
    if (error) logger(error)
  }, [error])

  return (
    <TopBarLayout>
      <ErrorContainer
        illustration={<ExclamationIllustration width={400} height={400} />}
        title={isBrowser ? title : 'Erro 500'}
      >
        <Stack space="4rem">
          <Display>Oops, aconteceu algo inesperado.</Display>
          <If
            condition={Boolean(caption)}
            render={() => (
              <Text size="small" color="neutralLow">
                {caption}
              </Text>
            )}
          />
          <Heading size="large">
            Já estamos resolvendo para você tentar novamente daqui a pouco. Se precisar, fale com a gente.
          </Heading>
          <Heading color="neutral">Estamos disponíveis 24h por dia, todos os dias da semana</Heading>
          <If condition={isLoggedIn}>
            <NextLink href="/sair" passHref legacyBehavior>
              <Button as="a" size="large" color="neutral">
                Entrar com outra conta
              </Button>
            </NextLink>
          </If>
        </Stack>
      </ErrorContainer>
    </TopBarLayout>
  )
}
