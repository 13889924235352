import { unmask } from '~/domains/banking/shared/helpers/unmask'
import { type KYC } from '~/domains/stone-account/ports/entities/KYC'
import { translateMetadataStatus } from '~/domains/stone-account/ports/kyc-translate-status'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { type PurchaseStoneCode, type Roles, type SetNullable } from '~/lib/types'

import { type EntityAccount, type EntityAccountDetail, type MeDetails } from '../entities'
import { type EntityData } from '../services/fetch-entity-detail'
import {
  type Entity,
  type OptIns,
  type PaymentAccount,
  type Person,
  type RawPaymentAccount,
  type Subject
} from '../types'

import { entityFingerprint, personFingerprint, subjectFingerprint } from './assertions'
import { kycRequestSelector } from './kycRequestSelector'
import { paymentAccountSelector } from './paymentAccountSelector'

type PersonPlatform = Pick<
  SetNullable<EntityAccountDetail, 'displayName'>,
  | 'id'
  | 'type'
  | 'document'
  | 'documentType'
  | 'email'
  | 'displayName'
  | 'approvalStatus'
  | 'resourceTypes'
  | 'fullName'
  | 'legalName'
  | 'insertedAt'
>

/**
 * @disclaimer It just worked using '{}' as type
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function renameProperty<T extends {}, K extends keyof T, N extends string>(obj: T, oldKey: K, newKey: N) {
  const cloneObject = Object.assign(obj, { [newKey]: obj[oldKey] })

  delete cloneObject[oldKey]

  return cloneObject as unknown as Omit<T, K> & { [k in N]: T[K] }
}

const roleParser = (resourceTypes: EntityAccountDetail['resourceTypes']): Roles => {
  return {
    acquirer: resourceTypes.includes('srn:resource:acquireraffiliation'),
    banking: resourceTypes.includes('srn:resource:paymentaccount'),
    credit: resourceTypes.includes('srn:resource:credit:borrower') || resourceTypes.includes('src:resource:creditline')
  }
}

export const personParser = (rawEntityDetail: PersonPlatform): Person => {
  const { id, type } = rawEntityDetail

  const idWithTypeDecorator = `${type}:${id}`

  return {
    [personFingerprint]: true,
    document: rawEntityDetail.document,
    documentType: rawEntityDetail.documentType,
    email: rawEntityDetail.email,
    displayName: rawEntityDetail.displayName || 'Não informado',
    id: idWithTypeDecorator,
    approvalStatus: rawEntityDetail.approvalStatus,
    roles: roleParser(
      /**
       * @disclaimer In entity list the resourceTypes is called resources
       */
      rawEntityDetail.resourceTypes || ((rawEntityDetail as any).resources as EntityAccountDetail['resourceTypes'])
    ),
    createdAt: rawEntityDetail.insertedAt,
    unsafe: {
      fullName: rawEntityDetail.fullName,
      legalName: rawEntityDetail.legalName
    }
  }
}

export const paymentAccountParser = (rawPaymentAccount: RawPaymentAccount, ownerKycStatus: KYC): PaymentAccount => {
  return {
    accountCode: rawPaymentAccount.accountCode,
    branchCode: rawPaymentAccount.branchCode,
    id: rawPaymentAccount.id,
    createdAt: rawPaymentAccount.createdAt,
    ownerId: rawPaymentAccount.ownerId,
    status: rawPaymentAccount.status,
    ownerDocument: rawPaymentAccount.ownerDocument,
    ownerName: rawPaymentAccount.ownerName,
    ownerKycStatus: ownerKycStatus,
    enabledFeatures: rawPaymentAccount.enabledFeatures
  }
}

const entityParser = (
  rawEntityDetail: EntityAccountDetail,
  paymentAccounts: RawPaymentAccount[] = [],
  stoneCodes: PurchaseStoneCode[]
): Entity<OptIns> => {
  const assessment = kycRequestSelector(rawEntityDetail, rawEntityDetail.assessments)

  const rawActivePaymentAccount = paymentAccountSelector(rawEntityDetail, paymentAccounts)

  const kycStatus = translateMetadataStatus(rawEntityDetail, assessment)

  /**
   * @disclaimer Indeed there is account withouts a active paymentAccount, accounts without banking
   * view in that case the paymentAccount shouldn't be accessed, so we can bypass the type checking
   */
  const paymentAccount = (rawActivePaymentAccount &&
    paymentAccountParser(rawActivePaymentAccount, kycStatus)) as PaymentAccount

  return {
    [entityFingerprint]: true,
    ...personParser(rawEntityDetail),
    kycStatus,
    rejectedKycChecks: rawEntityDetail.rejectedKycChecks,
    enabledFeatures: rawEntityDetail.enabledFeatures,
    paymentAccount: paymentAccount,
    kycRequest: assessment,
    stoneCodes: stoneCodes?.filter(({ cnpj }) => unmask(cnpj) === rawEntityDetail.document) || [],
    hasAnActivePaymentAccount: rawEntityDetail.resources.some(resource => {
      if (resource.type === 'srn:resource:paymentaccount') {
        return resource.data.data.some(resource => resource.status === 'active')
      }
      return false
    }),
    unsafe: {
      fullName: rawEntityDetail.fullName,
      legalName: rawEntityDetail.legalName
    }
  }
}

const subjectParser = (
  meDetails: MeDetails,
  rawAccounts: RawPaymentAccount[] = [],
  entities: EntityAccount[] = [],
  stoneCodes: PurchaseStoneCode[] = []
): Subject<OptIns> => {
  const { user: rawSubject, credentials, deviceType, changeEmailRequest } = meDetails

  /**
   * @disclaimer The api return a list of assessments, in order of priority, the first one is the
   * most important to be answered
   */
  const assessment = meDetails.assessments[0]

  const kycStatus = translateMetadataStatus(rawSubject, assessment)

  const subjectEntity = entities.find(entity => entity.id.includes(rawSubject.userId)) as EntityAccount

  return {
    [subjectFingerprint]: true,
    email: rawSubject.email,
    emailVerified: rawSubject.emailVerified,
    id: `user:${rawSubject.userId}`,
    phoneNumber: rawSubject.phoneNumber?.value,
    deviceType,
    address: rawSubject.address?.value,
    document: rawSubject.cpf,
    kycStatus: kycStatus,
    challengeBlocked: rawSubject.challengeBlocked,
    kycApproved: rawSubject.kycApproved,
    approvalStatus: rawSubject.approvalStatus,
    showKycChecks: rawSubject.showKycChecks,
    rejectedKycChecks: rawSubject.rejectedKycChecks,
    kycRequest: assessment,
    changeEmailRequest,
    paymentAccounts: rawAccounts.map(acc => paymentAccountParser(acc, 'approved' /*find this from organizations*/)),
    enabledFeatures: rawSubject.enabledFeatures,
    createdAt: rawSubject.createdAt,
    credentials,
    unsafe: {
      fullName: rawSubject.fullName,
      legalName: rawSubject.legalName,
      /**
       * @disclaimer need 'banking' opt-in to get this data
       */
      hasAnActivePaymentAccount: rawAccounts.some(
        acc => acc.ownerId.includes(rawSubject.userId) && acc.status === 'ACTIVE'
      ),
      /**
       * @todo remove this asap
       */
      entitiesKycRequests: entities.flatMap(entity => entity.assessments).filter(Boolean),
      roles: roleParser(subjectEntity.resources),
      /**
       * @deprecated
       * @todo remove this asap
       */
      stoneCodes: stoneCodes
    },
    displayName: rawSubject.fullName || rawSubject.legalName || 'Não Informado',
    organizations: entities
      .filter(entity => stringFormat.documentType(entity.document) === 'CNPJ')
      .map(entity => personParser(renameProperty(entity, 'resources', 'resourceTypes'))),
    users: entities
      .filter(entity => stringFormat.documentType(entity.document) === 'CPF')
      .map(entity => personParser(renameProperty(entity, 'resources', 'resourceTypes')))
  }
}

export const entityDetailParser = (rawData: EntityData) => {
  const { entityDetails, rawPaymentAccounts, stoneCodes, subjectDetails, entities } = rawData

  return {
    /**
     * @disclaimer this verification is necessary because the in entity select the got no entity yet
     */
    entity: entityDetails && entityParser(entityDetails, rawPaymentAccounts, stoneCodes),
    subject: subjectParser(subjectDetails, rawPaymentAccounts, entities, stoneCodes)
  }
}
