import { useForm } from 'react-hook-form'
import { Button, Card, CardBody, CardFooter, CardHeader, Heading, Icon, IconButton, Text } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { Input } from '~/domains/platform/design-system/input/input'
import { theme } from '~/ui/theme'

import { type RegisterNameModalProps } from './register-name-modal'

interface InputNameProps extends Pick<RegisterNameModalProps, 'onClose'> {
  onSubmit(name: string): void
}

export const RegisterNameInput = ({ onClose, onSubmit }: InputNameProps) => {
  const { register, watch } = useForm<{ name: string }>()

  const name = watch('name')

  const handleSubmit = () => {
    onSubmit(name)
  }

  return (
    <Box as="form" onSubmit={handleSubmit} data-testid="input-name-modal">
      <Card>
        <CardHeader
          slotEnd={<IconButton icon="close-outline" color="neutral" variant="contentOnly" onClick={onClose} />}
        >
          <Flex alignItems="center" justifyContent="center" gap="0.5rem" color="blue">
            <Icon use="info-round-solid" size="large" inheritColor />
            <Heading as="h1" weight="semi" style={{ flex: 1 }}>
              Cadastre um nome pra essa conta
            </Heading>
          </Flex>
        </CardHeader>
        <CardBody removeSpacing="top">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            minWidth="50ch"
            sx={{ p: { color: theme.colors.mediumGray }, div: { width: '100%' } }}
          >
            <Text as="p">Esse nome vai identificar sua conta no aplicativo.</Text>
            <Input {...register('name')} />
          </Flex>
        </CardBody>
        <CardFooter
          color="surfaceHigh"
          slotEnd={
            <Button size="small" shape="pill" color="positive" type="submit" disabled={!name}>
              Cadastrar nome
            </Button>
          }
        />
      </Card>
    </Box>
  )
}
