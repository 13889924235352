import { fetchCheckCreditAccess } from '~/domains/credit/shared/services/check-credit-access'
import { queryClient } from '~/ui/hooks/utils'

type CheckCreditAccessParams = {
  document: string
}

export const checkCreditAccess = async ({ document }: CheckCreditAccessParams): Promise<boolean> => {
  try {
    const { data } = await queryClient.fetchQuery(
      ['useCheckCreditAccess'],
      () => fetchCheckCreditAccess({ document }),
      {
        staleTime: 15 * (60 * 1000)
      }
    )
    return Boolean(data?.content?.hasCredit) || false
  } catch (_e) {
    return false
  }
}
