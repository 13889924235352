import { type MerchantsContentProps } from '~/domains/acquirer/shared/entities/merchants'
import { translateMetadataStatus } from '~/domains/stone-account/ports/kyc-translate-status'
import { type Assessment } from '~/lib/types/pandas/LegacyAssessment'
import { type Organization, type RawOrganization } from '~/lib/types/pandas/Organization'

import { entityRoles } from './entity-roles'

export function organizationDecorator(config: {
  assessment?: Assessment
  organization: RawOrganization
  stoneCode?: MerchantsContentProps
  accountScopes: string[] | undefined
}): Organization {
  const { accountScopes, assessment, organization, stoneCode } = config

  return {
    ...organization,
    fullName: fullName(),
    metadata: {
      kycStatus: translateMetadataStatus(organization, assessment),
      roles: entityRoles({
        stoneCode,
        accountScopes
      })
    }
  }

  function fullName() {
    return organization.fullName || stoneCode?.fantasyName || 'Não informado'
  }
}
