import { useState } from 'react'

import { Modal } from '~/ui/components/modals/modal/modal'

import { RegisterNameChallenge } from './register-name-challenge'
import { RegisterNameInput } from './register-name-input'

export interface RegisterNameModalProps {
  isOpen: boolean
  onClose(): void
  accountId: string
}

enum RegisterNameSteps {
  INPUT_NAME,
  CHALLENGE
}

type RegisterNameStepKeys = keyof typeof RegisterNameSteps

export const RegisterNameModal = (props: RegisterNameModalProps) => {
  const { isOpen, onClose, accountId } = props

  const [currentStep, setCurrentStep] = useState<RegisterNameStepKeys>('INPUT_NAME')
  const [name, setName] = useState<string>('')

  const onSubmitName = (inputName: string) => {
    setName(inputName)
    setCurrentStep('CHALLENGE')
  }

  const steps: Record<RegisterNameStepKeys, () => JSX.Element> = {
    INPUT_NAME: () => <RegisterNameInput onClose={onClose} onSubmit={onSubmitName} />,
    CHALLENGE: () => <RegisterNameChallenge name={name} accountId={accountId} onClose={onClose} />
  }

  return <Modal isOpen={isOpen}>{steps[currentStep]()}</Modal>
}
