import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { httpWithChallenge } from '~/domains/platform/infra/http/http-with-challenge'

export interface BarcodelessPayment {
  accountId: string
  paymentType: string
  paymentDetails: Record<string, unknown>
  scheduledTo?: string
}

export const barcodelessPayment = (
  { accountId, paymentType, paymentDetails, scheduledTo }: BarcodelessPayment,
  idempotency: string
) => {
  return httpWithChallenge({
    config: {
      headers: { 'x-stone-idempotency-key': idempotency },
      url: '/barcodeless_payments',
      method: 'POST',
      data: {
        accountId,
        paymentType,
        paymentDetails,
        scheduledTo
      }
    },
    client: httpClientBanking
  })
}

export async function dryRunBarcodelessPayment(
  { accountId, paymentType, paymentDetails, scheduledTo }: BarcodelessPayment,
  idempotency: string
) {
  let payload = {
    accountId,
    paymentType,
    paymentDetails
  }

  if (scheduledTo) {
    payload = { ...payload, scheduledTo } as BarcodelessPayment
  }

  return await httpClientBanking({
    url: '/dry_run/barcodeless_payments',
    method: 'POST',
    data: payload,
    headers: { 'x-stone-idempotency-key': idempotency }
  })
}
