import { Icon } from '@dlpco/ginga-stone'

import { DropdownMenu } from '~/ui/business-components/shared/dropdown-menu'

import { type SettingsMenuProps } from './interface'

const items = [
  {
    label: 'Voltar para conta',
    href: '/home',
    icon: <Icon use="brand-potencia-outline" size="large" />
  }
]

export function SettingsMenu(props: SettingsMenuProps) {
  const { subject } = props
  return <DropdownMenu subject={subject} items={items} />
}
