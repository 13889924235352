import { useEffect } from 'react'
import NextLink from 'next/link'
import { Heading, Icon, Text } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/ports'
import { Bucket } from '~/domains/platform/lib/bucket'
import { HiddenAmount } from '~/domains/platform/lib/hidden-sensitive/components/hidden-amount'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import analitica from './analitica'
import { BalanceLoading } from './loading'
import { WrapperAccount } from './styles'
import { useAccountBalance } from './use-account-balance'

export function Balance({ accountId }: { accountId: PaymentAccount['id'] }) {
  const { data: balanceData, isFetching, isError, isLoading } = useAccountBalance({ accountId })

  const balanceRequestError = Bucket.session.get('home::request::error') || []
  const componentName = 'Balance'
  const isRequestError = balanceRequestError.includes(componentName)

  useEffect(() => {
    if (!isLoading && !isRequestError && isError) {
      analitica.events.balanceHomeCard.requestError()
      Bucket.session.set('home::request::error', [...balanceRequestError, componentName])
    }
  }, [isLoading, isError, isRequestError])

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="flex-start" position="relative">
        <Choose>
          <Choose.When condition={isFetching}>
            <BalanceLoading />
          </Choose.When>
          <Choose.Otherwise>
            <NextLink href="/extrato" passHref legacyBehavior>
              <Flex flexDirection="column" gap="0.25rem">
                <Text size="small" color="neutral">
                  Saldo da Conta
                </Text>

                <Flex flexDirection="column" gap="0.5rem">
                  <Flex alignItems="center" data-testid="display">
                    <Heading weight="bold">
                      <HiddenAmount>
                        <WrapperAccount>
                          <Heading>R$&nbsp;</Heading>
                          <Heading weight="bold" data-cy="balance">
                            {stringFormat.currency(balanceData?.amount, true) ?? 0}
                          </Heading>
                        </WrapperAccount>
                      </HiddenAmount>
                    </Heading>
                  </Flex>

                  <If condition={balanceData?.amountBlocked !== 0}>
                    <Flex>
                      <Flex alignItems="center" gap="0.5rem">
                        <Icon use="lock-solid" color="neutralLow" style={{ width: '1rem', height: '1rem' }} />
                        <HiddenAmount>
                          <Text color="neutral" data-cy="balance-blocked">
                            {stringFormat.currency(balanceData?.amountBlocked).replace('-', '')}
                          </Text>
                        </HiddenAmount>
                      </Flex>
                      <Text color="neutral">&nbsp;bloqueados</Text>
                    </Flex>
                  </If>
                </Flex>
              </Flex>
            </NextLink>
          </Choose.Otherwise>
        </Choose>
      </Flex>
    </Box>
  )
}
