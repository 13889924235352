import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'

interface Discount {
  date?: string
  value?: string
}

interface CreateBoleto {
  amount: number
  invoiceType: string
  accountId?: string | string[]
  expirationDate: string
  limitDate?: string
  customer?: {
    document: string
    legalName: string
    tradeName: string
  }
  fine?: {
    date: string
    value: string
  }
  interest?: {
    date: string
    value: string
  }
  discounts?: Discount[]
}

export async function generateBoleto({ boletoId }: { boletoId?: string }) {
  return await httpClientBanking({
    url: `/exports/barcode_payment_invoices/${boletoId}`,
    method: 'GET',
    responseType: 'blob'
  })
}

export async function createBoleto<T>({
  amount,
  invoiceType,
  accountId,
  expirationDate,
  customer,
  limitDate,
  fine,
  interest,
  discounts
}: CreateBoleto) {
  return await httpClientBanking<T>({
    url: '/barcode_payment_invoices',
    method: 'POST',
    data: {
      amount,
      invoiceType,
      accountId,
      expirationDate,
      customer,
      limitDate,
      fine,
      interest,
      discounts
    }
  })
}

export async function cancelBoleto({ boletoId }: { boletoId: string }) {
  return await httpClientBanking({
    url: `/barcode_payment_invoices/${boletoId}/cancel`,
    method: 'POST'
  })
}

export async function getBoletoAmountLimits({ accountId }: { accountId: string }) {
  return await httpClientBanking({
    url: `/barcode_payment_invoices/limits/${accountId}`,
    method: 'GET'
  })
}
