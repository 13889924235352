import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { type CustomAxiosRequestConfig } from '~/domains/platform/infra/http/http-client-factory'
import { removeNullProperties } from '~/domains/platform/lib/object-helpers/remove-null-properties'
import { stringifyProperties } from '~/domains/platform/lib/object-helpers/stringify-properties'
import { type Cursor, type ExtractFirstParam } from '~/lib/types'

export interface PaginatedResponse<Data> {
  cursor: Cursor
  data: Data[]
}

export const queryParser = (obj: ExtractFirstParam<typeof removeNullProperties>) =>
  stringifyProperties(removeNullProperties(obj))

export const paginatedClient = <T>(config: CustomAxiosRequestConfig, cursor: Cursor, client = httpClientBanking) => {
  const { url, ...restOptions } = config

  const query = new URLSearchParams(queryParser({ paginated: true, ...cursor })).toString()
  const paginatedUrl = `${url}?${query}`

  const paginatedOptions = {
    url: paginatedUrl,
    ...restOptions
  }

  return client<{ data: T; cursor: Cursor }>(paginatedOptions)
}
