import { useState } from 'react'
import { usePopper } from 'react-popper'
import { useKey, useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Card, CardBody, CardHeader, Heading, Text } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'
import { type PurchaseStoneCode } from '~/lib/types/acquirer/Purchase'
import { useClickAway } from '~/ui/hooks/utils/ui/use-click-away'

import { type FilterPeriod } from '../period-filter/types'

import { ReportForm } from './form'
import { Wrapper } from './styles'

function formatFilterPeriod(filterPeriod: string) {
  const firstSpace = filterPeriod.indexOf(' ')
  const newPeriodSentence = filterPeriod.slice(firstSpace)

  return newPeriodSentence
}
type SalesReportProps = {
  entityDocument: string
  dateSales: Date[]
  filterPeriod: FilterPeriod
  isDisable?: boolean
  stoneCodes: PurchaseStoneCode[]
}

export function SalesReport(props: SalesReportProps) {
  const { dateSales, entityDocument, filterPeriod, isDisable, stoneCodes } = props
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const { attributes, styles } = usePopper(referenceElement, popperElement)
  const [isOpen, toggle] = useToggle(false)
  const close = () => toggle(false)

  useKey('Escape', close)

  const clickAwayRef = useClickAway(close)

  const handleReportSuccess = () => {
    toggle()
  }

  const handleClick = () => {
    toggle()
  }

  return (
    <Wrapper ref={clickAwayRef}>
      <Button
        color="neutral"
        size="small"
        icon="action-arrow-download-outline"
        ref={setReferenceElement}
        data-testid="button"
        onClick={handleClick}
        disabled={isDisable}
      >
        Baixar relatório
      </Button>

      <If
        condition={isOpen}
        render={() => (
          <Card
            style={{ ...(styles.popper as any), width: 'min(400px, 420px)', zIndex: 999, marginTop: '1rem' }}
            elevation={10}
            ref={setPopperElement}
            {...attributes.popper}
          >
            <CardHeader>
              <Stack space="0.5rem">
                <Heading weight="bold">Baixar relatório</Heading>
                <Text color="neutral">As últimas vendas levam até 1 hora pra serem mostradas nos relatórios.</Text>
              </Stack>
            </CardHeader>
            <CardBody removeSpacing={['top']}>
              <If condition={Boolean(filterPeriod)}>
                <Card color="surfaceHigh" spacing="small">
                  <CardBody>
                    <Flex justifyContent="space-between">
                      <Text color="neutral">Período filtrado:</Text>
                      <Text color="neutralHigh" weight="semi">
                        {formatFilterPeriod(filterPeriod.title)}
                      </Text>
                    </Flex>
                  </CardBody>
                </Card>
              </If>
              <ReportForm
                onSuccess={handleReportSuccess}
                dateSales={dateSales}
                entityDocument={entityDocument}
                stoneCodes={stoneCodes}
              />
            </CardBody>
          </Card>
        )}
      />
    </Wrapper>
  )
}
