import { type PropsWithChildren, type ReactNode, useRef } from 'react'
import FocusLock from 'react-focus-lock'
import { useKey, useLockBodyScroll } from 'react-use'
import { Cluster } from '@dlpco/fluid-layout'
import { IconButton } from '@dlpco/ginga-stone'

import { Box } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { If } from '~/domains/platform/lib/utilities-components'
import { useClickAway } from '~/ui/hooks/utils/ui/use-click-away'

import { HeaderSection, ModalTitle, Section, Wrapper } from './styles'

export interface SideModalProps {
  isOpen: boolean
  onClose(): void
  title?: string
  toolbar?: ReactNode
  style?: React.CSSProperties
  headerStyle?: React.CSSProperties
  headerPosition?: 'left' | 'right'
  disableClickAway?: boolean
  fullSize?: boolean
}

export function ContentModal(props: PropsWithChildren<SideModalProps>) {
  const {
    children,
    isOpen,
    onClose,
    title,
    toolbar,
    style,
    headerStyle,
    headerPosition = 'right',
    disableClickAway,
    fullSize
  } = props

  useLockBodyScroll(isOpen)
  useKey('Escape', onClose)

  /**@disclaimer replace function to disable useClickAway hook*/
  const nullFunction = () => {}

  const clickAwayRef = useClickAway(disableClickAway ? nullFunction : onClose)

  const headerRef = useRef<HTMLDivElement>(null)

  /**
   * @disclaimer We are adding 5 px to prevent the modal create a useless scroll bar
   */
  const headerHeight = (headerRef.current?.clientHeight || 0) + 5

  return (
    <If
      condition={isOpen}
      render={() => {
        const visibleClassName = isOpen ? 'visible' : ''

        return (
          <FocusLock disabled={!isOpen}>
            <Wrapper ref={clickAwayRef} className={visibleClassName} data-testid="modal" style={style}>
              <HeaderSection as="header" ref={headerRef} style={headerStyle} headerPosition={headerPosition}>
                <Cluster align="center" space="2rem">
                  <Box>
                    <IconButton
                      color="neutral"
                      icon="direction-arrow-left-outline"
                      variant="contentOnly"
                      size="large"
                      aria-label="Fechar"
                      onClick={onClose}
                    />
                    <ModalTitle data-testid="title">{title}</ModalTitle>
                  </Box>
                </Cluster>
                {toolbar}
              </HeaderSection>
              <Divider />
              <Section as="section" fullSize={Boolean(fullSize)} headerHeight={`${headerHeight}px`}>
                {children}
              </Section>
            </Wrapper>
          </FocusLock>
        )
      }}
    />
  )
}
