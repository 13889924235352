import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { usePopper } from 'react-popper'
import { useKey, useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Card, CardBody, CardHeader, Heading } from '@dlpco/ginga-stone'

import { Box } from '~/domains/platform/design-system'
import { Input } from '~/domains/platform/design-system/input/input'
import { Select } from '~/domains/platform/design-system/select/select'
import { timeMachine } from '~/domains/platform/lib/time-machine'
import { For, If } from '~/domains/platform/lib/utilities-components'
import { type MerchantsContentProps, type PurchaseStoneCode } from '~/lib/types'
import { useClickAway } from '~/ui/hooks/utils/ui/use-click-away'

import { SimplerFilterForm } from '../period-filter/simpler-filter-form'
import { type FilterPeriod } from '../period-filter/types'

import { ButtonWrapper } from './styles'

const searchFiltersDefault = {
  stoneCode: '',
  cardType: '',
  terminalType: '',
  cardLastDigits: '',
  serialNumber: '',
  captureDateFilterPeriod: { startDate: new Date(), finalDate: new Date(), title: '' }
}

export type searchFilters = typeof searchFiltersDefault

const cardTypesAndInstallments = [
  'Boleto',
  'Débito',
  'Crédito',
  'Crédito 1x',
  'Crédito 2x',
  'Crédito 3x',
  'Crédito 4x',
  'Crédito 5x',
  'Crédito 6x',
  'Crédito 7x',
  'Crédito 8x',
  'Crédito 9x',
  'Crédito 10x',
  'Crédito 11x',
  'Crédito 12x',
  'Voucher'
]

const terminalTypes = [
  { label: 'Maquininha', value: 'Pos' },
  { label: 'TEF', value: 'TEF' },
  { label: 'Mobile', value: 'Micro Pos' },
  { label: 'E-commerce', value: 'Ecommerce' }
]

type SearchSaleFormData = {
  stoneCode: string
  stoneId: string
  cardType: string
  terminalType: string
  cardLastDigits: string
  serialNumber: string
}

export function SearchSale(props: {
  merchants?: MerchantsContentProps[]
  filteredStonecodes: PurchaseStoneCode[]
  setIsFilteredBySearch: React.Dispatch<React.SetStateAction<boolean>>
  changeSearchFilters: (newFilters: searchFilters) => void
  setSelectedSale(values: { id: string; stoneCode: string }): void
}) {
  const { merchants, filteredStonecodes, changeSearchFilters, setSelectedSale, setIsFilteredBySearch } = props

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const { attributes, styles } = usePopper(referenceElement, popperElement)
  const [isOpen, toggle] = useToggle(false)
  const close = () => toggle(false)
  useKey('Escape', close)
  const clickAwayRef = useClickAway(close)

  const [captureDateFilterPeriod, setCaptureDateFilterPeriod] = useState<FilterPeriod>({
    startDate: timeMachine().subtractDay(6).startOfDay().toDate(),
    finalDate: timeMachine().endOfDay().toDate(),
    title: ''
  })

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<SearchSaleFormData>()

  const watchStoneId = watch('stoneId')
  const watchStoneCode = watch('stoneCode')

  const hasStoneId = Boolean(watchStoneId)

  const onSubmit = handleSubmit(values => {
    if (watchStoneId) {
      setSelectedSale({ id: watchStoneId, stoneCode: watchStoneCode || filteredStonecodes[0]?.stoneCode })
    } else {
      changeSearchFilters({
        stoneCode: values.stoneCode || filteredStonecodes[0]?.stoneCode,
        cardType: values.cardType,
        terminalType: values.terminalType,
        cardLastDigits: values.cardLastDigits,
        serialNumber: values.serialNumber,
        captureDateFilterPeriod
      })
    }

    setIsFilteredBySearch(true)
    close()
  })

  const sortBysalesTeamMemberName = ({ stoneCode }: PurchaseStoneCode) => {
    const SORT_ASCENDING = 1
    const SORT_DESCENDING = -1

    const isTeamMemberNameAPaymentLink =
      merchants?.find(({ affiliationCode }) => affiliationCode === stoneCode)?.salesTeamMemberName?.toLowerCase() ===
      'link abc'

    if (isTeamMemberNameAPaymentLink) {
      return SORT_ASCENDING
    }

    return SORT_DESCENDING
  }

  const sortedStoneCodes = filteredStonecodes.sort(sortBysalesTeamMemberName)

  return (
    <div ref={clickAwayRef}>
      <Button
        color="neutral"
        size="small"
        icon="search-outline"
        onClick={toggle}
        ref={setReferenceElement}
        data-testid="open-search-filter-button"
      >
        Buscar
      </Button>

      <If condition={isOpen}>
        <Box as="form" onSubmit={onSubmit}>
          <Card
            style={{ ...(styles.popper as any), zIndex: 999, width: 'min(500px, 100vw)', marginTop: '1rem' }}
            elevation={10}
            ref={setPopperElement}
            {...attributes.popper}
          >
            <CardHeader>
              <Heading weight="bold" data-testid="search-sale-title">
                Encontrar uma venda
              </Heading>
            </CardHeader>
            <CardBody removeSpacing={['top']}>
              <Stack space="1rem">
                <If condition={filteredStonecodes.length > 1}>
                  <Select label="Unidade" {...register('stoneCode')}>
                    <For
                      of={sortedStoneCodes}
                      render={item => {
                        const paymentLinkDescription =
                          merchants
                            ?.find(merchant => merchant.affiliationCode === item.stoneCode)
                            ?.salesTeamMemberName?.toLowerCase() === 'link abc'
                            ? '(Link de pagamento)'
                            : ''

                        const itemDescription = `${item.fantasyName} - ${item.stoneCode} ${paymentLinkDescription}`
                        return (
                          <option key={item.stoneCode} value={item.stoneCode}>
                            {itemDescription}
                          </option>
                        )
                      }}
                    />
                  </Select>
                </If>

                <Input label="Stone ID" {...register('stoneId')} error={errors?.stoneId?.message} />

                <SimplerFilterForm
                  label="Data da venda"
                  filterPeriod={captureDateFilterPeriod}
                  onChange={setCaptureDateFilterPeriod}
                  disabled={hasStoneId}
                />

                <Select
                  label="Forma de pagamento"
                  {...register('cardType')}
                  error={errors?.cardType?.message}
                  disabled={hasStoneId}
                >
                  <option key="" value="" disabled selected hidden>
                    Selecione
                  </option>

                  <For
                    of={cardTypesAndInstallments}
                    render={(option, index) => (
                      <option key={index + 1} value={option}>
                        {option}
                      </option>
                    )}
                  />
                </Select>

                <Select
                  label="Meio de captura"
                  {...register('terminalType')}
                  error={errors?.terminalType?.message}
                  disabled={hasStoneId}
                >
                  <option key="" value="" disabled selected hidden>
                    Selecione
                  </option>

                  <For
                    of={terminalTypes}
                    render={(type, index) => (
                      <option key={index + 1} value={type.value}>
                        {type.label}
                      </option>
                    )}
                  />
                </Select>

                <Input
                  label="4 últimos números do cartão"
                  placeholder="Exemplo: 5598"
                  maxLength={4}
                  {...register('cardLastDigits')}
                  disabled={hasStoneId}
                  error={errors?.cardLastDigits?.message}
                />

                <Input
                  label="Número de série da maquininha"
                  placeholder="Exemplo: 34248245"
                  {...register('serialNumber')}
                  error={errors?.serialNumber?.message}
                  disabled={hasStoneId}
                />
              </Stack>

              <ButtonWrapper>
                <Button size="large" data-testid="search-sales-action">
                  Buscar vendas
                </Button>
              </ButtonWrapper>
            </CardBody>
          </Card>
        </Box>
      </If>
    </div>
  )
}
