export type KindError = 'not_found' | 'invalid_status' | 'expired_datetime' | 'generic_error'

export type ErrorDescription = {
  title: string
  subtitle: string
  callToAction: string
}

type ErrorByKind = {
  response: {
    status: number
    data?: {
      type: string
      reason?: string
    }
  }
  error: ErrorDescription
}

export const PAYMENT_NOT_FOUND = {
  title: 'Pagamento não encontrado',
  subtitle: 'Esse pagamento não foi encontrado.',
  callToAction: 'Ir para o início'
}

export const PAYMENT_INVALID: ErrorDescription = {
  title: 'Pagamento inválido',
  subtitle: 'Não foi possível carregar as informações do pagamento.',
  callToAction: 'Ir para o início'
}

export const PAYMENT_EXPIRED: ErrorDescription = {
  title: 'Pagamento expirado',
  subtitle: 'Parece que o tempo passou rápido demais e o pagamento acabou expirando.',
  callToAction: 'Ir para o início'
}

export const GENERIC_ERROR: ErrorDescription = {
  title: 'Houve um problema com o pagamento',
  subtitle: 'Não foi possível prosseguir com o pagamento.',
  callToAction: 'Ir para o início'
}

export const KIND_ERRORS: Record<KindError, ErrorByKind> = {
  not_found: {
    response: { status: 404 },
    error: PAYMENT_NOT_FOUND
  },
  invalid_status: {
    response: { status: 422, data: { type: 'srn:error:invalid_status' } },
    error: PAYMENT_INVALID
  },
  expired_datetime: {
    response: { status: 422, data: { type: 'srn:error:expired_datetime' } },
    error: PAYMENT_EXPIRED
  },
  generic_error: {
    response: { status: 400, data: { reason: 'invalid_request_object', type: 'srn:error:validation' } },
    error: GENERIC_ERROR
  }
}
