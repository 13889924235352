import { Heading, IconBadge } from '@dlpco/ginga-stone'

import { analytics } from '~/domains/platform/lib/analytics'
import BlackBird from '~/domains/platform/lib/blackbird'
import { If } from '~/domains/platform/lib/utilities-components'

import { type TeamListItemStatus } from '../shared/entities'

import { NewInviteNotificationWarningCard } from './new-invite-notification-warning-card'

/**
 * @deprecated This component can only be used in the domains (legacy) project
 **/
export function NewInviteNotificationAlert({ invitationStatus }: { invitationStatus: TeamListItemStatus }) {
  return (
    <>
      <If condition={invitationStatus === 'waiting_kyc'}>
        <NewInviteNotificationWarningCard
          icon={<IconBadge icon="avatar-add-solid" color="info" variant="light" />}
          title={
            <Heading size="xSmall" weight="semi">
              Complete seu cadastro pelo aplicativo da Stone
            </Heading>
          }
          subTitle="Assim, você consegue usar as funcionalidades da conta que recebeu permissão pra acesso."
          buttonText="Conferir"
          buttonShape="pill"
          action={() => {
            BlackBird.travelTo('/confira-o-app')
          }}
        />
      </If>
      <If condition={invitationStatus === 'kyc_analysis'}>
        <NewInviteNotificationWarningCard
          icon={<IconBadge icon="clock-round-solid" color="info" variant="light" />}
          title={
            <Heading size="xSmall" weight="semi">
              Estamos analisando seus dados
            </Heading>
          }
          subTitle="Em breve, você vai poder usar as funcionalidades da conta que recebeu permissão pra acessar."
          buttonText="Conferir"
          buttonShape="pill"
          action={() => {
            BlackBird.travelTo('/conta-em-analise')
          }}
        />
      </If>
      <If condition={invitationStatus === 'pending_acceptance'}>
        <NewInviteNotificationWarningCard
          icon={<IconBadge icon="clock-round-solid" color="info" variant="light" />}
          title={
            <Heading size="xSmall" weight="semi">
              Novo convite de acesso
            </Heading>
          }
          subTitle="Você recebeu um convite."
          buttonText="Conferir"
          buttonShape="pill"
          action={() => {
            BlackBird.travelTo('/multiusuarios/equipe/convite-recebido')
            analytics.sendEvent('team - banner invitation received', { source: 'seletor de contas' })
          }}
        />
      </If>
    </>
  )
}
