import { Cluster } from '@dlpco/fluid-layout'
import { type MaxHeightProperty, type WidthProperty } from 'csstype'
import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
interface PopUpWrapperProps {
  width?: WidthProperty<string>
  maxHeight?: MaxHeightProperty<string>
  isOpen?: boolean
}

export const PopUpWrapper = styled.div<PopUpWrapperProps>`
  background: ${theme('colors.white')};
  border-radius: ${theme('radii.default')};
  cursor: pointer;
  margin-top: ${theme('space.small')};
  padding: ${theme('space.default')};
  overflow-y: auto;
  max-height: ${props => props.maxHeight || '25ch'};
  z-index: ${theme('zIndices.popover')};

  box-shadow: ${theme('shadows.card')};
  width: ${props => props.width || 'auto'};

  svg {
    /** prevent flex-wrap resize the icon */
    max-width: ${theme('space.large')};
    max-height: ${theme('space.large')};
    min-width: ${theme('space.large')};
    min-height: ${theme('space.large')};
  }

  /* animation */
  opacity: 0;
  visibility: hidden;
  transform: translateY(1rem);

  ${ifProp(
    'isOpen',
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    `
  )};
`

export const Wrapper = styled.div<{ width?: WidthProperty<string> }>`
  width: auto;
`
export const InputWrapper = styled.div<{ width?: WidthProperty<string> }>`
  width: ${props => props.width || '100%'};
`
export const SelectionWrapper = styled.div<{ focused?: boolean; disabled?: boolean; error?: boolean }>`
  padding: ${theme('space.medium')};
  border: 1px ${theme('colors.mediumGray3')} solid;
  border-radius: ${theme('radii.default')};
  justify-content: space-between;
  cursor: ${ifProp('disabled', 'no-drop', 'pointer')};
  display: flex;
  align-items: center;

  border-color: ${ifProp(
    'error',
    theme('colors.danger'),
    ifProp('focused', theme('colors.input.active'), theme('colors.input.border'))
  )};

  svg {
    max-width: ${theme('space.large')};
    max-height: ${theme('space.large')};
  }
`

export const Item = styled.div<{ focused?: boolean; disabled?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 2.5rem;
  justify-content: space-between;
  border-bottom: 1px solid ${theme('colors.mediumGray4')};
  color: ${ifProp('disabled', theme('colors.mediumGray3'))};
  padding: ${ifProp('disabled', '0.5rem 0')};
`

export const SelectionText = styled.div<{ isPlaceholder?: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${ifProp('isPlaceholder', theme('colors.mediumGray3'), 'unset')};

  width: 100%;
  padding-left: ${theme('space.default')};
`

export const SelectionTextValue = styled.div<{ withoutIcon?: boolean; primary?: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  padding-left: ${ifProp('withoutIcon', '1rem', '0')};
  color: ${ifProp('primary', '#008E5A')};
`

export const TextWithIcon = styled.div`
  display: flex;

  svg {
    margin-right: 1rem;
  }
`

export const StyledCluster = styled(Cluster)`
  > div:last-child {
    border-bottom: none;
  }
`
