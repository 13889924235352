import { useEffect, useState } from 'react'
import NextLink from 'next/link'
import { Button } from '@dlpco/ginga-stone'

import { Link } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'
import { isomorphicCookies } from '~/lib/helpers'

import { CookieConsentFlex, CookieConsentGrid, CookieConsentText } from './styles'

export const CONSENT_COOKIE__NAME = 'cookieConsentiment'
export const CONSENT_COOKIE_GIVEN__VALUE = 'given'

export function CookieConsent() {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const cookieConsentiment = isomorphicCookies.getAll()[CONSENT_COOKIE__NAME]

    if (cookieConsentiment === CONSENT_COOKIE_GIVEN__VALUE) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [])

  const handleClick = () => {
    setIsVisible(false)
    isomorphicCookies.set(CONSENT_COOKIE__NAME, CONSENT_COOKIE_GIVEN__VALUE)
  }

  return (
    <If condition={isVisible}>
      <CookieConsentGrid>
        <CookieConsentFlex>
          <CookieConsentText>
            Utilizamos cookies para possibilitar e aprimorar sua experiência em nosso site, de acordo com nossas
            políticas de{' '}
            <NextLink href="https://www.stone.com.br/aviso-de-privacidade/" passHref legacyBehavior>
              <Link target="_blank">privacidade</Link>
            </NextLink>{' '}
            e{' '}
            <NextLink href="https://www.stone.com.br/politica-de-cookies/" passHref legacyBehavior>
              <Link target="_blank">cookies</Link>
            </NextLink>
          </CookieConsentText>
          <Button size="large" onClick={() => handleClick()}>
            OK
          </Button>
        </CookieConsentFlex>
      </CookieConsentGrid>
    </If>
  )
}
