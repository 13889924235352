import { useEffect, useLayoutEffect } from 'react'
import { useToggle } from 'react-use'
import { type NextPage } from 'next'
import { useRouter } from 'next/router'

import BlackBird from '~/domains/platform/lib/blackbird'
import { RegisterNameModal } from '~/domains/stone-account/ports/components'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'

import { clientSideOnly } from '../../lib/client-side-only'
import { type WithDeusExMachinaProps } from '../deus-ex-machina/types'

const useIsomorphicEffect = (...parameters: Parameters<typeof useEffect>) => {
  const isomorphicLayout = clientSideOnly(() => useLayoutEffect, useEffect)

  isomorphicLayout(...parameters)
}

export function withRegisterName(Page: NextPage<WithDeusExMachinaProps>) {
  function WrappedPage(props: WithDeusExMachinaProps) {
    const { push, pathname } = useRouter()
    const query = BlackBird.getQuery()
    const [isOpen, setIsOpen] = useToggle(false)

    const shouldRegisterName = query.shouldRegisterName === 'true'

    useIsomorphicEffect(() => {
      if (shouldRegisterName) {
        setIsOpen(true)
      }
    }, [shouldRegisterName, setIsOpen])

    if (shouldRegisterName) {
      return (
        <>
          <Page {...props} />
          <RegisterNameModal
            isOpen={isOpen}
            accountId={String(isomorphicCookies.getAll()['identityId'])}
            onClose={() => push({ pathname, query: { ...query, shouldRegisterName: undefined } }, pathname)}
          />
        </>
      )
    }

    return <Page {...props} />
  }

  WrappedPage.displayName = `withRegisterName(${Page.displayName})`

  return WrappedPage
}
