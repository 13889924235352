import { useEffect, useRef } from 'react'
import ReactCodeInput, { type ReactCodeInputProps } from 'react-code-input'
import { Cluster } from '@dlpco/fluid-layout'
import styled, { css } from 'styled-components'

import { Text } from '~/domains/platform/design-system'
import { theme } from '~/ui/theme/theme'

interface CodeInputProps extends ReactCodeInputProps {
  inputStyle?: React.CSSProperties
  error?: string
  type?: 'number' | 'text' | 'password' | 'tel'
  name: string
}

const StyledReactCodeInput = styled(ReactCodeInput)<{ hasError: boolean }>`
  input {
    border: 1px solid ${theme.colors.mediumGray2};

    :nth-child(3) {
      margin-right: 2rem;
    }

    &:focus {
      border-color: ${theme.colors.primary};
      outline: none;
    }

    ${props =>
      props.hasError &&
      css`
        border-color: ${theme.colors.danger};
      `};
  }
`

const fields = 6
const CodeInput = ({ error, value, inputStyle, ...restProps }: CodeInputProps) => {
  const codeRef = useRef<any>()

  useEffect(() => {
    if (value === '' && codeRef.current) {
      if (codeRef.current.textInput[0]) codeRef.current.textInput[0].focus()
      for (let i = 0; i < fields; i++) {
        codeRef.current.state.input[i] = ''
      }
    }
  }, [fields, value])

  return (
    <>
      <Cluster space="1rem" showOverflow>
        <StyledReactCodeInput
          {...restProps}
          value={String(value)}
          fields={fields}
          hasError={Boolean(error)}
          autoFocus
          inputStyle={{
            width: '4rem',
            borderRadius: '5px',
            fontSize: '2rem',
            height: '5rem',
            padding: '1rem',
            textAlign: 'center',
            ...inputStyle
          }}
          ref={codeRef}
        />
      </Cluster>
      {error && (
        <Text role="alert" color="red" data-testid="error" fontSize={1} pt={0} marginTop="4px">
          {error}
        </Text>
      )}
    </>
  )
}

export default CodeInput
