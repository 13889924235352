import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'

import analitica from './analitica'

export function acquirerProfileMenu(entity: Entity) {
  return [
    {
      order: 8,
      label: 'Contas de recebimento',
      icon: 'bank-outline',
      show: entity?.roles?.acquirer,
      onClick: () => {
        analitica.events.profileMenu.click('contas de recebimento')
      },
      path: '/contas-de-recebimento',
      active: (route: string) => ['/settings/receivable-accounts', '/contas-de-recebimento'].includes(route)
    },
    {
      order: 5,
      label: 'Meu plano',
      icon: 'notebook-outline',
      show: true,
      path: '/meu-plano',
      active: (route: string) => ['/settings/my-plan', '/meu-plano'].includes(route)
    }
  ]
}
