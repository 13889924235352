import styled from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

export const Modal = styled.div<{ width?: string; height?: string }>`
  background-color: ${theme('colors.white')};
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${ifProp('width', prop('width'))};
  max-width: 90%;
  height: ${ifProp('height', prop('height'))};
  display: flex;
  flex-direction: column;
  z-index: ${theme('zIndices.modal')};

  animation: 0.4s fade-in ease-in;

  @keyframes modal-in {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }
`
