import { analiticaInstance } from '~/domains/platform/lib/analitica/analitica.config'

export default analiticaInstance.eventFactory('Acquirer', {
  salesHomeCard: {
    /**
     * @analitica when an error occurs during the sales request
     */

    requestError: () => ({
      label: 'home web - sales error state viewed'
    })
  },
  profileMenu: {
    /**
     * @analitica When the user click on item profile menu
     */
    click: (item: string) => ({
      label: 'home web - menu perfil chosen button',
      metadata: { 'menu-perfil': item }
    })
  }
})
