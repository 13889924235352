import dynamic from 'next/dynamic'
import { Cluster, Grid, Stack } from '@dlpco/fluid-layout'
import { Card, CardBody, Text } from '@dlpco/ginga-stone'
import groupBy from 'lodash.groupby'
import reduce from 'lodash.reduce'

import { Box, Flex } from '~/domains/platform/design-system'
import { For } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import { type ModalitySummaryCardProps, type ModalitySummaryItemProps, type ModalitySummaryProps } from './interface'
import { WrapperSummaryItem } from './styles'

enum Modalities {
  CREDIT = 'Crédito',
  DEBIT = 'Débito',
  VOUCHER = 'Voucher'
}

const { currency } = stringFormat

const BrandIcon = dynamic<any>(() => import('@dlpco/brand').then(mod => mod.BrandIcon) as any)

const ModalitySummaryCard = (props: ModalitySummaryCardProps) => (
  <Card>
    <CardBody>
      <Stack space="2rem">
        <Cluster justify="space-between">
          <Box>
            <Text color="neutralHigh" weight="semi">
              {props.title}
            </Text>
            <Text>{currency(props.value)}</Text>
          </Box>
        </Cluster>
        <Cluster justify="space-between" space="0.5rem">
          <For
            of={props.items}
            render={(item, index) => (
              <WrapperSummaryItem key={index}>
                <Flex gap="0.5rem">
                  <BrandIcon brandId={item.brandId} size="1.5rem" />
                  <Text color="neutralHigh">{item.name}</Text>
                </Flex>
                <Text color="neutralHigh">{currency(item.value)}</Text>
              </WrapperSummaryItem>
            )}
          />
        </Cluster>
      </Stack>
    </CardBody>
  </Card>
)

export const ModalitySummary = (props: ModalitySummaryProps) => {
  const { brands, title, saleResumeList } = props

  const salesSummary = saleResumeList.flatMap(item => {
    return item.elements
  })

  const salesSummaryByModality = groupBy(salesSummary, summaryItem => summaryItem.fundingAccount)

  function modalityGroupFactory(modality: Modalities) {
    const summaryByBrand = groupBy(salesSummaryByModality[modality], summaryItem => summaryItem.brand)

    function getBrandId(brandName: string) {
      return Number(brands.find(item => brandName.includes(item?.brandName.split(' ')[0]))?.brandId)
    }

    return reduce(
      summaryByBrand,
      (result, value, key) => {
        return result.concat({
          name: key,
          brandId: getBrandId(key),
          value: value.reduce((acc, item) => acc + item.grossAmount, 0)
        })
      },
      [] as ModalitySummaryItemProps[]
    )
  }

  function amountSummaryFactory(modality: Modalities) {
    if (salesSummaryByModality[modality])
      return salesSummaryByModality[modality].reduce((acc, item) => acc + item.grossAmount, 0)

    return 0
  }

  const cards: ModalitySummaryCardProps[] = [
    {
      title: Modalities.DEBIT,
      value: amountSummaryFactory(Modalities.DEBIT),
      items: modalityGroupFactory(Modalities.DEBIT)
    },
    {
      title: Modalities.CREDIT,
      value: amountSummaryFactory(Modalities.CREDIT),
      items: modalityGroupFactory(Modalities.CREDIT)
    },
    {
      title: Modalities.VOUCHER,
      value: amountSummaryFactory(Modalities.VOUCHER),
      items: modalityGroupFactory(Modalities.VOUCHER)
    }
  ]

  return (
    <>
      <Text size="large" weight="bold">
        {title}
      </Text>
      <Grid min="20ch" gutter="2rem">
        <For
          of={cards}
          render={(item, index) => (
            <ModalitySummaryCard title={item.title} items={item.items} value={item.value} key={index} />
          )}
        />
      </Grid>
    </>
  )
}
