import { Stack } from '@dlpco/fluid-layout'
import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'

import { Box } from '~/domains/platform/design-system'

export const StackWrapper = styled(Stack)`
  background-color: ${theme('colors.white')};
  padding: ${theme('space.tiny')};
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BoxWrapper = styled(Box)`
  align-items: center;
  border: 1px solid ${theme('colors.mediumGray4')};
  border-radius: ${theme('radii.default')};
  display: flex;
  min-height: 143px;
  padding: ${theme('space.tiny')} 1.5rem;
`

export const ItemShadow = styled.div<{ isOpen?: boolean }>`
  box-shadow: ${ifProp('isOpen', '0px 0px 16px rgba(0, 0, 0, 0.15)', 'unset')};
  border-radius: ${theme('radii.default')};
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme('colors.lightGray')};
  }
`
