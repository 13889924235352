import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { httpWithChallenge } from '~/domains/platform/infra/http/http-with-challenge'

export type ApprovePaymentConsentAction = 'authorize' | 'reject'

export type ApprovePaymentConsent = {
  action: ApprovePaymentConsentAction
  consentId: string
  accountId: string
}

export const getUrlByAction = (action: string, consentId: string) =>
  ({
    authorize: `/openfinance/customer/payments/v1/aspsp/consents/${consentId}/approve`,
    reject: `/openfinance/customer/payments/v1/aspsp/consents/${consentId}/reject`
  }[action])

export const approvePaymentConsent = ({ action, consentId, accountId }: ApprovePaymentConsent, idempotency: string) => {
  const url = getUrlByAction(action, consentId)

  return httpWithChallenge({
    config: {
      url,
      method: 'POST',
      headers: { 'x-stone-idempotency-key': idempotency },
      data: {
        accountId
      }
    },
    client: httpClientBanking
  })
}
