import { type PropsWithChildren, useLayoutEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { useKey, useToggle } from 'react-use'
import { Icon as IconCheckRound, Text } from '@dlpco/ginga-stone'

import { Box } from '~/domains/platform/design-system'
import { For, If } from '~/domains/platform/lib/utilities-components'
import { useClickAway } from '~/ui/hooks/utils/ui/use-click-away'

import { Selection } from './partials/selection'
import { type CustomSelectProps, type SelectionType } from './interface'
import * as S from './styles'

export const CustomSelect = (props: PropsWithChildren<CustomSelectProps>) => {
  const { label, error, items, width, name, disabled, value: initialValue, moreInfoHasWitherText } = props

  const [value, setValue] = useState<SelectionType | undefined>(initialValue)
  const [isOpen, toggleOpen] = useToggle(false)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const { attributes, styles } = usePopper(referenceElement, popperElement, {
    placement: 'bottom'
  })

  const close = () => toggleOpen(false)
  useKey('Escape', close)
  const clickAwayRef = useClickAway(close)

  const selectedItem = items.find(item => {
    if (item.value === value && !item.disabled) return item.value === value
  })

  const handleChange = (value: SelectionType) => {
    setValue(value)

    if (props.onChange && value) {
      props.onChange(value)
    }

    toggleOpen()
  }

  const popperWidth = clickAwayRef.current?.offsetWidth + 'px'

  useLayoutEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue)
    }
  }, [initialValue])

  return (
    <S.Wrapper ref={clickAwayRef}>
      <Box color={disabled ? 'mediumGray3' : 'darkGray'} pb={1}>
        <Text as="label" inheritColor htmlFor={name} data-testid="label">
          {label}
        </Text>
      </Box>
      <S.InputWrapper
        role="combobox"
        aria-label="caixa de seleção"
        width={width}
        ref={setReferenceElement}
        onClick={() => {
          if (!disabled) {
            toggleOpen()
          }
        }}
      >
        <Selection
          icon={selectedItem?.icon}
          value={selectedItem?.text}
          error={Boolean(error)}
          focused={isOpen}
          disabled={disabled}
          aria-label="valor selecionado"
        />
      </S.InputWrapper>

      <If condition={Boolean(error)}>
        <Box color="red" pt={0} marginTop="4px">
          <Text inheritColor role="alert" size="small">
            {error && error}
          </Text>
        </Box>
      </If>
      <S.PopUpWrapper
        aria-label="opções"
        isOpen={isOpen}
        width={popperWidth}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <S.StyledCluster>
          <For
            of={items}
            render={({ text, icon: Icon, value, disabled, moreInfo }) => (
              <S.Item
                onClick={() => {
                  if (!disabled) handleChange(value)
                }}
                disabled={disabled}
                key={value}
                aria-label={text}
              >
                <S.TextWithIcon>
                  {Icon && <Icon />}
                  <S.SelectionTextValue withoutIcon={!Icon} primary={selectedItem?.value === value && !disabled}>
                    {text}
                    <If condition={Boolean(moreInfo && disabled)}>
                      <Box sx={{ whiteSpace: 'normal' }}>
                        <Text color={moreInfoHasWitherText ? 'neutralLow' : 'neutralHigh'}>{moreInfo}</Text>
                      </Box>
                    </If>
                  </S.SelectionTextValue>
                </S.TextWithIcon>

                <If condition={selectedItem?.value === value && !disabled}>
                  <IconCheckRound use="check-round-solid" color="primary" size="large" />
                </If>
              </S.Item>
            )}
          />
        </S.StyledCluster>
      </S.PopUpWrapper>
    </S.Wrapper>
  )
}
