import { type SchedulingStatus, type SchedulingTypeCancel } from '~/lib/types'

export const typeTranslation: Record<SchedulingTypeCancel, string> = {
  internal_transfer: 'Transferência entre contas Stone',
  external_transfer: 'TED',
  barcode_payment: 'Pagamento',
  pix_payment: 'Pix',
  refund_for_pix_payment: 'Devolução Pix',
  barcodeless_payment: 'Pagamento de tributos e impostos sem código de barras'
}

export const translateType = (type: SchedulingTypeCancel) => typeTranslation[type]

const statusTranslationMap: Record<SchedulingStatus, string> = {
  FAILED: 'Ocorreu um problema com seu agendamento',
  FINISHED: 'Finalizado',
  APPROVED: 'Aprovado',
  REJECTED: 'Rejeitado',
  CREATED: 'Criado',
  REFUNDED: 'Devolvido',
  DELAYED_TO_NEXT_BUSINESS_DAY: 'Adiado para o próximo dia útil',
  SCHEDULED: 'Agendado',
  CANCELLED: 'Cancelado',
  EXPIRED: 'Expirado'
}

export const translateStatus = (item: SchedulingStatus) => statusTranslationMap[item]
