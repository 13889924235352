import { blobClient } from '~/domains/platform/infra/http/blob-client'
import { exportFile, isBarcodelessPayment, isShareablePixPayment, isStatement } from '~/lib/helpers'

type ReceiptsFormats = 'pdf' | 'ofx' | 'csv' | 'xlsx'

interface ShareReceiptProps {
  operationType?: string[]
  operation?: string
  type: string
  id: string
  name: string
  from?: string
  to?: string
}

const receiptFormat = {
  xlsx: 'application/vnd.ms-excel',
  ofx: 'application/x-ofx',
  pdf: 'application/json',
  csv: 'text/csv'
}

export const shareReceipt = (format: ReceiptsFormats) => {
  return async (
    { type, id, name, from, to, operationType, operation }: ShareReceiptProps,
    client = blobClient,
    exporter = exportFile
  ) => {
    let url = '/receipts'

    if (isShareablePixPayment(type) || isBarcodelessPayment(type) || isStatement(type)) {
      url = '/exports/receipts'
    }

    const { data } = await client({
      url,
      data: { resourceId: id, resourceType: type, startDate: from, endDate: to, operationType, operation },
      headers: {
        Accept: receiptFormat[format]
      }
    })

    await exporter({
      data,
      name: `Comprovante de ${name}.${format}`
    })
  }
}
