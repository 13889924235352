import { type ReactNode } from 'react'

import { Block } from './styles'

export interface EmptyBlockProps {
  color?: any
  fadeColor?: any
  height?: string
  width?: string
  style?: Record<any, any>
  shadow?: boolean
  space?: boolean
  children?: ReactNode
}

export const EmptyBlock = (props: EmptyBlockProps) => (
  <Block
    backgroundColor={props.color}
    height={props.height}
    width={props.width}
    shadow={props.shadow}
    fadeColor={props.fadeColor}
    space={props.space}
  >
    {props.children}
  </Block>
)
