import { Icon } from '@dlpco/ginga-stone'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'
import { analytics } from '~/domains/platform/lib/analytics'
import { DropdownMenu } from '~/ui/business-components/shared/dropdown-menu'

import { type BankingMenuProps } from './interface'

export const items = (entity: Entity) => {
  const hasBankingVision = entity.roles.banking

  return [
    {
      label: 'Perfil',
      href: '/dados-cadastrais',
      icon: <Icon use="avatar-outline" size="large" />,
      onClick: () => {
        analytics.sendEvent('home perfil')
        analytics.sendEvent('home web - profile selected', {
          'avatar-dropdown': 'meu-perfil'
        })
      }
    },
    {
      label: 'Limites',
      href: '/limites-transacionais',
      icon: <Icon use="adjustment-outline" size="large" />,
      onClick: () => {
        analytics.sendEvent('profile - account settings selected', {
          option: 'meus limites'
        })
      },
      show: hasBankingVision
    },
    {
      label: 'Contatos',
      href: Heimdall.pass(['new_contacts_web']) ? '/contatos-v2/onboarding' : '/contatos/meus-contatos',
      icon: <Icon use="contact-outline" size="large" />,
      onClick: () => {
        analytics.sendEvent('ctt select')
        analytics.sendEvent('home web - profile selected', {
          'avatar-dropdown': 'contatos'
        })
      },
      show: hasBankingVision
    },
    {
      label: Heimdall.pass(['account_request']) ? 'Criar conta' : 'Criar nova conta',
      href: Heimdall.pass(['account_request']) ? '/solicitar-conta/onboarding' : '/criar-conta',
      icon: <Icon use="brand-potencia-outline" size="large" />,
      onClick: () => {
        analytics.sendEvent('home web - profile selected', {
          'avatar-dropdown': 'criar-uma-nova-conta'
        })
        analytics.sendEvent('signup - perfil page', {
          event: 'Quando o usuário clicar em Abra sua conta logado'
        })
      }
    }
  ]
}

export function BankingMenu(props: BankingMenuProps) {
  const { subject, entity } = props
  return <DropdownMenu subject={subject} items={subject.document ? items(entity) : []} />
}
