import { useRouter } from 'next/router'
import { Grid, Stack } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { Box, Text } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { analytics } from '~/domains/platform/lib/analytics'
import BlackBird from '~/domains/platform/lib/blackbird'

export function ConfirmConsent() {
  const { push } = useRouter()
  const query = BlackBird.getQuery()

  const handleConfirmConsent = async () => {
    Heimdall.pass(['open_finance_payment_initiation_web_v2'])
      ? push({ pathname: '/open-finance/v2/pagamento/pin', query: { ...query } })
      : push({ pathname: '/open-finance/pagamento/pin', query: { ...query } })

    analytics.sendEvent('OPF - itp payment confirmation clicked', {
      event: 'Cliente confirmou o consentimento da transação via ITP (OPF)'
    })
  }

  const handleBackNavigation = async () => {
    Heimdall.pass(['open_finance_payment_initiation_web_v2'])
      ? push({ pathname: '/open-finance/v2/pagamento/consentimento', query: { ...query } })
      : push({ pathname: '/open-finance/pagamento/consentimento', query: { ...query } })
  }

  return (
    <Box p="0 1rem 1rem 1rem" maxWidth="48rem">
      <Stack space="1.5rem">
        <Text pb="0.25rem" fontWeight="bold" fontSize="large">
          Continue pra confirmar a transação
        </Text>

        <Text mt={0} fontSize="small" color="mediumGray">
          Ao confirmar, insira seu PIN e continue, você será redirecionado e seu pagamento será iniciado.
        </Text>

        <Divider />

        <Grid gutter="1rem" min="20ch">
          <Button variant="filled" color="primary" size="large" onClick={handleConfirmConsent}>
            Confirmar transação
          </Button>
          <Button variant="filled" color="neutral" size="large" onClick={handleBackNavigation}>
            Voltar
          </Button>
        </Grid>
      </Stack>
    </Box>
  )
}
