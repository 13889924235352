import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'

import { authenticator } from '../authenticator/authenticator'
import { type FeatureFlagsAvailable } from '../heimdall'

interface FlagrPayloadResponse {
  resourceId: string
  enabledFeatures: FeatureFlagsAvailable[]
}

export const fetchTokenFlags = async () => {
  const { token } = isomorphicCookies.getAll()

  const { stone_subject_id } = authenticator.parseToken({ token })

  const [resourceType, resourceId] = stone_subject_id.split(':')

  const response = await httpClientBanking<FlagrPayloadResponse[]>({
    url: '/flagr/features/resource',
    data: [{ resourceId: [resourceId], resourceType }],
    method: 'POST'
  })

  return (response?.data || []).map(flagr => flagr.enabledFeatures).flat()
}
