import { useRouter } from 'next/router'
import { Stack } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { SuccessCheckedIllustration } from '~/domains/platform/design-system/illustrations/success-checked.illustration'

import { WrapperButton } from './styles'

export function ConsentSuccess(props: { name?: string; link: string }) {
  const { push } = useRouter()
  return (
    <Box minWidth="80vw">
      <Stack space="10rem">
        <Flex alignItems="center">
          <SuccessCheckedIllustration />
          <Box>
            <Stack space="1rem">
              <Text fontSize="xLarge" fontWeight="medium">
                Você deu acesso a um aplicativo
              </Text>
              <Text color="mediumGray" maxWidth="590px" fontSize="medium">
                <strong>{props.name}</strong> agora tem acesso à sua conta. Você também pode configurar esse acesso em
                outras contas.
              </Text>
            </Stack>
          </Box>
        </Flex>
        <WrapperButton>
          <Button size="large" onClick={() => push(props.link)}>
            Ir para aplicação
          </Button>
        </WrapperButton>
      </Stack>
    </Box>
  )
}
