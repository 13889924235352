import { isMobile } from 'react-device-detect'
import { type NextPage } from 'next'
import { useRouter } from 'next/router'

import { Loader } from '~/domains/platform/design-system/loader/loader'
import BlackBird from '~/domains/platform/lib/blackbird'
import { CleanPageContainer } from '~/ui/containers/clean-page-container'

const BYPASS_ROUTES = ['/open-finance/payment', '/open-finance/my-payments']

export function withRedirectToMobileOnSmallScreen(Page: NextPage<any>) {
  function WrappedPage(props: any) {
    const { pathname } = useRouter()

    if (isMobile && !BYPASS_ROUTES.includes(pathname)) {
      BlackBird.travelTo('/confira-o-app')

      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    return <Page {...props} />
  }

  WrappedPage.displayName = `withRedirectToMobileOnSmallScreen(${Page.displayName})`

  return WrappedPage
}
