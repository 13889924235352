import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { type Organization } from '~/lib/types'

export async function createOrganization(document: string, documentType = 'cnpj') {
  return httpClientBanking<Organization>({
    url: '/organizations',
    method: 'POST',
    data: { document, documentType }
  })
}
