import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'

import { Select } from '~/domains/platform/design-system'

import { type SelectProps } from './interface'

export const Wrapper = styled(Select)<SelectProps>`
  border-width: ${props => props.theme.colors?.input.borderSize};
  border-style: solid;
  border-color: ${ifProp('error', theme('colors.danger'), theme('colors.input.border'))};
  padding: ${theme('space.medium')};

  &:active,
  &:focus {
    border-color: ${theme('colors.input.active')};

    & + label {
      color: ${theme('colors.input.active')};
    }
  }

  &:disabled {
    background-color: ${ifProp('placeholder', theme('colors.muted'))};
  }
`
