import { type PropsWithChildren } from 'react'
import { Stack } from '@dlpco/fluid-layout'

import { BalanceCard } from '../balance-card'
import { ProfileCard } from '../profile-card'

import { type AccountDetailsProps } from './interface'

export function AccountDetails(props: PropsWithChildren<AccountDetailsProps>) {
  const { ownerName, ownerDocument, id, sx } = props
  return (
    <Stack space="2rem" style={sx}>
      <ProfileCard name={ownerName} document={ownerDocument} />
      {id && <BalanceCard accountId={id} />}
    </Stack>
  )
}
