import { memo } from 'react'
import { type NextPage } from 'next'

import { Loader } from '~/domains/platform/design-system/loader/loader'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'
import { CleanPageContainer } from '~/ui/containers/clean-page-container'

import { type WithPageConfigProps } from '../../infra/page-enhancers/with-page-config'

import { mergeScopes } from './merge-scopes'
import { type IdentityScopes } from './types'
import { useResources } from './use-resources'

export function withIdentityScopes(Page: NextPage<WithPageConfigProps<'stone_account'>>) {
  function IdentityScopes(props: WithPageConfigProps<'stone_account'>) {
    const { subject } = props
    const { identityId } = isomorphicCookies.getAll()

    const { data: resources, isSuccess } = useResources({
      subjectId: subject.id,
      ownerId: identityId,
      status: 'active'
    })

    if (!isSuccess || !identityId) {
      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    const scopes = {
      key: identityId,
      scopes: mergeScopes(resources)
    }

    window.sessionStorage.setItem('identityScopes', JSON.stringify(scopes))

    return <Page {...props} />
  }

  function CachedIdentityScopes(props: WithPageConfigProps<'stone_account'>) {
    const { identityId } = isomorphicCookies.getAll()
    const identityScopes = window.sessionStorage.getItem('identityScopes')

    const { key } = (identityScopes ? JSON.parse(identityScopes) : []) as IdentityScopes

    if (!identityId) {
      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    if (key === identityId) {
      return <Page {...props} />
    }

    return <IdentityScopes {...props} />
  }

  CachedIdentityScopes.displayName = `withIdentityScopes(${Page.displayName})`

  return memo(CachedIdentityScopes)
}
