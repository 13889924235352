import { Grid } from '@dlpco/fluid-layout'
import { Alert, Button } from '@dlpco/ginga-stone'
import { type AxiosError } from 'axios'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/ports'
import { useToast } from '~/domains/platform/layout/toast'
import { If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { createAccount } from '~/lib/services/banking/create-account'
import { useWizardProps } from '~/ui/components/modals/wizard/use-wizard-props'

import { FieldWrapper, LinkWrapper } from './styles'

export function ReviewData(props: { subject: Subject<'stone_account_resources'>; implicit?: boolean; entity: Entity }) {
  const { subject, implicit, entity } = props
  const { organizations } = subject
  const {
    content,
    handleStep: { next, previous }
  } = useWizardProps()

  const { addToast } = useToast()

  const implicityEntity = implicit ? entity : content?.entity
  const document = implicityEntity?.document

  const isEntityOrganization = stringFormat.documentType(document ?? '') === 'CNPJ'

  async function onConfirmation() {
    function goToSuccess() {
      next({ email: subject.email })
    }

    function alertError() {
      addToast({ message: 'Tivemos um problema, tente novamente', type: 'error' })
    }

    try {
      const identifier = organizations.find(x => x.document === document)?.id.split(':')[1] ?? document

      await createAccount(identifier)

      goToSuccess()
    } catch (err) {
      const error = err as AxiosError

      if (error.isAxiosError) {
        const axiosError = error as AxiosError

        if (axiosError.response?.status === 422 && error.response?.data.reason === 'invalid_approval_status') {
          goToSuccess()
          return
        }

        alertError()
      }

      alertError()
    }
  }

  return (
    <Box>
      <Text fontSize="xLarge" marginBottom="2.5rem">
        Revise seus dados
      </Text>
      <If condition={isEntityOrganization}>
        <Alert color="warning" heading="Atenção!" style={{ marginBottom: '2.5rem' }}>
          Você precisa ser sócio desta empresa.
        </Alert>

        <Flex justifyContent="space-between" margin="1.25rem 0">
          <Text color="mediumGray2">Dados da empresa</Text>
          <If condition={!implicit}>
            <Button variant="contentOnly" onClick={() => previous()}>
              Editar
            </Button>
          </If>
        </Flex>

        <Grid gutter="1rem" min="30ch">
          <FieldWrapper
            label="Razão Social"
            value={entity?.displayName}
            labelColor="darkGray"
            renderProps={{ fontSize: 'normal', fontWeight: 'normal' }}
          />
          <FieldWrapper
            label="CNPJ"
            value={stringFormat.document(document)}
            labelColor="darkGray"
            renderProps={{ fontSize: 'normal', fontWeight: 'normal' }}
          />
          <If condition={Boolean(implicit)}>
            <Box sx={{ gridColumn: 2 }}>
              <Button variant="contentOnly" onClick={() => previous({ entity: undefined })}>
                Criar conta para outro CPNJ
              </Button>
            </Box>
          </If>
        </Grid>
      </If>
      <Text color="mediumGray2" margin="2.5rem 0 1.25rem 0">
        Dados pessoais
      </Text>

      <Grid gutter="1rem" min="30ch">
        <FieldWrapper
          label="Nome"
          value={subject.displayName}
          labelColor="darkGray"
          renderProps={{ fontSize: 'normal', fontWeight: 'normal' }}
        />
        <FieldWrapper
          label="CPF"
          value={stringFormat.document(subject.document)}
          labelColor="darkGray"
          renderProps={{ fontSize: 'normal', fontWeight: 'normal' }}
        />
      </Grid>
      <Text color="mediumGray2" margin="2.5rem 0 1.25rem 0">
        Seus dados de acesso
      </Text>
      <Grid gutter="1rem" min="30ch">
        <FieldWrapper
          label="E-mail"
          value={entity?.email ?? subject.email}
          labelColor="darkGray"
          renderProps={{ fontSize: 'normal', fontWeight: 'normal' }}
        />
        <FieldWrapper
          label="Senha"
          value="***********"
          labelColor="darkGray"
          renderProps={{ fontSize: 'normal', fontWeight: 'normal' }}
        />
      </Grid>
      <Text margin="3rem 0 1rem 0">
        Ao confirmar, você aceita os{' '}
        <LinkWrapper href="https://www.stone.com.br/contrato/" target="blank">
          Termos de uso
        </LinkWrapper>{' '}
        e a{' '}
        <LinkWrapper href="https://www.stone.com.br/seguranca/" target="blank">
          Política de Privacidade
        </LinkWrapper>
        .
      </Text>
      <Button size="large" onClick={onConfirmation}>
        Confirmar
      </Button>
    </Box>
  )
}
