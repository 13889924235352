import { unmask } from '~/lib/helpers/utils/unmask'
import { httpClientCustomer } from '~/lib/services/acquirer/http-client-customer'
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type DetailedPrepayCondition } from '../../rav/shared/entities/prepay-condition'

interface UseCustomerPrepayConditionCofing {
  cnpj: string
  affiliationKey?: string
}

export const getCustomerPrepayCondition = (config: UseCustomerPrepayConditionCofing) => {
  const { cnpj: document, affiliationKey } = config

  return httpClientCustomer({
    url: `/organizations/${unmask(document)}/prepayment/condition`,
    params: { customerExternalKey: affiliationKey }
  })
}

export function useCustomerPrepayCondition(config: UseCustomerPrepayConditionCofing) {
  const { cnpj, affiliationKey } = config
  const { data: response } = useQueryRequest<DetailedPrepayCondition>(
    ['useCustomerPrepayCondition', cnpj, affiliationKey],
    () => getCustomerPrepayCondition(config),
    {
      enabled: Boolean(affiliationKey)
    }
  )

  return { customerPrepaymentCondition: response?.data }
}
