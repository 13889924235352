import { type Dayjs } from 'dayjs'

import { type ExtractFourthParam } from '~/lib/types'

import { type InstalledPlugins } from '../index'
import { type TimeMachine, type Units } from '../time-machine'
import { type TMInstanceWithPlugins, type TmPlugin } from '../types'

interface isBetweenOptions {
  excludeStart?: boolean
  excludeEnd?: boolean
  includeStart?: boolean
  includeEnd?: boolean
}
export interface ComparisonTmMethods {
  isToday(): boolean
  isSame(date: Date | TimeMachine, config?: { ignoreTime?: boolean }): boolean
  isBefore(date: Date | TimeMachine): boolean
  isAfter(date: Date | TimeMachine): boolean
  isBetween(
    minDate: Date | TimeMachine,
    maxDate: Date | TimeMachine,
    unit?: Units,
    betweenOption?: isBetweenOptions
  ): boolean
}

const betweenOptionsMap: Record<keyof isBetweenOptions, '(' | ')' | '[' | ']'> = {
  excludeStart: '(',
  includeStart: '[',
  excludeEnd: ')',
  includeEnd: ']'
}

function getBetweenOption(options?: isBetweenOptions): ExtractFourthParam<Dayjs['isBetween']> {
  let startOption = '('
  let endOption = ')'

  for (const op in options) {
    if (op in betweenOptionsMap) {
      if (op.endsWith('End')) {
        endOption = betweenOptionsMap[op as keyof isBetweenOptions]
      } else {
        startOption = betweenOptionsMap[op as keyof isBetweenOptions]
      }
    }
  }

  return `${startOption}${endOption}` as ExtractFourthParam<Dayjs['isBetween']>
}

export const comparisonTmPlugin: TmPlugin<ComparisonTmMethods> = (instance, timeMachine) => {
  const proto = instance.prototype

  proto.isBetween = function (min, max, unit, betweenOptions) {
    const betweenOption = getBetweenOption(betweenOptions)

    return this['$dateEngine'].isBetween(new Date(min.valueOf()), new Date(max.valueOf()), unit, betweenOption)
  }

  proto.isSame = function (tm, config = {}) {
    if (config.ignoreTime) {
      const dateToCompare = (this as TMInstanceWithPlugins<InstalledPlugins>).format('defaultApi')
      const dateParsed = timeMachine(tm).format('defaultApi')

      return dateToCompare === dateParsed
    }

    return this.valueOf() === tm.valueOf()
  }

  proto.isBefore = function (tm) {
    return this.valueOf() < tm.valueOf()
  }

  proto.isAfter = function (tm) {
    return this.valueOf() > tm.valueOf()
  }

  proto.isToday = function () {
    const dateParsed = (this as TMInstanceWithPlugins<InstalledPlugins>).format('defaultApi')
    const todayParsed = timeMachine().format('defaultApi')

    return todayParsed === dateParsed
  }
}
