import { dynamicTemplate } from '~/lib/helpers/utils/dynamic-template'
import { type CancelSale, type CustomerResponse } from '~/lib/types'

import { httpClientCustomer } from '../http-client-customer/http-client-customer'

import { type CancelSaleProps } from './interface'

export const cancelSale = async (props: CancelSaleProps) => {
  const { authorizationChallengeCode, authorizationChallengeId, affiliationCode, transactionId, amountInCents } = props

  const url = dynamicTemplate('/affiliations/${affiliationCode}/transactions/${transactionId}/cancel', {
    affiliationCode,
    transactionId
  })

  const { data: response, ...restAxios } = await httpClientCustomer<CustomerResponse<CancelSale>>({
    method: 'POST',
    url,
    params: {
      amountInCents
    },
    headers: {
      'authorization-challengeid': authorizationChallengeId,
      'authorization-challengecode': authorizationChallengeCode
    }
  })
  return { data: response?.data, ...restAxios }
}
