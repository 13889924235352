import { useEffect, useState } from 'react'

import { isObjectEmpty } from '~/domains/platform/lib/object-helpers/is-object-empty'

const intialStep = 0

export function useSteps(lenght: number, initialContent: Record<string, any> = {}) {
  const [content, setContent] = useState(initialContent)
  const [step, setStep] = useState(intialStep)
  const [pathTracker, setPathTracker] = useState<number[]>([intialStep])

  const isLastStep = lenght - 1 === step
  const isFirstStep = step === intialStep

  function reset() {
    setStep(intialStep)
    setPathTracker([intialStep])
    setContent({})
  }

  function goTo(step: number, additionalContent?: any) {
    setContent({ ...content, ...additionalContent })
    setStep(step)
  }

  function next(additionalContent?: any) {
    if (!isLastStep) {
      setContent({ ...content, ...additionalContent })
      setStep(step + 1)
    }
  }

  function previous() {
    if (!isFirstStep) {
      const previousStep = pathTracker[pathTracker.length - 2]
      setStep(previousStep)
    }
  }

  useEffect(() => {
    if (pathTracker.includes(step)) {
      const syntheticPath = pathTracker.slice(intialStep, pathTracker.indexOf(step) + 1)
      setPathTracker(syntheticPath)
    } else {
      setPathTracker([...pathTracker, step])
    }
  }, [step])

  useEffect(() => {
    if (!isObjectEmpty(initialContent) && isObjectEmpty(content)) {
      setContent(initialContent)
    }
  }, [initialContent])

  return { step, next, previous, goTo, reset, isLastStep, isFirstStep, content, setContent }
}
