import dayjs, { type Dayjs } from 'dayjs'

import { type InstalledPlugins } from './index'
import { type ExtendedTimeMachine, type TimeMachineWithPlugins, type TmPlugin } from './types'

const isTimeMachine = (date: any): date is TimeMachine => date instanceof TimeMachine

export const kPluginInstalled = Symbol('tm__plugin_installed')

export type Units = 'd' | 'D' | 'M' | 'y' | 'h' | 'm' | 's' | 'ms' | 'w'

interface TmConfig {
  date?: Date | string | number
}

/**
 * @class TimeMachine - a class that represents a date and time with a set of methods to manipulate it
 */
class TimeMachine {
  private readonly $dateEngine: Dayjs

  constructor(config?: TmConfig) {
    this.$dateEngine = dayjs(config?.date)
  }

  public clone() {
    return new TimeMachine({ date: this.toDate() })
  }

  /**
   * @see https://stackoverflow.com/a/10339568
   */
  public valueOf() {
    return this.toDate().getTime()
  }

  public toISOString() {
    return this.toDate().toISOString()
  }

  public toString() {
    return this.toDate().toUTCString()
  }

  public add(number: number, units: Units) {
    const nextValue = this.$dateEngine.add(number, units)

    return timeMachine(nextValue.toDate())
  }

  public startOf(unit: Units) {
    const nextValue = this.$dateEngine.startOf(unit)

    return timeMachine(nextValue.toDate())
  }

  public endOf(unit: Units) {
    const nextValue = this.$dateEngine.endOf(unit)

    return timeMachine(nextValue.toDate())
  }

  public subtract(number: number, units: Units) {
    return this.add(number * -1, units)
  }

  public toDate() {
    return new Date(this.$dateEngine.valueOf())
  }
}

/**
 * Receives any representation of a Date or a TimeMachine instance
 *
 * @returns a new TimeMachine instance
 *
 * @example const today = timeMachine() // returns TimeMachine instance
 * @example const someDay = timeMachine('11-04-2000') // returns TimeMachine instance
 */
function timeMachine(date?: Date | string | number | TimeMachine) {
  if (isTimeMachine(date)) {
    return date.clone()
  }

  const config = {
    date
  }

  return new TimeMachine(config)
}

timeMachine.extends = <T = unknown>(plugin: TmPlugin<T>) => {
  if (!plugin[kPluginInstalled]) {
    plugin(TimeMachine as ExtendedTimeMachine<T>, timeMachine as unknown as TimeMachineWithPlugins<InstalledPlugins>)
    plugin[kPluginInstalled] = true
  }

  return timeMachine
}

export { TimeMachine, timeMachine }
