type NonNullProperties<T> = T extends {
  [K in keyof T]: T[K]
}
  ? { [K in keyof T]: Exclude<T[K], null> }
  : never

export const removeNullProperties = <T extends Record<string | number | symbol, any>>(obj: T): NonNullProperties<T> => {
  const objWithoutNullValues = Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== null))

  return objWithoutNullValues as NonNullProperties<T>
}
