import { type ReactNode } from 'react'
import { type ButtonComponent, type OwnPropsOf, Button, Text } from '@dlpco/ginga-stone'

import { Box, Card, Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'

export interface WarningCardProps {
  icon?: ReactNode
  title: string | ReactNode
  subTitle?: string | ReactNode
  buttonText?: string | ReactNode
  action?(): void
  secondAction?(): void
  secondButton?: string
  buttonShape?: OwnPropsOf<ButtonComponent>['shape']
}

export function NewInviteNotificationWarningCard(props: WarningCardProps) {
  const { icon, title, subTitle, buttonText, action, secondAction, secondButton, buttonShape = 'regular' } = props

  return (
    <Card p={3}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <If condition={Boolean(icon)}>
            <Box aria-label="icon">{icon}</Box>
          </If>
          <Flex flexDirection="column" marginLeft={2}>
            <Text color="neutralHigh" weight="bold">
              {title}
            </Text>
            <Text color="neutral" size="small">
              {subTitle}
            </Text>
          </Flex>
        </Flex>
        <Flex minWidth="auto">
          <If condition={Boolean(secondButton)}>
            <Box data-testid="secondButton">
              <Button variant="contentOnly" onClick={secondAction}>
                {secondButton}
              </Button>
            </Box>
          </If>
          <Box marginLeft={4} data-testid="button">
            <Button role="link" onClick={action} shape={buttonShape}>
              {buttonText}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Card>
  )
}
