import { memo } from 'react'
import { type NextPage } from 'next'

import { Loader } from '~/domains/platform/design-system/loader/loader'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { CleanPageContainer } from '~/ui/containers/clean-page-container'

import { type WithPageConfigProps } from '../../infra/page-enhancers/with-page-config'

import { type IsIdentityOwner } from './types'
import { useResources } from './use-resources'

export function withVerifyIdentityOwnership(Page: NextPage<WithPageConfigProps<'stone_account'>>) {
  function VerifyIdentityOwnership(props: WithPageConfigProps<'stone_account'>) {
    const { subject } = props
    const { identityId } = isomorphicCookies.getAll()

    const { data: resources, isSuccess } = useResources({ resourceId: stringFormat.identityId(identityId) })

    if (!isSuccess || !identityId) {
      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    const ownerResources = resources.find(resource => resource.ownerId === subject.id)

    const isIdentityOwner = {
      key: identityId,
      isOwner: Boolean(ownerResources?.isOwner)
    }

    if (isIdentityOwner.isOwner) {
      window.sessionStorage.setItem('isIdentityOwner', JSON.stringify(isIdentityOwner))
    } else {
      window.sessionStorage.removeItem('isIdentityOwner')
    }

    return <Page {...props} />
  }

  function CachedVerifyIdentityOwnership(props: WithPageConfigProps<'stone_account'>) {
    const { identityId } = isomorphicCookies.getAll()
    const isIdentityOwner = window.sessionStorage.getItem('isIdentityOwner')

    const { key } = (isIdentityOwner ? JSON.parse(isIdentityOwner) : []) as IsIdentityOwner

    if (!identityId) {
      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    if (key === identityId) {
      return <Page {...props} />
    }

    return <VerifyIdentityOwnership {...props} />
  }

  CachedVerifyIdentityOwnership.displayName = `withVerifyIdentityOwnership(${Page.displayName})`

  return memo(CachedVerifyIdentityOwnership)
}
