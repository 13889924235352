import { splitEvery } from '~/domains/platform/lib/array-helpers/split-every'
import { httpClientCustomer } from '~/lib/services/acquirer/http-client-customer/http-client-customer'
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type MerchantsContentProps, type MerchantsProps } from '../../shared/entities/merchants'

// This Api below has a limit of 170 parameters, due it we have to stack the requests
// see: https://3.basecamp.com/4129278/buckets/10365580/messages/3835728840#__recording_3886821913
const MAX_LIMIT_SC = 100

export async function fetchMerchantsBySplit(stoneCodes: string[]) {
  const associatedStoneCodeChunks = splitEvery(MAX_LIMIT_SC, stoneCodes)

  return Promise.all(
    associatedStoneCodeChunks.map(async chunk => {
      const stoneCodesFormatted = chunk.join(',')
      const url = `/merchants/summary?affiliationCodes=${stoneCodesFormatted}`

      const {
        data: { data: response }
      } = await httpClientCustomer<MerchantsProps>({
        url: url
      })

      return response
    })
  ).then(dataByChunks => dataByChunks.flat())
}

export function useMerchants(stoneCodes?: string[]) {
  const { data, ...restQuery } = useQueryRequest<MerchantsContentProps[], unknown, true>(
    ['useMerchants', stoneCodes],
    () => stoneCodes && fetchMerchantsBySplit(stoneCodes),
    {
      refetchOnWindowFocus: false
    }
  )

  return { merchants: data, ...restQuery }
}
