import styled, { css, keyframes } from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

const fadeOpacity = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const Fixed = styled.div`
  position: fixed;
  z-index: ${theme('zIndices.wizard')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const FullscreenDialog = styled.div<{ headerHeight: string }>`
  width: 100vw;
  height: 100vh;
  background-color: ${theme('colors.white')};
  padding-top: ${prop('headerHeight')};
  opacity: 1;
  animation: ${fadeOpacity} 0.2s ease;
  overflow-y: scroll;
`

export const ContentWrapper = styled.div<{ grow: boolean; fullWidth: boolean }>`
  max-width: 80ch;
  ${(ifProp('grow'),
  css`
    flex: 1;
    display: flex;

    & > * {
      flex-grow: 1;
    }
  `)}
  ${(ifProp('fullWidth'),
  css`
    max-width: unset;
  `)}
`
