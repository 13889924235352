import { Badge } from '@dlpco/ginga-stone'

import { Flex, Text } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'

type AccountInfoProps = {
  active?: boolean
  chosenAccount?: boolean
  document: string
  accountNumber: string
}

export function AccountInfo({ active = false, chosenAccount = false, document, accountNumber }: AccountInfoProps) {
  return (
    <Flex flexDirection="column" alignItems="flex-start">
      <Text fontSize="small">CPF/CNPJ: {stringFormat.document(document)}</Text>
      <Text fontSize="small">Conta: {stringFormat.accountNumber(accountNumber)}</Text>
      <Flex>
        <If condition={active}>
          <Badge
            css={{ marginRight: '0.25rem' }}
            color="positive"
            variant="solid"
            aria-label={`Sessão ativa: ${accountNumber}`}
          >
            sessão ativa
          </Badge>
        </If>
        <If condition={chosenAccount}>
          <Badge color="info" variant="solid" aria-label={`Conta selecionada: ${accountNumber}`}>
            conta selecionada
          </Badge>
        </If>
      </Flex>
    </Flex>
  )
}
