import { useToggle } from 'react-use'
import { Grid } from '@dlpco/fluid-layout'
import { Badge, Heading, Icon, Text } from '@dlpco/ginga-stone'
import dayjs from 'dayjs'

import { Box, Flex } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { Choose, For } from '~/domains/platform/lib/utilities-components'
import { type ImmutableMetadataReceivable, type Institution, type Integrations as IntegrationsType } from '~/lib/types'

import { IntegrationsDetail } from '../details'
import { statusBadgeMap, statusTranslateMap } from '../status-map'
import { BadgeWrapper, ContentWrapper } from '../styles'

import { BoxWrapper, ItemShadow, StackWrapper } from './styles'

interface CardProps {
  integration: IntegrationsType
  institutions: Institution[]
}

const RESOURCE_RECEIVABLE = 'srn:resource:receivable'

const CardIntegrations = (props: CardProps) => {
  const { integration, institutions } = props
  const [detailOpen, toggleDetail] = useToggle(false)

  const handleInstitutionName = () => {
    if (integration.resourceType === RESOURCE_RECEIVABLE) {
      const integrationIspbCode = (integration.immutableMetadata as ImmutableMetadataReceivable).document.slice(0, 8)
      return institutions?.find(institution => institution.ispbCode.match(integrationIspbCode))?.name
    }

    return integration.subjectName ?? 'Stone Pagamentos S.A. Stone'
  }

  const institutionName = handleInstitutionName()

  return (
    <Box>
      <ItemShadow isOpen={detailOpen}>
        <BoxWrapper onClick={toggleDetail}>
          <ContentWrapper gridTemplateColumns="2fr 1fr">
            <ContentWrapper gridTemplateColumns="48px 2fr" gap="1rem">
              <Icon use="bank-outline" color="neutral" style={{ width: '40px', height: '40px' }} />
              <Box>
                <Text weight="bold">{institutionName}</Text>
                <Flex alignItems="inline" mt={1}>
                  <Text color="neutral">Adicionada em</Text>
                  <Flex ml="0.2rem">
                    <Text color="neutral" weight="bold">
                      {dayjs(integration.createdAt).format('DD/MM/YYYY')}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </ContentWrapper>
            <ContentWrapper gridTemplateColumns="2fr 4fr">
              <BadgeWrapper>
                <Badge color={statusBadgeMap[integration.status].color}>{statusTranslateMap[integration.status]}</Badge>
              </BadgeWrapper>
              <Icon use="chevron-right-outline" color="neutral" size="large" />
            </ContentWrapper>
          </ContentWrapper>
        </BoxWrapper>
      </ItemShadow>
      <IntegrationsDetail
        isOpen={detailOpen}
        integration={integration}
        institutionName={institutionName}
        toggle={toggleDetail}
      />
    </Box>
  )
}

interface IntegrationsProps {
  integrations: IntegrationsType[]
  institutions: Institution[]
}

export function Integrations(props: IntegrationsProps) {
  const { integrations, institutions } = props

  const integrationsInstitutions = integrations.filter(integration => integration.resourceType === RESOURCE_RECEIVABLE)
  const integrationsApps = integrations.filter(
    integration => integration.resourceType === 'srn:resource:paymentaccount'
  )

  return (
    <StackWrapper space="2rem">
      <Heading weight="semi">Quem monitora sua agenda Stone</Heading>
      <Choose>
        <Choose.When condition={integrationsInstitutions.length > 0}>
          <ContentWrapper gridTemplateColumns="repeat(2, 1fr)" gap="1.5rem">
            <For
              of={integrationsInstitutions}
              render={(integrationsInstitution: IntegrationsType, index) => (
                <CardIntegrations
                  key={`${integrationsInstitution.resourceId}${index}`}
                  integration={integrationsInstitution}
                  institutions={institutions}
                />
              )}
            />
          </ContentWrapper>
        </Choose.When>
        <Choose.Otherwise>
          <Text size="large"> Você não possui integrações.</Text>
        </Choose.Otherwise>
      </Choose>
      <Heading weight="semi">Aplicativos com permissões na sua conta</Heading>
      <Choose>
        <Choose.When condition={integrationsApps.length > 0}>
          <ContentWrapper gridTemplateColumns="repeat(2, 1fr)" gap="1.5rem">
            <For
              of={integrationsApps}
              render={(integrationsApp: IntegrationsType, index) => (
                <CardIntegrations
                  key={`${index}${integrationsApp.resourceId}`}
                  integration={integrationsApp}
                  institutions={institutions}
                />
              )}
            />
          </ContentWrapper>
        </Choose.When>
        <Choose.Otherwise>
          <Text size="large">Você não possui integrações.</Text>
        </Choose.Otherwise>
      </Choose>
    </StackWrapper>
  )
}

export function IntegrationsLoader() {
  const items = Array.from(Array(2).keys())
  return (
    <StackWrapper space="2rem">
      <Heading weight="semi">Quem monitora sua agenda Stone</Heading>
      <For
        of={items}
        render={index => (
          <Grid gutter="1.5rem" min="5rem" key={index}>
            <Skeleton width="100%" height="8ch" />
            <Skeleton width="100%" height="8ch" />
          </Grid>
        )}
      />
      <Heading weight="semi">Aplicativos com permissões na sua conta</Heading>
      <Grid gutter="1.5rem" min="5rem">
        <Skeleton width="100%" height="8ch" />
        <Skeleton width="100%" height="8ch" />
      </Grid>
    </StackWrapper>
  )
}
