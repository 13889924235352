import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'

import { type CheckCreditAccess } from '../entities'

export async function fetchCheckCreditAccess({ document }: { document: string }) {
  const response = await httpClientCredit<CheckCreditAccess>({
    url: `experience-bff-service/api/web/v1/users/check-credit-access?IncludeProductType=revolving&IncludeProductType=loan`,
    method: 'GET',
    params: { document }
  })

  const { data } = response

  if (data && data?.content) data.content.hasCredit = Object.values(data?.content || {}).some(val => val === true)

  return {
    ...response,
    data
  }
}
