import { Box, Flex } from '~/domains/platform/design-system'
import { EmptyBlock } from '~/ui/components/utils/empty-block'
import { theme } from '~/ui/theme'

export function BalanceLoading() {
  return (
    <Flex aria-busy role="progressbar" aria-valuetext="Carregando" flexDirection="column">
      <Box>
        <EmptyBlock
          color={theme.colors.lightGray}
          fadeColor={theme.colors.mediumGray3}
          height="20px"
          width="100px"
          space
          style={{
            marginRight: '8px'
          }}
        />
      </Box>
      <Box>
        <EmptyBlock
          color={theme.colors.lightGray}
          fadeColor={theme.colors.mediumGray3}
          height="17px"
          width="70px"
          space
        />
      </Box>
    </Flex>
  )
}
