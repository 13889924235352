import { type FieldProps, Field } from '~/domains/platform/design-system/field/field'
import { stringFormat } from '~/lib/helpers/utils/string-format'

export interface DocumentProps extends FieldProps {
  defaultLabel?: boolean
}

export const Document = (props: DocumentProps) => {
  const { value, label: passedLabel, defaultLabel, formatter = stringFormat.document, ...restProps } = props
  const label = passedLabel ? passedLabel : defaultLabel ? stringFormat.documentType(value) : undefined

  return <Field {...restProps} formatter={formatter} label={label} value={value} />
}
