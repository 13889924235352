import { type ReactNode } from 'react'

import { useHiddenSensitive } from '../hooks/use-hidden-sensitive'

interface HiddenAmountProps {
  children: ReactNode
}

export const HIDDEN_CHARACTERS = '••••'

export const HiddenAmount = ({ children }: HiddenAmountProps) => {
  const [isHiddenSensitive] = useHiddenSensitive()

  if (isHiddenSensitive) {
    return <>{HIDDEN_CHARACTERS}</>
  }

  return <>{children}</>
}
