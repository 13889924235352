import { useForm, useWatch } from 'react-hook-form'
import { useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Heading, Radio, Text } from '@dlpco/ginga-stone'

import { reportSales } from '~/domains/acquirer/ports/services/report-sales'
import { Box, Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { useToast } from '~/domains/platform/layout/toast'
import { timeMachine } from '~/domains/platform/lib/time-machine'
import { exportFile } from '~/lib/helpers/utils/export-file'
import { type PurchaseStoneCode } from '~/lib/types/acquirer/Purchase'

import { type FormValues } from './interface'
import { ButtonWrapper } from './styles'

export type ReportFormProps = {
  dateSales: Date[]
  entityDocument: string
  onSuccess?: () => void
  stoneCodes: PurchaseStoneCode[]
}

export const ReportForm = (props: ReportFormProps) => {
  const SALE_NOT_EXPORTED = 'Não foi possível baixar o relatório deste período. Entre em contato com a gente.'
  const SALE_EXPORTED = 'Você baixou o relatório!'

  const { dateSales, onSuccess, stoneCodes } = props

  const stringfiedStoneCodes = stoneCodes.map(item => item.stoneCode).join(',')

  const { addToast } = useToast()

  const { handleSubmit, register, control } = useForm<FormValues>({
    defaultValues: {
      exportType: 'sales',
      format: 'csv'
    }
  })

  const [loading, toggleLoading] = useToggle(false)

  const watchExportType = useWatch({ name: 'exportType', control })

  const onSubmit = handleSubmit(async values => {
    toggleLoading()

    try {
      const initialDate = timeMachine(dateSales[0])
      const finalDate = timeMachine(dateSales[1])

      const { data } = await reportSales({
        merchantIds: stringfiedStoneCodes,
        exportType: values.exportType,
        finalDate: finalDate.format('defaultApi'),
        format: values.format,
        startDate: initialDate.format('defaultApi')
      })

      await exportFile({
        data,
        name: `Relatorio de Vendas - ${initialDate.format('defaultApi')} a ${finalDate.format('defaultApi')}.${
          values.format
        }`
      })

      addToast({
        message: SALE_EXPORTED,
        type: 'success'
      })

      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      addToast({
        message: SALE_NOT_EXPORTED,
        type: 'error'
      })
    } finally {
      toggleLoading()
    }
  })

  return (
    <Stack space="1.5rem" as="form" role="form" aria-label="relatório de vendas" onSubmit={onSubmit}>
      <Dimmer isVisible={loading}>
        <Loader />
      </Dimmer>

      <Stack space="0.5rem">
        <Heading size="small" weight="semi">
          Tipo de relatório
        </Heading>

        <Flex justifyContent="space-between">
          <Box>
            <Text>Lista de Vendas</Text>
            <Text size="small" color="neutral">
              Dados detalhados de cada venda
            </Text>
          </Box>
          <Radio role="radio" aria-label="Lista de Vendas" value="sales" {...register('exportType')} />
        </Flex>
        <Flex justifyContent="space-between">
          <Box>
            <Text>Consolidado</Text>
            <Text size="small" color="neutral">
              Dados das vendas agrupados
            </Text>
          </Box>
          <Radio {...register('exportType')} role="radio" aria-label="Consolidado" value="consolidated" />
        </Flex>
      </Stack>

      <Stack space="0.5rem">
        <Heading size="small" weight="semi">
          Tipo de exportação
        </Heading>
        <Flex justifyContent="space-between">
          <Box>
            <Text>CSV</Text>
            <Text size="small" color="neutral">
              Arquivo de Excel
            </Text>
          </Box>
          <Radio defaultChecked {...register('format')} role="radio" aria-label="CSV" value="csv" />
        </Flex>

        <Flex justifyContent="space-between">
          <Box>
            <Text>XLS</Text>
            <Text size="small" color="neutral">
              Arquivo de Excel
            </Text>
          </Box>
          <Radio {...register('format')} role="radio" aria-label="XLS" value="xlsx" />
        </Flex>

        <Flex justifyContent="space-between">
          <Box>
            <Text>PDF</Text>
            <Text size="small" color="neutral">
              Documento não editável
            </Text>
          </Box>
          <Radio
            {...register('format')}
            role="radio"
            aria-label="PDF"
            value="pdf"
            disabled={watchExportType === 'sales'}
          />
        </Flex>
      </Stack>

      <ButtonWrapper>
        <Button size="large" type="submit" role="button">
          Baixar
        </Button>
      </ButtonWrapper>
    </Stack>
  )
}
