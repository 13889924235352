import { type ComponentProps } from 'react'
import { Cluster } from '@dlpco/fluid-layout'
import styled, { keyframes } from 'styled-components'
import { ifProp, theme } from 'styled-tools'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { toRem } from '~/lib/helpers'

const fadeOpacity = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const WizardBanner = styled(Cluster)<Partial<ComponentProps<typeof Cluster>>>`
  margin-left: -${toRem(160)};

  svg {
    float: left;
    min-width: 300px;
    max-width: 50%;
  }
`

WizardBanner.defaultProps = {
  space: '3rem',
  showOverflow: true,
  noWrap: true,
  align: 'center'
}

export const WizardWrapper = styled(Box)`
  width: 100%;
  position: fixed;
  height: calc(100vh - ${theme('sizes.header.height')});
  z-index: ${theme('zIndices.wizard')};
  top: ${theme('sizes.header.height')};
  left: 0;
  overflow-y: auto;
  opacity: 1;
  animation: ${fadeOpacity} 0.15s ease-in;
  background-color: ${theme('colors.white')};
`

export const WizardBox = styled(Box)<{ mainContent?: boolean }>`
  background-color: ${ifProp('mainContent', theme('colors.white'), theme('colors.lightGray'))};
  padding-right: ${ifProp('mainContent', '3rem')};
  padding-bottom: 3rem;
`

export const Title = styled(Text)`
  color: ${theme('colors.primary')};
  font-size: ${toRem(24)};
`

export const ContentWrapper = styled.div`
  margin-left: ${toRem(80)};
  height: 100%;
`

export const SuperFlex = styled(Flex)`
  > div {
    width: 100%;
  }
`
