import styled from 'styled-components'

export const LoaderWrapper = styled.div`
  width: 100%;
`

export const WrapperButton = styled.div`
  display: flex;
  align-content: center;
  flex: 1;
  justify-content: flex-end;
  flex-wrap: wrap;

  > * {
    max-width: 30ch;
    flex: 1;
    margin-right: 1rem;
  }
`
