import { Stack } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { ExclamationIllustration } from '~/domains/platform/design-system/illustrations/exclamation.illustration'
import BlackBird from '~/domains/platform/lib/blackbird'

export function HasConsent(props: { name?: string }) {
  return (
    <Box mt="2rem">
      <Stack space="15rem">
        <Flex alignItems="center">
          <ExclamationIllustration />
          <Box ml="1rem">
            <Stack space="1rem">
              <Text fontSize="xLarge" fontWeight="medium">
                Permissão já concedida
              </Text>
              <Text color="mediumGray" maxWidth="590px" fontSize="medium">
                Parece que <strong>{props.name}</strong> já possui acesso a sua Conta Stone.
              </Text>
            </Stack>
          </Box>
        </Flex>
        <Box>
          <Button onClick={() => BlackBird.goHome()} size="large">
            Ok, entendi
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}
