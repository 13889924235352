import { Box } from '~/domains/platform/design-system'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/types'
import { withInjectedProps } from '~/domains/platform/infra/page-enhancers/compositional/with-injected-props'
import { type Consent as ConsentType, type ConsentResponse } from '~/lib/types'
import { Wizard } from '~/ui/components/modals/wizard/wizard'

import { AccountDetails } from '../../banking'

import { AccessAccountItem, ConsentAccessAccount } from './access-account'
import { ConsentChooseAccount } from './choose-account'
import { ConsentedScopes } from './consented-scopes'

export function Consent(props: {
  account: PaymentAccount
  baseRedirectUrl: string
  consent?: ConsentType[] | ConsentResponse<ConsentType[]>
  redirectUrl?: string
  name?: string
  selection?: ConsentType
  setSelection: (value: ConsentType) => void
  onGiveAccess: () => void
  isOpen: boolean
  toggle(nextValue?: boolean): void
}) {
  const {
    consent,
    name,
    account: { ownerName, ownerDocument, id },
    baseRedirectUrl,
    selection,
    redirectUrl,
    setSelection,
    onGiveAccess,
    isOpen,
    toggle
  } = props
  const onwerConsent = (consent as ConsentType[])?.find(cnt => cnt.ownerDocument === ownerDocument)

  const withConsent = withInjectedProps({ consent, selection, name, setSelection })
  const withGiveAccess = withInjectedProps({ onwerConsent, name, redirectUrl, baseRedirectUrl, onGiveAccess })

  function stepsFactory(inner: boolean) {
    return inner
      ? [
          {
            mainContent: withGiveAccess(ConsentAccessAccount),
            sideContent: () => (
              <>
                <AccountDetails ownerDocument={ownerDocument} ownerName={ownerName} id={id} />
                <Box mt="2rem">
                  <ConsentedScopes consentedScopes={onwerConsent?.consentedScopes} />
                </Box>
              </>
            )
          }
        ]
      : [
          {
            mainContent: withConsent(ConsentChooseAccount)
          },
          {
            mainContent: withGiveAccess(ConsentAccessAccount),
            sideContent: () => <AccessAccountItem consentedScopes={selection?.consentedScopes} />
          }
        ]
  }

  return (
    <Wizard
      isOpen={isOpen}
      toggle={toggle}
      title="Aplicativos com acesso à conta"
      steps={stepsFactory(Boolean(redirectUrl))}
    />
  )
}
