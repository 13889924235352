import styled, { keyframes } from 'styled-components'

const fadeInLeft = keyframes`
    0% {
      left: -300px;
      opacity: 0;
    }

    100% {
      left: 0;
      opacity: 1;
    }
`

export const AnimationWrapper = styled.div`
  padding-left: 0.75rem;
  animation: ${fadeInLeft} 0.3s ease-in;
  margin-bottom: 6rem;
`
