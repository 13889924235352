type ScrollRef = HTMLElement | typeof window

const scrollBottom = (el: ScrollRef, behavior: ScrollBehavior) => {
  const scrollHeight = (el as HTMLElement)?.scrollHeight || globalThis.document.body.scrollHeight

  el.scrollTo({ left: 0, top: scrollHeight, behavior })
}

const scrollTop = (el: ScrollRef, behavior: ScrollBehavior) => {
  el.scrollTo({ left: 0, top: 0, behavior })
}

export const useScrollTo = (el: ScrollRef = window, behavior: ScrollBehavior = 'smooth') => {
  return {
    scrollTop: () => scrollTop(el, behavior),
    scrollBottom: () => scrollBottom(el, behavior)
  }
}
