import { type TmPlugin } from '../types'

export interface ValidationMethods {
  isValid: () => boolean
}

export const validationTMPlugin: TmPlugin<ValidationMethods> = instance => {
  const proto = instance.prototype

  proto.isValid = function () {
    return this['$dateEngine'].isValid()
  }
}
