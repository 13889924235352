import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/types'
import { withInjectedProps } from '~/domains/platform/infra/page-enhancers/compositional/with-injected-props'
import { Wizard } from '~/ui/components/modals/wizard'

import { Banner, BoletoDetails, BoletoIssuance, DepositInfo } from './partials'

export function Deposit(props: { isOpen: boolean; toggle: () => void; account: PaymentAccount }) {
  const { isOpen, toggle, account } = props
  const withAccount = withInjectedProps({ account })
  const withAccountId = withInjectedProps({ accountId: account.id })

  const steps = [
    {
      mainContent: withAccountId(BoletoIssuance),
      sideContent: withAccount(DepositInfo)
    },
    {
      mainContent: Banner,
      sideContent: withAccount(BoletoDetails)
    }
  ]

  return <Wizard isOpen={isOpen} growContent toggle={toggle} title="Depositar" steps={steps} />
}
