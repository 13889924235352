import { type ApprovalStatus } from '~/domains/platform/infra/deus-ex-machina/types'

import { type Assessment, type KYC } from './entities/KYC'

export function translateAssessmentStatus(assessment: Assessment): KYC {
  if (assessment.reason === 'risk_analysis' && assessment.status === 'requested') return 'blocked'
  else if (assessment.status === 'requested') return 'responsePending'
  return 'analysis'
}

export function translateMetadataStatus(owner: { approvalStatus?: ApprovalStatus }, assessment?: Assessment): KYC {
  if (assessment && assessment.isMandatory) return translateAssessmentStatus(assessment)

  const { approvalStatus } = owner

  if (approvalStatus === 'approved') return 'approved'
  if (approvalStatus === 'partially_blocked' || approvalStatus === 'limited') return 'responsePending'
  if (approvalStatus === 'rejected') return 'rejected'

  return 'analysis'
}
