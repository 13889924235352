import { Icon as IconChevronDown } from '@dlpco/ginga-stone'

import { Choose, If } from '~/domains/platform/lib/utilities-components'

import { type SelectionIcon } from '../interface'
import { SelectionText, SelectionWrapper } from '../styles'

interface SelectionProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: string
  icon?: SelectionIcon
  focused?: boolean
  disabled?: boolean
  error?: boolean
}

export const Selection = (props: SelectionProps) => {
  const { value, icon: Icon, focused, disabled, error, ...restProps } = props

  return (
    <SelectionWrapper focused={focused} error={error} disabled={disabled} {...restProps}>
      <If condition={Boolean(Icon)}>{Icon && <Icon />}</If>
      <Choose>
        <Choose.When condition={Boolean(value)}>
          <SelectionText>{value}</SelectionText>
        </Choose.When>
        <Choose.Otherwise>
          <SelectionText isPlaceholder>Selecione</SelectionText>
        </Choose.Otherwise>
      </Choose>
      <IconChevronDown use="chevron-down-outline" size="large" />
    </SelectionWrapper>
  )
}
