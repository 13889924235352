import { Cluster, Grid, Stack } from '@dlpco/fluid-layout'
import { Badge, Icon, Tooltip } from '@dlpco/ginga-stone'
import dayjs from 'dayjs'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { Field } from '~/domains/platform/design-system/field/field'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'
import { paymentCaptureTranslate, stringFormat } from '~/lib/helpers'
import { type Sale, type SaleOperation } from '~/lib/types'
import { Money } from '~/ui/components/fields/money'

import { statusBadgeMap } from '../status-badge'

import { OperationsTable, OperationsTableHeader } from './styles'

interface SaleInformationProps {
  sale: Sale
}

interface LifeCycleProps {
  category: SaleOperation['category']
  status: SaleOperation['status']
  installmentNumber: SaleOperation['installmentNumber']
  installmentsQty: Sale['installmentsQty']
}

function LifeCycle(props: LifeCycleProps) {
  const { category, status, installmentNumber, installmentsQty } = props
  const installment = `${installmentNumber}/${installmentsQty}`

  return (
    <>
      <Choose>
        <Choose.When condition={category.includes('Chargeback')}>
          <Icon use="action-arrow-refresh-outline" color="negative" size="large" />
        </Choose.When>
        <Choose.When condition={category.includes('Cancelamento')}>
          <Icon use="square-error-outline" color="negative" size="large" />
        </Choose.When>
        <Choose.When condition={category.includes('Estorno')}>
          <Icon use="action-arrow-refresh-outline" color="warning" size="large" />
        </Choose.When>
        <Choose.When condition={category.includes('Antecipação')}>
          <Icon use="round-clock-outline" color="info" size="large" />
        </Choose.When>
        <Choose.When condition={category.includes('Venda')}>
          <Choose>
            <Choose.When condition={status === 'A receber'}>
              <Icon use="round-clock-outline" color="warning" size="large" />
            </Choose.When>
            <Choose.Otherwise>
              <Icon use="check-round-solid" color="primary" size="large" />
            </Choose.Otherwise>
          </Choose>
        </Choose.When>
      </Choose>

      <Text fontWeight="heading">
        <Choose>
          <Choose.When condition={category === 'Cancelamento'}>Cancelamento</Choose.When>
          <Choose.When condition={category === 'Cancelamento Parcial'}>Cancelamento Parcial {installment}</Choose.When>
          <Choose.When condition={category === 'Cancelamento solicitado'}>
            Cancelamento Parcial {installment}
          </Choose.When>
          <Choose.When condition={category === 'Antecipação'}> Antecipada Parcela {installment} </Choose.When>
          <Choose.When condition={category === 'Estorno'}> Estorno </Choose.When>
          <Choose.When condition={category === 'Estorno Parcial'}> Estorno Parcial {installment} </Choose.When>
          <Choose.When condition={category === 'Chargeback'}>Chargeback</Choose.When>
          <Choose.When condition={category === 'Chargeback Parcial'}>Chargeback Parcial {installment}</Choose.When>
          <Choose.When condition={category === 'Venda'}>
            <Choose>
              <Choose.When condition={status === 'A receber'}>Aguardando</Choose.When>
              <Choose.Otherwise>
                {installmentsQty > 1 ? `Recebida Parcela ${installment}` : `Recebido`}
              </Choose.Otherwise>
            </Choose>
          </Choose.When>
        </Choose>
      </Text>
    </>
  )
}

export function SaleInformation(props: SaleInformationProps) {
  const { sale } = props
  const {
    grossAmount,
    netAmount,
    status,
    card,
    installmentsQty,
    presentationDate,
    serialNumber,
    captureMethod,
    operations,
    id
  } = sale

  const { brand, fundingAccount, lastDigits } = card
  return (
    <Stack space="1.5rem">
      <Grid gutter="2rem" min="30ch">
        <Money pretty renderProps={{ color: 'colors.darkGray' }} label="Valor Bruto" value={grossAmount} />
        <Field label="Valor liquido" value={stringFormat.currency(netAmount)} />
        <Field label="Status" value={status}>
          {value => (
            <Flex>
              <Badge color={statusBadgeMap[status]} aria-label={`badge-${statusBadgeMap[status]}`}>
                {value}
              </Badge>
            </Flex>
          )}
        </Field>
        <Box />
      </Grid>

      <Divider />
      <Grid gutter="1.5rem" min="30ch">
        <Field label="Feita em" value={dayjs(presentationDate).utc().format('DD/MM/YYYY [às] HH:mm')} />
        <Field label="Número do cartão" value={lastDigits} />
        <Field label="StoneID" renderProps={{ color: 'primary' }} value={id} />
        <Field label="Meio de captura" value={paymentCaptureTranslate[captureMethod]} />
      </Grid>

      <Grid gutter="1.5rem" min="30ch">
        <Field label="Bandeira" value={brand} />
        <Field label="Serial number" value={serialNumber || '-'} />
        <Field label="Tipo" value={`${fundingAccount} ${installmentsQty}x`} />
        <Box />
      </Grid>

      <Divider />

      <Text fontWeight="medium" fontSize="large" marginTop="2rem">
        Acompanhamento financeiro
      </Text>

      <OperationsTable>
        <OperationsTableHeader>
          <Text>Ciclo de vida</Text>
          <Text>Categoria</Text>
          <Text>Parcela</Text>
          <Text>Valor Bruto</Text>
          <Text>Valor Líquido</Text>
          <Text>Status Corrente</Text>
        </OperationsTableHeader>
        <For
          of={operations}
          render={(item, key) => (
            <Box key={item.id} data-testid={`operation-${key}`}>
              <Cluster align="center" space="1rem">
                <Box>
                  <LifeCycle
                    category={item.category}
                    installmentNumber={item.installmentNumber}
                    status={item.status}
                    installmentsQty={installmentsQty}
                  />

                  <If condition={item.category === 'Cancelamento'}>
                    <Box ml={2}>
                      <Tooltip
                        content={
                          <>
                            <b>{sale.status}</b> <br />
                            Confira o prazo padrão de estorno das vendas canceladas. <br />
                            <br />
                            <b>Débito: </b>até 7 dias úteis. <br />
                            <b>Crédito: </b>se a fatura do cartão usado estiver aberta no dia do cancelamento, o estorno{' '}
                            <br /> vai acontecer nesta fatura. Caso contrário, vai ser feito na próxima. <br />
                            <b>Cartão pré-pago: </b>variável. É possível consultar o prazo com o emissor do cartão.{' '}
                            <br />
                          </>
                        }
                      >
                        <Box style={{ cursor: 'pointer' }}>
                          <Icon use="round-help-outline" />
                        </Box>
                      </Tooltip>
                    </Box>
                  </If>
                </Box>
              </Cluster>
              <Text>{item.category}</Text>
              <Text>{item.installmentNumber}</Text>
              <Text color="mediumGray">{stringFormat.currency(item.grossAmount)}</Text>
              <Text>{stringFormat.currency(item.netAmount)}</Text>
              <Box>
                <Badge color={statusBadgeMap[item.status]} aria-label={`badge-${statusBadgeMap[item.status]}`}>
                  {item.status}
                </Badge>
                <Text color="mediumGray2">{dayjs(item.statusDate).utc().format('DD/MM/YYYY HH:mm')}</Text>
              </Box>
            </Box>
          )}
        />
      </OperationsTable>
    </Stack>
  )
}
