import groupBy from 'lodash.groupby'

import { useStoneBrandFees } from '~/domains/miscellaneous/ports/hooks/use-stone-brands'
import { type PurchaseStoneCodeFee } from '~/lib/types'

import { type Brand } from '../entities'

const sortByBrandId = (a: PurchaseStoneCodeFee, b: PurchaseStoneCodeFee) => (a.cardBrandId > b.cardBrandId ? 1 : -1)

export const useStoneBrandFeesSimulator = (stonecode?: number | string) => {
  const { brandFees, ...restResponse } = useStoneBrandFees(stonecode)

  function transformData(data?: PurchaseStoneCodeFee[]) {
    if (data) {
      const filteredData = data.filter(({ transactionProfileId }) => [1, 5].includes(transactionProfileId))

      const sortedByBrandId = filteredData.sort(sortByBrandId)

      const brands = sortedByBrandId.map<Brand>(({ cardBrandId, transactionProfileId }) => ({
        cardBrandId,
        transactionProfileId
      }))

      const groupedByCardBrandId = groupBy(brands, ({ cardBrandId }) => String(cardBrandId))

      return Object.values(groupedByCardBrandId)
    }
  }

  return { data: transformData(brandFees), ...restResponse }
}
