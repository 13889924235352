import styled from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

import { Box } from '~/domains/platform/design-system'

import { type AvatarProps } from './interface'

export const Circle = styled(Box)<Pick<AvatarProps, 'color' | 'backgroundColor' | 'fontSize' | 'size'>>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: ${prop('fontSize')};
  color: ${theme(`colors.${prop('color')}`)};
  font-weight: bolder;
  height: ${ifProp('size', prop('size'), '40px')};
  justify-content: center;
  width: ${ifProp('size', prop('size'), '40px')};
`

Circle.defaultProps = {
  color: 'white',
  backgroundColor: 'primary'
}
