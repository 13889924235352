import { type ComponentProps, type KeyboardEvent, type ReactNode } from 'react'
import FocusLock from 'react-focus-lock'
import { useKey, useToggle } from 'react-use'
import NextLink from 'next/link'
import { Center, Cluster } from '@dlpco/fluid-layout'
import { Button, Icon as IconChevronDown, Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { Box, Flex, Link, Text as TextStyleSystem } from '~/domains/platform/design-system'
import { Avatar } from '~/domains/platform/design-system/avatar/avatar'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { type Subject } from '~/domains/platform/infra/deus-ex-machina/ports'
import { For, If } from '~/domains/platform/lib/utilities-components'
import { useClickAway } from '~/ui/hooks/utils/ui/use-click-away'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { Icon, MenuDropdown, MenuItem, MenuWrapper } from './styles'

interface MenuItem {
  label: string | ReactNode
  icon?: ReactNode
  href: ComponentProps<typeof NextLink>['href']
  as?: string
  onClick?: () => void
  show?: boolean
}

export interface MenuProps {
  items: MenuItem[]
  subject: Subject
}

export const WrapperUserInfo = styled.div<{ minimal: boolean }>`
  display: ${ifProp('minimal', 'none', 'block')};
`

function isKeyEnterOrSpace<Element = HTMLElement>(event: KeyboardEvent<Element>) {
  return event.key === 'Enter' || event.key === ' '
}

export function Menu(props: MenuProps) {
  const { items } = props
  const [isOpen, toggleIsOpen] = useToggle(false)

  const {
    subject: { displayName, email }
  } = props

  const { isViewMedium } = useViewSize()

  const close = () => toggleIsOpen && toggleIsOpen(false)

  useKey('Escape', close)

  const ref = useClickAway(() => {
    toggleIsOpen(false)
  })

  function handleWrapperKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    const isPressedKeyEnterOrSpace = isKeyEnterOrSpace(event)
    if (isPressedKeyEnterOrSpace) toggleIsOpen()
  }

  function handleWrapperClick() {
    toggleIsOpen(!isOpen)
  }

  return (
    <FocusLock disabled={!isOpen}>
      <MenuWrapper
        contentSide="left"
        showOverflow
        onKeyDown={handleWrapperKeyDown}
        onClick={handleWrapperClick}
        ref={ref}
      >
        <Box>
          <Box mr={2}>
            <Cluster noWrap space="0.5rem" align="center">
              <Flex>
                <WrapperUserInfo minimal={isViewMedium}>
                  <Box>
                    <TextStyleSystem
                      data-cy="name-profile"
                      textAlign="right"
                      sx={{ whiteSpace: 'nowrap' }}
                      fontWeight="600"
                    >
                      {displayName}
                    </TextStyleSystem>
                    <TextStyleSystem data-cy="email-profile" fontSize="small" color="mediumGray2" textAlign="right">
                      {email}
                    </TextStyleSystem>
                  </Box>
                </WrapperUserInfo>

                <Avatar data-cy="avatar-profile" name={displayName} />
              </Flex>
            </Cluster>
          </Box>
          <Center>
            <Icon turn={isOpen}>
              <IconChevronDown use="chevron-down-outline" color="neutral" size="large" />
            </Icon>
          </Center>
          <If condition={isOpen}>
            <MenuDropdown>
              <For of={items} render={RenderMenuItem} />
              <Flex alignItems="center" justifyContent="center" p="0.5rem">
                <Button
                  as="a"
                  href="https://www.stone.com.br/seguranca/"
                  color="neutral"
                  target="_blank"
                  size="small"
                  variant="contentOnly"
                >
                  Política de Privacidade
                </Button>
                <Button
                  as="a"
                  href="https://contratos.stone.com.br/docs/conta-stone-1"
                  color="neutral"
                  target="_blank"
                  size="small"
                  variant="contentOnly"
                >
                  Termos de Serviço
                </Button>
              </Flex>
            </MenuDropdown>
          </If>
        </Box>
      </MenuWrapper>
    </FocusLock>
  )
}

function RenderMenuItem({ href, label, icon, onClick, show = true }: MenuItem, index: number) {
  function handleItemKeyDown(event: KeyboardEvent<HTMLAnchorElement>) {
    event.stopPropagation()

    const isPressedKeyEnterOrSpace = isKeyEnterOrSpace(event)

    if (isPressedKeyEnterOrSpace && onClick) onClick()
  }

  return (
    <If condition={show}>
      <NextLink href={href} passHref legacyBehavior>
        <Link key={index} onKeyDown={handleItemKeyDown} onClick={onClick}>
          <MenuItem>
            <Cluster space="1rem">
              <Flex alignItems="center">
                <If condition={Boolean(icon)}>{icon}</If>
                <Text weight="bold">{label}</Text>
              </Flex>
            </Cluster>
          </MenuItem>
          <Divider />
        </Link>
      </NextLink>
    </If>
  )
}
