import { type MerchantsProps } from '~/domains/acquirer/shared/entities/merchants'
import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { recursiveClient } from '~/domains/platform/infra/http/recursive-client'
import { httpClientCustomer } from '~/lib/services/acquirer/http-client-customer/http-client-customer'
import { type RawBFF } from '~/lib/types/pandas/BFF'
import { type Resource } from '~/lib/types/pandas/Resource'

import { splitEvery } from '../../../lib/array-helpers/split-every'
import { bffDecorator } from '../helpers/decorator'

export async function getBFF() {
  const { data: homeScreen } = await getHomeScreen()
  const { data: resources } = await getResources()
  const { data: stoneCodes } = await getStoneCodes()

  return bffDecorator(homeScreen, stoneCodes, resources?.data)

  async function getResources() {
    return httpClientBanking<{ data: Resource[] }>({
      url: '/resources',
      params: { resource_type: 'srn:resource:paymentaccount', limit: 100 }
    })
  }

  async function getHomeScreen() {
    const bff = await httpClientBanking<RawBFF>({ url: '/screens/home/web' })
    if (bff?.data.organizations.cursor?.after) {
      const { data: completeOrganizationsList } = await recursiveClient(
        { url: '/organizations' },
        bff.data.organizations.data,
        { after: bff.data.organizations.cursor.after }
      )
      return {
        ...bff,
        data: {
          ...bff.data,
          organizations: {
            ...bff.data.organizations,
            data: completeOrganizationsList
          }
        }
      }
    }
    return bff
  }

  async function getStoneCodes() {
    const data = await Promise.all(
      [...(homeScreen.users?.data ?? []), homeScreen.user].map(async function getUsersStoneCodes({ email }) {
        try {
          const {
            data: { data: portalUser }
          } = await httpClientCustomer<{ data: { associatedStoneCodeCollection: string[] } }>({
            url: `/portal/users/${encodeURIComponent(email)}`
          })
          const responseGroupByChunks = await getStoneCodesPerChunks({
            stoneCodes: portalUser.associatedStoneCodeCollection
          })
          return responseGroupByChunks
        } catch (error) {
          return []
        }
      })
    ).then(x => x.flat())

    return { data }

    async function getStoneCodesPerChunks({ stoneCodes }: { stoneCodes: string[] }) {
      return Promise.all(
        splitEvery(90, stoneCodes).map(async (chunk: string[]) => {
          return httpClientCustomer<MerchantsProps>({ url: `/merchants/summary?affiliationCodes=${chunk}` }).then(
            response => response.data.data
          )
        })
      ).then(data => data.flat())
    }
  }
}
