//transaction status
export const SCHEDULED_STATUS = 'SCHEDULED'
export const FINISHED_STATUS = 'FINISHED'
export const FAILED_STATUS = 'FAILED'
export const CANCELED_STATUS = 'CANCELED'
export const IN_PROGRESS_STATUS = 'IN_PROGRESS'
export const EXPIRED_STATUS = 'EXPIRED'

//translate transaction status
export const SCHEDULED = 'Agendado'
export const FINISHED = 'Concluído'
export const FAILED = 'Falha'
export const CANCELED = 'Cancelado'
export const IN_PROGRESS = 'Processando'
export const EXPIRED = 'Expirado'

export type BadgeType = 'positive' | 'negative' | 'info' | 'warning' | 'help'

export type Status = 'SCHEDULED' | 'FINISHED' | 'FAILED' | 'CANCELED' | 'IN_PROGRESS' | 'EXPIRED'

export type TranslatedStatus = 'Agendado' | 'Concluído' | 'Falha' | 'Cancelado' | 'Processando' | 'Expirado'

export interface BadgeCompose {
  status: TranslatedStatus
  badgeType: BadgeType
}

const BADGE_TRANSLATED_MAP: Record<Status, BadgeCompose> = {
  SCHEDULED: { status: 'Agendado', badgeType: 'help' },
  FINISHED: { status: 'Concluído', badgeType: 'positive' },
  FAILED: { status: 'Falha', badgeType: 'negative' },
  CANCELED: { status: 'Cancelado', badgeType: 'negative' },
  IN_PROGRESS: { status: 'Processando', badgeType: 'info' },
  EXPIRED: { status: 'Expirado', badgeType: 'warning' }
}

export const badgeCompose = new Proxy(BADGE_TRANSLATED_MAP, {
  get(target: typeof BADGE_TRANSLATED_MAP, key: keyof typeof BADGE_TRANSLATED_MAP) {
    return key in target ? target[key] : { status: 'Falha', badgeType: 'negative' }
  }
})
