import styled from 'styled-components'
import { theme } from 'styled-tools'

export const AccountDescription = styled.dl`
  margin-top: 0.75rem;
  margin-bottom: -0.75rem;

  dt {
    margin-top: 0.75rem;
    font-size: ${theme('fontSizes.normal')};
    color: ${theme('colors.mediumGray2')};
  }

  dd {
    margin-bottom: 0.75rem;
    font-size: ${theme('fontSizes.medium')};
    font-weight: ${theme('fontWeights.medium')};
    color: ${theme('colors.darkGray')};
  }
`

export const Spacer = styled.div`
  margin-top: 0.5rem;
`

export const WithScrollableContent = styled.div`
  > * > * {
    overflow-y: initial;
  }

  > * > * > * > * {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  }
`

export const BankDetails = styled.div`
  overflow: auto;
  flex-grow: 1;

  > :last-child {
    margin-bottom: 0;
  }
`
