import { type NextPage } from 'next'
import { useRouter } from 'next/router'

import { Loader } from '~/domains/platform/design-system/loader/loader'
import { CleanPageContainer } from '~/ui/containers/clean-page-container'

import { type WithDeusExMachinaProps } from '../deus-ex-machina/types'

export function withIdAssessment(Page: NextPage<WithDeusExMachinaProps>) {
  function WrappedPage({ subject, ...rest }: WithDeusExMachinaProps) {
    const { kycRequest: userKycRequest } = subject
    const { push } = useRouter()
    const idAssessment = localStorage.getItem('idAssessment')

    const hasDocumentCaptureRequest =
      userKycRequest?.checks?.some(check => check.reason === 'document_capture' && check.status === 'requested') &&
      userKycRequest?.isMandatory &&
      userKycRequest?.reason === 'document_capture' &&
      userKycRequest?.status === 'requested'

    if (hasDocumentCaptureRequest && idAssessment !== 'success') {
      push('/em-analise/cpf')

      return (
        <CleanPageContainer>
          <Loader />
        </CleanPageContainer>
      )
    }

    return <Page subject={subject} {...rest} />
  }

  WrappedPage.displayName = `withIdAssesment(${Page.displayName})`

  return WrappedPage
}
