import { useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Badge, Button, Icon, Text } from '@dlpco/ginga-stone'
import dayjs from 'dayjs'

import { Box, Flex } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { Field } from '~/domains/platform/design-system/field/field'
import { For } from '~/domains/platform/lib/utilities-components'
import { type Integrations } from '~/lib/types'
import { SideModal } from '~/ui/components/modals/side-modal'
import { type SideModalProps } from '~/ui/components/modals/side-modal/interface'
import { theme } from '~/ui/theme'

import { IntegrationsChallenge } from '../challenge'
import { statusBadgeMap, statusTranslateMap } from '../status-map'
import { BadgeWrapper, ContentWrapper } from '../styles'

interface IntegrationsDetailProps extends SideModalProps {
  isOpen: boolean
  integration: Integrations
  institutionName?: string
  toggle?: () => void
}

export const translateScope: Record<string, string> = {
  'expend:read ': 'Busca as operações pendentes em um determinado recurso',
  'expend:transfers:external': 'Aprova pedidos de transferência para contas internas',
  'expend:transfers:internal': 'Aprova pedidos de transferência para contas outras contas Stone',
  'expend:payments': 'Aprova pedidos de pagamentos com código de barras',
  'expend:boletoissuance': 'Aprova pedidos de emissão de boleto',
  'expend:instantpayment': 'Aprova pedidos de pagamentos instantâneos',
  'expend:paymentlinks': 'Aprova pedidos de links de pagamentos',
  'expend:pix_payment': 'Aprova pedidos de pagamentos PIX',
  'paymentaccount:transfers:external': 'Cria pedidos de transferência para contas externas',
  'paymentaccount:transfers:internal': 'Cria pedidos de transferência para outras contas Stone ',
  'paymentaccount:payrolls:write': 'Cria pedidos de transferência para folhas de pagamento',
  'paymentaccount:payments': 'Cria pedidos de pagamentos com código de barras',
  'paymentaccount:instantpayment': 'Cria pedidos de pagamentos instantâneos',
  'paymentaccount:paymentlinks:read': 'Visualiza links de pagamentos',
  'paymentaccount:paymentlinks:write': 'Cria links de pagamentos',
  'paymentaccount:boletoissuance': 'Cria pedidos de emissão de boleto',
  'paymentaccount:payrolls:read': 'Visualiza informações das folhas de pagamento',
  'paymentaccount:read': 'Visualiza saldo, extratos e comprovantes de uma conta de pagamento',
  'paymentaccount:contact:read': 'Busca de contatos associados à conta de pagamento',
  'paymentaccount:contact:write': 'Cria, atualiza ou remove contatos associados à conta de pagamento',
  'paymentaccount:fees:read ': 'Busca as taxas associadas à conta',
  'paymentaccount:settlement_policies:reject_from_source:read':
    'Visualiza políticas de liquidação do tipo "Rejeitar da Fonte" na conta de pagamento',
  'paymentaccount:settlement_policies:reject_from_source:write':
    'Cria ou Modifica políticas de liquidação do tipo "Rejeitar da Fonte" na conta de pagamento',
  'paymentaccount:investment': 'Criação de conta de investimento',
  'paymentaccount:loans ': 'Cria propostas de empréstimo',
  'investment:read': 'Lê dados de uma conta de investimento',
  'investment:write': 'Escrita sobre uma conta de investimento',
  'investment:space:read': 'Leitura de espaços de uma conta de investimento',
  'investment:space:write': 'Escrita de espaços de uma conta de investimento',
  'investment:space:delete': 'Deleção de espaços de uma conta de investimento',
  'investment:space:deposit': 'Depósito sobre espaços de conta de investimento',
  'investment:space:withdrawal': 'Saque sobre espaços de conta de investimento',
  'pix:entry': 'Cria uma chave do PIX',
  'pix:entry_claim': 'Cria pedidos de portabilidade de chaves do PIX',
  'pix:payment_invoice': 'Cria pedidos de pagamentos PIX',
  'pix:payment': 'Cria pagamentos PIX',
  'card:read': 'Visualiza dados do cartão',
  'card:write': 'Atualiza informações e o status do cartão',
  'virtualcard:read': 'Visualiza dados do cartão virtual',
  'virtualcard:write': 'Atualiza informações e o status do cartão virtual',
  'salary:portability': 'Cria pedidos de portabilidade de salário para a conta de pagamento',
  'signup:paymentaccount': 'Cria/consulta pedido de cadastro de conta de pagamento',
  'receivable:all': 'Consultar/adiantar recebíveis',
  'entity:write': 'Atualiza informações do perfil',
  'entity:read': 'Visualiza informações do perfil',
  'entity:legal_write': 'Atualiza informações legais do perfil como documentos',
  'entity:loan:accept': 'Aceita uma proposta de empréstimo',
  'entity:loan:create': 'Cria uma proposta de empréstimo',
  'principal:consent': 'Permite conceder acesso a um usuário ou aplicação'
}

export const IntegrationsDetail = (props: IntegrationsDetailProps) => {
  const { isOpen, integration, institutionName, toggle } = props
  const [isChallengeOpen, toggleIsChallengeOpen] = useToggle(false)
  const resourceTypeTranslate =
    integration.resourceType === 'srn:resource:receivable' ? 'Recebiveis' : 'Integração com a conta'
  const scopes = integration.scope.split(' ')
  const { subjectId, resourceId, resourceType } = integration

  return (
    <>
      <SideModal title="Detalhes da integração" isOpen={isOpen} toggle={toggle}>
        <Stack space="1.5rem">
          <ContentWrapper gridTemplateColumns="2fr 1fr">
            <Field
              fontSize="small"
              label="Tipo"
              value={resourceTypeTranslate}
              renderProps={{ fontSize: theme.fontSizes.medium, fontWeight: 'bold' }}
            />
            <BadgeWrapper>
              <Badge color={statusBadgeMap[integration.status].color}>{statusTranslateMap[integration.status]}</Badge>
            </BadgeWrapper>
          </ContentWrapper>
          <Divider />
          <Field
            fontSize="small"
            label="Adicionada em"
            value={dayjs(integration.createdAt).format('D MMMM, YYYY')}
            renderProps={{ fontSize: theme.fontSizes.medium, fontWeight: 'bold' }}
          />
          <Divider />
          <Field
            fontSize="small"
            label="Adquirente"
            value={institutionName}
            renderProps={{ fontSize: theme.fontSizes.medium, fontWeight: 'bold' }}
          />
          <Divider />
          <ContentWrapper gridTemplateColumns="22px auto" gap="1.5rem" alignItems="flex-start">
            <Icon use="round-clock-outline" color="neutral" size="large" />

            <Box mb="2rem">
              <Text weight="bold" size="large">
                Permissões solicitadas:
              </Text>
              <For
                of={scopes}
                render={(scope, index) => (
                  <Flex mt="1.5rem" key={index}>
                    <Text key={index}>{translateScope[scope]}</Text>
                  </Flex>
                )}
              />
            </Box>
          </ContentWrapper>
          <Button color="negative" variant="contentOnly" size="large" onClick={toggleIsChallengeOpen}>
            Cancelar pedido
          </Button>
        </Stack>
      </SideModal>
      <IntegrationsChallenge
        subjectId={subjectId}
        resourceId={resourceId}
        resourceType={resourceType}
        isChallengeOpen={isChallengeOpen}
        onToggle={toggleIsChallengeOpen}
      />
    </>
  )
}
