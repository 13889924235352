import { type ReactNode, createContext, useContext, useMemo } from 'react'

import {
  type PaginatedFetcher,
  type UseInfiniteRequestResponse,
  useInfiniteRequest
} from '~/ui/hooks/utils/service/use-infinite-request'

interface InfiniteListProviderProps {
  children: ReactNode
  url: string
  stateFactory?<Data, Error>(props: stateFactoryProps): UseInfiniteRequestResponse<Data, Error>
}

export type useInfiniteListResponse<Data, Error> = UseInfiniteRequestResponse<Data, Error>

interface stateFactoryProps {
  url: string
  paginatedFetcherHook: PaginatedFetcher
}

function DefaultStateFactory<Data, Error>({
  url,
  paginatedFetcherHook
}: stateFactoryProps): useInfiniteListResponse<Data, Error> {
  const requestConfig = useMemo(() => ({ url }), [url])
  const swrConfig = useMemo(() => ({}), [])
  const state = useInfiniteRequest<Data, Error>(requestConfig, swrConfig, paginatedFetcherHook)
  return state
}

export function infiniteListContextFactory<Data, Error>(paginatedFetcherHook: PaginatedFetcher) {
  // we cast the context initial values here so we don't need to mock hook's response
  const initialValues = {} as UseInfiniteRequestResponse<Data, Error>

  const InfiniteListContext = createContext(initialValues)

  function InfiniteListProvider({ children, url, stateFactory = DefaultStateFactory }: InfiniteListProviderProps) {
    const state = stateFactory<Data, Error>({ url, paginatedFetcherHook })
    return <InfiniteListContext.Provider value={state}>{children}</InfiniteListContext.Provider>
  }

  function useInfiniteList() {
    return useContext(InfiniteListContext)
  }

  return { InfiniteListContext, InfiniteListProvider, useInfiniteList }
}
