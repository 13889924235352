import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type InvestmentBalance } from '../../fixed-income/shared/entities'

type InvestmentBalanceApiResponse = {
  balance: InvestmentBalance
  defaultBucketId: string
  hasInvestedBefore: boolean
  bucketCount: number
}

export async function fetchInvestment({ accountId }: { accountId: string }) {
  const { data } = await httpClientBanking<InvestmentBalanceApiResponse>({ url: `/investments/accounts/${accountId}` })
  return data
}

export function useInvestmentBalance({
  accountId,
  config,
  fetcher = fetchInvestment
}: {
  accountId: string
  config?: Omit<UseQueryRequestOptions<InvestmentBalanceApiResponse, unknown, true>, 'refetchInterval'>
  fetcher?: typeof fetchInvestment
}) {
  const { data, ...rest } = useQueryRequest<InvestmentBalanceApiResponse, unknown, true>(
    ['useInvestmentBalance', accountId],
    () => fetcher({ accountId }),
    {
      enabled: Boolean(accountId),
      refetchInterval: 60 * 1000 * 2, // two minutes,
      refetchIntervalInBackground: true,
      ...config
    }
  )

  return {
    data: {
      bucketCount: data?.bucketCount ?? 0,
      hasInvestedBefore: Boolean(data?.hasInvestedBefore),
      defaultBucketId: data?.defaultBucketId,
      hasInvestment: Boolean(data?.balance?.netAmount),
      amount: data?.balance?.netAmount
    },
    ...rest
  }
}
