import { type InstalledPlugins } from '../index'
import { type TMInstanceWithPlugins, type TmPlugin } from '../types'

export interface BusinessDayTmMethods {
  isBusinessDay(day: number): boolean
  businessDaysAdd(days: number): TMInstanceWithPlugins<InstalledPlugins>
  businessDaysSubtract(days: number): TMInstanceWithPlugins<InstalledPlugins>
  dayOfWeek(): number
}

export const businessDayTMPlugin: TmPlugin<BusinessDayTmMethods> = instance => {
  const proto = instance.prototype

  proto.isBusinessDay = function (day) {
    const workingWeekdays = [1, 2, 3, 4, 5]

    return workingWeekdays.includes(day)
  }

  proto.dayOfWeek = function () {
    return this['$dateEngine'].day()
  }

  proto.businessDaysAdd = function (days: number) {
    const numericDirection = days < 0 ? -1 : 1
    let daysRemaining = Math.abs(days)
    let currentDay = this.clone()

    while (daysRemaining > 0) {
      currentDay = currentDay.add(numericDirection, 'd')
      if (this.isBusinessDay((currentDay as unknown as BusinessDayTmMethods).dayOfWeek())) daysRemaining -= 1
    }

    return currentDay as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.businessDaysSubtract = function (days: number) {
    return this.businessDaysAdd(days * -1)
  }
}
