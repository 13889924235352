import { analytics } from '~/domains/platform/lib/analytics'

export async function homeMenu() {
  return [
    {
      order: 3,
      label: 'Início',
      icon: 'home-outline',
      onClick: () => {
        analytics.sendEvent('home web - lateral menu button chosen', { lateralmenu: 'inicio' })
      },
      path: '/home',
      active: (route: string) => ['/home'].includes(route)
    }
  ]
}
