import { Grid } from '@dlpco/fluid-layout'
import styled from 'styled-components'

export const WrapperGrid = styled(Grid)`
  margin-top: 1.8rem;
`
export const WrapperButton = styled.div`
  max-width: 320px;
  display: grid;
`
