import { type CustomAxiosRequestConfig } from '~/domains/platform/infra/http/http-client-factory'
import { type Cursor } from '~/lib/types'

import { paginatedClient } from './paginated-client'

interface Response<T> {
  data: T[]
}

export const recursiveClient = async <T>(
  config: CustomAxiosRequestConfig,
  initialData: T[] = [],
  cursor: Cursor = { limit: 500 },
  client = paginatedClient
): Promise<Response<T>> => {
  const response = await client<T[]>(config, cursor)

  const {
    data: { data, cursor: nextCursor }
  } = response

  const buffer = [...initialData, ...data]

  if (nextCursor.after) return recursiveClient(config, buffer, { ...cursor, after: nextCursor.after }, client)

  return { data: buffer }
}
