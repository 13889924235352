import { Box, Text } from '~/domains/platform/design-system'

import { List } from './styles'

export function Info() {
  return (
    <Box padding="2rem">
      <Text fontSize="large" mb="1.25rem" ml="-1rem">
        Das vendas à gestão, uma única solução
      </Text>
      <List>
        <li>Novas opções de venda</li>
        <Text mb="2rem">Além da Maquininha, venda com Pix, Link de Pagamento e boleto.</Text>
        <li>Gestão completa e integrada</li>
        <Text mb="2rem">
          A gestão financeira da sua empresa fica ainda mais simples. Além de todas as funcionalidades do app, o Cartão
          Stone também facilita sua rotina.
        </Text>
        <li>Empréstimos para o seu negócio crescer</li>
        <Text>Receba ofertas de empréstimos flexíveis para pagar e que chegam quando você mais precisa.</Text>
      </List>
    </Box>
  )
}
