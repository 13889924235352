import { type PropsWithChildren } from 'react'
import dynamic from 'next/dynamic'
import { Cluster } from '@dlpco/fluid-layout'
import { type OwnPropsOf, Badge, Icon, Text, Tooltip } from '@dlpco/ginga-stone'
import dayjs from 'dayjs'

import { Box, Flex } from '~/domains/platform/design-system'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { paymentCaptureIconList, stringFormat } from '~/lib/helpers'
import { type Sale } from '~/lib/types'
import { MainContent } from '~/ui/components/layouts/main-content'
import { List } from '~/ui/components/utils/list'

import { type RenderItemProps, type SalesListProps } from './interface'
import { ItemGrid, ListCard, ListHeader, WrapperDate, WrapperText, WrapperTextIcon } from './styles'

export function SalesListItem(props: RenderItemProps) {
  const { onClick, sale, brands } = props
  const { currency } = stringFormat
  const { captureMethod } = sale

  const brandCode =
    brands.find(item => {
      return item?.brandName === sale.card.brand
    })?.brandId || 0

  const statusColors = (
    statusText: string
  ): { badgeColor: OwnPropsOf<typeof Badge>['color']; statusValueColor: string } => {
    if (statusText === 'Capturada') {
      return {
        badgeColor: 'positive',
        statusValueColor: 'stoneGreen'
      }
    }

    return {
      badgeColor: 'negative',
      statusValueColor: 'mediumGray'
    }
  }

  const BrandIcon = dynamic<any>(() => import('@dlpco/brand').then(mod => mod.BrandIcon) as any)

  const paymentMethod = sale.card.fundingAccount + ' ' + sale.installmentsQty + 'x'

  return (
    <ItemGrid data-testid="sales-list-item" onClick={() => onClick && onClick(sale)}>
      <Box>
        <WrapperDate>{dayjs(sale.statusDate).utc().format('DD/MM/YYYY HH:mm')}</WrapperDate>
      </Box>

      <Flex alignItems="center" gap="0.5rem">
        <Badge
          color={statusColors(sale.status).badgeColor}
          aria-label={`badge-${statusColors(sale.status).badgeColor}`}
        >
          {sale.status}
        </Badge>
        <If condition={sale.status === 'Cancelada'}>
          <Tooltip
            content={
              <>
                <b>{sale.status}</b> <br />
                Confira o prazo padrão de estorno das vendas canceladas. <br />
                <br />
                <b>Débito: </b>até 7 dias úteis. <br />
                <b>Crédito: </b>se a fatura do cartão usado estiver aberta no dia do cancelamento, o estorno <br /> vai
                acontecer nesta fatura. Caso contrário, vai ser feito na próxima. <br />
                <b>Cartão pré-pago: </b>variável. É possível consultar o prazo com o emissor do cartão. <br />
              </>
            }
          >
            <Box style={{ cursor: 'pointer' }}>
              <Icon use="round-help-outline" />
            </Box>
          </Tooltip>
        </If>
      </Flex>

      <Box>
        <WrapperTextIcon>
          {paymentCaptureIconList[captureMethod]}
          <WrapperText>{paymentMethod}</WrapperText>
          <Choose>
            <Choose.When condition={Boolean(brandCode)}>
              <BrandIcon brandId={brandCode} />
            </Choose.When>
            <Choose.Otherwise>
              <WrapperText>{sale.card.brand}</WrapperText>
            </Choose.Otherwise>
          </Choose>
        </WrapperTextIcon>
      </Box>
      <Box>
        <WrapperText>{sale.id}</WrapperText>
      </Box>
      <Box>
        <WrapperText>
          <Choose>
            <Choose.When condition={!sale.serialNumber}>-</Choose.When>
            <Choose.Otherwise>{sale.serialNumber}</Choose.Otherwise>
          </Choose>
        </WrapperText>
      </Box>
      <Cluster space="0.5rem" align="center" justify="flex-end">
        <Box>
          <WrapperText textAlign="right" color={statusColors(sale.status).statusValueColor}>
            {currency(sale.grossAmount)}
          </WrapperText>

          <Icon use="chevron-right-outline" />
        </Box>
      </Cluster>
    </ItemGrid>
  )
}

export function SalesList(props: PropsWithChildren<SalesListProps>) {
  const { sales, onItemClick, brands } = props

  return (
    <MainContent>
      <ListCard>
        <ListHeader>
          <Text weight="bold">Data</Text>
          <Text weight="bold">Status</Text>
          <Text weight="bold">Forma de pagamento</Text>
          <Tooltip
            content={
              <>
                <b>Stone ID: </b>
                Identificador único de venda feita na Stone. <br /> Nas vendas pagas pela maquininha, ele aparece no
                comprovante <br /> impresso após o pagamento.
              </>
            }
            placement="top"
            alignment="start"
          >
            <Flex alignItems="center" gap="0.5rem">
              <Text weight="bold">Stone ID</Text>
              <Icon use="round-help-outline" />
            </Flex>
          </Tooltip>
          <Tooltip
            content={
              <>
                <b>Número de Série: </b>
                Número que identifica sua maquininha <br /> e fica embaixo do código de barras na parte de trás.
              </>
            }
            placement="top"
            alignment="start"
          >
            <Flex alignItems="center" gap="0.5rem">
              <Text weight="bold" style={{ maxWidth: '60px' }}>
                Número de Série
              </Text>
              <Icon use="round-help-outline" />
            </Flex>
          </Tooltip>
          <Text weight="bold">Valor da Venda</Text>
        </ListHeader>
        <List<Sale>
          items={sales}
          render={sale => <SalesListItem sale={sale} onClick={onItemClick} brands={brands} key={sale.id} />}
        />
      </ListCard>
    </MainContent>
  )
}
