import { Grid } from '@dlpco/fluid-layout'
import { Card, CardBody, Text } from '@dlpco/ginga-stone'

import { Choose } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import { type SalesResumeItemProps, type SalesResumeProps } from './interface'
import { WrapperValue } from './styles'

function SalesResumeItem(props: SalesResumeItemProps) {
  const { currency } = stringFormat
  const {
    title,
    value,
    isCurrency = false,
    cardColor = 'surface',
    valueColor = 'white',
    titleColor = 'neutral'
  } = props

  return (
    <Card color={cardColor}>
      <CardBody>
        <Text color={titleColor}>{title}</Text>
        <WrapperValue color={valueColor}>
          <Choose>
            <Choose.When condition={isCurrency}>{currency(value)}</Choose.When>
            <Choose.Otherwise>{value}</Choose.Otherwise>
          </Choose>
        </WrapperValue>
      </CardBody>
    </Card>
  )
}

export function SalesResume(props: SalesResumeProps) {
  const cards: SalesResumeItemProps[] = [
    {
      title: 'Total Vendido',
      value: props.grossAmount,
      titleColor: 'onColorHigh',
      isCurrency: true,
      cardColor: 'surfacePrimary'
    },
    {
      title: 'Vendas Capturadas',
      value: props.transactions,
      valueColor: 'stoneGreen'
    },
    {
      title: 'Vendas Canceladas',
      value: props.canceledTransactions,
      valueColor: 'red'
    }
  ]

  return (
    <Grid min="20ch" gutter="2rem">
      {cards.map((item, index) => (
        <SalesResumeItem
          key={index}
          title={item.title}
          value={item.value}
          valueColor={item.valueColor}
          titleColor={item.titleColor}
          isCurrency={item.isCurrency}
          cardColor={item.cardColor}
        />
      ))}
    </Grid>
  )
}
