import { useRouter } from 'next/router'
import { Stack } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'

import { Box, Text } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { type Consent, type Scope } from '~/lib/types'
import { useWizardProps } from '~/ui/components/modals/wizard/use-wizard-props'
import { List } from '~/ui/components/utils/list/list'

import { ConsentedScopes } from '../consented-scopes'
import { Li, Ul } from '../styles'

import { WrapperButton, WrapperGrid } from './styles'

export const AccessAccountItem = (props: { consentableScopes?: Scope[]; consentedScopes?: Scope[] }) => {
  const { consentableScopes, consentedScopes } = props

  return (
    <Stack space="1rem">
      <Choose>
        <Choose.When condition={Boolean(consentableScopes)}>
          <Text color="darkGray2" fontSize="medium" fontWeight="bold">
            Ao dar o acesso, você permite que o aplicativo:
          </Text>
          <Divider />
          <Ul>
            <Stack space="1rem">
              <List
                items={consentableScopes as Scope[]}
                render={(scope, index) => <Li key={index}>{scope.description}</Li>}
              />
            </Stack>
          </Ul>
        </Choose.When>
        <Choose.Otherwise>
          <ConsentedScopes consentedScopes={consentedScopes} />
        </Choose.Otherwise>
      </Choose>
    </Stack>
  )
}

export function ConsentAccessAccount(props: {
  baseRedirectUrl: string
  redirectUrl?: string
  name?: string
  onwerConsent?: Consent
  onGiveAccess: () => void
}) {
  const {
    name,
    baseRedirectUrl,
    redirectUrl = baseRedirectUrl + `&consent_result=ignored`,
    onwerConsent,
    onGiveAccess
  } = props
  const { push } = useRouter()
  const { content } = useWizardProps()

  return (
    <Box>
      <Stack space="2rem">
        <Text fontSize="xLarge" fontWeight="medium">
          {name} deseja ter acesso à sua conta Stone
        </Text>
        <If condition={Boolean(content?.consent?.consentableScopes ?? onwerConsent?.consentableScopes)}>
          <AccessAccountItem
            consentableScopes={content?.consent?.consentableScopes ?? onwerConsent?.consentableScopes}
          />
        </If>
        <WrapperGrid gutter="1.5rem" min="20ch">
          <WrapperButton>
            <Button color="neutral" size="large" onClick={() => push(redirectUrl)}>
              Agora não
            </Button>
          </WrapperButton>
          <WrapperButton>
            <Button size="large" onClick={onGiveAccess}>
              Dar acesso
            </Button>
          </WrapperButton>
        </WrapperGrid>
      </Stack>
    </Box>
  )
}
