import { type AxiosError } from 'axios'

import { logger } from '~/domains/platform/infra/monitoring/logger'
import { splitEvery } from '~/domains/platform/lib/array-helpers/split-every'
import { CUSTOMER_API_ENDPOINT } from '~/lib/constants'
import { dynamicTemplate } from '~/lib/helpers/utils/dynamic-template'
import { httpClientCustomer } from '~/lib/services/acquirer/http-client-customer/http-client-customer'
import { type MerchantsContentProps, type MerchantsProps, type PurchaseStoneCode } from '~/lib/types'

export function stoneCodesResponseDecorator(usersStoneCodes: MerchantsContentProps[]): PurchaseStoneCode[] {
  return usersStoneCodes.map(
    ({ affiliationCode, affiliationKey, companyName, document, mccId, mccDescription, fantasyName }) => ({
      object: '',
      stoneCode: affiliationCode,
      legalName: companyName,
      mcc: mccDescription,
      mccNumber: mccId,
      cnpj: document,
      fantasyName,
      affiliationKey
    })
  )
}

async function getStoneCodesByAffiliationCode(affiliationCodes: string[]) {
  try {
    /**
     * @disclaimer
     * This Api below has a limit of 170 parameters, due it we have to stack the requests with some
     * number bellow 100 because the commas separators count as a parameter, we are using 90 as
     * limit for the moment just in case.
     *
     * @see https://3.basecamp.com/4129278/buckets/10365580/messages/3835728840#__recording_3886821913
     */

    const MAX_LIMIT_SC = 90

    const associatedStoneCodeChunks = splitEvery(MAX_LIMIT_SC, affiliationCodes)

    const responseGroupByChunks = await Promise.all(
      associatedStoneCodeChunks.map(async chunk => {
        const merchantsUrl = dynamicTemplate('/merchants/summary?affiliationCodes=${stoneCodes}', {
          stoneCodes: chunk.join(',')
        })

        const {
          data: { data: response }
        } = await httpClientCustomer<MerchantsProps>({
          baseURL: CUSTOMER_API_ENDPOINT(),
          url: merchantsUrl
        })
        return response
      })
    ).then(dataByChunks => dataByChunks.flat())

    return responseGroupByChunks
  } catch (error) {
    logger(error)
    return []
  }
}

async function getAffiliationCodes() {
  try {
    const {
      data: { data: portalUser }
    } = await httpClientCustomer<{ data: any; messages: string[] }>({
      baseURL: CUSTOMER_API_ENDPOINT(),
      url: '/users/current'
    })

    return portalUser.affiliationCodes
  } catch (error) {
    const errorStatus = (error as AxiosError)?.response?.status
    if (errorStatus === 404) {
      return []
    }
    throw error
  }
}

export async function getStoneCodes() {
  const affiliationCodes = await getAffiliationCodes()

  const usersStoneCodes = await getStoneCodesByAffiliationCode(affiliationCodes)

  return stoneCodesResponseDecorator(usersStoneCodes.flat())
}
