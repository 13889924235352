import { type ReactNode } from 'react'
import { Cluster, Stack } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'
import { type MaxWidthProperty } from 'csstype'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { type SxStyleProp } from '~/domains/platform/design-system/types'
import { Choose, If } from '~/domains/platform/lib/utilities-components'

export interface EmptyStateProps {
  title: string | ReactNode
  subtitle?: string | ReactNode
  body?: string | ReactNode
  callToAction?: string
  width?: MaxWidthProperty<string>
  toolbar?: ReactNode
  illustration?: ReactNode
  action?: (...args: any) => void
  children?: ReactNode
  customStyles?: SxStyleProp
  rowDirecton?: boolean
}

export const EmptyState = (props: EmptyStateProps) => {
  const {
    illustration: Illustration,
    title,
    subtitle,
    body,
    callToAction,
    action,
    width,
    toolbar,
    children,
    customStyles,
    rowDirecton
  } = props

  return (
    <Flex
      flexDirection={rowDirecton ? 'row' : 'column'}
      justifyContent="center"
      alignItems="center"
      alignSelf="center"
      sx={{ textAlign: 'center', minHeight: rowDirecton ? 'inherit' : '60vh', ...customStyles }}
      width={width}
    >
      {Illustration}

      <Box p="1rem">
        <Stack space="1rem">
          <Box alignSelf="center">
            <Text sx={{ maxWidth: '50ch' }} fontSize={rowDirecton ? 'medium' : 'large'} fontWeight="bold">
              {title}
            </Text>
            {subtitle && (
              <Text mt={0} sx={{ maxWidth: '50ch' }} fontSize="large" color="primary" fontWeight="bold">
                {subtitle}
              </Text>
            )}
          </Box>
          <If condition={Boolean(body)}>
            <Choose>
              <Choose.When condition={typeof body === 'string'}>
                <Text alignSelf="center" sx={{ maxWidth: '50ch' }} color="mediumGray2">
                  {body}
                </Text>
              </Choose.When>
              <Choose.Otherwise>{body}</Choose.Otherwise>
            </Choose>
          </If>

          <If condition={Boolean(children)}>{children}</If>

          <If
            condition={Boolean(action && callToAction)}
            render={() => (
              <Flex justifyContent="center" alignSelf="center" pt={rowDirecton ? 'tiny' : 'xLarge'}>
                <Button color="neutral" size="large" onClick={action}>
                  {callToAction}
                </Button>
              </Flex>
            )}
          />

          <If condition={Boolean(toolbar)}>
            <Cluster align="center" justify="center" space="2rem">
              <Box>{toolbar}</Box>
            </Cluster>
          </If>
        </Stack>
      </Box>
    </Flex>
  )
}
