import { httpClientCustomer } from '~/lib/services/acquirer/http-client-customer/http-client-customer'
import { type PurchaseStoneCodeBrands, type PurchaseStoneCodeFees } from '~/lib/types/acquirer/Purchase'
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

export const getStoneBrands = (stonecode?: number | string, getStoneBrandsFees?: boolean) => {
  const affiliationCode = stonecode

  return httpClientCustomer({
    url: getStoneBrandsFees ? `/merchants/${affiliationCode}/fees` : `/merchants/${affiliationCode}/brands`
  })
}

export function useStoneBrands(stonecode?: number | string) {
  const { data, ...restQuery } = useQueryRequest<PurchaseStoneCodeBrands>(['useStoneBrands', stonecode], () =>
    getStoneBrands(stonecode)
  )

  const brands = data?.data?.data || []

  return { brands, ...restQuery }
}

export function useStoneBrandFees(stonecode?: number | string) {
  const getStoneBrandsFees = true

  const { data, ...restQuery } = useQueryRequest<PurchaseStoneCodeFees>(['useStoneBrandsFees', stonecode], () =>
    getStoneBrands(stonecode, getStoneBrandsFees)
  )

  const brandFees = data?.data?.data || []

  return { brandFees, ...restQuery }
}
