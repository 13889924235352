import { Box, Flex, Text } from '~/domains/platform/design-system'
import { type FieldProps, Field } from '~/domains/platform/design-system/field/field'
import { stringFormat, toRem } from '~/lib/helpers'

export interface MoneyProps extends FieldProps {
  pretty?: boolean
  prettyInline?: boolean
  prettySmaller?: boolean
}

function PrettyInlineRender(value: number, renderProps?: Record<string, any>) {
  const normalized = stringFormat.currency(value, true)
  const color = renderProps?.color
  const fontSize = renderProps?.fontSize || 'large'

  return (
    <Text color={color} fontSize={fontSize} fontWeight="bold">
      <Text as="span" fontWeight="normal">
        R${' '}
      </Text>
      {normalized}
    </Text>
  )
}

function PrettySmallerRender(value: number, renderProps?: Record<string, any>) {
  const normalized = stringFormat.currency(value, true)
  const color = renderProps?.color

  return (
    <Flex>
      <Box>
        <Text
          sx={{ marginRight: '0.15rem', marginTop: '0.35rem', fontWeight: 'body' }}
          color={color}
          fontSize={toRem(16)}
        >
          R$
        </Text>
      </Box>
      <Flex>
        <Text color={color} fontSize={toRem(22)} fontWeight="bold">
          {normalized}
        </Text>
      </Flex>
    </Flex>
  )
}

const PrettyRender = (value: number, renderProps?: Record<string, any>) => {
  const normalized = stringFormat.currency(value, true)
  const color = renderProps?.color
  const [natural, decimal] = normalized.split(',')

  return (
    <Flex>
      <Box>
        <Text
          sx={{ marginRight: '0.15rem', marginTop: '0.35rem', fontWeight: 'bold' }}
          color={color}
          fontSize={toRem(16)}
        >
          R$
        </Text>
      </Box>
      <Flex>
        <Text color={color} fontSize={toRem(28)} fontWeight="bold">
          {natural}
          <Text as="span" color={color} fontSize={toRem(16)}>
            ,{decimal}
          </Text>
        </Text>
      </Flex>
    </Flex>
  )
}

export const Money = (props: MoneyProps) => {
  const {
    value,
    pretty,
    prettySmaller,
    prettyInline,
    renderProps,
    formatter = stringFormat.currency,
    ...fieldProps
  } = props
  const color = value >= 0 ? 'primary' : 'danger'

  return (
    <Field
      {...fieldProps}
      value={value}
      formatter={pretty || prettyInline || prettySmaller ? undefined : formatter}
      renderProps={{ color, ...renderProps, 'data-testid': 'money' }}
    >
      {pretty ? PrettyRender : prettyInline ? PrettyInlineRender : prettySmaller ? PrettySmallerRender : undefined}
    </Field>
  )
}
