import styled from 'styled-components'
import { type LayoutProps, layout } from 'styled-system'
import { ifProp } from 'styled-tools'

export const DimmerWrapper = styled.div<LayoutProps & { isVisible?: boolean }>`
  ${layout}
  display: ${ifProp('isVisible', 'flex', 'none')};
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  user-select: none;
  justify-content: center;
  z-index: 700;
`
