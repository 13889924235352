export function PaperDocumentsErrorIllustration({ width = 120, height = 120, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M101.897 34.6301L94.5205 96.6986L54.127 91.8971L48.0505 91.1756L46.6795 91.0121L46.585 91.0016L46.813 89.0861L47.011 87.4271L50.659 56.7191L53.7715 30.5291L53.9605 28.9346L66.3745 30.4091L101.897 34.6301Z"
        fill="#BBBDBF"
      />
      <path
        d="M81.5791 80.5358C81.4816 80.5358 81.3916 80.4713 81.3631 80.3723L67.3262 30.8078C67.2917 30.6878 67.3622 30.5633 67.4807 30.5303C67.6007 30.4958 67.7252 30.5663 67.7582 30.6848L81.7966 80.2493C81.8311 80.3693 81.7606 80.4938 81.6421 80.5268C81.6196 80.5328 81.6001 80.5358 81.5791 80.5358Z"
        fill="#1D232A"
      />
      <path
        d="M81.9103 85.7199L51.4873 91.3584L48.0508 90.9459L48.1978 89.2149L50.6593 56.3904L52.6468 29.8959L53.8618 29.3379L67.5433 30.7464L81.9103 85.7199Z"
        fill="#1D232A"
        stroke="#1D232A"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M65.1193 23.4L18 33.8994L31.5948 94.9105L78.7141 84.4111L65.1193 23.4Z" fill="#E8E9EA" />
      <path
        d="M27.5057 43.8652C27.4022 43.8652 27.3092 43.7932 27.2867 43.6897C27.2597 43.5682 27.3362 43.4482 27.4577 43.4212L60.5971 36.0352C60.7186 36.0067 60.8386 36.0847 60.8656 36.2062C60.8926 36.3277 60.8161 36.4477 60.6946 36.4747L27.5552 43.8607C27.5387 43.8637 27.5222 43.8652 27.5057 43.8652Z"
        fill="#1D232A"
      />
      <path
        d="M29.659 53.5293C29.5555 53.5293 29.4625 53.4573 29.44 53.3538C29.413 53.2323 29.4895 53.1123 29.611 53.0853L62.7505 45.6992C62.8705 45.6707 62.992 45.7487 63.019 45.8702C63.046 45.9917 62.9695 46.1117 62.848 46.1387L29.7085 53.5233C29.692 53.5263 29.6755 53.5293 29.659 53.5293Z"
        fill="#1D232A"
      />
      <path
        d="M31.8133 63.1929C31.7098 63.1929 31.6168 63.1209 31.5943 63.0174C31.5673 62.8959 31.6438 62.7759 31.7653 62.7489L64.9048 55.3628C65.0263 55.3358 65.1463 55.4123 65.1733 55.5338C65.2003 55.6553 65.1238 55.7753 65.0023 55.8023L31.8628 63.1869C31.8463 63.1899 31.8298 63.1929 31.8133 63.1929Z"
        fill="#1D232A"
      />
      <path
        d="M33.9676 72.856C33.8641 72.856 33.7711 72.7855 33.7486 72.6805C33.7216 72.559 33.7981 72.439 33.9196 72.412L67.0591 65.0259C67.1806 64.9989 67.3006 65.0754 67.3276 65.1969C67.3546 65.3184 67.2781 65.4384 67.1566 65.4654L34.0171 72.8515C33.9991 72.8545 33.9826 72.856 33.9676 72.856Z"
        fill="#1D232A"
      />
      <path
        d="M36.1199 82.5184C36.0164 82.5184 35.9234 82.4479 35.9009 82.3429C35.8739 82.2214 35.9504 82.1014 36.0719 82.0744L59.4569 76.8619C59.5769 76.8349 59.6984 76.9114 59.7254 77.0329C59.7524 77.1544 59.6759 77.2744 59.5544 77.3014L36.1694 82.5139C36.1529 82.5169 36.1364 82.5184 36.1199 82.5184Z"
        fill="#1D232A"
      />
      <g filter="url(#filter0_d_1848_27027)">
        <path
          d="M89.3996 111.6C98.678 111.6 106.2 104.078 106.2 94.8C106.2 85.5216 98.678 78 89.3996 78C80.1212 78 72.5996 85.5216 72.5996 94.8C72.5996 104.078 80.1212 111.6 89.3996 111.6Z"
          fill="#4A4F55"
        />
      </g>
      <path
        d="M97.4605 100.367C98.1489 101.057 98.1489 102.172 97.4605 102.861C97.1163 103.205 96.6648 103.377 96.2134 103.377C95.762 103.377 95.3106 103.205 94.9664 102.861L89.3997 97.2942L83.8317 102.861C83.4875 103.205 83.0361 103.377 82.5847 103.377C82.1332 103.377 81.6818 103.205 81.3376 102.861C80.6492 102.172 80.6492 101.057 81.3376 100.367L86.9056 94.8001L81.3376 89.2321C80.6492 88.5437 80.6492 87.4264 81.3376 86.738C82.026 86.0496 83.1419 86.0496 83.8317 86.738L89.3997 92.306L94.9664 86.738C95.6548 86.0496 96.7721 86.0496 97.4605 86.738C98.1489 87.4264 98.1489 88.5437 97.4605 89.2321L91.8939 94.8001L97.4605 100.367Z"
        fill="#FCFCFC"
      />
      <defs>
        <filter
          id="filter0_d_1848_27027"
          x="69.5996"
          y="77"
          width="39.5996"
          height="39.5996"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1848_27027" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1848_27027" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
