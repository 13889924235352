import { Heading, Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { Box, Flex } from '~/domains/platform/design-system'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/ports'
import { analytics } from '~/domains/platform/lib/analytics'
import BlackBird from '~/domains/platform/lib/blackbird'
import { HiddenAmount } from '~/domains/platform/lib/hidden-sensitive/components/hidden-amount'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { useAccountBalance } from '~/ui/business-components/banking/balance-card/use-account-balance'

import { SharedSkeletonFeedback } from '../shared/components/shared-skeleton-feedback'
import { useInvestmentBalance } from '../shared/hooks/use-investment-balance'

const WrapperAccount = styled(Flex)`
  &:hover {
    & > h3 {
      color: ${theme('colors.primary')};
    }
  }
`

export function InvestmentBalanceCard({ accountId }: { accountId: PaymentAccount['id'] }) {
  const hasInvestmentSCFIV2 = Heimdall.pass(['investment_scfi_v1_pf', 'investment_scfi_v1_pj'], 'some')
  const hasInvestmentPilot = Heimdall.pass(
    ['investment_daily_pf', 'investment_daily_pj', 'investment_monthly_pf', 'investment_monthly_pj'],
    'some'
  )

  const {
    data: investmentBalance,
    isFetching: isInvestmentBalanceFetching,
    isError: isInvestmentBalanceError
  } = useInvestmentBalance({
    accountId,
    config: { enabled: Boolean(accountId && (hasInvestmentPilot || hasInvestmentSCFIV2)) }
  })
  const {
    data: accountBalance,
    isFetching: isAccountBalanceFetching,
    isError: isAccountBalanceError
  } = useAccountBalance({ accountId })

  if (isInvestmentBalanceFetching || isAccountBalanceFetching) {
    return (
      <Flex flexDirection="column" gap="0.25rem">
        <SharedSkeletonFeedback width="10ch" height="1rem" />
        <SharedSkeletonFeedback width="20ch" height="1.25rem" />
      </Flex>
    )
  }

  if (!hasInvestmentPilot && !hasInvestmentSCFIV2) {
    return null
  }

  if (isInvestmentBalanceError || isAccountBalanceError) {
    return null
  }

  if (
    stringFormat.normalizeAmount(String(accountBalance?.amount) ?? '0') === 0 &&
    !investmentBalance?.hasInvestedBefore
  ) {
    return null
  }

  if (!investmentBalance?.hasInvestedBefore) {
    return (
      <Flex alignItems="center" justifyContent="space-between" gap="0.75rem">
        <Heading size="xSmall" weight="regular">
          Faça seu dinheiro render mais que na poupança
        </Heading>
        <Box
          as="span"
          onClick={() => {
            analytics.sendEvent('investment - start button clicked')
            if (hasInvestmentSCFIV2) {
              BlackBird.travelTo('/rendimentos/v2/quer-guardar-dinheiro')
              return
            }

            BlackBird.travelTo('/rendimentos/sobre-seus-rendimentos')
          }}
        >
          <Text color="primaryHigh" size="small" weight="semi">
            Guardar
          </Text>
        </Box>
      </Flex>
    )
  }

  return (
    <Flex alignItems="end" justifyContent="space-between" gap="0.25rem">
      <Box>
        <Box mb="0.25rem">
          <Text size="small" color="neutral">
            Rendendo
          </Text>
        </Box>

        <Heading weight="bold">
          <HiddenAmount>
            <WrapperAccount data-testid="investment-balance-amount">
              <Heading size="small">R$&nbsp;</Heading>
              <Heading size="small" weight="bold">
                {stringFormat.currency(investmentBalance?.amount ?? 0, true)}
              </Heading>
            </WrapperAccount>
          </HiddenAmount>
        </Heading>
      </Box>

      <Box
        as="span"
        role="link"
        onClick={() => {
          analytics.sendEvent('investment - open home button clicked')
          if (hasInvestmentSCFIV2) {
            BlackBird.travelTo('/rendimentos/v2')
            return
          }

          BlackBird.travelTo('/rendimentos')
        }}
      >
        <Box textAlign="right" style={{ wordBreak: 'break-word' }}>
          <Text color="primaryHigh" size="small" weight="semi">
            Conferir rendimentos
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}
