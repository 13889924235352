import { httpClientOpenFinance } from '~/lib/services'
import { type AuthConsent } from '~/lib/types'

import { useRequest } from '../../utils/service/use-request'

interface RequestType {
  accountId: string
  clientId: string
  jwe: string
}

export const useAuth = ({ accountId, clientId, jwe }: RequestType) => {
  return useRequest<AuthConsent>(
    {
      url: '/auth',
      method: 'POST',
      data: { accountId, jwe, clientId }
    },
    {},
    httpClientOpenFinance
  )
}
