import { Stack } from '@dlpco/fluid-layout'
import { Heading, Text } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { type Scope } from '~/lib/types'
import { List } from '~/ui/components/utils/list/list'

import { Li, Ul } from '../styles'

interface ConsentedScopesProps {
  consentedScopes?: Scope[]
}

export function ConsentedScopes(props: ConsentedScopesProps) {
  const { consentedScopes } = props
  return (
    <>
      <Flex mb="2rem">
        <Heading color="neutral" weight="bold">
          Permissões consentidas:
        </Heading>
      </Flex>
      <Choose>
        <Choose.When condition={Boolean(consentedScopes && consentedScopes?.length > 0)}>
          <Ul>
            <Stack space="1rem">
              <List
                items={consentedScopes as Scope[]}
                render={(scope, index) => <Li key={index}>{scope.description}</Li>}
              />
            </Stack>
          </Ul>
        </Choose.When>
        <Choose.Otherwise>
          <Text>Você não possui permissões consentidas.</Text>
        </Choose.Otherwise>
      </Choose>
    </>
  )
}
