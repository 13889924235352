import styled from 'styled-components'
import { ifNotProp, ifProp, theme } from 'styled-tools'

export const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`

export const NavLink = styled.a<{ current?: boolean; disabled?: boolean }>`
  background-color: ${ifProp('current', theme('colors.lighterGreen'), 'none')};
  cursor: ${ifNotProp('disabled', 'pointer')};
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  text-decoration: none;
  border-radius: 0.5rem;
  margin: 0 0 0.125rem 0;
  width: 100%;
  color: ${ifProp(
    'disabled',
    theme('colors.mediumGray2'),
    ifProp('current', theme('colors.highPrimary'), theme('colors.darkGray'))
  )};

  &:hover {
    background-color: ${ifProp('current', theme('colors.lighterGreenHover'), theme('colors.neutralOverlay'))};
  }

  span {
    :first-child {
      path {
        color: ${ifProp('disabled', theme('colors.mediumGray2'), ifProp('current', theme('colors.stoneGreen')))};
      }
    }
  }
`

NavLink.defaultProps = { tabIndex: 0 }
