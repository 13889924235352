import { useEffect } from 'react'
import { Stack } from '@dlpco/fluid-layout'
import { Badge, Card, CardAlert, Text } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { If } from '~/domains/platform/lib/utilities-components'
import { AsyncRenderer } from '~/domains/platform/lib/utilities-components/async-renderer/async-renderer'
import { trackEvents } from '~/lib/helpers'
import { type OpenFinanceStatementEntry } from '~/lib/types/open-finance/OpenFinanceStatementEntry'
import { Money } from '~/ui/components/fields/money'
import { SideModal } from '~/ui/components/modals/side-modal/side-modal'

import { badgeCompose } from '../../helpers/badge-compose'
import { rejectionReasonCompose } from '../../helpers/reject-reason-compose'

import { type TransactionDetailOpenFinanceProps } from './interface'
import { Details } from './partials'

export const TransactionDetailOpenFinance = (props: TransactionDetailOpenFinanceProps) => {
  const { url, fetcher, transformer, ...sideModalProps } = props

  useEffect(() => {
    trackEvents({
      action: 'click',
      category: 'open-finance',
      label: 'Cliente visualizou os detalhes de uma transação via Open Finance',
      trackServices: ['analytics']
    })
  }, [])

  return (
    <SideModal title="Detalhes da transação" {...sideModalProps}>
      <AsyncRenderer
        fetcherHook={fetcher}
        transformer={transformer}
        loader={() => (
          <Dimmer isVisible>
            <Loader />
          </Dimmer>
        )}
        url={url}
        render={({ data }: { data: OpenFinanceStatementEntry }) => {
          const { amount, rejectionReason, paymentTransactionStatus } = data

          const absAmount = Math.abs(amount)

          const { status, badgeType } = badgeCompose[paymentTransactionStatus]

          const paymentStatusAlert =
            paymentTransactionStatus === 'IN_PROGRESS' ||
            paymentTransactionStatus === 'CANCELED' ||
            paymentTransactionStatus === 'FAILED'

          const { type, title, subTitle } =
            rejectionReasonCompose[paymentStatusAlert ? paymentTransactionStatus : rejectionReason]

          return (
            <>
              <Stack space="1rem">
                <If condition={paymentStatusAlert}>
                  <Box>
                    <Card>
                      <CardAlert color={type}>
                        <Text inheritColor weight="bold">
                          {title}
                        </Text>
                        <Text>{subTitle}</Text>
                      </CardAlert>
                    </Card>
                  </Box>
                </If>
                <Flex justifyContent="space-between">
                  <Box>
                    <Money pretty value={absAmount} label="Valor" renderProps={{ color: 'darkGray', fontSize: '1' }} />
                  </Box>
                  <Box>
                    <Badge as="span" color={badgeType} variant={'light'}>
                      {status}
                    </Badge>
                  </Box>
                </Flex>
                <Details data={data} />
              </Stack>
            </>
          )
        }}
      />
    </SideModal>
  )
}
