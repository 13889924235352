import { analiticaInstance } from '~/domains/platform/lib/analitica/analitica.config'

export default analiticaInstance.eventFactory('StoneAccount', {
  profileMenu: {
    /**
     * @analitica When the user click on go back button
     */
    click: (item: string) => ({
      label: 'home web - menu perfil chosen button',
      metadata: { 'menu-perfil': item }
    })
  }
})
