import { useToggle } from 'react-use'
import { Center } from '@dlpco/fluid-layout'
import { Button, Icon } from '@dlpco/ginga-stone'
import dayjs from 'dayjs'
import groupBy from 'lodash.groupby'

import { DogIllustration } from '~/domains/platform/design-system/illustrations/dog.illustration'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/ports'
import BlackBird from '~/domains/platform/lib/blackbird'
import { For, If } from '~/domains/platform/lib/utilities-components'
import {
  InfiniteScrollList,
  InfiniteScrollListItemAmount,
  InfiniteScrollListItemDescription,
  InfiniteScrollListItemGroupBalance,
  InfiniteScrollListItemGroupDate,
  InfiniteScrollListItemGroupHeader,
  InfiniteScrollListItemHeader,
  InfiniteScrollListItemIcon,
  InfiniteScrollListItemInfo,
  InfiniteScrollListItemShadow,
  InfiniteScrollListItemWrapper
} from '~/domains/platform/lib/utilities-components/infinite-scroll/infinite-scroll-list'
import { dynamicTemplate, stringFormat, trackAnalyticsEvent, translateTypeSchedule } from '~/lib/helpers'
import { type SchedulingEntry } from '~/lib/types'
import { TransactionDetail } from '~/ui/business-components/banking/transaction-detail'
import { Money } from '~/ui/components/fields/money'
import { MainContentHeader } from '~/ui/components/layouts/main-content'
import { EmptyState } from '~/ui/components/utils/empty-state/empty-state'
import { useInfiniteList } from '~/ui/contexts'

import { Detail, Toolbar } from './partials'

// this is defined here because it is specific to schedulings
type ScheduledToObject = { scheduledTo: string; scheduledToEffective: string }

const sortByDate = (
  { scheduledTo: first, scheduledToEffective: firstEffective }: ScheduledToObject,
  { scheduledTo: second, scheduledToEffective: secondEffective }: ScheduledToObject
) => {
  const firstDate = firstEffective || first
  const secondDate = secondEffective || second
  return dayjs(firstDate).isAfter(secondDate) ? 1 : -1
}

const byDate = ({ scheduledToEffective, scheduledTo }: ScheduledToObject) =>
  dayjs(scheduledToEffective || scheduledTo).format('DD/MM/YYYY')

const formatResult = ([key, values]: [string, ScheduledToObject[]]) => ({ key, values })

export const groupByDate = (data: ScheduledToObject[]) => {
  const sortedData = data.sort(sortByDate)
  const groupedByDate = groupBy(sortedData, byDate)

  return Object.entries(groupedByDate).map(formatResult)
}

const SchedulingsEntryItem = ({ entry, account }: { entry: SchedulingEntry; account: PaymentAccount }) => {
  const formatter = (value: number) => stringFormat.currency(value, true)
  const [detailOpen, toggleDetail] = useToggle(false)

  const { id: accountId } = account
  const { id: operationId, amount, type, title, subtitle } = entry

  const handleClick = () => {
    toggleDetail()

    trackAnalyticsEvent({
      action: 'click',
      category: 'Schedule',
      label: `Clicar em detalhes: ${translateTypeSchedule(type)}`
    })
  }

  const transactionDetailUrl = dynamicTemplate('/accounts/${accountId}/operations/${operationId}', {
    accountId,
    operationId
  })

  return (
    <>
      <InfiniteScrollListItemShadow key={operationId} onClick={handleClick} isOpen={detailOpen}>
        <InfiniteScrollListItemWrapper>
          <InfiniteScrollListItemIcon>
            <Icon use="calendar-outline" />
          </InfiniteScrollListItemIcon>
          <InfiniteScrollListItemInfo>
            <InfiniteScrollListItemHeader>{title}</InfiniteScrollListItemHeader>
            <InfiniteScrollListItemDescription>{subtitle}</InfiniteScrollListItemDescription>
          </InfiniteScrollListItemInfo>
          <InfiniteScrollListItemAmount as="div">
            <Money value={amount} formatter={formatter} />
          </InfiniteScrollListItemAmount>
        </InfiniteScrollListItemWrapper>
      </InfiniteScrollListItemShadow>

      <TransactionDetail
        isOpen={detailOpen}
        title={title}
        transformer={(data: SchedulingEntry) => ({ ...data, type })}
        toggle={toggleDetail}
        toolbar={data => <Toolbar entry={data} cancellCallback={toggleDetail} />}
        url={`${transactionDetailUrl}?type=${type}`}
      >
        {data => {
          return <Detail entry={data} />
        }}
      </TransactionDetail>
    </>
  )
}

const SchedulingsGroup = ({
  date,
  entries,

  account
}: {
  date: string
  entries: SchedulingEntry[]

  account: PaymentAccount
}) => {
  if (entries.length === 0) return null

  const groupBalance = entries.reduce((acc, entry) => acc + entry.amount, 0)

  return (
    <>
      <InfiniteScrollListItemGroupHeader fontWeight="light">
        <InfiniteScrollListItemGroupDate fontWeight="light">{date} </InfiniteScrollListItemGroupDate>
        <InfiniteScrollListItemGroupBalance>
          <Money value={groupBalance} renderProps={{ color: 'mediumGray', fontWeight: 'light' }} />
        </InfiniteScrollListItemGroupBalance>
      </InfiniteScrollListItemGroupHeader>
      <For
        of={entries}
        render={(item: SchedulingEntry) => {
          return <SchedulingsEntryItem key={item.id} entry={item} account={account} />
        }}
      />
    </>
  )
}

const EMPTY_STATE_TITLE = 'Você não possui nenhuma transação agendada.'
const EMPTY_STATE_SUBTITLE = 'Todos agendamentos e recebimentos de venda aparecerão aqui.'

export const List = ({
  loading,
  isFreemium,
  account
}: {
  loading?: boolean
  isFreemium?: boolean
  account: PaymentAccount
}) => {
  const { list } = useInfiniteList()

  return (
    <InfiniteScrollList
      header={
        <If condition={Boolean(list && list.length > 0)}>
          <MainContentHeader title="Agendamentos" />
        </If>
      }
      externalLoading={loading}
      transformData={groupByDate}
      render={data => {
        if (data && data.length > 0) {
          return (
            <For
              of={data}
              render={({ key, values }: { key: string; values: SchedulingEntry[] }) => (
                <SchedulingsGroup key={key} date={key} entries={values} account={account} />
              )}
            />
          )
        }

        return (
          <Center>
            <EmptyState
              title={EMPTY_STATE_TITLE}
              body={EMPTY_STATE_SUBTITLE}
              illustration={<DogIllustration />}
              toolbar={
                <If condition={!isFreemium}>
                  <Button
                    color="neutral"
                    size="large"
                    icon="transfer-outline"
                    onClick={() => {
                      trackAnalyticsEvent({
                        action: 'click',
                        category: 'Schedule',
                        label: 'Abrir modal de transferir via empty state de agendamentos'
                      })
                      BlackBird.travelTo('/transferir/selecione-contato')
                    }}
                  >
                    Agendar Transferência
                  </Button>
                  <Button
                    color="neutral"
                    size="large"
                    icon="code-bar-outline"
                    onClick={() => {
                      trackAnalyticsEvent({
                        action: 'click',
                        category: 'Schedule',
                        label: 'Abrir a página pagar via empty state da página agendamentos'
                      })
                      BlackBird.travelTo('/pagar')
                    }}
                  >
                    Agendar Pagamento
                  </Button>
                </If>
              }
            />
          </Center>
        )
      }}
    />
  )
}
