import type * as FlexWebChatModule from '@twilio/flex-webchat-ui'

import { clientSideOnly } from '~/domains/platform/lib/client-side-only'

export const twilio = (function () {
  if (clientSideOnly(() => 'Twilio' in window, false)) return window.Twilio.FlexWebChat
  return {} as typeof window.Twilio.FlexWebChat
})()

declare global {
  interface Window {
    Twilio: {
      FlexWebChat: typeof FlexWebChatModule
    }
  }
}
