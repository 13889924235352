import styled, { css } from 'styled-components'
import { ifProp, switchProp, theme } from 'styled-tools'

import { toRem } from '~/lib/helpers'

import { type SideModalProps } from './interface'

type WrapperProps = Required<Pick<SideModalProps, 'position' | 'animation'>>

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${theme('colors.white')};
  bottom: 0;
  display: flex;
  flex-direction: 'column';
  padding-top: ${theme('space.xLarge')};
  position: fixed;
  top: ${theme('sizes.header.height')};
  width: ${toRem(400)};
  box-shadow: ${theme('shadows.large')};
  max-height: calc(100vh - ${theme('sizes.header.height')});
  overflow-y: auto;
  word-break: break-word;

  @media (max-width: 768px) {
    width: 100%;
  }

  ${switchProp('position', {
    left: css`
      left: 0;
      z-index: ${theme('zIndices.overlay')};

      &.visible {
        animation: modal-in-fade 0.25s ease-in;
      }
    `,
    right: css`
      z-index: ${theme('zIndices.sideModal')};
      right: 0;

      &.visible {
        animation: modal-in 0.25s ease-in;
      }
    `
  })}

  &.visible {
    ${switchProp('animation', {
      slide_from_right: css`
        animation: slide-from-right 0.25s ease-in;
      `,
      slide_from_left: css`
        animation: slide-from-left 0.25s ease-in;
      `,
      fade: css`
        animation: fade 0.25s ease-in;
      `
    })}
  }

  @keyframes slide-from-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slide-from-left {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export const SideModalTitle = styled.div`
  font-size: ${theme('fontSize.medium')};
  font-weight: ${theme('fontWeights.heading')};
  padding-bottom: ${theme('space.large')};
  padding-left: ${theme('space.large')};
  padding-right: ${theme('space.large')};
`

export const ChildrenWrapper = styled.div`
  padding: ${theme('space.large')};
`

export const HeaderWrapper = styled.div<{ border?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
  ${ifProp(
    'border',
    css`
      border-bottom: 1px solid ${theme('colors.border.disabled')};
    `
  )};
`
