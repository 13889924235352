import { useEffect, useState } from 'react'

import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { useMutationRequest } from '~/ui/hooks/utils/service/use-query-request'

import { FeatureFlagsStorage } from './feature-flags-storage'
import { type FeatureFlagsAvailable } from './types'

interface FlagrPayloadResponse {
  resourceId: string
  enabledFeatures: FeatureFlagsAvailable[]
}

type ResourceIdentifier = {
  [key: string]: string
}

type ReduceReturnType = {
  resourceId: string[]
  resourceType: string
}

function createFeatureFlagsPayload(resources: ResourceIdentifier) {
  return Object.entries(resources).reduce<ReduceReturnType[]>(
    (acc, [resourceType, resourceId]) => [...acc, { resourceId: [resourceId], resourceType }],
    []
  )
}

async function fetchFeatureFlags(resources: ResourceIdentifier) {
  const response = await httpClientBanking<FlagrPayloadResponse[]>({
    url: '/flagr/features/resource',
    data: createFeatureFlagsPayload(resources),
    method: 'POST'
  })

  return (response?.data || []).map(flagr => flagr.enabledFeatures).flat()
}

interface UseHeimdallProps {
  identifierList: ResourceIdentifier
  bffEnabledFeatures: string[]
}

export function useHeimdall({ identifierList, bffEnabledFeatures }: UseHeimdallProps) {
  const cachedIdentifiers = FeatureFlagsStorage.get().identifiers
  const identifiers = Object.values(identifierList).filter(Boolean)

  const [isReady, setReady] = useState(false)

  const {
    mutate,
    data: featureFlagsFromFlagr = [],
    isSuccess
  } = useMutationRequest<FeatureFlagsAvailable[], unknown, true>(
    ['fetchFeatureFlags'],
    async opts => await fetchFeatureFlags(opts)
  )

  useEffect(() => {
    const upSetFlags = () => {
      const shouldUpdateCache = identifiers.every(id => cachedIdentifiers.includes(id)) === false

      if (shouldUpdateCache) {
        mutate(identifierList)
      } else {
        setReady(true)
      }
    }

    upSetFlags()
  }, [])

  useEffect(() => {
    const shouldPersist = isSuccess && !isReady && Boolean(featureFlagsFromFlagr.length)

    if (shouldPersist) {
      const featureFlags = [...featureFlagsFromFlagr, ...bffEnabledFeatures] as FeatureFlagsAvailable[]

      FeatureFlagsStorage.set({ identifiers, featureFlags })

      setReady(true)
    }
  }, [bffEnabledFeatures, featureFlagsFromFlagr, identifiers, isReady, isSuccess])

  return { isReady }
}
