import { Cluster, Grid, Stack } from '@dlpco/fluid-layout'

import { Box, Card, Text } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { For } from '~/domains/platform/lib/utilities-components'

import { WrapperSummaryItem } from './styles'

const CREDIT = 'Crédito'
const DEBIT = 'Débito'
const VOUCHER = 'Voucher'

const ModalitySummaryCard = ({ title, ...rest }: { title: string }) => (
  <Card backgroundColor="white" {...rest}>
    <Stack space="2rem">
      <Cluster justify="space-between">
        <Box>
          <Text color="darkGray" fontWeight="medium">
            {title}
          </Text>
          <Skeleton height="2rem" width="10ch" />
        </Box>
      </Cluster>
      <Cluster justify="space-between">
        <WrapperSummaryItem>
          <Skeleton height="2rem" width="28%" />
          <Skeleton height="2rem" width="28%" />
          <Skeleton height="2rem" width="28%" />
        </WrapperSummaryItem>
      </Cluster>
    </Stack>
  </Card>
)

export const ModalitySummaryLoader = () => {
  const cards = [
    {
      title: DEBIT
    },
    {
      title: CREDIT
    },
    {
      title: VOUCHER
    }
  ]

  return (
    <>
      <Text fontSize="3" fontWeight="bold">
        Total por Modalidade
      </Text>
      <Grid min="20ch" gutter="2rem">
        <For of={cards} render={(item, index) => <ModalitySummaryCard title={item.title} key={index} />} />
      </Grid>
    </>
  )
}
