import { type ReactNode } from 'react'
import NextLink from 'next/link'
import { Cluster, Stack } from '@dlpco/fluid-layout'
import { Icon } from '@dlpco/ginga-stone'

import { Box, Card, Flex, Link, Text } from '~/domains/platform/design-system'
import { Choose, If } from '~/domains/platform/lib/utilities-components'

import { Money } from '../../fields/money'

export interface StatisticsItemProps {
  title: string
  subtitle: string
  moneyAmount: number
  href?: string
  icon?: ReactNode
  as?: string
  onClick?: () => void
}

function BaseCard({
  title,
  subtitle,
  href,
  icon,
  amount
}: {
  title: string
  subtitle: string
  href?: string
  icon: ReactNode
  amount: number
}) {
  return (
    <Card px="2rem" py="1rem" sx={{ '& *': { textDecoration: 'none' } }}>
      <Cluster space="1rem" justify="space-between" align="center">
        <Box>
          <Stack space="0.5rem">
            <Text color="mediumGray" fontWeight="medium" data-testid="title">
              {title}
            </Text>
            <Stack space="0.25rem">
              <Money data-testid="money" prettyInline renderProps={{ color: 'darkGray' }} value={amount} />
              {/* the font-weight: normal above is to compensate the one in the anchor with the variant of nav*/}
              <Text data-testid="subtitle" fontWeight="normal" color="mediumGray2">
                {subtitle}
              </Text>
            </Stack>
          </Stack>
          <If condition={Boolean(href || icon)}>
            <Flex flexDirection="column" alignItems="flex-end">
              <Choose>
                <Choose.When condition={Boolean(icon)}>{icon}</Choose.When>
                <Choose.Otherwise>
                  <Icon use="chevron-right-outline" data-testid="chevron" color="neutralLow" />
                </Choose.Otherwise>
              </Choose>
            </Flex>
          </If>
        </Box>
      </Cluster>
    </Card>
  )
}

export function StatisticsItem(props: StatisticsItemProps) {
  const { title, subtitle, icon, moneyAmount: amount, href, onClick } = props

  if (href) {
    return (
      <NextLink href={href} passHref legacyBehavior>
        <Link style={{ textDecoration: 'none', display: 'block' }} onClick={onClick}>
          <BaseCard title={title} subtitle={subtitle} amount={amount} icon={icon} />
        </Link>
      </NextLink>
    )
  }

  return <BaseCard title={title} icon={icon} subtitle={subtitle} amount={amount} />
}
