import { createContext } from 'react'

import { type StepProps } from './interface'

const identity = (x: any) => x

const initialValues = {
  step: 0,
  close: identity,
  goToKey: identity,
  handleStep: {
    next: identity,
    previous: identity
  },
  content: {},
  setContent: () => {},
  goTo: identity,
  reset: () => null
}

export const WizardContext = createContext<StepProps>(initialValues)

export default WizardContext
