import { Grid, Stack } from '@dlpco/fluid-layout'

import { Box } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'

export function SaleInformationLoader() {
  return (
    <Stack space="1.5rem">
      <Grid gutter="2rem" min="30ch">
        <Skeleton width="100%" height="5ch" />
        <Skeleton width="100%" height="5ch" />
        <Skeleton width="100%" height="5ch" />
        <Box />
      </Grid>

      <Divider />

      <Grid gutter="1.5rem" min="30ch">
        <Skeleton width="100%" height="5ch" />
        <Skeleton width="100%" height="5ch" />
        <Skeleton width="100%" height="5ch" />
        <Skeleton width="100%" height="5ch" />
      </Grid>

      <Grid gutter="1.5rem" min="30ch">
        <Skeleton width="100%" height="5ch" />
        <Skeleton width="100%" height="5ch" />
        <Skeleton width="100%" height="5ch" />
        <Box />
      </Grid>
    </Stack>
  )
}
