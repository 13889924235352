import { Icon } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { type MerchantsContentProps } from '~/domains/acquirer/shared/entities/merchants'
import { type TerminalDevices } from '~/domains/acquirer/terminals/terminals-list/entities'
import { Box, Text } from '~/domains/platform/design-system'

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: unset !important;
    height: unset !important;
  }
`

interface StonecodeItemProps {
  fantasyName: string
  stoneCode: string
  style?: React.CSSProperties
  merchants?: MerchantsContentProps[]
  terminalDevices?: TerminalDevices[]
}
const defineTypeAndLabel = ({
  stoneCode,
  merchants,
  terminalDevices
}: {
  stoneCode: string
  merchants?: MerchantsContentProps[]
  terminalDevices?: TerminalDevices[]
}) => {
  if (merchants?.find(afk => stoneCode === afk.affiliationCode && afk.salesTeamMemberName === 'WhatsAppPay')) {
    return {
      type: 'WhatsAppPay',
      label: 'WhatsApp'
    }
  } else {
    const type = terminalDevices?.some(({ affiliationCode }) => affiliationCode === stoneCode) ? 'device' : 'ecommerce'
    return {
      type,
      label: type === 'device' ? 'Maquinhas e TEF' : 'E-commerce ou link'
    }
  }
}

export const StonecodeItem = (props: StonecodeItemProps) => {
  const { style, stoneCode, fantasyName, merchants, terminalDevices } = props

  const { type, label } = defineTypeAndLabel({ merchants, terminalDevices, stoneCode })

  return (
    <Wrapper style={{ ...style }}>
      <Box ml={3}>
        <Icon
          use={type === 'device' ? 'device-card-machine-outline' : 'bag-outline'}
          color="primary"
          style={{ width: '40px', height: '40px' }}
        />
      </Box>
      <Box ml={3}>
        <Text fontWeight="bold" color="darkGray">
          {fantasyName}
        </Text>
        <Text color="mediumGray">{`${label} • # ${stoneCode}`}</Text>
      </Box>
    </Wrapper>
  )
}
