import { type NextPage } from 'next'
import { useRouter } from 'next/router'

import { Loader } from '~/domains/platform/design-system/loader/loader'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { CleanPageContainer } from '~/ui/containers/clean-page-container'

import { type WithDeusExMachinaProps } from '../deus-ex-machina/types'

export function withPinRegister(Page: NextPage<WithDeusExMachinaProps>) {
  function WrappedPage({ subject, ...rest }: WithDeusExMachinaProps) {
    const { push } = useRouter()
    const query = BlackBird.getQuery()
    const pinConfigured = subject.credentials.includes('pin')

    return (
      <Choose>
        <Choose.When condition={Boolean(pinConfigured)}>
          <Page subject={subject} {...rest} />
        </Choose.When>
        <Choose.Otherwise
          render={() => {
            const { pin } = query as Record<string, string>
            if (!pinConfigured && pin !== 'success') push('/pin')
            return (
              <CleanPageContainer>
                <Loader />
              </CleanPageContainer>
            )
          }}
        />
      </Choose>
    )
  }

  WrappedPage.displayName = `withPinRegister(${Page.displayName})`

  return WrappedPage
}
