import { useState } from 'react'
import { useRouter } from 'next/router'
import { Grid, Stack } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'
import { type AxiosError } from 'axios'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { Box, Text } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { uuid } from '~/domains/platform/lib/crypto'
import { approvePaymentConsent } from '~/lib/services/open-finance/approve-payment'
import { paymentConsent } from '~/lib/services/open-finance/payment'
import { useOpenFinanceContext } from '~/ui/hooks/open-finance'

import { GENERIC_ERROR, PAYMENT_EXPIRED, PAYMENT_INVALID, PAYMENT_NOT_FOUND } from '../../helpers'
import { type ConsentRedirectProps } from '../context/provider'

export function CancelConsent() {
  const { data: contextData, saveData } = useOpenFinanceContext()
  const { consent } = contextData
  const { push } = useRouter()
  const [idempotency] = useState(uuid())
  const chosenAccount = contextData.chosenAccount || {}
  const openFinancePaymentIniciationFlag = Heimdall.pass(['open_finance_payment_initiation_web_v2'])

  const handleCancelConsent = async () => {
    try {
      const { data } = await paymentConsent(
        {
          action: 'reject',
          consentId: consent?.id as string,
          accountId: consent?.accountId as string
        },
        idempotency
      )()

      saveData({ ...contextData, redirect: data as ConsentRedirectProps })
      push('/open-finance/pagamento/redirecionar')
    } catch (error) {
      const err = error as AxiosError
      const response = err?.response

      if (response?.status === 404) {
        saveData({ ...contextData, error: PAYMENT_NOT_FOUND })
        push('/open-finance/pagamento/erro')
      }

      if (response?.status === 422 && response?.data?.type === 'srn:error:invalid_status') {
        saveData({ ...contextData, error: PAYMENT_INVALID })
        push('/open-finance/pagamento/erro')
      }

      if (response?.status === 422 && response?.data?.type === 'srn:error:expired_datetime') {
        saveData({ ...contextData, error: PAYMENT_EXPIRED })
        push('/open-finance/pagamento/erro')
      }

      saveData({ ...contextData, error: GENERIC_ERROR })
      push('/open-finance/pagamento/erro')
    }
  }

  const handleRejectConsent = async () => {
    try {
      const { data } = await approvePaymentConsent(
        {
          action: 'reject',
          consentId: consent?.id as string,
          accountId: chosenAccount.id
        },
        idempotency
      )()

      saveData({ ...contextData, redirect: data as ConsentRedirectProps })
      push('/open-finance/v2/pagamento/redirecionar')
    } catch (error) {
      const err = error as AxiosError
      const response = err?.response

      if (response?.status === 404) {
        saveData({ ...contextData, error: PAYMENT_NOT_FOUND })
        push('/open-finance/v2/pagamento/erro')
      }

      if (response?.status === 422 && response?.data?.type === 'srn:error:invalid_status') {
        saveData({ ...contextData, error: PAYMENT_INVALID })
        push('/open-finance/v2/pagamento/erro')
      }

      if (response?.status === 422 && response?.data?.type === 'srn:error:expired_datetime') {
        saveData({ ...contextData, error: PAYMENT_EXPIRED })
        push('/open-finance/v2/pagamento/erro')
      }

      saveData({ ...contextData, error: GENERIC_ERROR })
      push('/open-finance/v2/pagamento/erro')
    }
  }

  const handleBackNavigation = async () => {
    openFinancePaymentIniciationFlag
      ? push('/open-finance/v2/pagamento/consentimento')
      : push('/open-finance/pagamento/consentimento')
  }

  return (
    <Box p="0 1rem 1rem 1rem" maxWidth="48rem">
      <Stack space="1.5rem">
        <Text pb="0.25rem" fontWeight="bold" fontSize="large">
          Você quer cancelar sua transação?
        </Text>

        <Text mt={0} fontSize="small" color="mediumGray">
          Ao cancelar, você será redirecionado e seu pagamento cancelado.
        </Text>

        <Divider />

        <Grid gutter="1rem" min="20ch">
          <Button
            variant="filled"
            color="primary"
            size="large"
            onClick={openFinancePaymentIniciationFlag ? handleRejectConsent : handleCancelConsent}
          >
            Cancelar transação
          </Button>
          <Button variant="filled" color="neutral" size="large" onClick={handleBackNavigation}>
            Voltar
          </Button>
        </Grid>
      </Stack>
    </Box>
  )
}
