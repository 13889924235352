import { Stack } from '@dlpco/fluid-layout'
import { Icon } from '@dlpco/ginga-stone'

import { StatisticsItem } from '~/ui/components/cards/statistics-item'
import { useRequest } from '~/ui/hooks/utils/service/use-request'

export const Sidebar = ({ accountId }: { accountId: string }) => {
  const url = `/accounts/${accountId}/balance`

  const { data }: any = useRequest<{ scheduledBalance: number }>({
    url
  })

  const scheduledBalance = data?.scheduledBalance ? Math.abs(data.scheduledBalance) : 0

  return (
    <Stack space="2rem">
      <StatisticsItem
        moneyAmount={scheduledBalance}
        icon={<Icon use="export-outline" color="negative" />}
        title="Saídas agendadas"
        subtitle="Próximos 12 meses"
      />
    </Stack>
  )
}
