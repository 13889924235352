import { type PropsWithChildren } from 'react'
import Image from 'next/image'
import NextLink from 'next/link'
import { Icon } from '@dlpco/ginga-stone'
import Logo from 'public/logo-stone.svg'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

import { Flex, Link } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

interface CustomSidebarProps {
  toggleOpen: () => void
  open: boolean
}

const WrapperDrawer = styled.button<{ minimal: boolean }>`
  display: ${ifProp('minimal', 'block', 'none')};
  margin-right: 1rem;
  cursor: pointer;
  background-color: inherit;
  border-width: 0;
`

export function CustomSidebar(props: PropsWithChildren<CustomSidebarProps>) {
  const { open, toggleOpen } = props
  const query = BlackBird.getQuery()
  const { isViewMedium } = useViewSize()

  return (
    <Flex alignItems="center" data-cy="custom-navbar">
      <Choose>
        <Choose.When condition={open}>
          <WrapperDrawer onClick={toggleOpen} minimal={isViewMedium}>
            <Icon use="close-outline" size="large" />
          </WrapperDrawer>
        </Choose.When>
        <Choose.Otherwise>
          <WrapperDrawer onClick={toggleOpen} minimal={isViewMedium}>
            <Icon use="menu-web-outline" size="large" />
          </WrapperDrawer>
        </Choose.Otherwise>
      </Choose>
      <NextLink href={{ pathname: '/home', query }} passHref legacyBehavior>
        <Link>
          <Image src={Logo} />
        </Link>
      </NextLink>
    </Flex>
  )
}
