import { useRequest } from '~/ui/hooks/utils/service/use-request'

export interface AsyncRendererProps {
  url?: string
  render: (props: any) => JSX.Element
  transformer?: (data: any) => any
  fetcherHook?: (args: any) => any
  fetcherHookProps?: Record<string, any>
  fetcherHookConfig?: Record<string, any>
  loader: (props: any) => JSX.Element
  dataPath?: string
}

/** @deprecated This component it's not used anymore. */
export const AsyncRenderer = (props: AsyncRendererProps) => {
  const {
    url,
    render,
    transformer = x => x,
    fetcherHook = useRequest,
    fetcherHookProps,
    fetcherHookConfig,
    dataPath = 'data',
    loader
  } = props
  const response = fetcherHook({ url, ...fetcherHookProps }, { ...fetcherHookConfig })

  if (response.data) {
    const transformedResponse = { ...response, [dataPath]: transformer(response[dataPath]) }
    return <>{render(transformedResponse)}</>
  }

  return <>{loader}</>
}
