import { InvestmentBalanceCard } from '~/domains/investment/ports/investment-balance-card'
import { Box } from '~/domains/platform/design-system'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/ports'
import { If } from '~/domains/platform/lib/utilities-components'

import { Balance } from './balance'
import { Wrapper } from './styles'

export interface BalanceCardProps {
  accountId: PaymentAccount['id']
  showInvestmentBalance?: boolean
  showBalance?: boolean
}

export function BalanceCard({ accountId, showInvestmentBalance = false, showBalance }: BalanceCardProps) {
  return (
    <Wrapper aria-live="polite" data-testid="button" aria-expanded={showBalance}>
      <Balance accountId={accountId} />

      <If condition={showInvestmentBalance}>
        <Box pt="1rem">
          <InvestmentBalanceCard accountId={accountId} />
        </Box>
      </If>
    </Wrapper>
  )
}
