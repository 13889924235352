import { Icon } from '@dlpco/ginga-stone'

import { type Subject } from '~/domains/platform/infra/deus-ex-machina/ports'
import { analytics } from '~/domains/platform/lib/analytics'
import { type MenuProps, Menu } from '~/ui/components/layouts/menu/menu'

export interface DropdownMenuProps {
  subject: Subject
  items: MenuProps['items']
}

const fixedItems = [
  {
    href: '/stone-account/logout',
    label: 'Sair',
    icon: <Icon use="logout-outline" size="large" />,
    as: '/sair',
    iconFirst: true,
    onClick: () => {
      analytics.sendEvent('home web - dropdown avatar clicked', {
        'avatar-dropdown': 'sair'
      })
    }
  }
]

export function DropdownMenu({ subject, items }: DropdownMenuProps) {
  const menuItems = items ? [...items, ...fixedItems] : fixedItems
  return <Menu subject={subject} items={menuItems} />
}
