import axios, { type AxiosTransformer } from 'axios'

import { httpClientFactory } from '~/domains/platform/infra/http/http-client-factory'
import { keysToSnakecase, transformResponse } from '~/domains/platform/infra/http/http-helpers'
import { responseUnauthorizedAccessInterceptor } from '~/domains/platform/infra/http/interceptors/unauthorized-access-interceptor'
import { BANKING_API_ENDPOINT } from '~/lib/constants'

export const httpClientStoneAccount = httpClientFactory({
  baseURL: BANKING_API_ENDPOINT(),
  transformRequest: [keysToSnakecase, ...(axios.defaults.transformRequest as AxiosTransformer[])],
  transformResponse: [...(axios.defaults.transformResponse as AxiosTransformer[]), transformResponse],
  responseErrorInterceptors: [responseUnauthorizedAccessInterceptor]
})
