import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Box, Text } from '~/domains/platform/design-system'

export const Title = styled(Text)`
  font-weight: ${theme('fontWeights.body')};
  font-size: ${theme('fontSizes.xLarge')};
  color: ${theme('colors.darkGray')};
`

export const AccountDetailTitle = styled(Title)`
  font-size: ${theme('fontSizes.large')};
`

export const EmphasizeTitle = styled(Title)`
  font-weight: ${theme('fontWeights.heading')};
`

export const SubTitle = styled(Text)`
  font-size: ${theme('fontSizes.medium')};
  color: ${theme('colors.mediumGray2')};
`

export const StyledText = styled(Text)`
  font-size: ${theme('fontSizes.medium')};
`

export const BoxIllustration = styled(Box)`
  svg,
  img {
    height: auto;
    width: 70%;
    @media (max-width: 60rem) {
      height: auto;
      width: 100%;
    }
  }
`
