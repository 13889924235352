import { analytics } from '~/domains/platform/lib/analytics'

import { type AllowedBrands, type FilterPeriod } from '../entities/filter-options'

type AvailableFormats = 'pdf' | 'csv' | 'xlsx'
type copiedData = 'Stone ID' | 'Código de autorização'
type AvailableFiltersTranslaed = 'Menu' | 'Período'

const reportTypeValues = {
  sales: 'Lista de vendas',
  consolidated: 'Resumo de vendas'
}

const cancellaltionFormActions = {
  default_back: 'Voltar (seta)',
  cancel_sale: 'Cancelar venda',
  button_back: 'Voltar'
}

const cancellationChallengeActions = {
  lost_access: 'Perdi acesso a esse e-mail',
  resend: 'Reenviar',
  confirm: 'Confirmar'
}

const cancellationConfirmationActions = {
  close: 'Fechar',
  back: 'Voltar pra venda'
}

const cancellationFailedActions = {
  help: 'Falar com a Stone',
  back: 'Voltar pro inicio'
}

const saleType = {
  Card: 'Cartão',
  Pix: 'Pix',
  PaymentSlip: 'Boleto',
  PaymentSlipLegacy: 'Boleto legado'
}

const filtersActions = {
  remove_filters: 'Remover filtros',
  filter: 'Filtrar'
}

const preFixedPeriodFilter = {
  today: 'Hoje',
  yesterday: 'Ontem',
  last_7: '7 dias',
  last_15: '15 dias',
  last_30: '30 dias',
  this_month: 'Esse mẽs',
  last_month: 'Mês passado',
  last_three_months: 'Últimos 3 meses',
  last_six_months: 'Últimos 6 meses',
  last_year: 'Últimos 12 meses',
  custom: 'Personalizado',
  initial_hour: 'Hora inicial',
  final_hour: 'Hora final'
}

const prefixedPaymentMethods = {
  credit: 'Crédito',
  debit: 'Débito',
  voucher: 'Voucher',
  pix: 'pix',
  paymentSlip: 'Boleto'
}

const prefixedTerminalType = {
  Maquininha: 'Maquininha',
  TEF: 'TEF',
  'E-commerce': 'E-commerce'
}

const periodFilterActionsOptions = {
  ...preFixedPeriodFilter,
  ...filtersActions
}

const paymentMethodsActionsOptions = {
  ...prefixedPaymentMethods,
  ...filtersActions
}

const modalFilterActions = {
  ...prefixedPaymentMethods,
  ...preFixedPeriodFilter,
  ...filtersActions,
  ...prefixedTerminalType,

  lastFourDigits: 'Últimos 4 dígitos do cartão',
  installment: 'Número de parcelas',
  back: 'Voltar'
}

const pixModalDetailActions = {
  statement: 'Ir pra extrato',
  close: 'Fechar'
}

export const salesAnalyticsEvents = {
  homeMenu: () => {
    analytics.sendEvent('home - sales selected')
  },
  removeAllFiler: () => {
    analytics.sendEvent('sales - remove filter selected')
  },
  simulator: () => {
    analytics.sendEvent('sales - simulate sales selected')
  },
  report: () => {
    analytics.sendEvent('sales - report selected')
  },
  reportDownload: (reportType: keyof typeof reportTypeValues, format: AvailableFormats) => {
    analytics.sendEvent('sales - report downloaded', {
      propertyName: 'type screen',
      sampleValues: `${reportTypeValues[reportType]} ${format}`
    })
  },
  goToFaq: () => {
    analytics.sendEvent('sales - transaction failed')
  },
  goToSaleDetail: (saleTypeSelected: keyof typeof saleType) => {
    analytics.sendEvent('sales - list sales details selected', {
      propertyName: 'sale type',
      sampleValues: saleType[saleTypeSelected]
    })
  },
  cancellation: () => {
    analytics.sendEvent('sales - cancel transaction selected')
  },
  cancellationReceipt: () => {
    analytics.sendEvent('sales - cancellation receipt selected')
  },
  copiedSaleCode: (copiedDataTitle: copiedData) => {
    analytics.sendEvent('sales - copy codes selected', {
      propertyName: 'type screen',
      sampleValues: copiedDataTitle
    })
  },
  cancellationForm: (action: keyof typeof cancellaltionFormActions) => {
    analytics.sendEvent('sales - cancel sales requested', {
      propertyName: 'button chosen',
      sampleValues: cancellaltionFormActions[action]
    })
  },
  cancellationChallenge: (action: keyof typeof cancellationChallengeActions) => {
    analytics.sendEvent('sales - cancel sales code', {
      propertyName: 'button chosen',
      sampleValues: cancellationChallengeActions[action]
    })
  },
  successCancellation: (action: keyof typeof cancellationConfirmationActions) => {
    analytics.sendEvent('sales - cancel sales confirmed', {
      propertyName: 'button chosen',
      sampleValues: cancellationConfirmationActions[action]
    })
  },
  failedCancellation: (action: keyof typeof cancellationFailedActions) => {
    analytics.sendEvent('sales - cancel sales error', {
      propertyName: 'button chosen',
      sampleValues: cancellationFailedActions[action]
    })
  },
  separetedFiltersSelection: (filter: AvailableFiltersTranslaed) => {
    analytics.sendEvent('sales - list sales filter selected', {
      propertyName: 'button chosen',
      sampleValues: filter
    })
  },
  filterForm: (selection: FilterPeriod | keyof typeof periodFilterActionsOptions) => {
    analytics.sendEvent('sales - period filter selected', {
      propertyName: 'button chosen',
      sampleValues: periodFilterActionsOptions[selection]
    })
  },
  paymentMethod: (selection: keyof typeof prefixedPaymentMethods | keyof typeof paymentMethodsActionsOptions) => {
    analytics.sendEvent('sales - funding source filter selected', {
      propertyName: 'button chosen',
      sampleValues: paymentMethodsActionsOptions[selection]
    })
  },
  lastFourDigits: (selection: keyof typeof filtersActions) => {
    analytics.sendEvent('sales - number card filter selected', {
      propertyName: 'button chosen',
      sampleValues: filtersActions[selection]
    })
  },
  modalFilterSelection: (
    selection: keyof typeof modalFilterActions | keyof typeof prefixedPaymentMethods | FilterPeriod
  ) => {
    analytics.sendEvent('sales - filter menu selected', {
      propertyName: 'button chosen',
      sampleValues: modalFilterActions[selection]
    })
  },
  brandSelection: (selection: AllowedBrands) => {
    analytics.sendEvent('sales - filter menu selected', {
      propertyName: 'button chosen',
      sampleValues: selection
    })
  },
  installmentFilter: (selection: keyof typeof filtersActions) => {
    analytics.sendEvent('sales - installments filter selected', {
      propertyName: 'button chosen',
      sampleValues: filtersActions[selection]
    })
  },
  installmentFilterWithDefinedValue: (installment: number) => {
    analytics.sendEvent('sales - installments filter selected', {
      propertyName: 'button chosen',
      sampleValues: `Parcela ${installment}x selecionada.`
    })
  },
  pixBannerClose: () => {
    analytics.sendEvent('sales - pix integrated tooltip selected')
  },
  situationTooltipView: () => {
    analytics.sendEvent('sales - list sales status selected')
  },
  pixDetailModalAction: (action: keyof typeof pixModalDetailActions) => {
    analytics.sendEvent('sales - details sales modal pix selected', {
      propertyName: 'type screen',
      sampleValues: pixModalDetailActions[action]
    })
  }
}
