import styled from 'styled-components'
import { type ColorProps, type LayoutProps, color, layout } from 'styled-system'
import { type Merge } from 'type-fest'

export const Divider = styled.div<Merge<ColorProps, LayoutProps>>`
  ${layout}
  ${color}
  width: 100%;
  height: 1px;
`

Divider.defaultProps = {
  backgroundColor: 'lightGray'
}
