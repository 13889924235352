import { type PropsWithChildren, type ReactNode } from 'react'

import { type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import { DropdownMenu } from '~/ui/business-components/shared/dropdown-menu'
import { TopBarLayout } from '~/ui/components/layouts/top-bar-layout'

export interface TopBarOnlyContainerProps {
  subject: Subject
  children?: ReactNode
}

export function TopBarOnlyContainer(props: PropsWithChildren<TopBarOnlyContainerProps>) {
  const { subject, children } = props

  return <TopBarLayout topBarContent={<DropdownMenu subject={subject} items={[]} />}>{children}</TopBarLayout>
}
