import { useState } from 'react'
import { useRouter } from 'next/router'
import { type AxiosError } from 'axios'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { type AnswerChallenge } from '~/domains/platform/infra/http/http-with-challenge'
import { uuid } from '~/domains/platform/lib/crypto'
import { trackEvents } from '~/lib/helpers/utils/ports/analytics'
import { paymentConsent } from '~/lib/services'
import { approvePaymentConsent } from '~/lib/services/open-finance/approve-payment'
import { ChallengeForm } from '~/ui/business-components/shared/challenge-form'
import { useOpenFinanceContext } from '~/ui/hooks/open-finance'

import { PAYMENT_EXPIRED, PAYMENT_INVALID, PAYMENT_NOT_FOUND } from '../../helpers'

export function ConsentChallenge() {
  const { data: contextData, saveData } = useOpenFinanceContext()
  const { push } = useRouter()
  const { consent } = contextData as Record<string, any>
  const [idempotency] = useState(uuid())

  const openFinancePaymentIniciationFlagV2 = Heimdall.pass(['open_finance_payment_initiation_web_v2'])
  const chosenAccount = contextData.chosenAccount || {}

  const handleOnRequestPaymentConsent = async (payload?: AnswerChallenge) => {
    return paymentConsent(
      {
        action: 'authorize',
        consentId: consent?.id,
        accountId: consent?.accountId
      },
      idempotency
    )(payload)
  }

  const handleOnRequestApproveConsent = async (payload?: AnswerChallenge) => {
    return approvePaymentConsent(
      {
        action: 'authorize',
        consentId: consent?.id,
        accountId: chosenAccount.id
      },
      idempotency
    )(payload)
  }

  const handleOnSuccess = async (response: any) => {
    const { data } = response
    saveData({ ...contextData, redirect: { ...data } })
    Heimdall.pass(['open_finance_payment_initiation_web_v2'])
      ? push('/open-finance/v2/pagamento/redirecionar')
      : push('/open-finance/pagamento/redirecionar')
  }

  const handleOnError = async (error: AxiosError) => {
    const response = error?.response

    if (response?.status === 404) {
      saveData({ ...contextData, error: PAYMENT_NOT_FOUND })
      Heimdall.pass(['open_finance_payment_initiation_web_v2'])
        ? push('/open-finance/v2/pagamento/erro')
        : push('/open-finance/pagamento/erro')

      trackEvents({
        trackServices: ['recording', 'analytics'],
        action: 'click',
        category: 'open-finance',
        label: 'Pagamento via Open Finance não encontrado'
      })
    }

    if (response?.status === 422 && response?.data?.type === 'srn:error:invalid_status') {
      saveData({ ...contextData, error: PAYMENT_INVALID })
      Heimdall.pass(['open_finance_payment_initiation_web_v2'])
        ? push('/open-finance/v2/pagamento/erro')
        : push('/open-finance/pagamento/erro')

      trackEvents({
        trackServices: ['recording', 'analytics'],
        action: 'click',
        category: 'open-finance',
        label: 'Pagamento via Open Finance inválido'
      })
    }

    if (response?.status === 422 && response?.data?.type === 'srn:error:expired_datetime') {
      saveData({ ...contextData, error: PAYMENT_EXPIRED })
      Heimdall.pass(['open_finance_payment_initiation_web_v2'])
        ? push('/open-finance/v2/pagamento/erro')
        : push('/open-finance/pagamento/erro')

      trackEvents({
        trackServices: ['recording', 'analytics'],
        action: 'click',
        category: 'open-finance',
        label: 'Pagamento via Open Finance expirado'
      })
    }
  }

  return (
    <ChallengeForm
      onRequest={openFinancePaymentIniciationFlagV2 ? handleOnRequestApproveConsent : handleOnRequestPaymentConsent}
      onSuccess={handleOnSuccess}
      onError={handleOnError}
    />
  )
}
