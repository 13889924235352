import axios, { type AxiosTransformer } from 'axios'

import { BANKING_API_ENDPOINT } from '~/lib/constants'

import { responseUnauthorizedAccessInterceptor } from './interceptors/unauthorized-access-interceptor'
import { type CustomAxiosRequestConfig, httpClientFactory } from './http-client-factory'
import { keysToSnakecase, responseSuccessInterceptor, transformResponse } from './http-helpers'

export const httpClientBackend = httpClientFactory({
  baseURL: BANKING_API_ENDPOINT(),
  responseSuccessInterceptor,
  requestInterceptor: passAlongHeaders,
  transformRequest: [keysToSnakecase, ...(axios.defaults.transformRequest as AxiosTransformer[])],
  transformResponse: [...(axios.defaults.transformResponse as AxiosTransformer[]), transformResponse],
  responseErrorInterceptors: [responseUnauthorizedAccessInterceptor]
})

// TODO: Delete this
function passAlongHeaders(config: CustomAxiosRequestConfig) {
  return {
    ...config,
    headers: {
      ...config.headers,
      'device-generated-id': config.ctx?.req?.headers['device-generated-id'],
      'platform-id': config.ctx?.req?.headers['platform-id']
    }
  }
}
