import styled from 'styled-components'

export const ContentWrapper = styled.div<{ gridTemplateColumns: string; gap?: string; alignItems?: string }>`
  align-items: ${props => props.gap ?? 'center'};
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  gap: ${props => props.gap ?? '0.9rem'};
`

export const BadgeWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-left: 1.5rem;
`
export const ChipWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: 0.5rem;
`
