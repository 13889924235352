import { type OptIns } from '../types'

export class OptInReferenceError extends ReferenceError {
  constructor(prop: string | symbol, optIn: OptIns) {
    super(
      `You must opt-in to ${optIn} to access ${String(
        prop
      )}. Eg: withPageConfig(Page, { exMachinaOptIns: ['${optIn}'] })`
    )
  }
}

export const forbiddenAccessHandler = <T extends Record<string | symbol, unknown>>(
  target: T,
  requiresMap: Partial<Record<keyof T, OptIns>>,
  optIns: OptIns[]
) => {
  return new Proxy(target, {
    get: (target, prop, receiver) => {
      if (Object.keys(requiresMap).includes(String(prop)) && !optIns.includes(requiresMap[prop] as OptIns)) {
        throw new OptInReferenceError(prop, requiresMap[prop] as OptIns)
      }

      return Reflect.get(target, prop, receiver)
    }
  })
}
