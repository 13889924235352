import { Stack } from '@dlpco/fluid-layout'

import { Text } from '~/domains/platform/design-system'
import { Field } from '~/domains/platform/design-system/field/field'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/types'
import { If } from '~/domains/platform/lib/utilities-components'
import { dynamicRenderTemplate, stringFormat } from '~/lib/helpers'
import { AccountDetails } from '~/ui/business-components/banking/account-details'
import { DateField } from '~/ui/components/fields/date-field'
import { Document } from '~/ui/components/fields/document'
import { useWizardProps } from '~/ui/components/modals/wizard/use-wizard-props'

export interface BoletoDetailsProps {
  account: PaymentAccount
}

export function BoletoDetails(props: { account: PaymentAccount }) {
  const {
    account: { ownerName, ownerDocument, id }
  } = props

  const { content } = useWizardProps()
  const { withBarcodeMask, currency } = stringFormat

  if (content) {
    const {
      writableLine,
      expirationDate,
      feeMetadata: { fee, remainingFree }
    } = content
    const feeAmount = fee > 0 ? currency(fee) : 'Grátis'

    return (
      <Stack space="3rem">
        <AccountDetails ownerDocument={ownerDocument} ownerName={ownerName} id={id} />
        <Stack space="1rem">
          <Text fontSize="large">Detalhes do boleto</Text>
          <Document label="Código do boleto" value={withBarcodeMask(writableLine)} />
          <DateField label="Vencimento" value={expirationDate} />
          <Field label="Tarifa quando o boleto for pago" value={feeAmount} />
          <If
            condition={Boolean(remainingFree && remainingFree > 0)}
            render={() => {
              const remainingFreeText = dynamicRenderTemplate(
                `Você ainda tem ${remainingFree} boleto(s) grátis este mês.`,
                {
                  remainingFree: (
                    <Text fontWeight="bold" as="span">
                      {remainingFree}
                    </Text>
                  )
                }
              )
              return <Field value={remainingFreeText} />
            }}
          />
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack space="1rem">
      <Skeleton width={100} height={20} />
      <Skeleton width={100} height={20} />
      <Skeleton width={100} height={20} />
    </Stack>
  )
}
