import { NightOwl } from '~/domains/platform/core/night-owl/night-owl'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'
import { analytics } from '~/domains/platform/lib/analytics'
import { publish } from '~/domains/platform/lib/pub-sub'

import { type KYC } from '../../../stone-account/ports/entities/KYC'

function handleMultiuserNavigation(isIdentityOwner: boolean, identityKycStatus: KYC, isABFreemium: boolean) {
  if (isABFreemium) {
    if (identityKycStatus === 'analysis') return '/conta-em-analise'

    return '/home'
  }

  if (isIdentityOwner) return '/multiusuarios/equipe'

  return '/multiusuarios/equipe/acessos-recebidos'
}

export async function stoneAccountMenu(entity: Entity) {
  const hasBankingVision = entity.roles.banking
  const isFreemium = entity.kycStatus !== 'approved'

  const isABFreemium = hasBankingVision && isFreemium

  const identityKycStatus = entity.kycStatus

  const isIdentityOwner = NightOwl.verifyIdentityOwnership()

  return [
    {
      order: 0,
      label: 'Integrações',
      icon: 'handshake-outline',
      show: false,
      onClick: () => {
        analytics.sendEvent('home web - lateral menu button chosen', { lateralmenu: 'integrações' })
      },
      path: '/integracoes',
      active: (route: string) => ['/settings/consent-integrations'].includes(route)
    },
    {
      order: 16,
      label: 'Equipe',
      icon: 'team-outline',
      onClick: () => {
        if (isABFreemium && identityKycStatus !== 'analysis') publish('show:team:modal')

        analytics.sendEvent('home web - lateral menu button chosen', { lateralmenu: 'equipe' })
      },
      path: handleMultiuserNavigation(isIdentityOwner, identityKycStatus, isABFreemium),
      active: (route: string) =>
        ['/stone-account/multiuser/team-member-details', '/stone-account/multiuser/team'].includes(route)
    }
  ]
}
