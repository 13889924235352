import { httpClientCustomer } from '~/lib/services/acquirer/http-client-customer/http-client-customer'
import { type TerminalDeviceResponse } from '~/lib/types/acquirer/Terminals'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

interface UseTerminalDevicesConfig extends UseQueryRequestOptions<TerminalDeviceResponse, unknown> {
  pageNumber?: number
  pageSize?: number
}

export const fetchTerminalDevices = ({
  document,
  pageNumber,
  pageSize,
  statusId
}: {
  document: string
  pageNumber?: number
  pageSize?: number
  statusId?: number
}) => {
  return httpClientCustomer<TerminalDeviceResponse>({
    url: `/organizations/${document}/terminal-devices`,
    params: {
      statusId,
      pageNumber,
      pageSize
    }
  })
}

export function useTerminalDevices(
  document: string,
  { pageNumber, pageSize }: UseTerminalDevicesConfig = {},
  queryConfig?: UseQueryRequestOptions<TerminalDeviceResponse>
) {
  const { data, ...restQuery } = useQueryRequest<TerminalDeviceResponse>(
    ['useTerminalDevices', document, pageNumber, pageSize],
    () => fetchTerminalDevices({ document, pageNumber, pageSize, statusId: 1 }),
    {
      enabled: Boolean(document),
      ...queryConfig
    }
  )

  return {
    terminalDevices: data?.data.data ?? [],
    totalDevices: data?.data.total,
    totalPages: data?.data.totalPages,
    ...restQuery
  }
}
