export type RejectReason =
  | 'INSUFFICIENT_FUNDS'
  | 'EXPIRED_BEFORE_AUTHORISED'
  | 'EXPIRED_BEFORE_CONSUMED'
  | 'GENERIC_MESSAGE'
  | 'UNKNOWN'
  | 'IN_PROGRESS'
  | 'DATA_PGTO_INVALIDA'
  | 'USER_CONTA_INEXISTENTE'
  | 'FORMA_PGTO_INVALIDA'
  | 'CANCELED'
  | 'FAILED'

export interface RejectReasonCompose {
  type: 'info' | 'warning' | 'negative' | 'positive' | 'help'
  title: string
  subTitle: string
}

const GENERIC_MESSAGE_COMPOSE: RejectReasonCompose = {
  type: 'negative',
  title: 'Transação não concluída',
  subTitle:
    'Algo inesperado aconteceu e a transação não foi concluída. Tente novamente no aplicativo onde deu início a sua operação.'
}

export const TRANSLATE_REJECTION_REASON_MAP: Record<RejectReason, RejectReasonCompose> = {
  INSUFFICIENT_FUNDS: {
    type: 'negative',
    title: 'Saldo insuficiente',
    subTitle: 'O saldo disponível da conta é menor que o valor da transação.'
  },
  EXPIRED_BEFORE_AUTHORISED: {
    type: 'warning',
    title: 'Transação expirada',
    subTitle: 'O prazo para a confirmação ou cancelamento da transação ultrapassou os 5 minutos (prazo máximo).'
  },
  EXPIRED_BEFORE_CONSUMED: {
    type: 'warning',
    title: 'Tempo de processamento expirado',
    subTitle: 'A instituição responsável não processou a transação dentro do tempo hábil.'
  },
  GENERIC_MESSAGE: GENERIC_MESSAGE_COMPOSE,
  UNKNOWN: GENERIC_MESSAGE_COMPOSE,
  DATA_PGTO_INVALIDA: GENERIC_MESSAGE_COMPOSE,
  USER_CONTA_INEXISTENTE: GENERIC_MESSAGE_COMPOSE,
  FORMA_PGTO_INVALIDA: GENERIC_MESSAGE_COMPOSE,
  FAILED: GENERIC_MESSAGE_COMPOSE,
  IN_PROGRESS: {
    type: 'info',
    title: 'Sua transação está sendo processada',
    subTitle: 'Aguarde alguns minutos até que sua transação seja processada.'
  },
  CANCELED: {
    type: 'negative',
    title: 'Transação não concluída',
    subTitle: 'Agendamento de pagamento cancelado pelo usuário.'
  }
}

export const rejectionReasonCompose = new Proxy(TRANSLATE_REJECTION_REASON_MAP, {
  get(target: typeof TRANSLATE_REJECTION_REASON_MAP, key: keyof typeof TRANSLATE_REJECTION_REASON_MAP) {
    return key in target ? target[key] : GENERIC_MESSAGE_COMPOSE
  }
})
