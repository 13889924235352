import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Text } from '~/domains/platform/design-system'

export const ListCard = styled.div`
  border-top: 2px solid ${theme('colors.mediumGray4')};

  > * {
    padding: 1rem 2rem;

    :not(:last-child) {
      border-bottom: 1px solid ${theme('colors.mediumGray4')};
    }
  }
`

export const ListHeader = styled.div`
  grid-template-columns: 0.8fr 1.2fr 1.5fr 1fr 1fr 0.9fr;
  display: grid;
  align-items: center;
  grid-gap: 0.5rem;
  background: ${theme('colors.mediumGray4')};
`

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1.2fr 1.5fr 1fr 1fr 0.9fr;
  grid-gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${theme('colors.lightGray')};
  }
`

export const WrapperText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const WrapperTextIcon = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: auto 1fr 1fr;
`

export const WrapperIcon = styled.div`
  text-align: center;
`

export const WrapperDate = styled(WrapperText)`
  white-space: break-spaces;
`
