import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Link } from '~/domains/platform/design-system'
import { Field } from '~/domains/platform/design-system/field/field'

export const FieldWrapper = styled(Field)`
  label {
    font-weight: bold;
  }
`

export const LinkWrapper = styled(Link)`
  font-weight: ${theme('fontWeights.bold')};
  text-decoration: none;
`

export const List = styled.ul`
  li {
    font-weight: ${theme('fontWeights.bold')};
  }
`
