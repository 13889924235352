import { Cluster } from '@dlpco/fluid-layout'
import { CopyButton } from '@dlpco/ginga-stone'

import { Box, Text } from '~/domains/platform/design-system'
import { Field } from '~/domains/platform/design-system/field/field'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/ports'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { Document } from '~/ui/components/fields/document'

interface ContentFactory extends Pick<PaymentAccount, 'ownerName' | 'ownerDocument' | 'accountCode'> {
  agency: string
  bankCode: string
  bank: string
}

const copyToClipboardContentFactory = ({
  ownerName,
  ownerDocument,
  bank,
  bankCode,
  agency,
  accountCode
}: ContentFactory) => `
  ${'Nome'}: ${ownerName}
  ${'Documento'}: ${ownerDocument}
  ${bank}: ${bankCode}
  ${'Agência'}: ${agency}
  ${'Conta'}: ${accountCode}
`

export function AccountInfo(props: { account: PaymentAccount; inline?: boolean }) {
  const {
    account: { accountCode, ownerName, ownerDocument },
    inline
  } = props
  const { accountNumber } = stringFormat
  const normalizedAccountCode = accountNumber(accountCode)

  const accountInfo = copyToClipboardContentFactory({
    ownerName,
    ownerDocument,
    bank: '197',
    bankCode: 'Stone Pagamentos S.A.',
    agency: '0001',
    accountCode: normalizedAccountCode
  })

  if (inline)
    return (
      <>
        <Box paddingBottom="8px">
          <Text fontSize="medium" fontWeight="bold">
            {ownerName}
          </Text>

          <Text color="mediumGray2">{stringFormat.document(ownerDocument)}</Text>
        </Box>
        <Cluster space="1rem" align="center">
          <Box>
            <Text color="mediumGray">
              {'Conta'}:{' '}
              <Text as="span" color="darkGray2">
                {normalizedAccountCode}
              </Text>
            </Text>
            <Text color="mediumGray">
              {'Agência'}:{' '}
              <Text as="span" color="darkGray2">
                0001
              </Text>
            </Text>
            <Text color="mediumGray">
              {'Instituição'}:{' '}
              <Text as="span" color="darkGray2">
                197
              </Text>
            </Text>
            <CopyButton color="neutral" copiedValue={accountInfo}>
              Copiar
            </CopyButton>
          </Box>
        </Cluster>
      </>
    )

  return (
    <>
      <Document label="Documento" value={ownerDocument} />
      <Field label="Instituição" value={`${'197'} - ${'Stone Pagamentos S.A.'}`} />
      <Field label={'Agência'} value="0001" />
      <Field label="Conta" value={normalizedAccountCode} />
      <Box mt={2}>
        <CopyButton color="neutral" copiedValue={accountInfo}>
          Copiar dados da conta
        </CopyButton>
      </Box>
    </>
  )
}
