import { forwardRef } from 'react'
import { useToggle } from 'react-use'
import { Icon } from '@dlpco/ginga-stone'
import { type InputFieldProps, InputField } from '@stone-payments/jade'

import { Input } from '~/domains/platform/design-system/input/input'
import { type InputProps } from '~/domains/platform/design-system/input/interface'

export const JadePasswordInput = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const [isVisible, toggle] = useToggle(false)

  return (
    <InputField
      {...props}
      type={isVisible ? 'text' : 'password'}
      trailingItem={{ type: 'icon', content: isVisible ? 'eye-open' : 'eye-close' }}
      onTrailingItemClick={toggle}
      ref={ref}
    />
  )
})

/**
 * @deprecated The component uses Ginga Input so it shouldn't be used, you should use the new JadePasswordInput.
 * @see [InputField Jade DS](https://musical-adventure-r4kzg5j.pages.github.io/docs/?path=/docs/form-inputfield--docs)
 */
export const PasswordInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [isVisible, toggle] = useToggle(false)

  return (
    <Input
      {...props}
      endAdornment={
        isVisible ? (
          <Icon use="eye-open-outline" style={{ margin: 'auto' }} />
        ) : (
          <Icon use="eye-close-outline" style={{ margin: 'auto' }} />
        )
      }
      onAdornmentClick={toggle}
      type={isVisible ? 'text' : 'password'}
      ref={ref}
    />
  )
})
