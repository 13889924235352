import { type TimeMachine } from '../time-machine'
import { type TmPlugin } from '../types'

export interface FriendlyMethods {
  duration: (dateToCompare: Date | TimeMachine) => string
  fromNow: () => string
}

export const friendlyTMPlugin: TmPlugin<FriendlyMethods> = instance => {
  const proto = instance.prototype

  proto.duration = function (dateToCompare) {
    return this['$dateEngine'].to(new Date(dateToCompare.valueOf())).replace(/\um(a)?/i, '1')
  }

  proto.fromNow = function () {
    return this['$dateEngine'].fromNow()
  }
}
