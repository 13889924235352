import { Text } from '@dlpco/ginga-stone'

import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { For } from '~/domains/platform/lib/utilities-components'

import { ItemGrid, ListCard, ListHeader } from './styles'

const items = Array(10).fill(null)

export function SalesListLoader() {
  return (
    <ListCard>
      <ListHeader>
        <Text weight="bold">Data</Text>
        <Text weight="bold">Status</Text>
        <Text weight="bold">Forma de pagamento</Text>
        <Text weight="bold">Stone ID</Text>
        <Text weight="bold">Número de Série</Text>
        <Text weight="bold">Valor da Venda</Text>
      </ListHeader>
      <For
        of={items}
        render={(_, index) => (
          <ItemGrid key={`item-grid-${index}`}>
            <Skeleton width="100%" height="5ch" />
            <Skeleton width="100%" height="5ch" />
            <Skeleton width="100%" height="5ch" />
            <Skeleton width="100%" height="5ch" />
            <Skeleton width="100%" height="5ch" />
            <Skeleton width="100%" height="5ch" />
          </ItemGrid>
        )}
      />
    </ListCard>
  )
}
