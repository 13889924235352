import axios, { type AxiosTransformer } from 'axios'

import { bffHeadersInterceptor } from '~/domains/credit/shared/interceptors/bff-headers-interceptor'
import { httpClientFactory } from '~/domains/platform/infra/http/http-client-factory'
import { transformResponse } from '~/domains/platform/infra/http/http-helpers'
import { responseUnauthorizedAccessInterceptor } from '~/domains/platform/infra/http/interceptors/unauthorized-access-interceptor'
import { CREDIT_API_ENDPOINT } from '~/lib/constants'

export const httpClientCredit = httpClientFactory({
  baseURL: CREDIT_API_ENDPOINT(),
  transformResponse: [...(axios.defaults.transformResponse as AxiosTransformer[]), transformResponse],
  requestInterceptor: bffHeadersInterceptor,
  responseErrorInterceptors: [responseUnauthorizedAccessInterceptor]
})
