import { type ReactNode } from 'react'
import { Cluster } from '@dlpco/fluid-layout'

import { Box, Flex } from '~/domains/platform/design-system'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { If } from '../../lib/utilities-components'

interface AsideLayoutProps {
  children: ReactNode
  aside?: ReactNode
  scrollAside?: boolean
  nonStickyAside?: boolean
  flex?: number
  header?: ReactNode | boolean
}

export const AsideLayout = ({ children, aside, flex = 3, header = false }: AsideLayoutProps) => {
  const { isViewMedium } = useViewSize()

  return (
    <>
      <If condition={Boolean(header)}>
        <Flex flexDirection="column" gap="1.5rem">
          {header}
        </Flex>
      </If>
      <Cluster showOverflow space={isViewMedium ? '1rem' : '2rem'}>
        <Flex flexDirection={isViewMedium ? 'column-reverse' : 'row'} sx={{ flexWrap: 'nowrap!important' }}>
          <Box flex={isViewMedium ? 'auto' : 6} data-testid="children">
            {children}
          </Box>
          <Box flex={isViewMedium ? 'auto' : flex} data-testid="aside">
            {aside}
          </Box>
        </Flex>
      </Cluster>
    </>
  )
}
