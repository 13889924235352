import { HeimdallManager } from '~/domains/platform/core/heimdall/heimdall'
import { fetchTokenFlags } from '~/domains/platform/core/token-flags/fetch-token-flags'

type SourceIdentifier = 'entity' | 'bff'

export const getExMachinaSource = async (): Promise<SourceIdentifier> => {
  const featureFlags = await fetchTokenFlags()

  const storage = {
    get() {
      return { featureFlags }
    }
  }

  const hasEntityAccounts = new HeimdallManager(storage).pass(['entity_account_list'])

  return hasEntityAccounts ? 'entity' : 'bff'
}
