import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type Resource, type ResourceResponse } from './types'

const fetchResources = ({
  subjectId,
  ownerId,
  resourceId,
  resourceType,
  status,
  isPrincipal
}: Partial<Resource & { isPrincipal: boolean }>) => {
  return httpClientBanking<ResourceResponse<Resource[]>>({
    url: '/resources',
    params: {
      subject_id: subjectId,
      owner_id: ownerId,
      resource_id: resourceId,
      resource_type: resourceType,
      status,
      is_principal: isPrincipal
    }
  })
}

export function useResources({
  subjectId,
  ownerId,
  resourceId,
  resourceType,
  status,
  isPrincipal
}: Partial<Resource & { isPrincipal: boolean }>) {
  const { data: data, ...restQuery } = useQueryRequest<ResourceResponse<Resource[]>>(
    ['useResources', subjectId, ownerId, resourceId, resourceType, status, isPrincipal],
    () => fetchResources({ subjectId, ownerId, resourceId, resourceType, status, isPrincipal })
  )

  return { data: data?.data.data || [], ...restQuery }
}
