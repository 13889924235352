import { useCallback, useEffect, useState } from 'react'
import { Button } from '@dlpco/ginga-stone'

import {
  type SendAuthorizationChallengeCodeResponse,
  sendAuthorizationChallengeCode
} from '~/domains/acquirer/ports/services/send-authorization-challenge-code'
import { Box, Flex, Text } from '~/domains/platform/design-system'
import { DogIllustration } from '~/domains/platform/design-system/illustrations/dog.illustration'
import { SuccessCheckedIllustration } from '~/domains/platform/design-system/illustrations/success-checked.illustration'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { cancelSale } from '~/lib/services'
import { type CancelSaleProps } from '~/lib/services/acquirer/cancel-sale/interface'
import { type CancelSale } from '~/lib/types'
import { Confirmer } from '~/ui/business-components/shared/challenge-form/partials'
import { EmptyState } from '~/ui/components/utils/empty-state/empty-state'
interface CancelTransactionProps {
  transactionId: string
  affiliationCode: string
  email: string
  onBackToSales: () => void
  amountToCancel: number
  challengeCodeService?: () => Promise<SendAuthorizationChallengeCodeResponse>
  cancelService?: (config: CancelSaleProps) => Promise<CancelSale>
}

const INVALID_CODE_MESSAGE = 'Código de confirmação incorreto ou expirado'

enum State {
  LOADING = 'LOADING',
  CANCELING = 'CANCELING',
  SUCCESS = 'SUCCESS',
  GENERIC_ERROR = 'GENERIC_ERROR',
  CONFIRM = 'CONFIRM'
}

export const CancelTransaction = (props: CancelTransactionProps) => {
  const {
    affiliationCode,
    transactionId,
    email,
    amountToCancel,
    onBackToSales,
    challengeCodeService = sendAuthorizationChallengeCode,
    cancelService = cancelSale
  } = props

  const [token, setToken] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [currentState, setCurrentState] = useState<keyof typeof State>('LOADING')

  const getData = useCallback(async () => {
    setCurrentState('LOADING')
    try {
      const { data: tokenData } = await challengeCodeService()
      setToken(tokenData)
      setCurrentState('CONFIRM')
    } catch (error) {
      setCurrentState('GENERIC_ERROR')
      return
    }
  }, [challengeCodeService])

  useEffect(() => {
    getData()
  }, [getData])

  const onSubmit = async (authorizationChallengeCode: string) => {
    if (!token) {
      setCurrentState('GENERIC_ERROR')
      return
    }

    try {
      setCurrentState('CANCELING')

      await cancelService({
        authorizationChallengeCode,
        authorizationChallengeId: token,
        transactionId,
        affiliationCode,
        amountInCents: amountToCancel
      })

      setCurrentState('SUCCESS')
    } catch (error) {
      const [message] = (error as any)?.response?.data?.messages ?? []
      const status = (error as any)?.response?.status
      if (status === 401 && message === INVALID_CODE_MESSAGE) {
        setErrorMessage('Código de verificação inválido ou expirado')
        setCurrentState('CONFIRM')
      } else {
        setCurrentState('GENERIC_ERROR')
      }
    }
  }

  return (
    <Choose>
      <Choose.When condition={currentState === 'CANCELING'}>
        <Flex height="100%" justifyContent="center" alignItems="center">
          <Loader size={80} />
          <Box ml="3rem">
            <Text fontSize="xLarge" fontWeight="bold" width="30ch">
              Estamos agendando o cancelamento da sua transação...
            </Text>
            <Text fontSize="large" color="mediumGray" width="45ch">
              Ela aparecerá como cancelada nas primeiras horas do próximo dia.
            </Text>
          </Box>
        </Flex>
      </Choose.When>
      <Choose.When condition={currentState === 'SUCCESS'}>
        <Flex height="100%" justifyContent="center" flexDirection="column">
          <Flex height="100%" justifyContent="center" alignItems="center">
            <SuccessCheckedIllustration />
            <Box ml="2rem">
              <Text fontSize="xLarge" fontWeight="bold">
                Pronto!
              </Text>
              <Text fontSize="medium" color="mediumGray" maxWidth="60ch">
                O cancelamento da sua transação foi agendado com sucesso.
                <Text fontWeight="bold" fontSize="medium">
                  Ela aparecerá como cancelada nas primeiras horas do próximo dia.
                </Text>
              </Text>
            </Box>
          </Flex>
          <Flex>
            <Flex width="20rem" flexDirection="column">
              <Button size="large" color="neutral" onClick={() => BlackBird.goHome()}>
                Voltar ao início
              </Button>
            </Flex>
            <Flex width="20rem" ml="1.5rem" flexDirection="column">
              <Button size="large" onClick={onBackToSales}>
                Ok, Entendi
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Choose.When>
      <Choose.When condition={currentState === 'GENERIC_ERROR'}>
        <EmptyState illustration={<DogIllustration />} title="Aconteceu um erro inesperado" />
      </Choose.When>
      <Choose.When condition={currentState === 'CONFIRM'}>
        <Confirmer
          onConfirm={onSubmit}
          toConfirm={email}
          verificationError={errorMessage}
          onRevalidate={() => getData()}
        />
      </Choose.When>
      <Choose.Otherwise>
        <Loader />
      </Choose.Otherwise>
    </Choose>
  )
}
