import { Icon, Text, Tooltip } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { Choose } from '../../utilities-components'
import { useHiddenSensitive } from '../hooks/use-hidden-sensitive'

import analitica from './analitica'
interface HiddenSensitiveToggleConfig {
  onClick?: () => void
}

function HiddenSensitiveToggleTooltip({ children }: { children: React.ReactNode }) {
  const { isViewMedium } = useViewSize()
  const [isHiddenSensitive] = useHiddenSensitive()

  return (
    <Choose>
      <Choose.When condition={!isViewMedium}>
        <Tooltip
          content={
            <Box width="max-content">
              {isHiddenSensitive ? (
                <Text color="onColorHigh" aria-label="exibir">
                  Exibir valores
                </Text>
              ) : (
                <Text color="onColorHigh" aria-label="ocultar">
                  Ocultar valores
                </Text>
              )}
            </Box>
          }
          placement="right"
        >
          {children}
        </Tooltip>
      </Choose.When>
      <Choose.Otherwise>{children}</Choose.Otherwise>
    </Choose>
  )
}

export const HiddenSensitiveToggle = ({ onClick }: HiddenSensitiveToggleConfig) => {
  const [isHiddenSensitive, toggleHiddenSensitive] = useHiddenSensitive()

  return (
    <HiddenSensitiveToggleTooltip>
      <Flex
        as="button"
        backgroundColor={isHiddenSensitive ? 'lighterGreen' : 'unset'}
        padding="0.5rem"
        borderRadius="0.5rem"
        margin="0 auto"
        border="none"
        sx={{
          cursor: 'pointer',
          ':hover': {
            backgroundColor: isHiddenSensitive ? 'lighterGreenHover' : 'neutralOverlay'
          }
        }}
        aria-label={isHiddenSensitive ? 'open' : 'close'}
        onClick={() => {
          toggleHiddenSensitive()
          onClick && onClick()
          analitica.events.HiddenSensitiveToggle.click(isHiddenSensitive)
        }}
      >
        <Icon
          use={isHiddenSensitive ? 'eye-close-outline' : 'eye-open-outline'}
          color={isHiddenSensitive ? 'primaryHigh' : 'neutral'}
        />
      </Flex>
    </HiddenSensitiveToggleTooltip>
  )
}
