import { type FC } from 'react'
import { Cluster } from '@dlpco/fluid-layout'
import styled from 'styled-components'

import { Box, Text } from '~/domains/platform/design-system'
import { Avatar } from '~/domains/platform/design-system/avatar/avatar'
import { If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import { type ProfileCardProps } from './interface'

const WrapperText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 4px;
`

export const ProfileCard: FC<ProfileCardProps> = ({ name, document, minimal }: ProfileCardProps) => {
  const documentType = stringFormat.documentType(document)
  const formattedDocument = stringFormat.document(document)

  const documentCopy = minimal ? formattedDocument : `${documentType}: ${formattedDocument}`

  const arialLabelCopy = `Conta atual - Nome: ${name} - ${documentType}: ${formattedDocument}`

  return (
    <Cluster aria-label={arialLabelCopy} space="1rem" align="center" noWrap>
      <Box>
        <If condition={Boolean(!minimal)}>
          <Avatar name={name} backgroundColor="blue" />
        </If>
        <Box width="80%">
          <WrapperText aria-label="Nome" fontSize="normal" fontWeight="bold">
            {name}
          </WrapperText>
          <Box minWidth={180} aria-label="documento">
            <Text color="mediumGray" fontSize="small">
              {documentCopy}
            </Text>
          </Box>
        </Box>
      </Box>
    </Cluster>
  )
}
