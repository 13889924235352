import { timeMachine } from '~/domains/platform/lib/time-machine'

export const translatePeriod = (period: string, customStartDate?: Date, customFinalDate?: Date) => {
  const base = timeMachine()

  const lastMonthTimeMachine = base.getLastMonth()
  const lastSevenDaysTimeMachine = base.getLast7Days()
  const lastSixMonthsTimeMachine = base.getLast6Months()
  const lastYearTimeMachine = base.getLastYear()

  const today = [base.startOfDay(), base.endOfDay()]
  const yesterday = base.getYesterday()
  const last7 = [lastSevenDaysTimeMachine[0].startOfDay(), lastSevenDaysTimeMachine[1].endOfDay()]
  const last30 = base.getLast30Days()
  const thisMonth = base.getThisMonth()
  const lastMonth = [lastMonthTimeMachine[0].startOfDay(), lastMonthTimeMachine[1].endOfDay()]
  const lastSixMonths = [lastSixMonthsTimeMachine[0].startOfDay(), lastSixMonthsTimeMachine[1].endOfDay()]
  const lastYear = [lastYearTimeMachine[0].startOfDay(), base.endOfDay()]

  switch (period) {
    case 'today':
      return {
        startDate: today[0].toDate(),
        finalDate: today[1].toDate(),
        title: 'de hoje'
      }
    case 'yesterday':
      return {
        startDate: yesterday[0].toDate(),
        finalDate: yesterday[1].toDate(),
        title: 'de ontem'
      }
    case 'last_7':
      return {
        startDate: last7[0].toDate(),
        finalDate: last7[1].toDate(),
        title: 'dos últimos 7 dias'
      }
    case 'last_30':
      return {
        startDate: last30[0].toDate(),
        finalDate: last30[1].toDate(),
        title: 'dos últimos 30 dias'
      }
    case 'this_month':
      return {
        startDate: thisMonth[0].toDate(),
        finalDate: thisMonth[1].toDate(),
        title: 'deste mês'
      }
    case 'last_month':
      return {
        startDate: lastMonth[0].toDate(),
        finalDate: lastMonth[1].toDate(),
        title: 'do mês passado'
      }
    case 'last_six_months':
      return {
        startDate: lastSixMonths[0].toDate(),
        finalDate: lastSixMonths[1].toDate(),
        title: 'dos últimos 6 meses'
      }
    case 'last_year':
      return {
        startDate: lastYear[0].toDate(),
        finalDate: lastYear[1].toDate(),
        title: 'do último ano'
      }
    default:
      return {
        startDate: timeMachine(customStartDate).startOfDay().toDate(),
        finalDate: timeMachine(customFinalDate).endOfDay().toDate(),
        title: `de ${timeMachine(customStartDate).format('brazilianPattern')} - ${timeMachine(customFinalDate).format(
          'brazilianPattern'
        )}`
      }
  }
}
