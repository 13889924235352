import { Heimdall } from '~/domains/platform/core/heimdall'
import { httpClientCustomer } from '~/lib/services/acquirer/http-client-customer'
import { httpClientPortal } from '~/lib/services/acquirer/http-client-portal'

import { SALES_RESUME_URL, SALES_URL } from '../../shared/urls'

export type Extension = 'pdf' | 'csv' | 'xlsx'

interface ReportSales {
  merchantIds: string
  finalDate: string
  format: Extension | string
  includeSplits?: boolean
  startDate: string
  exportType: string
}

export async function reportSales({
  merchantIds,
  finalDate,
  format,
  includeSplits = false,
  startDate,
  exportType
}: ReportSales) {
  const legacyUrl = exportType?.includes('consolidated') ? SALES_RESUME_URL : SALES_URL
  const accept = format === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

  return Heimdall.pass(['reports_new_route'])
    ? await httpClientCustomer<Blob>({
        url: '/affiliations/sales',
        method: 'GET',
        params: {
          finalDate,
          startDate,
          affiliationCodes: merchantIds,
          includes: 'financialMovements'
        },
        headers: {
          Accept: accept
        },
        responseType: 'blob'
      })
    : await httpClientPortal<Blob>({
        url: legacyUrl,
        method: 'GET',
        params: {
          finalDate,
          format,
          includeSplits,
          startDate
        },
        headers: {
          merchantIds: merchantIds,
          Accept: accept,
          range: 'all'
        },
        responseType: 'blob'
      })
}
