import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { Grid, Stack, StackSplit } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'
import dayjs from 'dayjs'

import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { MoneyInput } from '~/domains/platform/design-system/money-input/money-input'
import { For } from '~/domains/platform/lib/utilities-components'
import { calculator, stringFormat, trackAnalyticsEvent } from '~/lib/helpers'
import { createBoleto } from '~/lib/services'
import { useWizardProps } from '~/ui/components/modals/wizard/use-wizard-props'

import { type BoletoProps } from '../../interface'
import { EmphasizeTitle, SubTitle, Title } from '../styles'

export const MAX_AMOUNT = 1000000
export const MIN_AMOUNT = 2000

const amountOptions = [1, 5, 10, 50, 100, 250, 500]

export function BoletoIssuance({ accountId }: { accountId: string }) {
  ReactGA.modalview('/depositar/')
  const {
    handleStep: { next }
  } = useWizardProps()

  const [isLoading, setIsLoading] = useState(false)
  const [currentValue, setCurrentValue] = useState(0)
  const [error, setError] = useState('')

  const { currency, onlyNumbers } = stringFormat

  const validRange = `A quantia deve estar entre ${currency(MIN_AMOUNT)} e ${currency(MAX_AMOUNT)}`

  const valueIsNotInTheRange = !calculator.inRange(currentValue, MIN_AMOUNT, MAX_AMOUNT)

  useEffect(() => {
    if (valueIsNotInTheRange) {
      setError(validRange)
    } else setError('')
  }, [valueIsNotInTheRange, validRange])

  const formattedAmount = currency(Math.round((currentValue / 100) * 100))

  const expirationDate = dayjs().add(7, 'day').format('YYYY-MM-DD')

  function handleChangeInputValue(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    const formattedValue = Number(onlyNumbers(value))
    setCurrentValue(formattedValue)
  }

  function handleChangeValue(valueToAdd: number) {
    const newValue = Math.round((currentValue / 100 + valueToAdd) * 100)
    setCurrentValue(newValue)
  }

  async function onSubmit(event: React.FormEvent) {
    try {
      event.preventDefault()
      setIsLoading(true)

      const { data: boleto } = await createBoleto<BoletoProps>({
        amount: currentValue,
        invoiceType: 'deposit',
        accountId,
        expirationDate: expirationDate
      })

      next(boleto)
    } catch (error) {
      setError('Ocorreu um erro. Tente novamente.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Stack space="1rem" as="form" onSubmit={onSubmit}>
        <Title>
          Quanto você deseja <EmphasizeTitle as="span">depositar na sua Conta?</EmphasizeTitle>
        </Title>
        <SubTitle>
          Crie um Boleto Stone e pague em qualquer instituição. <br />
          {validRange}
        </SubTitle>
        <Stack space="0.5rem">
          <MoneyInput
            name="amount"
            label="Valor"
            error={error}
            value={formattedAmount}
            onChange={handleChangeInputValue}
          />
          <Grid min="50px" gutter="0.5rem">
            <For
              of={amountOptions}
              render={value => (
                <Button
                  color="neutral"
                  size="large"
                  type="button"
                  aria-label={`Adicionar +${value}`}
                  data-qa-id={`input-amount-${value}`}
                  onClick={() => handleChangeValue(value)}
                  key={value}
                >
                  +{value}
                </Button>
              )}
            />
          </Grid>
        </Stack>
        <StackSplit space="1rem">
          <Button
            size="large"
            type="submit"
            disabled={isLoading || valueIsNotInTheRange}
            onClick={() => {
              trackAnalyticsEvent({
                action: 'click',
                category: 'Deposit',
                label: 'Clicar em criar novo boleto de depósito'
              })
            }}
          >
            Criar boleto
          </Button>
        </StackSplit>
      </Stack>
      {isLoading && (
        <Dimmer isVisible>
          <Loader />
        </Dimmer>
      )}
    </>
  )
}
