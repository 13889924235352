import { type PropsWithChildren, type ReactNode } from 'react'
import styled from 'styled-components'

import { Box, Card, Flex, Text } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'

export interface MainContentProps {
  title?: string
  rightButton?: ReactNode
  filterActions?: ReactNode
}

const ContentWrapper = styled(Card)`
  position: relative;
  padding: 0;
  min-height: min(75vh, 500px);
  padding-bottom: 2rem;
  .infinite-scroll-component {
    overflow: unset !important;
  }
`

export function MainContentHeader({ title, rightButton, filterActions }: PropsWithChildren<MainContentProps>) {
  return (
    <Flex p={title && 'xLarge'} justifyContent="space-between" alignItems="center">
      <If
        condition={Boolean(title)}
        render={() => (
          <Box>
            <Text color="darkGray" fontSize="large" fontWeight="bold">
              {title}
            </Text>
          </Box>
        )}
      />

      <Flex justifyContent="space-between">
        <If condition={Boolean(rightButton)}>
          <Box paddingRight="1rem">{rightButton}</Box>
        </If>

        {filterActions}
      </Flex>
    </Flex>
  )
}

export function MainContent(props: any) {
  const { children } = props
  return <ContentWrapper>{children}</ContentWrapper>
}
