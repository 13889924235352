import styled, { css, keyframes } from 'styled-components'
import { type LayoutProps, layout } from 'styled-system'

import { Box } from '~/domains/platform/design-system'
import { theme } from '~/ui/theme'

export interface SkeletonProps extends LayoutProps {
  intensity?: keyof typeof skeletonBackgrounds
}

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`

export const skeletonBackgrounds = {
  [1]: css`
    background: ${theme.colors.lightGray}
      linear-gradient(90deg, ${theme.colors.lightGray}, ${theme.colors.lightGray6}, ${theme.colors.lightGray}) no-repeat
      200px 100%;
  `,
  [2]: css`
    background: ${theme.colors.lightGray6}
      linear-gradient(90deg, ${theme.colors.lightGray6}, ${theme.colors.lightGray}, ${theme.colors.lightGray6})
      no-repeat 200px 100%;
  `
}

export const Skeleton = styled(Box)<SkeletonProps>`
  ${layout}
  line-height: 1;
  border-radius: 4px;
  display: inline-block;
  ${({ intensity }) => (intensity ? skeletonBackgrounds[intensity] : skeletonBackgrounds[1])};
  animation: ${skeletonKeyframes} 4s ease alternate infinite;
`
