import { createContext, useContext, useState } from 'react'

import { Bucket } from '../../bucket'
import { clientSideOnly } from '../../client-side-only'
import { publish, useSubscribeEvent } from '../../pub-sub'

const HiddenSensitiveContext = createContext<{
  hiddenSensitive: boolean
  triggerToggle(nextValue?: boolean): void
}>({
  hiddenSensitive: true,
  triggerToggle() {}
})

export function HiddenSensitiveProvider({ children, nextValue }: { children: React.ReactNode; nextValue?: boolean }) {
  const [hiddenSensitive, setHiddenSensitive] = useState(
    clientSideOnly(() => Boolean(Bucket.local.get('is::sensitive::hidden')), Boolean(nextValue)) || Boolean(nextValue)
  )

  useSubscribeEvent('hide:sensitive', e => toggleHiddenSensitive(e.detail))

  function toggleHiddenSensitive(nextValue: boolean) {
    setHiddenSensitive(Boolean(nextValue))
    Bucket.local.set('is::sensitive::hidden', Boolean(nextValue))
  }

  function triggerToggle(nextValue = !hiddenSensitive) {
    publish('hide:sensitive', nextValue)
  }

  return (
    <HiddenSensitiveContext.Provider value={{ triggerToggle, hiddenSensitive }}>
      {children}
    </HiddenSensitiveContext.Provider>
  )
}

export function useHiddenSensitive(): [boolean, (nextValue?: boolean) => void] {
  const { hiddenSensitive, triggerToggle } = useContext(HiddenSensitiveContext)
  return [hiddenSensitive, triggerToggle]
}
