import dayjs from 'dayjs'

import { type FieldProps, Field } from '~/domains/platform/design-system/field/field'

export interface DateFieldProps extends FieldProps {
  format?: string
  utc?: boolean
}

/**
 * @deprecated Ginga Components should be used instead:
 * @see [DateTime](https://ginga.docs.stone.credit/?path=/story/formata%C3%A7%C3%A3o-datetime--elemento&args=format:)
 */
export const DateField = (props: DateFieldProps) => {
  const { value, label, format = 'DD/MM/YYYY', ...restProps } = props
  const dateFormatter = props.utc ? dayjs.utc : dayjs
  const dateFormatted = dateFormatter(value).format(format)

  return <Field {...restProps} label={label} value={dateFormatted} />
}
