import { publish } from '~/domains/platform/lib/pub-sub'

import { twilio } from '../adapters'
import { getDepartmentsList } from '../services'

enum DEPARTMENT_KEYS {
  'TERMINALS' = 1, // Maquinha e Aplicativos
  'SAFETY' = 2, // Bloqueios Temporários
  'LOANS' = 3, // Empréstimo
  'CYBER' = 5, // Vendas por e-commerce
  'OTHERS' = 6 // Outros Assuntos
}

async function startEngagementWithDepartment(departmentId: number) {
  const { data: departmentsList } = await getDepartmentsList()

  const departmentItem = departmentsList?.find(item => item.id === departmentId)

  if (departmentItem) {
    twilio.Actions.invokeAction('StartEngagement', {
      formData: {
        ...departmentItem,
        selected: false
      }
    })
  }
}

function open(department?: keyof typeof DEPARTMENT_KEYS) {
  if (department) startEngagementWithDepartment(DEPARTMENT_KEYS[department])

  twilio.Actions.invokeAction('ToggleChatVisibility')
}

function openPublicChat() {
  publish('open:public:chat')
}

function restart() {
  twilio.Actions.invokeAction('RestartEngagement')
}

export const chat = { open, restart, openPublicChat }
