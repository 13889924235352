import { type ValidationMethods, validationTMPlugin } from '~/domains/platform/lib/time-machine/plugins/validation'

import { type ArithmeticsTmMethods, arithmeticsTMPlugin } from './plugins/arithmetics'
import { type BusinessDayTmMethods, businessDayTMPlugin } from './plugins/business-day'
import { type ComparisonTmMethods, comparisonTmPlugin } from './plugins/comparison'
import { type DiffTmMethods, diffTMPlugin } from './plugins/diff'
import { type EdgesTmMethods, edgesTMPlugin } from './plugins/edges'
import { type FormatTmMethods, formatTmPlugin } from './plugins/format'
import { type FriendlyMethods, friendlyTMPlugin } from './plugins/friendly'
import { type RangeTmMethods, rangeTMPlugin } from './plugins/range'
import { type SaleRangeTmMethods, saleRangeTMPlugin } from './plugins/sale-range'
import { timeMachine as originalTimeMachineWrapper } from './time-machine'
import { type TimeMachineWithPlugins } from './types'

export type InstalledPlugins = ComparisonTmMethods &
  FormatTmMethods &
  FriendlyMethods &
  ArithmeticsTmMethods &
  BusinessDayTmMethods &
  EdgesTmMethods &
  DiffTmMethods &
  RangeTmMethods &
  SaleRangeTmMethods &
  ValidationMethods

originalTimeMachineWrapper.extends(comparisonTmPlugin)
originalTimeMachineWrapper.extends(formatTmPlugin)
originalTimeMachineWrapper.extends(friendlyTMPlugin)
originalTimeMachineWrapper.extends(arithmeticsTMPlugin)
originalTimeMachineWrapper.extends(businessDayTMPlugin)
originalTimeMachineWrapper.extends(edgesTMPlugin)
originalTimeMachineWrapper.extends(diffTMPlugin)
originalTimeMachineWrapper.extends(rangeTMPlugin)
originalTimeMachineWrapper.extends(saleRangeTMPlugin)
originalTimeMachineWrapper.extends(validationTMPlugin)

const timeMachine = originalTimeMachineWrapper as unknown as TimeMachineWithPlugins<InstalledPlugins>

export { timeMachine }
