import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/types'
import { timeMachine } from '~/domains/platform/lib/time-machine'

import { type OutboundPixPayments, type OutboundPixPaymentsTarget } from '../entities'

interface CreateOutboundPix {
  account: PaymentAccount
  target?: OutboundPixPaymentsTarget | null
  key: string | null
  transactionId?: string
  amount?: number | null
  scheduledTo?: string | Date | null
  description?: string
}

export function createOutboundPix(payload: CreateOutboundPix, idempotency: string) {
  const { account, target = null, key, transactionId, amount, scheduledTo, description } = payload

  return httpClientBanking<OutboundPixPayments>({
    url: '/pix/outbound_pix_payments',
    method: 'POST',
    headers: { 'x-stone-idempotency-key': idempotency },
    data: {
      accountId: account.id,
      transactionId,
      key,
      amount,
      target,
      scheduledTo: scheduledTo ? timeMachine(scheduledTo).format('defaultApi') : null,
      description
    }
  })
}
