import { isPerson, isSubject } from '~/domains/platform/infra/deus-ex-machina/helpers/assertions'
import { type PaymentAccount, type Person, type Subject } from '~/domains/platform/infra/deus-ex-machina/ports'

import { type Assessment, type KYC } from './entities/KYC'
import { kycAssessment } from './kyc-assessment'
import { translateAssessmentStatus } from './kyc-translate-status'

export interface IdentityKycStatusProps {
  entityId: string
  organizations: Person[]
  subject: Subject
  accounts: PaymentAccount[]
}

export interface AssessmentStatusProps {
  identityKycStatus: KYC
  userAssessment?: Assessment
  organizationAssessments?: Assessment[]
}

function identityKycStatus(options: IdentityKycStatusProps) {
  const { organizations, subject, entityId } = options

  const entities =
    organizations.length > 0 ? [...organizations, subject].filter(entity => entity.id !== entityId) : [subject]

  const freemiumIdentity = entities.find(entity => {
    if (isSubject(entity) && entity.unsafe.hasAnActivePaymentAccount && entity.approvalStatus !== 'rejected') {
      return true
    } else {
      if (isPerson(entity) && entity.roles.banking && entity.approvalStatus !== 'rejected') {
        return true
      }
    }

    return false
  })

  const freemiumUser = subject.unsafe.hasAnActivePaymentAccount && subject.approvalStatus !== 'rejected'

  const freemiumUserOrIdentity = freemiumUser || freemiumIdentity

  return { freemiumIdentity, freemiumUser, freemiumUserOrIdentity }
}

function assessmentStatus(options: AssessmentStatusProps) {
  const { userAssessment, organizationAssessments, identityKycStatus } = options

  const riskAssessment = kycAssessment.findAssessmentByReason('risk_analysis', userAssessment, organizationAssessments)

  const updateAssessment = kycAssessment.findAssessmentByReason('update', userAssessment, organizationAssessments)

  const deviceAssessment = kycAssessment.findAssessmentByReason(
    'device_registration',
    userAssessment,
    organizationAssessments
  )

  const hasAssessment = riskAssessment || updateAssessment || deviceAssessment

  const status = hasAssessment ? translateAssessmentStatus(hasAssessment) : identityKycStatus

  return { status, riskAssessment, updateAssessment, deviceAssessment }
}

export const kycStatus = {
  identityKycStatus,
  assessmentStatus
}
