import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/types'
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

async function fetchBalance(accountId: PaymentAccount['id']) {
  const { data } = await httpClientBanking<{ balance: number; blockedBalance: number }>({
    url: `/accounts/${accountId}/balance`
  })
  return { amount: data?.balance, amountBlocked: data?.blockedBalance }
}

export function useAccountBalance({ accountId }: { accountId: PaymentAccount['id'] }) {
  return useQueryRequest<Awaited<ReturnType<typeof fetchBalance>>, unknown, true>(
    ['balance', accountId],
    () => fetchBalance(accountId),
    { enabled: Boolean(accountId) }
  )
}
