import styled, { css } from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

import { Card } from '~/domains/platform/design-system'
import { toRem } from '~/lib/helpers'

export const Container = styled.div`
  a {
    text-decoration: none;
  }

  .title {
    color: ${props => props.theme.colors.darkGray2};
    font-size: ${props => props.theme.fontSizes.normal};
    font-weight: ${theme('fontWeights.bold')};
    margin-top: ${toRem(0)};
    margin-bottom: ${toRem(8)};
  }

  .subtitle {
    color: ${props => props.theme.colors.mediumGray2};
    font-size: ${props => props.theme.fontSizes.small};
  }

  .chevron {
    color: ${props => props.theme.colors.mediumGray};
    height: ${toRem(32)};
  }
`

export const CardWrapper = styled(Card)<{ background?: string }>`
  background-color: ${theme('colors.white')};

  ${ifProp(
    'background',
    css`
      background-color: ${prop('background')};
    `
  )}

  border: 1px solid ${theme('colors.mediumGray3')};
  cursor: pointer;

  &:hover {
    border: 1px solid ${theme('colors.green')};

    .title {
      color: ${theme('colors.green')};
    }
  }
`

export const CardWithBackground = styled(CardWrapper)<{ background?: string; color?: string }>`
  ${ifProp(
    'background',
    css`
      border: 1px solid ${prop('background')};

      .title {
        color: ${prop('color')};
      }

      .subtitle {
        color: ${prop('color')};
      }

      svg {
        & > * {
          fill: ${prop('color')};
        }
      }

      &:hover {
        background-color: ${prop('background')};
        border: 1px solid ${prop('background')};
        opacity: 0.9;

        .title {
          color: ${prop('color')};
        }
      }
    `
  )}
`

export const IconWrapper = styled.div`
  margin-right: 1.5rem;
`
