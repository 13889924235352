import { useState } from 'react'
import { useRouter } from 'next/router'
import { Stack } from '@dlpco/fluid-layout'
import { Badge, Checkbox, IconButton, Text, Tooltip } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Box, Text as StyledText } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { analiticaInstance } from '~/domains/platform/lib/analitica/analitica.config'
import BlackBird from '~/domains/platform/lib/blackbird'
import { For, If } from '~/domains/platform/lib/utilities-components'
import { entitiesListFactory, isomorphicCookies, stringFormat, trackEvents } from '~/lib/helpers'

import { AnimationWrapper } from './animation-wrapper'
import { type IdentityGroupProps, type ProfileSwitchProps } from './interface'

const WrapperText = styled(StyledText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  max-width: 10rem;
`

const WrapperIdentity = styled(Box)`
  @media (max-width: 360px) {
    display: block;
  }
  display: grid;
  grid-template-columns: 30px auto;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: 'center';
`

function IdentityGroup(props: IdentityGroupProps) {
  const { callback, title, entities, currentEntity, defaultEntity, onChecked } = props
  const { route, push, asPath } = useRouter()

  const query = BlackBird.getQuery()

  function swapIdentity(id: string) {
    return function eventCallback() {
      push({ pathname: route, query: { ...query } }, asPath, { shallow: true })

      isomorphicCookies.set('identityId', id)
      analiticaInstance.updateMetadata({ identity: id })

      callback()

      trackEvents({
        action: 'click',
        category: 'Profile',
        label: 'Escolher uma conta na página de trocar de conta',
        trackServices: ['analytics']
      })
    }
  }

  return (
    <AnimationWrapper>
      <Stack space="1.5rem">
        <Text color="neutral" size="xSmall" weight="bold">
          {title}
        </Text>

        <Stack space="2rem">
          <For
            of={entities}
            render={entity => (
              <Box
                data-testid={`entity-group:${entity.id}`}
                key={entity.id}
                sx={{
                  wordBreak: 'break-word'
                }}
              >
                <WrapperIdentity>
                  <Tooltip
                    content={defaultEntity === entity.id ? 'Conta padrão' : 'Definir como padrão'}
                    placement="left"
                  >
                    <Checkbox
                      checked={defaultEntity === entity.id}
                      data-qa-id="setasDefault"
                      onChange={() => {
                        onChecked && onChecked(entity)
                      }}
                    />
                  </Tooltip>
                  <Stack space="0.25rem" style={{ cursor: 'pointer' }} onClick={swapIdentity(entity.id)}>
                    <WrapperText
                      data-cy="profile-name"
                      color={currentEntity.id === entity.id ? 'stoneGreen' : 'darkGray'}
                    >
                      {entity.displayName}
                    </WrapperText>
                    <Text color="neutral" size="xSmall" data-cy="profile-document">
                      {stringFormat.documentType(entity.document || '')}: {stringFormat.document(entity.document || '')}
                    </Text>
                    <If condition={!entity.roles.banking && !entity.roles.acquirer}>
                      <Badge color="neutral" style={{ marginRight: 'auto' }}>
                        Pendente
                      </Badge>
                    </If>
                  </Stack>
                </WrapperIdentity>
              </Box>
            )}
          />
        </Stack>
      </Stack>
    </AnimationWrapper>
  )
}

export function ProfileSwitch(props: ProfileSwitchProps) {
  const { currentEntity, callback, subject } = props
  const { organizations, users } = subject

  const [defaultEntity, setDefaultEntity] = useState(isomorphicCookies.getAll()['defaultIdentity'])

  const entities = entitiesListFactory({ organizations, subject, users })
  const personalEntities = entities.filter(({ document }) => stringFormat.documentType(document) === 'CPF')
  const organizationIdentities = entities.filter(({ document }) => stringFormat.documentType(document) === 'CNPJ')

  function handleDefaultEntity(defaultEntityId: string) {
    isomorphicCookies.set('defaultIdentity', defaultEntityId)
    setDefaultEntity(defaultEntityId)
  }

  return (
    <AnimationWrapper>
      <Stack space="1.5rem" data-cy="profile-switch">
        <Box data-testid="icon-button-container" style={{ paddingTop: '0.75rem' }}>
          <IconButton
            icon="direction-arrow-left-outline"
            variant="contentOnly"
            color="neutral"
            size="small"
            onClick={() => callback()}
          />
        </Box>
        <Box style={{ padding: ' 0 0.75rem' }}>
          <Text weight="bold">Em qual conta você quer entrar?</Text>
        </Box>

        <If condition={personalEntities.length > 0}>
          <IdentityGroup
            defaultEntity={defaultEntity}
            onChecked={entity => {
              handleDefaultEntity(entity.id)
            }}
            callback={callback}
            currentEntity={currentEntity}
            entities={personalEntities}
            title="PESSOAL"
          />
          <Divider />
        </If>

        <If condition={organizations.length > 0}>
          <IdentityGroup
            defaultEntity={defaultEntity}
            onChecked={entity => {
              handleDefaultEntity(entity.id)
            }}
            callback={callback}
            currentEntity={currentEntity}
            entities={organizationIdentities}
            title="NEGÓCIOS"
          />
        </If>
      </Stack>
    </AnimationWrapper>
  )
}
