import styled from 'styled-components'
import { theme } from 'styled-tools'

export const Title = styled.div`
  color: ${theme('colors.darkGray')};
  font-size: ${theme('fontSizes.xLarge')};
`
export const Subtitle = styled.div`
  color: ${theme('colors.mediumGray2')};
`
export const PasswordBlockText = styled.div`
  font-size: ${theme('fontSizes.medium')};
  color: ${theme('colors.mediumGray')};
`
