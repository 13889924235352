import { type NextPage } from 'next'

import { type FeatureFlagsAvailable } from '~/domains/platform/core/heimdall/types'
import { withIdentityScopes } from '~/domains/platform/core/night-owl/with-identity-scopes'
import { withVerifyIdentityOwnership } from '~/domains/platform/core/night-owl/with-verify-identity-ownership'
import { compose } from '~/domains/platform/lib/array-helpers/compose'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'

import { type OptIns, type WithOptInsProps, withDeusExMachina, withOptIns } from '../deus-ex-machina/ports'

import { withChat } from './compositional/with-chat'
import { type NavigationType, withNavigation } from './compositional/with-navigation'
import { withRedirectToMobileOnSmallScreen } from './compositional/with-redirect-to-mobile'
import { withEntityKYC } from './with-entity-kyc'
import { withHeimdallGrantAccessPage } from './with-heimdall-grant-access-page'
import { withHeimdallSetFeatureFlags } from './with-heimdall-set-feature-flags'
import { withIdAssessment } from './with-id-assessment'
import { withPinRegister } from './with-pin-register'
import { withRBAC } from './with-rbac'
import { withRegisterName } from './with-register-name'

interface PageConfig<T extends OptIns = never> {
  chat?: boolean
  deusExMachina?: boolean
  entityKYC?: boolean
  exMachinaOptIns?: T[]
  heimdallGrantAccessPage?: FeatureFlagsAvailable | FeatureFlagsAvailable[]
  heimdallSetFeatureFlags?: boolean
  idAssessment?: boolean
  identityScopes?: boolean
  isIdentityOwner?: boolean
  navigation?: NavigationType
  pinRegister?: boolean
  rbac?: boolean
  redirectToMobile?: boolean
  registerName?: boolean
}

type PageConfigKey = keyof PageConfig

const isRedirectToMobile = Boolean(isomorphicCookies.getAll()['redirectToMobile'])

const defaultPageConfig: PageConfig = {
  chat: true,
  deusExMachina: true,
  entityKYC: true,
  exMachinaOptIns: [],
  heimdallGrantAccessPage: undefined,
  heimdallSetFeatureFlags: true,
  idAssessment: true,
  identityScopes: true,
  isIdentityOwner: true,
  navigation: 'default',
  pinRegister: true,
  rbac: true,
  redirectToMobile: !isRedirectToMobile,
  registerName: true
}

const disabledDeusExMachinaConfig: PageConfig = {
  chat: true,
  deusExMachina: false,
  entityKYC: false,
  exMachinaOptIns: undefined,
  heimdallGrantAccessPage: undefined,
  heimdallSetFeatureFlags: false,
  idAssessment: false,
  identityScopes: false,
  isIdentityOwner: false,
  navigation: undefined,
  pinRegister: false,
  rbac: false,
  redirectToMobile: false
}

const pageConfigHocMap: Record<keyof PageConfig, (x: any) => any> = {
  chat: withChat,
  identityScopes: withIdentityScopes,
  isIdentityOwner: withVerifyIdentityOwnership,
  heimdallSetFeatureFlags: withHeimdallSetFeatureFlags,
  heimdallGrantAccessPage: withHeimdallGrantAccessPage,
  entityKYC: withEntityKYC,
  rbac: withRBAC,
  idAssessment: withIdAssessment,
  pinRegister: withPinRegister,
  redirectToMobile: withRedirectToMobileOnSmallScreen,
  navigation: withNavigation,
  registerName: withRegisterName,
  deusExMachina: withDeusExMachina,
  exMachinaOptIns: withOptIns
}

function configFactory(pageConfig: PageConfig<OptIns>) {
  const config = (
    pageConfig.deusExMachina !== false ? { ...defaultPageConfig, ...pageConfig } : disabledDeusExMachinaConfig
  ) as PageConfig

  return {
    ifeHoc: (key: PageConfigKey) => (config[key] ? pageConfigHocMap[key] : (x: any) => x),
    ifeHocWithArgs: (key: PageConfigKey) => (config[key] ? pageConfigHocMap[key](config[key]) : (x: any) => x)
  }
}

export type WithPageConfigProps<T extends OptIns = never> = WithOptInsProps<T>

export function withPageConfig<T extends OptIns = never, HasServerSideProps extends boolean = false>(
  Page: NextPage<HasServerSideProps extends true ? any : WithOptInsProps<T>>,
  pageConfig: PageConfig<T> = defaultPageConfig
): NextPage<WithOptInsProps<T>> {
  const { ifeHoc, ifeHocWithArgs } = configFactory(pageConfig)

  const WrappedPage = compose(
    ifeHoc('deusExMachina'),
    ifeHoc('registerName'),
    ifeHoc('pinRegister'),
    ifeHoc('idAssessment'),
    ifeHoc('entityKYC'),
    ifeHoc('heimdallSetFeatureFlags'),
    ifeHoc('identityScopes'),
    ifeHoc('isIdentityOwner'),
    ifeHoc('rbac'),
    ifeHoc('redirectToMobile'),
    ifeHocWithArgs('heimdallGrantAccessPage'),
    ifeHocWithArgs('navigation'),
    ifeHoc('chat'),
    ifeHocWithArgs('exMachinaOptIns')
  )(Page)

  WrappedPage.displayName = `withPageConfig(${Page.displayName})`

  return WrappedPage
}
