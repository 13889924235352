import { type RawPaymentAccount } from '../types'

export const paymentAccountSelector = (
  entity: { id: string },
  rawList: RawPaymentAccount[]
): RawPaymentAccount | undefined => {
  const ownedAccounts = rawList.filter(account => account.ownerId.includes(entity.id))

  return ownedAccounts.find(item => item.status === 'ACTIVE')
}
