import { Stack } from '@dlpco/fluid-layout'

import { Text } from '~/domains/platform/design-system'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/ports'
import { AccountDetails } from '~/ui/business-components/banking/account-details'

import { AccountInfo } from '../../account-info'

export function DepositInfo(props: { account: PaymentAccount }) {
  const { account } = props
  const { ownerName, ownerDocument, id } = account

  return (
    <Stack space="2rem">
      <AccountDetails ownerDocument={ownerDocument} ownerName={ownerName} id={id} />
      <Stack space="1rem">
        <Text fontSize="large">Prefere depositar por TED?</Text>
        <Text color="mediumGray2">Receba dinheiro das contas que você quiser direto na sua Conta Stone.</Text>
      </Stack>
      <Stack space="1rem">
        <AccountInfo account={account} />
      </Stack>
    </Stack>
  )
}
