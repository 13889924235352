import { type TimeMachine } from '../time-machine'
import { type TmPlugin } from '../types'

export interface DiffTmMethods {
  diffSecond(date: Date | TimeMachine): number
  diffMinute(date: Date | TimeMachine): number
  diffDay(date: Date | TimeMachine): number
  diffMonth(date: Date | TimeMachine): number
}

export const diffTMPlugin: TmPlugin<DiffTmMethods> = instance => {
  const proto = instance.prototype

  proto.diffSecond = function (date: Date | TimeMachine) {
    return this['$dateEngine'].diff(date.valueOf(), 'second')
  }

  proto.diffMinute = function (date: Date | TimeMachine) {
    return this['$dateEngine'].diff(date.valueOf(), 'minute')
  }

  proto.diffDay = function (date: Date | TimeMachine) {
    return this['$dateEngine'].diff(date.valueOf(), 'day')
  }

  proto.diffMonth = function (date: Date | TimeMachine) {
    return this['$dateEngine'].diff(date.valueOf(), 'month')
  }
}
