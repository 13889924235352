import { type SimulateSales } from '~/lib/types'

import { httpClientCustomer } from '../http-client-customer'

interface CancellationReceipt {
  saleId?: string
  merchantIds?: string
}

interface SaleSimulator {
  stoneCode: string
  amount: number
  label: string
  installments: string
  product: string
}

interface ResponseSalesSimulator {
  data: SimulateSales
  messages: []
}

export async function cancellationReceipt({ saleId, merchantIds }: CancellationReceipt) {
  return await httpClientCustomer<Blob>({
    url: `/affiliations/${merchantIds}/transactions/${saleId}/cancellation-receipt`,
    method: 'GET',
    responseType: 'blob'
  })
}

export async function saleSimulator({ stoneCode, amount, label, installments, product }: SaleSimulator) {
  return await httpClientCustomer<ResponseSalesSimulator>({
    url: `/merchants/${stoneCode}/simulate-sales?label=${label}&product=${product}&amount=${amount}&installments=${installments}`,
    method: 'GET'
  })
}
