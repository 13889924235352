interface IdentifierPlatform {
  id: string
}

type IdentifierListFactoryConfig = Partial<Record<'entity' | 'subject' | 'account', IdentifierPlatform>>

interface ResourceIdentifier {
  [key: string]: string
}

const extractEntityIdentifier = (entity: IdentifierPlatform): [string, string] => {
  entity.id.split(':')

  return entity.id.split(':') as [string, string]
}

export function identifierListFactory({ account, entity, subject }: IdentifierListFactoryConfig): ResourceIdentifier {
  const finalIdentifierList = {} as { [key: string]: string }

  if (account) {
    finalIdentifierList.account = account.id
  }

  if (entity) {
    const [resourceType, resourceId] = extractEntityIdentifier(entity)
    finalIdentifierList[resourceType] = resourceId
  }

  if (subject) {
    const [resourceType, resourceId] = extractEntityIdentifier(subject)
    finalIdentifierList[resourceType] = resourceId
  }

  return finalIdentifierList
}
