import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Flex, Text } from '~/domains/platform/design-system'

export const CookieConsentGrid = styled.div`
  display: grid;
  place-items: center;
  background-color: ${theme('colors.white')};

  width: 100vw;
  min-height: 80px;
  padding: 1rem;

  position: fixed;
  left: 0;
  top: 0;

  z-index: ${theme('zIndices.alert')};
`

export const CookieConsentFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const CookieConsentText = styled(Text)`
  font-weight: 600;
  min-width: 38ch;
  text-align: center;
  margin-right: 1rem;
  padding: 5px 0 5px 0;
`
