import { useCallback, useEffect, useState } from 'react'
import { Card, CardBody } from '@dlpco/ginga-stone'
import { type AxiosError, type AxiosResponse } from 'axios'

import { Box } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { type AnswerChallenge } from '~/domains/platform/infra/http/http-with-challenge'
import { useToast } from '~/domains/platform/layout/toast'
import { Choose } from '~/domains/platform/lib/utilities-components'
import {
  BAD_CHALLENGE_SOLUTION_ERROR,
  CHALLENGE_REQUIRED_ERROR,
  INVALID_VERIFICATION_CODE,
  NEED_VERIFICATION_ERROR
} from '~/lib/constants'
import { type Challenge, type ChallengeRequired } from '~/lib/types/banking/Challenge'
import { useWizardProps } from '~/ui/components/modals/wizard/use-wizard-props'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { ChallengeDialog, Confirmer, ResetPinCard } from './partials'

export interface ChallengeFormProps {
  onSuccess: (response: any) => void
  onError?: (error: any) => void
  toConfirm?: string
  onRequest: (payload?: AnswerChallenge) => Promise<AxiosResponse<any | Challenge>>
  onCancel?: () => void
}

function ChallengeForm({ onSuccess, onError, toConfirm, onRequest, onCancel }: ChallengeFormProps) {
  const { isViewSmall } = useViewSize()
  const [submitting, setSubmitting] = useState(true)

  const [verificationCode, setVerificationCode] = useState('')

  const { addToast } = useToast()
  const {
    handleStep: { previous }
  } = useWizardProps()

  const [challengeRequired, setChallengeRequired] = useState<ChallengeRequired | undefined>()
  const [verificationRequired, setVerificationRequired] = useState()
  const [errorMessage, setErrorMessage] = useState('')

  const makeRequest = useCallback(
    async (code?: string) => {
      try {
        setErrorMessage('')
        setVerificationCode(code || '')

        const response = await onRequest({
          verificationRequired: verificationRequired,
          code: code
        })

        if (response.status >= 200 && response.status < 300) {
          return onSuccess(response)
        }
      } catch (error) {
        const { response } = error as AxiosError
        const status = response?.status
        const responseType = response?.data?.type

        if (status === 403 && responseType === CHALLENGE_REQUIRED_ERROR) {
          const required = response?.data.challenge as ChallengeRequired
          setSubmitting(false)
          return setChallengeRequired(required)
        }

        if (status === 422 && responseType === NEED_VERIFICATION_ERROR) {
          setVerificationRequired(response?.data.details.verificationId)
          return
        }

        if (status === 422 && responseType === INVALID_VERIFICATION_CODE) {
          setErrorMessage('Você errou a senha.')
          addToast({ message: 'O código está incorreto. Tente novamente', type: 'error' })
          return
        }

        if (onError) {
          onError(error)
          return
        }

        addToast({ message: 'Tivemos um problema, tente novamente', type: 'error' })
        previous()
      }
    },
    [onSuccess, verificationRequired, addToast, previous, onRequest, onError]
  )

  useEffect(() => {
    makeRequest()
  }, [])

  const handleSubmitChallenge = async (challenge: string) => {
    try {
      if (challengeRequired) {
        setSubmitting(true)

        const response = await onRequest({
          challengeId: challengeRequired.id,
          passwordType: challengeRequired.requiredTypes[0],
          password: challenge,
          verificationRequired,
          code: verificationCode
        })

        setSubmitting(false)
        onSuccess(response)
      }
    } catch (error) {
      const { response } = error as AxiosError
      const status = response?.status
      const responseType = response?.data?.type

      if (status === 403 && responseType === BAD_CHALLENGE_SOLUTION_ERROR) {
        const remainingAttempts = response?.data.details?.remainingAttempts
        const errorMessage = remainingAttempts
          ? `Você errou a senha e só restam ${remainingAttempts} tentativas.`
          : 'Você errou a senha.'
        setErrorMessage(errorMessage)
        setSubmitting(false)
        return
      }

      setSubmitting(false)

      if (onError) {
        onError(error)
        return
      }

      addToast({ message: 'Tivemos um problema, tente novamente', type: 'error' })
    }
  }

  return (
    <Choose>
      <Choose.When
        condition={Boolean(challengeRequired)}
        render={() => (
          <Box sx={{ display: 'grid', gridGap: '1rem', gridTemplateColumns: isViewSmall ? '1fr' : '2fr 1fr' }}>
            <Card spacing="large" elevation={3}>
              <CardBody>
                <ChallengeDialog
                  onSubmitChallenge={handleSubmitChallenge}
                  passwordType={(challengeRequired as ChallengeRequired).requiredTypes[0]}
                  error={errorMessage}
                  loading={submitting}
                  onCancel={onCancel}
                />
              </CardBody>
            </Card>
            <ResetPinCard />
          </Box>
        )}
      />
      <Choose.When
        condition={Boolean(verificationRequired)}
        render={() => (
          <Confirmer
            onConfirm={code => {
              makeRequest(code)
            }}
            toConfirm={toConfirm}
            verificationError={errorMessage}
          />
        )}
      />
      <Choose.Otherwise>
        <Dimmer isVisible={submitting}>
          <Loader />
        </Dimmer>
      </Choose.Otherwise>
    </Choose>
  )
}

export { ChallengeDialog, ChallengeForm }
