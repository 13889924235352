import { Controller, useForm } from 'react-hook-form'
import { Cluster, Stack } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'

import { Box, Text } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { PasswordInput } from '~/domains/platform/design-system/password-input/password-input'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { type ChallengeType } from '~/lib/types'

import CodeInput from './code-input'
import { PasswordBlockText, Subtitle, Title } from './styles'

interface ChallengeDialogProps {
  onSubmitChallenge: (password: string) => void
  onCancel?: () => void
  passwordType: ChallengeType
  loading?: boolean
  error?: string
}

export const ChallengeDialog = ({
  onSubmitChallenge,
  onCancel,
  passwordType,
  loading = false,
  error
}: ChallengeDialogProps) => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      challenge: ''
    }
  })

  const onSubmit = handleSubmit(async ({ challenge }) => {
    onSubmitChallenge(challenge)
    reset()
  })

  return (
    <Stack space="2rem" as="form" onSubmit={onSubmit}>
      <Stack space="1rem">
        <Dimmer isVisible={loading}>
          <Loader />
        </Dimmer>
        <Choose>
          <Choose.When condition={passwordType.toLowerCase() === 'pin'}>
            <Title aria-label="Digite sua Senha">
              Digite sua <strong>Senha</strong>
            </Title>
            <Subtitle aria-label="Use sua senha de 6 dígitos para continuar">
              Use sua <strong>senha de 6 dígitos</strong> para continuar
            </Subtitle>
            <Controller
              render={({ field }) => (
                <CodeInput
                  {...field}
                  disabled={loading}
                  fields={6}
                  type="password"
                  inputMode="numeric"
                  error={errors.challenge?.message}
                />
              )}
              name="challenge"
              control={control}
              rules={{ required: 'Campo obrigatório' }}
            />
          </Choose.When>
          <Choose.When condition={passwordType.toLowerCase() === 'otp'}>
            <Title aria-label="Digite o código de autenticação de 2 fatores">
              Digite o <strong>código de autenticação de 2 fatores</strong>
            </Title>
            <Subtitle aria-label="Abra o Google Authenticator e digite os números que aparecem no aplicativo para continuar">
              Abra o <strong>Google Authenticator</strong> e digite os números que aparecem no aplicativo para continuar
            </Subtitle>

            <Controller
              render={({ field }) => (
                <CodeInput
                  {...field}
                  disabled={loading}
                  inputMode="numeric"
                  fields={6}
                  error={errors.challenge?.message}
                />
              )}
              name="challenge"
              control={control}
              rules={{ required: 'Campo obrigatório' }}
            />
          </Choose.When>
          <Choose.Otherwise>
            <Title aria-label="Digite sua Senha">
              Digite sua <strong>Senha</strong>
            </Title>
            <Subtitle aria-label="Use sua senha de acesso ao aplicativo para continuar">
              Use sua <strong>senha de acesso ao aplicativo</strong> para continuar
            </Subtitle>
            <Box mt="1rem">
              <PasswordInput
                {...register('challenge', { required: 'Campo obrigatório' })}
                title="Digite sua senha para continuar"
                placeholder="Digite sua senha"
                data-testid="password-input"
                autoFocus
                disabled={loading}
                error={errors.challenge?.message}
              />
            </Box>
          </Choose.Otherwise>
        </Choose>
        <If condition={Boolean(error)}>
          <Text color="red" fontSize="medium" role="alert">
            {error}
          </Text>
          <PasswordBlockText>Fique atento!</PasswordBlockText>
          <PasswordBlockText>A sua Conta vai ser bloqueada caso você continue errando sua senha.</PasswordBlockText>
        </If>
      </Stack>
      <Cluster justify="flex-end" space="1rem">
        <Box>
          <If condition={Boolean(onCancel)}>
            <Button shape="pill" color="neutral" onClick={onCancel} type="button">
              Cancelar
            </Button>
          </If>
          <Button disabled={loading} shape="pill" type="submit">
            Confirmar
          </Button>
        </Box>
      </Cluster>
    </Stack>
  )
}
