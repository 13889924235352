import { Field } from '~/domains/platform/design-system/field/field'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { type OperationsDetails, type SchedulingEntry, type SchedulingTypeCancel } from '~/lib/types'
import { DateField } from '~/ui/components/fields/date-field'

type PartialProps = { entry: SchedulingEntry }
type Partial = (props: PartialProps) => JSX.Element | null
type TypePartialMapProps = Record<SchedulingTypeCancel, Partial>

const TypePartialMap: TypePartialMapProps = {
  internal_transfer: ({ entry }: PartialProps) => <External entry={entry} />,
  external_transfer: ({ entry }: PartialProps) => <External entry={entry} />,
  barcode_payment: ({ entry }: PartialProps) => <Payment entry={entry} />,
  barcodeless_payment: ({ entry }: PartialProps) => <Barcodeless entry={entry} />,
  pix_payment: ({ entry }: PartialProps) => <External entry={entry} />,
  refund_for_pix_payment: ({ entry }: PartialProps) => <External entry={entry} />
}

export const Detail = (props: PartialProps) => {
  const { entry } = props

  const { createdAt, type, scheduledTo } = entry

  const Component = TypePartialMap[type]

  return (
    <>
      <DateField label="Realizado em" value={createdAt} />
      <DateField label="Agendado para" value={scheduledTo} />
      <Component {...props} />
    </>
  )
}

const External = (props: PartialProps) => {
  const { entry } = props
  const {
    counterpartyAgent: {
      account: { accountCode, branchCode } = {},
      entity: { name },
      institution: { compe: institutionNumberCode, name: institutionName }
    }
  } = entry as SchedulingEntry

  return (
    <>
      <Field label="Destino" value={name} />
      <Field
        label="Instituição"
        value={`${institutionNumberCode} - ${institutionName || 'Instituição desconhecida'}`}
      />
      <Field label="Agência" value={branchCode} />
      <Field label="Conta" value={accountCode} />
    </>
  )
}

const Payment = (props: PartialProps) => {
  const { entry } = props
  const {
    title,
    operationsDetails: { barcode }
  } = entry as OperationsDetails

  return (
    <>
      <Field label="Destino" value={title} />
      <Field label="Código de Barras" value={barcode} />
    </>
  )
}

const Barcodeless = (props: PartialProps) => {
  const { entry } = props

  return <Field label="Tarifa" value={entry.fee > 0 ? stringFormat.currency(entry.fee) : 'Grátis'} />
}
