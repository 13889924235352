import { useEffect } from 'react'
import { useRafState } from 'react-use'
import debounce from 'lodash.debounce'

import { clientSideOnly } from '~/domains/platform/lib/client-side-only'
import { theme } from '~/ui/theme'

const useDebouncedWindowSize = (initialWidth = Infinity, initialHeight = Infinity) => {
  const [state, setState] = useRafState<{ width: number; height: number }>({
    width: clientSideOnly(() => window.innerWidth, initialWidth),
    height: clientSideOnly(() => window.innerHeight, initialHeight)
  })

  useEffect((): (() => void) | void => {
    const handler = debounce(() => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }, 500)

    window.addEventListener('resize', handler)

    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  return state
}

export function useViewSize() {
  const { width } = useDebouncedWindowSize()

  const isViewSmall = width <= theme.windowSizes.small
  const isViewMedium = width <= theme.windowSizes.medium
  const isViewLarge = width <= theme.windowSizes.large

  const isWidthSmall = width >= theme.windowSizes.small
  const isWidthMedium = width >= theme.windowSizes.medium
  const isWidthLarge = width >= theme.windowSizes.large

  return { isWidthSmall, isWidthMedium, isWidthLarge, isViewSmall, isViewMedium, isViewLarge }
}
