import styled from 'styled-components'
import { theme } from 'styled-tools'

export const Ul = styled.ul`
  margin-left: 1.25rem;
`

export const Li = styled.li`
  &::marker {
    color: ${theme('colors.primary')};
    font-size: ${theme('fontSizes.medium')};
  }
`
