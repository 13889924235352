import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { httpClientBackend } from '~/domains/platform/infra/http/http-client-backend'
import { type PaginatedResponse } from '~/domains/platform/infra/http/paginated-client'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { type PaymentLink, type PaymentLinkConfig, type PaymentLinkExpiration } from '~/lib/types'
import { type UseRequestClientConfig } from '~/ui/hooks/utils/service/use-request'

export function cancelCharge({ accountId, chargeId }: { accountId: string; chargeId: string }) {
  return httpClientBanking({
    url: `/payment_links/charges/${chargeId}`,
    method: 'DELETE',
    data: { accountId }
  })
}

export function cancelOrder({ accountId, orderId }: { accountId: string; orderId: string }) {
  return httpClientBanking({
    url: `/payment_links/orders/${orderId}/closed`,
    method: 'PATCH',
    headers: {
      'x-stone-idempotency-key': orderId
    },
    data: {
      accountId,
      status: 'canceled'
    }
  })
}

function getDurationInMinutes(expiration: PaymentLinkExpiration) {
  const mapDuration = {
    oneHour: 60,
    twelveHours: 12 * 60,
    oneDay: 24 * 60,
    oneWeek: 7 * 24 * 60,
    fifteenDays: 15 * 24 * 60,
    oneMonth: 30 * 24 * 60
  }

  return mapDuration[expiration]
}

export function createPaymentLink({
  config,
  amount,
  description,
  name,
  numberOfInstallments,
  accountId,
  expiration
}: {
  config?: PaymentLinkConfig
  amount: number
  description: string
  name: string
  numberOfInstallments: number
  accountId: string
  expiration: PaymentLinkExpiration
}) {
  if (Heimdall.pass(['charge_link_v2_pf', 'charge_link_v2_pj'], 'some'))
    throw new Error('Criação de link de pagamento na visualização antiga não permitida')

  function getPaymentMethods(config: PaymentLinkConfig) {
    const paymentMethods = []

    if (config.creditCard.enabled) {
      paymentMethods.push('credit_card')
    }

    if (config.bankSlip.enabled) {
      paymentMethods.push('boleto')
    }

    return paymentMethods
  }

  function getSuccessUrl(config: PaymentLinkConfig) {
    return `${stringFormat.trimEnd(config.paymentUrlHost, '/t')}${config.successUrl}`
  }

  if (!config) throw Error('config cant be null')

  const paymentLinkExpiration = getDurationInMinutes(expiration)

  const data = {
    accountId,
    items: [
      {
        amount,
        description,
        quantity: 1
      }
    ],
    customer: {
      name
    },
    closed: config.closed,
    payments: [
      {
        amount: amount,
        payment_method: config.paymentMethod,
        checkout: {
          expires_in: paymentLinkExpiration,
          skip_checkout_success_page: config.skipCheckoutSuccessPage,
          billing_address_editable: config.billingAddressEditable,
          customer_editable: config.customerEditable,
          accepted_payment_methods: getPaymentMethods(config),
          accepted_multi_payment_methods: config.acceptedMultiPaymentMethods,
          success_url: getSuccessUrl(config),
          credit_card: {
            capture: config.creditCard.capture,
            installments: [...Array(numberOfInstallments)].map((_, y) => ({
              number: y + 1,
              total: amount
            }))
          }
        }
      }
    ]
  }

  return httpClientBanking<PaymentLink>({ url: `/payment_links/orders`, data, method: 'POST' })
}

export async function getPaymentLinks(clientConfig: UseRequestClientConfig) {
  const { url } = clientConfig

  const response = await httpClientBackend<PaginatedResponse<PaymentLink>>({
    ...clientConfig,
    url,
    params: {
      ...clientConfig.params
    }
  })
  return response
}
