import { analiticaInstance } from '~/domains/platform/lib/analitica/analitica.config'

export default analiticaInstance.eventFactory('Balance home card', {
  balanceHomeCard: {
    /**
     * @analitica when an error occurs during the balance request
     */

    requestError: () => ({
      label: 'home web - balance error state viewed'
    })
  }
})
