import { type Assessment } from '~/lib/types/pandas/LegacyAssessment'

export const kycRequestSelector = (
  entity: { id: string },
  organizationAssessments: Assessment[],
  userAssessment?: Assessment
) => {
  const [, identityId] = entity.id.split(':')

  if (userAssessment) return userAssessment

  return organizationAssessments?.find(item => item.organizationId === identityId)
}
