import { uuid } from '~/domains/platform/lib/crypto'
import { removeNullProperties } from '~/domains/platform/lib/object-helpers/remove-null-properties'

import { CreditBucket } from '../../ports/bucket'

const SESSION_KEY = 'credit::monitoring::session-key'
const SESSION_SAGA_KEY = 'credit::monitoring::saga-key'

export const getRandomSequence = (length = 10): string => {
  return uuid().replace(/-/g, '').substring(0, length)
}

export const generate = {
  idempotencyKey: () => getRandomSequence(32),
  sessionKey: () => getRandomSequence(25),
  sagaKey: () => {
    const key = getRandomSequence(25)
    CreditBucket.set(SESSION_SAGA_KEY, key)
    return key
  }
}
export const sessionKeyStorage = {
  get(): string {
    const value = CreditBucket.get(SESSION_KEY)

    if (!value) {
      const key = generate.sessionKey()
      CreditBucket.set(SESSION_KEY, key)
      return key
    }

    return value
  },
  clear() {
    CreditBucket.remove(SESSION_KEY)
  }
}

export const getSagaKey = (): string | null => {
  const value = CreditBucket.get(SESSION_SAGA_KEY)
  if (!value) return null
  return value
}

export const getKeysFromStorage = () =>
  removeNullProperties({
    sagaKey: getSagaKey(),
    sessionKey: sessionKeyStorage.get()
  })
