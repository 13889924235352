import { useForm } from 'react-hook-form'
import MaskedInput from 'react-text-mask'
import { Grid, Stack } from '@dlpco/fluid-layout'
import { Alert, Button } from '@dlpco/ginga-stone'

import { Box, Text } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { Input } from '~/domains/platform/design-system/input/input'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/ports'
import { masks, stringFormat, unmask } from '~/lib/helpers'
import { useWizardProps } from '~/ui/components/modals/wizard/use-wizard-props'
export function CompanyInfo(props: { accounts: PaymentAccount[] }) {
  const { accounts } = props

  const {
    content,
    handleStep: { next }
  } = useWizardProps()
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<{ fullName: string; document: string }>({ defaultValues: content?.entity })

  const onSubmit = handleSubmit(async ({ fullName, document }) => {
    next({ entity: { fullName, document: unmask(document) } })
  })

  const documentFormRegistration = register('document', {
    required: 'Campo obrigatório',
    validate: value => {
      if (stringFormat.documentType(unmask(value)) !== 'CNPJ') return 'CNPJ inválido'

      if (
        accounts
          ?.filter(account => account.status === 'ACTIVE')
          .map(account => account.ownerDocument)
          .some(document => document === unmask(value))
      )
        return 'Já existe uma conta com esse CNPJ'
    }
  })

  return (
    <form data-testid="company-info-form" onSubmit={onSubmit}>
      <Stack space="3.25rem">
        <Text fontSize="xLarge" marginBottom="2.5rem">
          Informe os dados da sua empresa
        </Text>
        <Box>
          <Alert color="warning" heading="Atenção!" style={{ marginBottom: '2.5rem' }}>
            Você precisa ser sócio desta empresa.
          </Alert>

          <Stack space="1.25rem">
            <Text color="mediumGray2" marginBottom="0.5rem">
              Dados da empresa
            </Text>
            <Divider />
            <Grid gutter="1rem" min="30ch">
              <Input
                label="Razão Social"
                {...register('fullName', { required: 'Campo obrigatório' })}
                placeholder="Ex: Stone Pagamentos SA"
                error={errors.fullName?.message}
              />
              <MaskedInput
                mask={masks.cnpj}
                guide={false}
                render={(ref, maskedProps) => (
                  <Input
                    label="CNPJ"
                    placeholder="000.000.000/0001-00"
                    error={errors.document?.message}
                    {...maskedProps}
                    {...documentFormRegistration}
                    ref={input => {
                      if (input) {
                        ref(input)
                        documentFormRegistration.ref(input)
                      }
                    }}
                    onChange={event => {
                      maskedProps.onChange(event)
                      documentFormRegistration.onChange(event)
                    }}
                    onBlur={event => {
                      maskedProps.onBlur(event)
                      documentFormRegistration.onBlur(event)
                    }}
                  />
                )}
              />
            </Grid>
          </Stack>
        </Box>
        <Box>
          <Button size="large">Continuar</Button>
        </Box>
      </Stack>
    </form>
  )
}
