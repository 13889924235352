import { useState } from 'react'

import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/types'
import { type ConsentPayment } from '~/lib/types'

import { initialOpenFinanceData, OpenFinanceContext } from './context'

export type ConsentRedirectProps = {
  softwareLogoUri: string
  brandName: string
  redirectUri: string
  action: () => void
}

type ConsentErrorProps = {
  title: string
  subtitle: string
  callToAction: string
  action?: () => void
}
export interface InitialDataProps {
  chosenAccount: PaymentAccount
  consent?: ConsentPayment
  redirect: ConsentRedirectProps
  error: ConsentErrorProps
}

export function OpenFinanceProvider({ children }: { children: React.ReactNode }) {
  const [data, setData] = useState<InitialDataProps>(initialOpenFinanceData)

  function saveData(invite: InitialDataProps) {
    setData(invite)
  }

  const contextValue = {
    data,
    saveData
  }

  return <OpenFinanceContext.Provider value={contextValue}>{children}</OpenFinanceContext.Provider>
}
