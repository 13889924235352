import { type PropsWithChildren, type ReactNode } from 'react'

import { Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import * as S from './styles'

export interface SidebarLayoutProps {
  aside: ReactNode
  transparentSide?: boolean
  fullscreen?: boolean
  open?: boolean
}

export function SidebarLayout(props: PropsWithChildren<SidebarLayoutProps>) {
  const { aside, children, transparentSide, fullscreen, open = true } = props
  const { isViewMedium } = useViewSize()

  return (
    <Flex flex="1">
      <If condition={Boolean(open)}>
        <S.BackgroundOverlay minimal={isViewMedium} />
        <S.Side transparent={transparentSide} minimal={isViewMedium}>
          {aside}
        </S.Side>
      </If>
      <S.Container fullscreen={Boolean(fullscreen)}>{children}</S.Container>
    </Flex>
  )
}
