import { unmask } from '~/lib/helpers/utils/unmask'
import { httpClientRav } from '~/lib/services/acquirer/http-client-rav/http-client-rav'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type DetailedPrepayCondition } from '../../rav/shared/entities/prepay-condition'

export const fetchPrepayCondition = (
  document: string,
  warrantyDetails: boolean,
  affiliationKey?: string,
  fetcher = httpClientRav
) => {
  const url = `/customer/${unmask(document)}/condition`

  return fetcher<DetailedPrepayCondition>({
    url,
    params: {
      customerExternalKey: affiliationKey,
      warrantyDetails
    }
  })
}

export function usePrepayCondition(
  document: string,
  affiliationKey?: string,
  warrantyDetails = true,
  queryOptions?: UseQueryRequestOptions<DetailedPrepayCondition>
) {
  const { data: axiosResponse, ...restQuery } = useQueryRequest<DetailedPrepayCondition>(
    ['usePrepayCondition', document, affiliationKey],
    () => fetchPrepayCondition(document, warrantyDetails, affiliationKey),
    {
      cacheTime: 0,
      enabled: Boolean(affiliationKey),
      ...queryOptions
    }
  )
  return { data: axiosResponse?.data, ...restQuery }
}
