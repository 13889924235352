import { Grid } from '@dlpco/fluid-layout'
import { Card, CardBody, Text } from '@dlpco/ginga-stone'

import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'

import { type SalesResumeItemProps } from './interface'
import { WrapperValue } from './styles'

type SalesResumeLoaderItemProps = Pick<SalesResumeItemProps, 'title' | 'titleColor' | 'cardColor'>

function SalesResumeLoaderItem(props: SalesResumeLoaderItemProps) {
  const { title, cardColor = 'surface', titleColor = 'neutral' } = props

  return (
    <Card color={cardColor}>
      <CardBody>
        <Text color={titleColor}>{title}</Text>
        <WrapperValue>
          <Skeleton height="2rem" width="min(50%, 20ch)" />
        </WrapperValue>
      </CardBody>
    </Card>
  )
}

export function SalesResumeLoader() {
  const cards: SalesResumeLoaderItemProps[] = [
    {
      title: 'Total Vendido',
      titleColor: 'onColorHigh',
      cardColor: 'surfacePrimary'
    },
    {
      title: 'Vendas Capturadas'
    },
    {
      title: 'Vendas Canceladas'
    }
  ]

  return (
    <Grid min="20ch" gutter="2rem">
      {cards.map((item, index) => (
        <SalesResumeLoaderItem key={index} title={item.title} titleColor={item.titleColor} cardColor={item.cardColor} />
      ))}
    </Grid>
  )
}
