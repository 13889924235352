import { httpClientCustomer } from '~/lib/services/acquirer/http-client-customer/http-client-customer'
import { type CustomerResponse } from '~/lib/types/acquirer/Shared'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

type Fee = {
  cardBrandId: number
  flatRate: number | null
  id: number
  installmentRate: number | null
  minimumDiscountAmount: number | null
  rate: number
  settlementDays: number
  transactionProfileId: number
}

export const getMerchantFee = (affiliationCode: string) => {
  return httpClientCustomer({
    url: `/merchants/${affiliationCode}/fees`
  })
}

export const useMerchantFee = (
  affiliationCode: string,
  queryConfig?: UseQueryRequestOptions<CustomerResponse<Fee[]>>
) => {
  const { data: response, ...restQuery } = useQueryRequest<CustomerResponse<Fee[]>>(
    ['useMerchantFee', affiliationCode],
    () => getMerchantFee(affiliationCode),
    queryConfig
  )

  const fees = response?.data.data || []

  return { fees, ...restQuery }
}
