interface OwnerMetadata {
  kycStatus: string
}

interface AccountMetadata {
  ownerKycStatus: string
}

interface RawAccount {
  id: string
}

interface Account extends RawAccount {
  metadata: AccountMetadata
}

interface Owner {
  metadata: OwnerMetadata
}

interface AccountDecoratorConfig {
  account: RawAccount
  owner?: Owner
}

export function accountDecorator(config: AccountDecoratorConfig): Account {
  const { account, owner } = config
  return {
    ...account,
    metadata: {
      ...account,
      ownerKycStatus: owner?.metadata.kycStatus || 'approved'
    }
  }
}
