import { type InstalledPlugins } from '../index'
import { type TMInstanceWithPlugins, type TmPlugin } from '../types'

export interface ArithmeticsTmMethods {
  addDay: (days: number) => TMInstanceWithPlugins<InstalledPlugins>
  addWeek: (weeks: number) => TMInstanceWithPlugins<InstalledPlugins>
  addMonth: (months: number) => TMInstanceWithPlugins<InstalledPlugins>
  addYear: (years: number) => TMInstanceWithPlugins<InstalledPlugins>
  subtractHour: (hours: number) => TMInstanceWithPlugins<InstalledPlugins>
  subtractDay: (days: number) => TMInstanceWithPlugins<InstalledPlugins>
  subtractWeek: (weeks: number) => TMInstanceWithPlugins<InstalledPlugins>
  subtractMonth: (months: number) => TMInstanceWithPlugins<InstalledPlugins>
  subtractYear: (years: number) => TMInstanceWithPlugins<InstalledPlugins>
}

export const arithmeticsTMPlugin: TmPlugin<ArithmeticsTmMethods> = instance => {
  const proto = instance.prototype

  proto.addDay = function (days) {
    return this.add(days, 'd') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.addWeek = function (weeks) {
    return this.add(weeks, 'w') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.addMonth = function (months) {
    return this.add(months, 'M') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.addYear = function (years) {
    return this.add(years, 'y') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.subtractDay = function (days) {
    return this.subtract(days, 'd') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.subtractWeek = function (weeks) {
    return this.subtract(weeks, 'w') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.subtractMonth = function (months) {
    return this.subtract(months, 'M') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.subtractYear = function (years) {
    return this.subtract(years, 'y') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.subtractHour = function (hours) {
    return this.subtract(hours, 'h') as TMInstanceWithPlugins<InstalledPlugins>
  }
}
