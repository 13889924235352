import { type Primitive } from 'type-fest'

type CanStringify = Exclude<Primitive, symbol>

export const stringifyProperties = <T extends Record<K, CanStringify>, K extends string | number | symbol>(
  obj: T
): Record<K, string> => {
  const objWithStringValues = Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, String(value)]))

  return objWithStringValues as Record<K, string>
}
