import { type ReactNode, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { logger } from '~/domains/platform/infra/monitoring/logger'
import { Choose, For } from '~/domains/platform/lib/utilities-components'

import { GenericErrorMessage } from '../../utils/generic-error-message'
import { Fullscreen } from '../fullscreen'

import { WizardContext } from './context'
import { type StepProps, type WizardProps } from './interface'
import { useSteps } from './use-steps'

const DefaultRenderContainer = ({ children }: { children: ReactNode }) => <>{children}</>

export const Wizard = ({
  isOpen,
  noPadding,
  noPaddingMobile,
  toggle,
  title,
  steps,
  growContent,
  renderAll,
  openOnStep,
  openWithContent,
  renderContainer: RenderContainer = DefaultRenderContainer,
  fullScreenProps,
  withoutHeader,
  reload,
  hiddenCloseButton = false
}: WizardProps) => {
  const { reset, goTo, next, previous, step, isFirstStep, content, setContent, isLastStep } = useSteps(
    steps.length,
    openWithContent
  )

  function goToKey(key: string, content?: Record<string, any>) {
    let index = 0

    steps.forEach((x, i) => {
      if (x.key === key) index = i
    })

    goTo(index, content)
  }

  const currentStepKey = steps[step]?.key

  const currentItem = steps[step]
  const { mainContent: MainContent, sideContent: SideContent, toolbar: Toolbar } = currentItem

  const close = () => {
    toggle(false)
    reset()
  }

  const state: StepProps = {
    close,
    reset,
    goTo,
    goToKey,
    handleStep: {
      next,
      previous
    },
    step,
    currentStepKey,
    content,
    setContent
  }

  const reloadPage = reload ?? close

  // TODO: this warning must be removed after a wizard refactor
  useEffect(() => {
    openOnStep && goTo(Number(openOnStep))
  }, [openOnStep])

  return (
    <ErrorBoundary FallbackComponent={GenericErrorMessage} onError={error => logger(error)}>
      <WizardContext.Provider value={state}>
        <RenderContainer {...state}>
          <Fullscreen
            noPadding={noPadding}
            noPaddingMobile={noPaddingMobile}
            withoutHeader={withoutHeader}
            growContent={growContent}
            aside={
              SideContent && (
                <Choose>
                  <Choose.When
                    condition={Boolean(renderAll)}
                    render={() => (
                      <For
                        of={Object.values(steps)}
                        render={({ sideContent: Content }, index) => (
                          <div key={index} style={{ display: index !== step ? 'none' : undefined }}>
                            {Content && <Content {...state} />}
                          </div>
                        )}
                      />
                    )}
                  />
                  <Choose.Otherwise>
                    <SideContent {...state} />
                  </Choose.Otherwise>
                </Choose>
              )
            }
            content={
              <Choose>
                <Choose.When
                  condition={Boolean(renderAll)}
                  render={() => (
                    <For
                      of={Object.values(steps)}
                      render={({ mainContent: Content }, index) => (
                        <div key={index} style={{ display: index !== step ? 'none' : undefined }}>
                          <Content style={{ display: index !== step && 'none' }} {...state} />
                        </div>
                      )}
                    />
                  )}
                />
                <Choose.Otherwise>
                  <MainContent {...state} />
                </Choose.Otherwise>
              </Choose>
            }
            useBackArrow={!isLastStep && !isFirstStep}
            isOpen={isOpen}
            hiddenCloseButton={hiddenCloseButton}
            onClose={hiddenCloseButton === false && (isLastStep || isFirstStep) ? reloadPage : previous}
            title={title}
            toolbar={Toolbar && <Toolbar {...state} />}
            {...fullScreenProps}
          />
        </RenderContainer>
      </WizardContext.Provider>
    </ErrorBoundary>
  )
}
