import { type BadgeComponent, type OwnPropsOf } from '@dlpco/ginga-stone'

import { type Integrations } from '~/lib/types'

type VariantColorMap = {
  color: OwnPropsOf<BadgeComponent>['color']
}

export const statusBadgeMap: Record<Integrations['status'], VariantColorMap> = {
  active: {
    color: 'positive'
  },
  pending: {
    color: 'negative'
  },
  inactive: {
    color: 'negative'
  }
}

export const statusTranslateMap: Record<Integrations['status'], string> = {
  active: 'Ativa',
  pending: 'Pendente',
  inactive: 'Inativo'
}
