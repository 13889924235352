import { type AxiosResponse } from 'axios'

import { stringFormat } from '~/lib/helpers/utils/string-format'

import { createOrganization } from '../create-organization'
import { createOrganizationAccount } from '../create-organization-account'
import { createPersonalAccount } from '../create-personal-account'

export async function createAccount(identifier: string): Promise<AxiosResponse<{ singUpRequestId: string }>> {
  const identifierType = stringFormat.documentType(identifier)
  if (identifierType === 'CPF') return createPersonalAccount()
  if (identifierType === 'CNPJ') {
    const { data: organization } = await createOrganization(identifier)

    const organizationId = organization?.id?.split(':')?.[1]

    return createOrganizationAccount(organizationId)
  }

  return createOrganizationAccount(identifier)
}
