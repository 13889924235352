import { type NextPage } from 'next'
import curry from 'lodash.curry'

import { forbiddenAccessHandler } from '../helpers/forbidden-access-handler'
import { type Entity, type OptIns, type Subject, type WithDeusExMachinaProps } from '../types'

const optInsSubjectMap: Partial<Record<keyof Subject<OptIns>, OptIns>> = {
  organizations: 'stone_account_resources',
  users: 'stone_account_resources',
  paymentAccounts: 'stone_account_resources',
  kycRequest: 'stone_account_kyc'
}

const optInsEntityMap: Partial<Record<keyof Entity<OptIns>, OptIns>> = {
  stoneCodes: 'acquirer',
  paymentAccount: 'banking',
  kycRequest: 'stone_account_kyc'
}

const optInControl = (data: { entity: Entity<OptIns>; subject: Subject<OptIns> }, exMachinaOptIns: OptIns[]) => {
  const { subject, entity } = data

  return {
    subject: forbiddenAccessHandler(subject, optInsSubjectMap, exMachinaOptIns),
    entity: entity && forbiddenAccessHandler(entity, optInsEntityMap, exMachinaOptIns)
  }
}

type DeusExMachinaOptIns<T extends OptIns> = 'stone_account' extends T
  ? { entity: Entity<T>; subject: Subject<T> }
  : { entity: Entity<T> }

type PageConfigWithoutOptIns = Omit<WithDeusExMachinaProps, 'subject' | 'entity'>
export type WithOptInsProps<T extends OptIns> = PageConfigWithoutOptIns & DeusExMachinaOptIns<T>

export const withOptIns = curry(function (
  exMachinaOptIns: OptIns[],
  Page: NextPage<WithDeusExMachinaProps>
): NextPage<WithOptInsProps<OptIns>> {
  function WrappedPage(props: WithOptInsProps<OptIns>) {
    const { entity, subject, ...propsWithoutExMachina } = props

    const optInControlledData = optInControl({ entity, subject }, exMachinaOptIns)

    return <Page {...propsWithoutExMachina} {...optInControlledData} />
  }

  WrappedPage.displayName = `withOptIns(${Page.displayName})`

  return WrappedPage
})
