import { type ReactNode } from 'react'
import styled from 'styled-components'

interface CleanPageContainerProps {
  children: ReactNode
  height?: string
}

const Container = styled.div<{ height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${props => (props.height ? props.height : '100vh')};
`

export const CleanPageContainer = (props: CleanPageContainerProps) => {
  const { children, height } = props

  return <Container height={height}>{children}</Container>
}
