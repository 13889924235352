import { analiticaInstance } from '~/domains/platform/lib/analitica/analitica.config'

type LateralMenuLabel =
  | 'extrato'
  | 'open-finance'
  | 'agendamentos'
  | 'boletos'
  | 'seguros'
  | 'recarga'
  | 'saque'
  | 'folha-de-pagamento'
type HighlightsMenu = 'transferir' | 'pagar' | 'pix'

export default analiticaInstance.eventFactory('Banking Menu', {
  lateralMenu: {
    /**
     * @analitica When the user click on lateral menu
     */
    click: (buttonName: LateralMenuLabel) => ({
      label: 'home web - lateral menu button chosen',
      metadata: {
        lateralmenu: buttonName
      }
    })
  },

  highlightsMenu: {
    /**
     * @analitica When the user click on highlights menu
     */
    click: (buttonName: HighlightsMenu) => ({
      label: 'home web - shortcut payment selected',
      metadata: {
        lateralmenu: buttonName
      }
    })
  },

  paymentSelect: {
    /**
     * @analitica When the user select payment home
     */
    click: (eventLabel: string) => ({
      label: eventLabel
    })
  }
})
