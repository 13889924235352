import { type PropsWithChildren, type ReactNode } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Box, Flex } from '~/domains/platform/design-system'

import { useTheme } from '../../hooks/utils/ui/use-theme'

export interface TopBarLayoutProps {
  topBarContent?: ReactNode
  asideContent?: ReactNode
}

const Header = styled(Box)`
  align-items: center;
  background-color: ${theme('colors.white')};
  box-shadow: ${'0px 4px 4px rgba(13, 17, 27, 0.08)'};
  color: ${theme('colors.darkerGray1')};
  display: flex;
  height: ${theme('sizes.header.height')};
  justify-content: space-between;
  left: 0;
  border-top: 4px solid ${theme('colors.stoneGreen')};
  padding-left: 2rem;
  padding-right: 2rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${theme('zIndices.topMenu')};
  & a {
    display: block;

    & img {
      max-height: 26px;
      margin-top: 2px;
    }
  }
`

export function TopBarLayout(props: PropsWithChildren<TopBarLayoutProps>) {
  const { children, topBarContent, asideContent } = props

  const {
    sizes: {
      header: { height: headerHeight }
    }
  } = useTheme()

  return (
    <Box>
      <Header data-cy="navbar">
        {asideContent || (
          <Box alignSelf="center">
            <Image src="/logo-stone.svg" height={25} width={98} />
          </Box>
        )}
        <Box data-cy="navbar-children">{topBarContent}</Box>
      </Header>
      <Flex flexDirection="column" minHeight="100vh" pt={headerHeight}>
        {children}
      </Flex>
    </Box>
  )
}
