import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { toRem } from '~/lib/helpers'

export const Wrapper = styled(Box)`
  border-radius: ${toRem(8)};
  width: 100%;
  background-color: ${theme('colors.white')};
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
`

export const Bold = styled(Text)`
  font-weight: bold;
  line-height: 2rem;
  font-size: ${theme('fontSizes.large')};
`

export const WrapperBalance = styled(Flex)`
  min-height: 3rem;
`

export const WrapperAccount = styled(Flex)`
  &:hover {
    & > h3 {
      color: ${theme('colors.primary')};
    }
  }
`
