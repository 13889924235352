import { checkCreditAccess } from '~/domains/credit/ports/services/check-credit-access'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'
import { analytics } from '~/domains/platform/lib/analytics'

export async function creditMenu(entity: Entity) {
  const hasCreditAccess = Heimdall.pass(['credit_home_loan_web', 'credit_beta_web'])
    ? await checkCreditAccess({
        document: entity.document
      })
    : false

  return [
    {
      order: 16,
      label: 'Crédito',
      icon: 'money-hand-outline',
      show: hasCreditAccess,
      onClick: () => {
        analytics.sendEvent('home web - lateral menu button chosen', { lateralmenu: 'empréstimo' })
      },
      path: '/credito',
      active: (route: string) => ['/credit'].includes(route)
    }
  ]
}
