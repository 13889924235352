import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { type Subject } from '~/domains/platform/infra/deus-ex-machina/ports'
import { type Assessment } from '~/domains/stone-account/ports/entities/KYC'

interface AssessementAnswer {
  userId: Subject['id']
  assessmentId: string | undefined
  answers: {
    checkId: string | undefined
    value: string
  }[]
}

const extractIdOfAnUserId = (userId: string) => userId.slice(userId.indexOf(':') + 1)

export const assessmentByKYCAnswer = async ({ userId, assessmentId, answers }: AssessementAnswer) => {
  const id = extractIdOfAnUserId(userId)

  return await httpClientBanking<Assessment>({
    url: `/users/${id}/kyc/answer`,
    method: 'POST',
    data: {
      assessmentId,
      answers
    }
  })
}
