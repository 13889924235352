import { analiticaInstance } from '~/domains/platform/lib/analitica/analitica.config'

import { convertCentsToDecimalNumber } from '../../revolving-credit/shared/normalize-amount'

type BalanceEventType = {
  freeLimit?: number | null
  offerValue?: number | null
  type: string
  offerId?: string | null
  productId?: string | null
  offerStatus?: string | null
}
export default analiticaInstance.eventFactory('revolving credit action in main menu', {
  home: {
    /**
     * @analitica The user saw balance card in menu
     */
    homeRevolvingCreditBalanceView: ({
      freeLimit,
      offerValue,
      type,
      offerId,
      productId,
      offerStatus
    }: BalanceEventType) => {
      return {
        label: 'credit - home revolving credit balance view',
        metadata: {
          'free limit': convertCentsToDecimalNumber(freeLimit ?? 0),
          'offer value': convertCentsToDecimalNumber(offerValue ?? 0),
          type: type,
          'offer id': offerId,
          'product id': productId,
          'offer status': offerStatus
        }
      }
    },
    /**
     * @analitica The user click in balance card in menu
     */
    homeRevolvingCreditBalanceClick: ({
      freeLimit,
      offerValue,
      type,
      offerId,
      productId,
      offerStatus
    }: BalanceEventType) => {
      return {
        label: 'credit - home revolving credit balance clicked',
        metadata: {
          'free limit': convertCentsToDecimalNumber(freeLimit ?? 0),
          'offer amount': convertCentsToDecimalNumber(offerValue ?? 0),
          type: type,
          'offer id': offerId,
          'product id': productId,
          'offer status': offerStatus
        }
      }
    }
  }
})
