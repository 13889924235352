import { type InstalledPlugins } from '../index'
import { type TMInstanceWithPlugins, type TmPlugin } from '../types'

export interface EdgesTmMethods {
  startOfDay(): TMInstanceWithPlugins<InstalledPlugins>
  startOfWeek(): TMInstanceWithPlugins<InstalledPlugins>
  startOfMonth(): TMInstanceWithPlugins<InstalledPlugins>
  startOfYear(): TMInstanceWithPlugins<InstalledPlugins>
  endOfDay(): TMInstanceWithPlugins<InstalledPlugins>
  endOfWeek(): TMInstanceWithPlugins<InstalledPlugins>
  endOfMonth(): TMInstanceWithPlugins<InstalledPlugins>
  endOfYear(): TMInstanceWithPlugins<InstalledPlugins>
}

export const edgesTMPlugin: TmPlugin<EdgesTmMethods> = instance => {
  const proto = instance.prototype

  proto.startOfDay = function () {
    return this.startOf('d') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.startOfWeek = function () {
    return this.startOf('w') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.startOfMonth = function () {
    return this.startOf('M') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.startOfYear = function () {
    return this.startOf('y') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.endOfDay = function () {
    return this.endOf('d') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.endOfWeek = function () {
    return this.endOf('w') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.endOfMonth = function () {
    return this.endOf('M') as TMInstanceWithPlugins<InstalledPlugins>
  }

  proto.endOfYear = function () {
    return this.endOf('y') as TMInstanceWithPlugins<InstalledPlugins>
  }
}
