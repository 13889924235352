import { ExclamationIllustration } from '~/domains/platform/design-system/illustrations/exclamation.illustration'

import { EmptyState } from '../empty-state'

export const ERROR_TITLE = 'Oops, aconteceu algo inesperado.'
export const ERROR_BODY_TEXT = [
  'Já estamos resolvendo para você tentar novamente daqui a pouco. Se precisar, fale com a gente.',
  'Estamos disponíveis 24h por dia, todos os dias.'
]

export function GenericErrorMessage() {
  return (
    <EmptyState
      illustration={<ExclamationIllustration />}
      title={ERROR_TITLE}
      body={
        <>
          {ERROR_BODY_TEXT[0]} <br /> {ERROR_BODY_TEXT[1]}
        </>
      }
    />
  )
}
