import { Stack } from '@dlpco/fluid-layout'
import { type OwnPropsOf, Badge } from '@dlpco/ginga-stone'
import dayjs from 'dayjs'

import { Box, Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { Field } from '~/domains/platform/design-system/field/field'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { If } from '~/domains/platform/lib/utilities-components'
import { AsyncRenderer } from '~/domains/platform/lib/utilities-components/async-renderer/async-renderer'
import {
  BLOCKED,
  CANCELED,
  CANCELED_A,
  DONE,
  EXPIRED,
  FAILED,
  isDebit,
  PAID,
  PENDING,
  PROCESSING,
  RECEIVED,
  RECEIVED_A,
  REFUNDED,
  REFUNDED_A,
  SENT,
  SENT_A,
  stringFormat,
  translateStatus,
  UNBLOCKED
} from '~/lib/helpers'
import { type Operation } from '~/lib/types'
import { Money } from '~/ui/components/fields/money'
import { SideModal } from '~/ui/components/modals/side-modal'

import { type TransactionDetailProps } from './interface'
import { Toolbar } from './styles'

const getBadgeColorPerStatus = (translatedStatus: string, operation: Operation): OwnPropsOf<typeof Badge>['color'] => {
  switch (translatedStatus) {
    case PROCESSING:
      return 'warning'
    case PENDING:
      return 'info'
    case PAID:
      return isDebit(operation) ? 'negative' : 'positive'
    case RECEIVED:
    case RECEIVED_A:
      return 'positive'
    case DONE:
    case SENT:
    case SENT_A:
      return 'negative'
    case REFUNDED:
    case REFUNDED_A:
    case EXPIRED:
    case CANCELED:
    case CANCELED_A:
    case BLOCKED:
    case UNBLOCKED:
    case FAILED:
      return 'neutral'
    default:
      return isDebit(operation) ? 'negative' : 'positive'
  }
}

const DefaultHeader = (data: any, showFee = true, showDate = true) => {
  const createdAt = data?.createdAt
  const feeAmount = data?.feeAmount
  const type = data?.type
  const fee = data?.fee
  const feeInfo = feeAmount || fee
  const isCashbackType = type === 'cashback'

  return (
    <>
      <Divider />
      <If condition={showFee && !isCashbackType}>
        <Field label="Tarifa" value={feeInfo > 0 ? stringFormat.currency(feeInfo) : 'Grátis'} />
      </If>
      <If condition={showDate}>
        <Field label="Data" value={dayjs(createdAt).format('DD/MM/YYYY [às] HH:mm')} />
      </If>
      <If condition={isCashbackType}>
        <Field label="Tipo" value="Cashback" />
      </If>
    </>
  )
}

export const TransactionDetail = (props: TransactionDetailProps) => {
  const {
    url,
    fetcher,
    title,
    transformer,
    statusTranslator = translateStatus,
    statusBadgeColorFactory = getBadgeColorPerStatus,
    header = DefaultHeader,
    children,
    toolbar,
    showFee,
    showDate,
    ...sideModalProps
  } = props

  return (
    <SideModal title={title} {...sideModalProps}>
      <AsyncRenderer
        fetcherHook={fetcher}
        transformer={transformer}
        loader={() => (
          <Dimmer isVisible>
            <Loader />
          </Dimmer>
        )}
        render={({ data }) => {
          const { amount, status, type } = data
          const absAmount = Math.abs(amount)

          const operation = data?.operation || (data.amount < 0 ? 'debit' : 'credit')

          //TODO Verificar essa função para ver se ainda vamos mostrar alguma badge???
          const translatedStatus = statusTranslator(status, type, operation)

          return (
            <>
              <Stack space="1rem">
                <Flex justifyContent="space-between">
                  <Box>
                    <Money pretty value={absAmount} label="Valor" renderProps={{ color: 'darkGray', fontSize: '1' }} />
                  </Box>
                  <Box data-testid="status">
                    <Badge aria-label="badge-status" color={statusBadgeColorFactory(translatedStatus, operation)}>
                      {translatedStatus}
                    </Badge>
                  </Box>
                </Flex>
                {header(data, showFee, showDate)}
                {children(data)}
              </Stack>
              <Toolbar>
                <Stack space="1rem">
                  <Divider />
                  {toolbar && toolbar(data)}
                </Stack>
              </Toolbar>
            </>
          )
        }}
        url={url}
      />
    </SideModal>
  )
}
