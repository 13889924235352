import { createContext } from 'react'

import { type InitialDataProps } from './provider'

interface ContextProps {
  data: InitialDataProps
  saveData: (data: InitialDataProps) => void
}

export const initialOpenFinanceData = {} as InitialDataProps

export const OpenFinanceContext = createContext<ContextProps>({
  data: initialOpenFinanceData,
  saveData: initialData => {
    initialData
  }
})
