type Config = {
  stoneCode: any
  accountScopes?: string[]
}

export function entityRoles(config: Config) {
  const { stoneCode, accountScopes } = config

  return {
    acquirer: Boolean(stoneCode),
    banking: Boolean(accountScopes?.includes('paymentaccount:read')),
    credit: Boolean(accountScopes?.includes('paymentaccount:read') && accountScopes?.includes('paymentaccount:loans'))
  }
}
