import { timeMachine } from '~/domains/platform/lib/time-machine'

import { type Period } from '../filter-form'

export const checkPeriod = ({ startDate, finalDate }: { startDate: Date; finalDate: Date }): Period => {
  const startDateTimeMachine = timeMachine(startDate).startOfDay().toDate()
  const finalDateTimeMachine = timeMachine(finalDate).startOfDay().toDate()
  const startDateTimeMachineTest = timeMachine(startDate)
  const finalDateTimeMachineTest = timeMachine(finalDate)

  const today = timeMachine().startOfDay()

  const baseDate = timeMachine()
  const lastThirtyDays = baseDate.getLast30Days()
  const thisMonth = baseDate.getThisMonth()
  const lastMonth = baseDate.getLastMonth()
  const lastSixMonth = baseDate.getLast6Months()
  const lastYear = baseDate.getLastYear()

  if (finalDateTimeMachineTest.startOfDay().isSame(startDateTimeMachineTest.startOfDay())) {
    if (finalDateTimeMachineTest.endOfDay().isSame(today.endOfDay().toDate())) {
      return 'today'
    }

    if (timeMachine(startDateTimeMachine).isSame(timeMachine().getYesterday()[0])) {
      return 'yesterday'
    }
    return 'custom'
  }

  if (today.subtractDay(6).isSame(startDateTimeMachine) && today.isSame(finalDateTimeMachine)) {
    return 'last_7'
  }

  if (lastThirtyDays[0].isSame(startDateTimeMachine) && lastThirtyDays[1].startOfDay().isSame(finalDateTimeMachine)) {
    return 'last_30'
  }

  if (thisMonth[0].isSame(startDateTimeMachine) && thisMonth[1].startOfDay().isSame(finalDateTimeMachine)) {
    return 'this_month'
  }

  if (lastMonth[0].isSame(startDateTimeMachine) && lastMonth[1].startOfDay().isSame(finalDateTimeMachine)) {
    return 'last_month'
  }

  if (
    lastSixMonth[0].startOfDay().isSame(startDateTimeMachine) &&
    lastSixMonth[1].startOfDay().isSame(finalDateTimeMachine)
  ) {
    return 'last_six_months'
  }

  if (lastYear[0].isSame(startDateTimeMachine) && today.isSame(finalDateTimeMachine)) {
    return 'last_year'
  }

  return 'custom'
}
