import { type AnswerChallenge } from '~/domains/platform/infra/http/http-with-challenge'
import { useToast } from '~/domains/platform/layout/toast'
import { cancelIntegration } from '~/domains/stone-account/consent/services/cancel-integration'
import { type IntegrationsResourceType } from '~/lib/types'
import { ChallengeForm } from '~/ui/business-components'
import { ContentModal } from '~/ui/components/modals/content-modal/content-modal'
interface CancelIntegrationProps {
  subjectId: string
  resourceId: string
  resourceType: IntegrationsResourceType
  isChallengeOpen: boolean
  onToggle: (nextValue?: boolean) => void
}

export function IntegrationsChallenge(props: CancelIntegrationProps) {
  const { subjectId, resourceId, resourceType, isChallengeOpen, onToggle } = props
  const { addToast } = useToast()

  const handleRequest = async (payload?: AnswerChallenge) => {
    return cancelIntegration({
      subjectId,
      resourceId,
      resourceType
    })(payload)
  }

  return (
    <ContentModal isOpen={isChallengeOpen} onClose={onToggle}>
      <ChallengeForm
        onRequest={handleRequest}
        onError={() => {
          addToast({ message: 'Tivemos um problema. Tente novamente.', type: 'error' })
          onToggle()
        }}
        onSuccess={() => {
          addToast({ message: 'Sua integração foi cancelada.', type: 'success' })
          onToggle()
        }}
      />
    </ContentModal>
  )
}
