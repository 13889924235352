import { type Assessment } from '~/domains/stone-account/ports/entities/KYC'
import { translateMetadataStatus } from '~/domains/stone-account/ports/kyc-translate-status'
import { type MerchantsContentProps } from '~/lib/types/acquirer/Merchants'
import { type RawUser } from '~/lib/types/pandas/User'

import { entityRoles } from './entity-roles'

export function userDecorator(config: {
  assessment?: Assessment
  user: RawUser
  stoneCode?: MerchantsContentProps
  accountScopes?: string[]
}) {
  const { accountScopes, assessment, stoneCode, user } = config
  return {
    ...user,
    id: `user:${user.userId}`,
    document: user.cpf,
    metadata: {
      kycStatus: translateMetadataStatus(user, assessment),
      roles: entityRoles({
        stoneCode: stoneCode,
        accountScopes: accountScopes
      })
    }
  }
}
