import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { type BFF } from '~/lib/types'

import { MultiPromiseHandler } from '../../http/multi-promise-handler'
import { type Credentials } from '../entities'

import { getBFF } from './get-bff'

export interface BffData {
  bff: BFF
  credentials: Credentials[]
}

export async function fetchBff() {
  const handler = new MultiPromiseHandler<BffData>()

  handler.addPromise('bff', getBFF())

  handler.addPromise(
    'credentials',
    MultiPromiseHandler.extractAxiosResponse(httpClientBanking<Credentials[]>({ url: '/me/credentials' }))
  )

  return handler.execute()
}
