import { httpWithChallenge } from '~/domains/platform/infra/http/http-with-challenge'

import { httpClientOpenFinance } from '../http-client-open-finance'

export type PaymentConsentAction = 'authorize' | 'reject'

export type PaymentConsent = {
  action: PaymentConsentAction
  consentId: string
  accountId: string
}

export const getUrlByAction = (action: string, consentId: string) =>
  ({
    authorize: `/payment_consents/${consentId}/actions/authorize`,
    reject: `/payment_consents/${consentId}/actions/reject`
  }[action])

export const paymentConsent = ({ action, consentId, accountId }: PaymentConsent, idempotency: string) => {
  const url = getUrlByAction(action, consentId)

  return httpWithChallenge({
    config: {
      url,
      method: 'POST',
      headers: { 'x-stone-idempotency-key': idempotency },
      data: {
        accountId
      }
    },
    client: httpClientOpenFinance
  })
}
