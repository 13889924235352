import { useMap } from 'react-use'

import { type UsePaginatedRequestResponse } from '~/ui/hooks/utils/service/use-paginated-request'
import { type UseRequestClientConfig, type UseRequestSWRConfig, useRequest } from '~/ui/hooks/utils/service/use-request'

type UseMundiRequestClientConfig = UseRequestClientConfig
type UseMundiRequestSWRConfig<Data, Error> = UseRequestSWRConfig<MundiPaginatedResponse<Data>, Error>
type UseMundiRequestResponse<Data, Error> = Omit<UsePaginatedRequestResponse<Data, Error>, 'mutate'>

type MundiPaggPaging = {
  total: number
  previous?: string
  next: string
}

export interface MundiPaginatedResponse<Data> {
  data: Data[]
  paging: MundiPaggPaging
}

const initialQuery = { page: 1, size: 100 }

interface Query {
  page: number
}

export function useMundiRequest<Data, Error>(
  clientConfig: UseMundiRequestClientConfig,
  swrConfig: UseMundiRequestSWRConfig<Data, Error> = {}
): UseMundiRequestResponse<Data, Error> {
  const { url } = clientConfig

  const [query, queryActions] = useMap<Query>(initialQuery)

  const { data, ...restSWR } = useRequest<MundiPaginatedResponse<Data>, Error>(
    {
      ...clientConfig,
      url,
      params: {
        ...clientConfig.params,
        ...query
      }
    },
    {
      ...swrConfig,
      revalidateOnFocus: false
    }
  )

  function paginationFactory() {
    function moveTo(page: number) {
      queryActions.set('page', page)
    }

    function forward() {
      if (data?.paging?.next) {
        moveTo(query.page + 1)
      }
    }

    function backwards() {
      if (data?.paging?.next) {
        moveTo(query.page - 1)
      }
    }

    return {
      reset: queryActions.reset,
      forward,
      backwards,
      hasAfter: Boolean(data?.paging?.next),
      hasBefore: Boolean(data?.paging?.previous)
    }
  }

  return {
    ...restSWR,
    pagination: paginationFactory(),
    data: data?.data
  }
}
