import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Radio } from '@dlpco/ginga-stone'

import { type MerchantsContentProps } from '~/domains/acquirer/shared/entities/merchants'
import { Box, Text } from '~/domains/platform/design-system'
import { For } from '~/domains/platform/lib/utilities-components'
import { type PurchaseStoneCode, type TerminalDevices } from '~/lib/types'

import { StonecodeItem } from './stonecode-item'

interface StonecodeSelectionProps {
  stoneCodes: PurchaseStoneCode[]
  handleSelect(selected?: PurchaseStoneCode): void
  terminalDevices?: TerminalDevices[]
  merchants?: MerchantsContentProps[]
}

export const StonecodeSelection = (props: StonecodeSelectionProps) => {
  const { stoneCodes: stoneCodeList, handleSelect, terminalDevices, merchants } = props

  const { register, watch } = useForm()

  const currentStoneCode = watch('stonecode')

  useEffect(() => {
    handleSelect(stoneCodeList[currentStoneCode])
  }, [currentStoneCode])

  return (
    <Box as="form">
      <Text fontSize="large">Escolha um canal de vendas</Text>
      <For
        of={stoneCodeList}
        render={({ fantasyName, stoneCode }: PurchaseStoneCode, key) => (
          <Box mt={5} key={key}>
            <Radio id={fantasyName} {...register('stonecode')} value={key} />
            <StonecodeItem
              merchants={merchants}
              fantasyName={fantasyName}
              stoneCode={stoneCode}
              terminalDevices={terminalDevices}
            />
          </Box>
        )}
      />
    </Box>
  )
}
