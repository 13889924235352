import { useState } from 'react'
import { Button, Radio } from '@dlpco/ginga-stone'

import { Flex, Text } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { FieldSet } from '~/domains/platform/design-system/fieldset/fieldset'
import { For } from '~/domains/platform/lib/utilities-components'
import { type MerchantsBankAccount } from '~/lib/types'
import { SideModal } from '~/ui/components/modals/side-modal'
import { type SideModalProps } from '~/ui/components/modals/side-modal/interface'

import { AccountDescription, BankDetails, Spacer, WithScrollableContent } from './styles'

export interface BankAccountSelectorModalProps extends Omit<SideModalProps, 'children'> {
  bankDetails: MerchantsBankAccount[]
  initialSelection?: string
  onConfirm(value?: MerchantsBankAccount): void
}

export function BankAccountSelectorModal({
  bankDetails,
  initialSelection,
  onConfirm,
  isOpen,
  toggle
}: BankAccountSelectorModalProps) {
  const [selection, setSelection] = useState<string | undefined>(initialSelection)

  return (
    <WithScrollableContent>
      <SideModal notDismissible ignoreClickAway isOpen={isOpen} toggle={toggle} title="Alterar Conta">
        <Text mb={4} color="mediumGray">
          Selecione a conta desejada abaixo:
        </Text>
        <BankDetails>
          <For
            of={bankDetails}
            render={bankDetail => (
              <FieldSet
                key={bankDetail.metadata.affiliationKey}
                active={bankDetail.metadata.affiliationKey === selection}
              >
                <AccountDescription as="dl">
                  <Divider />
                  <dt>Tipo de conta</dt>
                  <dd>{bankDetail.bankAccountType.name}</dd>
                  <Divider />
                  <dt>Agência</dt>
                  <dd>{bankDetail.agencyNumber}</dd>
                  <Divider />
                  <dt>Conta</dt>
                  <dd>{bankDetail.metadata.accountNumberAndVerificationCode}</dd>
                </AccountDescription>
                <Spacer />
                <Radio
                  value={bankDetail.metadata.affiliationKey}
                  checked={bankDetail.metadata.affiliationKey === selection}
                  onChange={() => {
                    setSelection(bankDetail.metadata.affiliationKey)
                  }}
                />
                <Text fontSize="medium" fontWeight="medium" ml={2} color="darkGray">
                  {bankDetail.bank.name}
                </Text>
              </FieldSet>
            )}
          />
        </BankDetails>

        <Flex mt="1.5rem" flexDirection="column">
          <Button
            size="large"
            data-testid="confirm-btn"
            disabled={!selection}
            onClick={() => {
              onConfirm(bankDetails.find(bankDetail => bankDetail.metadata.affiliationKey === selection))
              toggle && toggle(false)
            }}
          >
            Confirmar Conta
          </Button>
        </Flex>
      </SideModal>
    </WithScrollableContent>
  )
}
