import { findAccountOwner, findResourceById, unmask } from '~/lib/helpers'
import { type Account, type BFF, type MerchantsContentProps, type RawBFF, type Resource } from '~/lib/types'

import { accountSelector } from '../../helpers/account-selector'

import { accountDecorator } from './account-decorator'
import { organizationsDecorator } from './organizations-decorator'
import { userDecorator } from './user-decorator'

export function bffDecorator(rawBff: RawBFF, usersStoneCodes: MerchantsContentProps[], resources: Resource[]): BFF {
  const { user, accounts, userKycRequests, organizationsKycRequests, users } = rawBff

  const decoratedUser = userDecorator({
    user,
    accountScopes: accountScopes(`user:${user.userId}`),
    stoneCode: stoneCode(user.cpf),
    assessment: userKycRequests
  })

  const decoratedOrgs = organizationsDecorator(rawBff, usersStoneCodes, resources)

  return {
    accounts: accounts.map(account => {
      return accountDecorator({
        account,
        owner: findAccountOwner(decoratedUser, decoratedOrgs, account)
      }) as Account
    }),
    organizations: decoratedOrgs,
    organizationsKycRequests: organizationsKycRequests?.data,
    user: decoratedUser,
    userKycRequests,
    users: users?.data.map(currentUser => {
      return userDecorator({
        user: currentUser,
        accountScopes: accountScopes(`user:${currentUser.userId}`),
        stoneCode: stoneCode(currentUser.cpf)
      })
    }),
    stoneCodes: usersStoneCodes
  }

  function stoneCode(document: string) {
    return usersStoneCodes.find(item => document === unmask(item.document))
  }

  function accountScopes(id: string) {
    const paymentAccount = accountSelector(accounts as Account[], id)
    const paymentAccountResource = findResourceById(resources, String(paymentAccount?.id))
    return paymentAccountResource?.scopes.map(x => x.name)
  }
}
