import { useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 *
 * @example
 * ```tsx
 * const ref = useRef(null)
 * useClickOutside(ref, () => console.log('clicked outside'))
 * return <div ref={ref}>Hello World</div>
 * ```
 */
export function useClickOutside(ref: React.RefObject<HTMLElement>, handler: (event: MouseEvent | TouchEvent) => void) {
  useEffect(() => {
    function listener(event: MouseEvent | TouchEvent) {
      const el = ref?.current

      if (!el || el.contains(event.target as Node)) {
        return
      }

      handler(event)
    }

    window.addEventListener('mousedown', listener)
    window.addEventListener('touchstart', listener)

    return () => {
      window.removeEventListener('mousedown', listener)
      window.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
