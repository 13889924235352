import { type AxiosResponse } from 'axios'

type MultiplePromiseHandlerStrategy = 'fulfilled' | 'settled'

export class MultiPromiseHandler<
  T extends Record<string, any>,
  S extends MultiplePromiseHandlerStrategy = MultiplePromiseHandlerStrategy
> {
  private _promises: [keyof T, Promise<T[keyof T]>, unknown][]
  private _strategy: MultiplePromiseHandlerStrategy

  static async extractAxiosResponse<T>(response: Promise<AxiosResponse<T>>): Promise<T> {
    return response.then(res => res.data)
  }

  constructor({ strategy }: { strategy?: S } = {}) {
    this._promises = []
    this._strategy = strategy || 'fulfilled'
  }

  addPromise(key: keyof T, promise: Promise<T[typeof key]>, defaultValue?: S extends 'settled' ? unknown : never) {
    this._promises.push([key, promise, defaultValue])
  }

  private async all<P>(values: Iterable<P | PromiseLike<P>>): Promise<T> {
    const results = await Promise.all(values)

    return results.reduce((acc, curr, index) => {
      const [key] = this._promises[index]

      return {
        ...acc,
        [key]: curr
      }
    }, {} as T)
  }

  private async allSettled<P>(values: Iterable<P | PromiseLike<P>>): Promise<T> {
    const results = await Promise.allSettled(values)

    return results.reduce((acc, curr, index) => {
      const [key, , fallback] = this._promises[index]

      return {
        ...acc,
        [key]: curr.status === 'fulfilled' ? curr.value : fallback
      }
    }, {} as T)
  }

  async execute() {
    const promises = this._promises.map(([, promise]) => promise)
    return this._strategy === 'settled' ? await this.allSettled(promises) : await this.all(promises)
  }
}
