export enum OfferEnum {
  notAcquired = 'não contratado',
  withLimit = 'com limite disponível',
  withoutLimit = 'sem limite disponível'
}

type GetRevolvingCreditOfferTypeProps = {
  type: 'followUp' | 'offer' | undefined
  amountLimit: number
  contractedAmount?: number
}
const getRevolvingCreditOfferType = ({
  amountLimit,
  type,
  contractedAmount
}: GetRevolvingCreditOfferTypeProps): OfferEnum => {
  const offerConditions = {
    [OfferEnum.notAcquired]: !contractedAmount && type === 'offer',
    [OfferEnum.withLimit]: !!amountLimit && amountLimit !== 0 && type === 'followUp',
    [OfferEnum.withoutLimit]: amountLimit === 0 && type === 'followUp'
  }

  return (
    (Object.keys(offerConditions).find(key => offerConditions[key as OfferEnum]) as OfferEnum) || OfferEnum.notAcquired
  )
}

export default getRevolvingCreditOfferType
