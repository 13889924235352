import { type NextPage } from 'next'

import { useHeimdall } from '~/domains/platform/core/heimdall/use-heimdall'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { CleanPageContainer } from '~/ui/containers/clean-page-container'

import { type Entity, type PaymentAccount, type Subject, type WithDeusExMachinaProps } from '../deus-ex-machina/types'
import { identifierListFactory } from '../helpers/identifier-list-factory'

interface ExtractFeatureFlagsFromBffProps {
  currentAccount: PaymentAccount
  entity: Entity
  subject: Subject
}

function extractFeatureFlagsFromBff(propsFromBFF: ExtractFeatureFlagsFromBffProps): string[] {
  const entitiesNames = ['subject', 'entity', 'currentAccount']
  const doesntHaveAllTheProps = !entitiesNames.every(key => key in propsFromBFF)

  let finalArray: string[] = []

  if (doesntHaveAllTheProps) return finalArray

  const { currentAccount, subject, entity } = propsFromBFF

  if (currentAccount) {
    finalArray = finalArray.concat(currentAccount.enabledFeatures)
  }

  if (entity) {
    finalArray = finalArray.concat(entity.enabledFeatures)
  }

  if (subject) {
    finalArray = finalArray.concat(subject.enabledFeatures)
  }

  return finalArray
}

export function withHeimdallSetFeatureFlags(Page: NextPage<WithDeusExMachinaProps>) {
  function HeimdallSetFeatureFlags({ subject, entity, ...rest }: WithDeusExMachinaProps) {
    const identifierList = identifierListFactory({ subject, entity, account: entity?.paymentAccount })

    const { isReady } = useHeimdall({
      identifierList,
      bffEnabledFeatures: extractFeatureFlagsFromBff({ currentAccount: entity?.paymentAccount, subject, entity })
    })

    if (isReady) {
      return <Page subject={subject} entity={entity} {...rest} />
    }

    return (
      <CleanPageContainer>
        <Loader />
      </CleanPageContainer>
    )
  }

  HeimdallSetFeatureFlags.displayName = `withHeimdallSetFeatureFlags(${Page.displayName})`

  return HeimdallSetFeatureFlags
}
