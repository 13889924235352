import { type ReactNode } from 'react'
import { Icon } from '@dlpco/ginga-stone'

import { Flex, Text } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'

import { CardWithBackground, Container, IconWrapper } from './styles'

export interface CardAccountProps {
  title: string | ReactNode
  subtitle: string | ReactNode
  icon?: ReactNode
  onClick?: () => void
  background?: string
  color?: string
}

export const CardAccount = (props: CardAccountProps) => {
  const { title, subtitle, icon, onClick, background, color } = props

  return (
    <Container data-testid="item">
      <div role="button" onClick={onClick}>
        <CardWithBackground background={background} color={color}>
          <Flex alignItems="center" flexDirection="row">
            <If condition={Boolean(icon)}>
              <IconWrapper>{icon}</IconWrapper>
            </If>
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              <Flex flexDirection="column" alignItems="flex-start">
                <Text data-testid="title" className="title">
                  {title}
                </Text>
                <Text data-testid="subtitle" className="subtitle">
                  {subtitle}
                </Text>
              </Flex>
              <Flex flexDirection="column" alignItems="flex-end">
                <Icon use="chevron-right-outline" data-testid="chevron" className="chevron" />
              </Flex>
            </Flex>
          </Flex>
        </CardWithBackground>
      </div>
    </Container>
  )
}
