import { useToggle } from 'react-use'

import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import { Choose } from '~/domains/platform/lib/utilities-components'

import { BalanceCard } from './components/balance-card.component'
import { MainMenu } from './components/main-menu.component'
import { SidebarActionButtonSwitchAccount, SidebarSwitchAccount } from './components/switch-account.component'

export function Sidebar({
  entity,
  subject
}: {
  entity: Entity<'acquirer' | 'banking'>
  subject: Subject<'stone_account_resources'>
}) {
  const [isSwappingAccount, toggleSwappingAccount] = useToggle(false)

  return (
    <Choose>
      <Choose.When condition={isSwappingAccount}>
        <SidebarSwitchAccount entity={entity} subject={subject} callback={toggleSwappingAccount} />
      </Choose.When>
      <Choose.Otherwise>
        <SidebarActionButtonSwitchAccount entity={entity} callback={toggleSwappingAccount} />

        <BalanceCard entity={entity} />
        <MainMenu entity={entity} />
      </Choose.Otherwise>
    </Choose>
  )
}
