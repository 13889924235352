import { type AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import camelCase from 'lodash.camelcase'

import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { createOutboundPix } from '~/domains/banking/transfer/services/create-outbound-pix'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/types'
import { httpWithChallenge } from '~/domains/platform/infra/http/http-with-challenge'
import { dynamicTemplate } from '~/lib/helpers/utils/dynamic-template'
import {
  type AccountFee,
  type BRCode,
  type OutboundPixPayments,
  type PixDynamicWithDueDatePaymentInvoices,
  type PixPaymentInvoices,
  type PixPaymentStaticInvoices
} from '~/lib/types'

interface CreatePixPaymentStaticInvoices {
  accountId: string
  amount?: number
  key?: string
  transactionId?: string
  additionalInformation?: string
}

interface AccountPixPaymentInvoicesFees {
  accountId: string
  newFeeFormat?: boolean
}

interface CreatePixPaymentDynamicInvoices {
  accountId: string
  key: string
  transactionId: string
  amount?: number
  additionalData?: {
    name: string
    value: string
  }[]
  requestForPayer: string | null
}

interface CreatePixPaymentDynamicWithDueDateInvoices extends CreatePixPaymentDynamicInvoices {
  customer: {
    name: string
    document: string
  }
  expirationDate: string
  paymentLimitPeriodDays: number
  discount: {
    type: string
    calculationMode: string
    dateDiscounts: {
      date: string
      fixedValue: number | null
      proportionalValue: {
        coefficient: number
        exponent: number
      } | null
    }[]
  } | null
  interest: {
    type: string
    period: string
    proportionalValue: {
      coefficient: number
      exponent: number
    }
  } | null
  fine: {
    type: string
    proportionalValue: {
      coefficient: number
      exponent: number
    }
  } | null
}

export function readBRCode(
  brcode: string,
  account: PaymentAccount,
  paymentDate?: Date | string
): Promise<AxiosResponse<BRCode>> {
  return httpClientBanking<BRCode>({
    method: 'POST',
    url: '/pix/outbound_pix_payments/brcodes',
    data: {
      brcode,
      paymentDate: paymentDate ? dayjs(paymentDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
      ownerAccount: account.ownerId
    }
  })
}

function extractInfoFromBRCodeFromAnyType(brcode: BRCode, key: string) {
  const brcodeType = camelCase(brcode.type)

  return (brcode as any)[brcodeType][key]
}

type CreateOutboundPixByBRCodePayload = {
  brcode: string
  account: PaymentAccount
  scheduledTo?: string | Date
}

export async function createOutboundPixByBRCode(
  payload: CreateOutboundPixByBRCodePayload,
  idempotency: string
): Promise<[AxiosResponse<OutboundPixPayments>, AxiosResponse<BRCode>]> {
  const { brcode, account, scheduledTo } = payload
  const brCodeResponse = await readBRCode(brcode, account, scheduledTo)
  const { data: qrCodeData } = brCodeResponse

  const createOutboundPixResponse = await createOutboundPix(
    {
      account,
      key: extractInfoFromBRCodeFromAnyType(qrCodeData, 'key') as string,
      amount: (extractInfoFromBRCodeFromAnyType(qrCodeData, 'amount') as number) || null,
      transactionId:
        qrCodeData.type !== 'static' ? extractInfoFromBRCodeFromAnyType(qrCodeData, 'transactionId') : null,
      scheduledTo: scheduledTo || null
    },
    idempotency
  )

  return [createOutboundPixResponse, brCodeResponse]
}

export async function createPixPaymentStaticInvoices(payload: CreatePixPaymentStaticInvoices, idempotency: string) {
  return await httpClientBanking<PixPaymentStaticInvoices>({
    url: '/pix_payment_static_invoices',
    method: 'POST',
    headers: { 'x-stone-idempotency-key': idempotency },
    data: payload
  })
}

export async function createPixPaymentDynamicInvoices(payload: CreatePixPaymentDynamicInvoices, idempotency: string) {
  return await httpClientBanking<PixPaymentInvoices>({
    url: '/pix_payment_invoices',
    method: 'POST',
    headers: { 'x-stone-idempotency-key': idempotency },
    data: payload
  })
}

export async function createPixPaymentDynamicWithDueDateInvoices(
  payload: CreatePixPaymentDynamicWithDueDateInvoices,
  idempotency: string
) {
  return await httpClientBanking<PixDynamicWithDueDatePaymentInvoices>({
    url: '/pix_payment_invoices_with_due_date',
    method: 'POST',
    headers: { 'x-stone-idempotency-key': idempotency },
    data: payload
  })
}

export async function getAccountPixPaymentInvoiceFees({
  accountId,
  newFeeFormat = true
}: AccountPixPaymentInvoicesFees) {
  const url = dynamicTemplate('/accounts/${accountId}/fees/pix_payment_invoice', { accountId })

  return await httpClientBanking<AccountFee>({
    url,
    method: 'GET',
    params: {
      new_fee_format: newFeeFormat
    }
  })
}

interface CreatePixKey {
  accountId: string
  keyType?: string
  key?: string
  participantIspb: string
}

export function createPixKey({ accountId, keyType, key, participantIspb }: CreatePixKey, idempotency: string) {
  const url = dynamicTemplate('/pix/${accountId}/entries', { accountId })

  return httpWithChallenge({
    config: {
      url: url,
      method: 'POST',
      headers: { 'x-stone-idempotency-key': idempotency },
      data: {
        keyType: keyType,
        key: key,
        participantIspb: participantIspb
      }
    },
    client: httpClientBanking
  })
}

interface DeletePixKey {
  accountId?: string
  keyId?: string
}

export function deletePixKey({ accountId, keyId }: DeletePixKey, idempotency: string) {
  const url = dynamicTemplate('/pix/${accountId}/entries/${keyId}', { accountId, keyId })

  return httpWithChallenge({
    config: {
      url: url,
      method: 'DELETE',
      headers: { 'x-stone-idempotency-key': idempotency },
      data: {
        reason: 'user_requested'
      }
    },
    client: httpClientBanking
  })
}
