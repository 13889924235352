import { identitiesListFactory } from '~/lib/helpers/auth/identities-list-factory'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'
import { type Identity, type Organization, type User } from '~/lib/types'

interface IdentitySelectorProps {
  organizations: Organization[]
  user: User
  users: User[]
}

export function identitySelector({ organizations = [], users = [], user }: IdentitySelectorProps): Identity {
  const { defaultIdentity, identityId: cookiesIdentityId } = isomorphicCookies.getAll()

  const identities = identitiesListFactory({ organizations, subject: user, users })

  const currentIdentity =
    identities.find(identity => identity.id === cookiesIdentityId) ||
    identities.find(identity => identity.id === defaultIdentity) ||
    identities.find(identity => Object.values(identity.metadata.roles).find(x => x)) ||
    identities.find(identity => identity.metadata.kycStatus === 'approved') ||
    identities.find(identity => identity.approvalStatus === 'limited') ||
    identities[0]

  return currentIdentity || user // fallback for when there's no valid identity
}
