import styled, { css } from 'styled-components'
import { ifNotProp, ifProp, theme } from 'styled-tools'

import { Box, Flex } from '../../design-system'

export const StyledTextButton = styled(Box)`
  width: 100%;
  padding: 1.25rem 1.5rem;
  cursor: pointer;
  border-bottom: 1px solid ${theme('colors.border.disabled')};
  &:hover {
    background-color: ${theme('colors.neutralOverlay')};
  }
`

export const StyledTitleBox = styled(Box)`
  width: 90%;
  > :nth-child(1) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const StyledBox = styled(Box)`
  padding: 0.75rem 0.75rem 3rem 0.75rem;
`

export const SpecialButton = styled.button<{ disabled?: boolean }>`
  background-color: ${theme('colors.neutralOverlay')};
  border: 0;
  border-radius: ${theme('radii.circle')};
  color: ${ifProp('disabled', theme('colors.mediumGray2', 'inherit'))};
  cursor: ${ifNotProp('disabled', 'pointer')};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${theme('fontSizes.small')};
  justify-content: center;
  outline-color: ${theme('colors.white')};
  padding: 1rem;
  margin: 0 0 0.5rem 0;
  width: 56px;
  height: 56px;
  position: relative;
  span {
    svg {
      :last-child {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(30%) translateY(-30%);
      }
      :first-child {
        position: relative;
        transform: none;
        font-size: ${theme('fontSizes.medium')};

        & > * {
          fill: ${ifProp('disabled', theme('colors.mediumGray2', 'inherit'))};
        }
      }
    }
  }

  ${ifNotProp(
    'disabled',
    css`
      &:hover {
        background-color: ${theme('colors.neutralOverlayHover')};
      }
    `
  )}
`
export const WrapperSpecialButton = styled(Flex)<{ disabled?: boolean }>`
  p {
    ${ifProp(
      'disabled',
      css`
        color: ${theme('colors.mediumGray')};
      `
    )}
  }
`

export const ActionWrapper = styled(Box)`
  padding: 1.5rem;
  border-bottom: 1px solid ${theme('colors.border.disabled')};
  line-height: 1rem;
`
