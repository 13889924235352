import { Bucket } from '~/domains/platform/lib/bucket'
import { type BucketKeyMappings } from '~/domains/platform/lib/bucket/types'

import { type CreditBucketKeyMappings } from './types'

class CreditBucket {
  private constructor() {}

  static set<T extends keyof CreditBucketKeyMappings>(key: T, data: BucketKeyMappings[T]) {
    return Bucket.session.set(key, data)
  }

  static get<T extends keyof CreditBucketKeyMappings>(key: T): BucketKeyMappings[T] | undefined {
    return Bucket.session.get(key)
  }

  static remove(key: keyof CreditBucketKeyMappings) {
    Bucket.session.remove(key)
  }
}

export { CreditBucket }
