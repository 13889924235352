import { httpWithChallenge } from '~/domains/platform/infra/http/http-with-challenge'
import { type IntegrationsResourceType } from '~/lib/types'

import { httpClientStoneAccount } from '../../shared/infra/http-client-stone-account'

interface CancelIntegrationProps {
  subjectId: string
  resourceId: string
  resourceType: IntegrationsResourceType
}

export function cancelIntegration({ subjectId, resourceId, resourceType }: CancelIntegrationProps) {
  const params = {
    subject_id: subjectId,
    resource_id: resourceId,
    resource_type: resourceType
  }

  return httpWithChallenge({
    config: {
      method: 'POST',
      url: '/me/integrations/revoke',
      params
    },
    client: httpClientStoneAccount
  })
}
