import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { useQueryRequest } from '~/ui/hooks/utils'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

import { type MainMenuRevolvingCredit } from '../entities'

const fifteenMinutes = 15 * (60 * 1000) // 15 mins

async function fetchCreditHome({ document }: { document: string }) {
  return await httpClientCredit<MainMenuRevolvingCredit>({
    url: `experience-bff-service/api/web/v1/home/global?include_product_type=Revolving&include_product_type=Loan`,
    method: 'GET',
    params: { document }
  })
}

export function useRevolvingCredit(
  { document }: { document: string },
  queryOptions?: UseQueryRequestOptions<MainMenuRevolvingCredit>
) {
  const { data: response, ...restQuery } = useQueryRequest<MainMenuRevolvingCredit>(
    ['useRevolvingCredit'],
    () => fetchCreditHome({ document }),
    {
      ...queryOptions,
      staleTime: fifteenMinutes
    }
  )

  const data = response?.data

  return {
    data,
    ...restQuery
  }
}
