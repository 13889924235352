import { Stack } from '@dlpco/fluid-layout'
import { Button, Card, CardBody, CardHeader, Heading, Text } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'

export function ResetPinCard() {
  return (
    <Box>
      <Card>
        <CardHeader>
          <Heading size="small" weight="semi" color="neutralHigh">
            ESQUECI MINHA SENHA
          </Heading>
        </CardHeader>
        <CardBody removeSpacing="top">
          <Flex width="100%">
            <Stack space="1.5rem">
              <Text color="neutral">Confira as orientações de como trocar sua senha pelo aplicativo.</Text>
              <Flex flexDirection="column" gap="1rem">
                <Button onClick={() => BlackBird.travelTo('/troca-de-pin')} color="neutral">
                  Conferir orientações
                </Button>
              </Flex>
            </Stack>
          </Flex>
        </CardBody>
      </Card>
    </Box>
  )
}
